import IMask, { pipe } from 'imask';
export { default as IMask, PIPE_TYPE, pipe } from 'imask';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, inject, forwardRef, ElementRef, Renderer2, PLATFORM_ID, EventEmitter, Directive, Input, Output, Pipe, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, COMPOSITION_BUFFER_MODE } from '@angular/forms';

class DefaultImaskFactory {
    create(el, opts) {
        return IMask(el, opts);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: DefaultImaskFactory, deps: [], target: i0.ɵɵFactoryTarget.Injectable }); }
    static { this.ɵprov = i0.ɵɵngDeclareInjectable({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: DefaultImaskFactory, providedIn: 'root' }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: DefaultImaskFactory, decorators: [{
            type: Injectable,
            args: [{ providedIn: 'root' }]
        }] });

const IMASK_FACTORY = new InjectionToken('IMASK_FACTORY', { providedIn: 'root', factory: () => inject(DefaultImaskFactory) });

const MASKEDINPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IMaskDirective),
    multi: true,
};
const DEFAULT_IMASK_ELEMENT = (elementRef) => elementRef.nativeElement;
class IMaskDirective {
    constructor() {
        this.onTouched = () => { };
        this.onChange = () => { };
        this._viewInitialized = false;
        this._composing = false;
        this._writing = false;
        this._elementRef = inject(ElementRef);
        this._renderer = inject(Renderer2);
        this._factory = inject(IMASK_FACTORY);
        this._platformId = inject(PLATFORM_ID);
        this._compositionMode = inject(COMPOSITION_BUFFER_MODE, { optional: true }) ?? !this._isAndroid();
        this.imaskElement = DEFAULT_IMASK_ELEMENT;
        this.accept = new EventEmitter();
        this.complete = new EventEmitter();
    }
    get element() {
        return this.imaskElement(this._elementRef, this);
    }
    get maskValue() {
        if (!this.maskRef)
            return (this.element?.value || '');
        if (this.unmask === 'typed')
            return this.maskRef.typedValue;
        if (this.unmask)
            return this.maskRef.unmaskedValue;
        return this.maskRef.value;
    }
    set maskValue(value) {
        if (this.maskRef) {
            if (this.unmask === 'typed')
                this.maskRef.typedValue = value;
            else if (this.unmask)
                this.maskRef.unmaskedValue = value;
            else
                this.maskRef.value = value;
        }
        else {
            this._renderer.setProperty(this.element, 'value', value);
        }
    }
    ngAfterViewInit() {
        if (this.imask)
            this.initMask();
        this._viewInitialized = true;
    }
    ngOnChanges(changes) {
        if (changes['elementRef'] && !this.imaskElement)
            this.imaskElement = DEFAULT_IMASK_ELEMENT;
        if (!changes['imask'] || !this._viewInitialized)
            return;
        if (this.imask) {
            if (this.maskRef)
                this.maskRef.updateOptions(this.imask);
            else {
                this.initMask();
                this.onChange(this.maskValue);
            }
        }
        else {
            this.destroyMask();
        }
    }
    destroyMask() {
        if (this.maskRef) {
            this.maskRef.destroy();
            delete this.maskRef;
        }
    }
    ngOnDestroy() {
        this.destroyMask();
        this.accept.complete();
        this.complete.complete();
    }
    beginWrite(value) {
        this._writing = true;
        this._writingValue = value;
    }
    endWrite() {
        this._writing = false;
        return this._writingValue;
    }
    writeValue(value) {
        value = (value == null && this.unmask !== 'typed' ? '' : value);
        if (this.maskRef) {
            this.beginWrite(value);
            this.maskValue = value;
            this.endWrite();
        }
        else {
            this._renderer.setProperty(this.element, 'value', value);
            this._initialValue = value;
        }
    }
    _onAccept() {
        const value = this.maskValue;
        // if value was not changed during writing don't fire events
        // for details see https://github.com/uNmAnNeR/imaskjs/issues/136
        if (this._writing && value === this.endWrite())
            return;
        this.onChange(value);
        this.accept.emit(value);
    }
    _onComplete() {
        this.complete.emit(this.maskValue);
    }
    initMask() {
        this.maskRef = this._factory.create(this.element, this.imask)
            .on('accept', this._onAccept.bind(this))
            .on('complete', this._onComplete.bind(this));
        if (this._initialValue != null)
            this.writeValue(this._initialValue);
        delete this._initialValue;
    }
    setDisabledState(isDisabled) {
        this._renderer.setProperty(this.element, 'disabled', isDisabled);
    }
    registerOnChange(fn) { this.onChange = fn; }
    registerOnTouched(fn) { this.onTouched = fn; }
    _handleInput(value) {
        // if mask is attached all input goes throw mask
        if (this.maskRef)
            return;
        if (!this._compositionMode || (this._compositionMode && !this._composing)) {
            this.onChange(value);
        }
    }
    _compositionStart() { this._composing = true; }
    _compositionEnd(value) {
        this._composing = false;
        this._compositionMode && this._handleInput(value);
    }
    _isAndroid() {
        return isPlatformBrowser(this._platformId) && /android (\d+)/.test(navigator.userAgent.toLowerCase());
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskDirective, deps: [], target: i0.ɵɵFactoryTarget.Directive }); }
    static { this.ɵdir = i0.ɵɵngDeclareDirective({ minVersion: "14.0.0", version: "16.2.12", type: IMaskDirective, isStandalone: true, selector: "[imask]", inputs: { imask: "imask", unmask: "unmask", imaskElement: "imaskElement" }, outputs: { accept: "accept", complete: "complete" }, host: { listeners: { "input": "_handleInput($event.target.value)", "blur": "onTouched()", "compositionstart": "_compositionStart()", "compositionend": "_compositionEnd($event.target.value)" } }, providers: [MASKEDINPUT_VALUE_ACCESSOR], exportAs: ["imask"], usesOnChanges: true, ngImport: i0 }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskDirective, decorators: [{
            type: Directive,
            args: [{
                    selector: '[imask]',
                    standalone: true,
                    exportAs: 'imask',
                    host: {
                        '(input)': '_handleInput($event.target.value)',
                        '(blur)': 'onTouched()',
                        '(compositionstart)': '_compositionStart()',
                        '(compositionend)': '_compositionEnd($event.target.value)'
                    },
                    providers: [MASKEDINPUT_VALUE_ACCESSOR],
                }]
        }], propDecorators: { imask: [{
                type: Input
            }], unmask: [{
                type: Input
            }], imaskElement: [{
                type: Input
            }], accept: [{
                type: Output
            }], complete: [{
                type: Output
            }] } });

/*
 * Transforms value through mask
 * Takes mask and optionally `from` and `to` pipe types.
 * Usage:
 *   value | imask:MASK_OR_MASKED:opt_from:opt_to
 * Example:
 *   {{ 2 | imask:mask }}
*/
class IMaskPipe {
    transform(...args) {
        return pipe(...args);
    }
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskPipe, deps: [], target: i0.ɵɵFactoryTarget.Pipe }); }
    static { this.ɵpipe = i0.ɵɵngDeclarePipe({ minVersion: "14.0.0", version: "16.2.12", ngImport: i0, type: IMaskPipe, isStandalone: true, name: "imask" }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskPipe, decorators: [{
            type: Pipe,
            args: [{ name: 'imask', standalone: true }]
        }] });

class IMaskModule {
    static { this.ɵfac = i0.ɵɵngDeclareFactory({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskModule, deps: [], target: i0.ɵɵFactoryTarget.NgModule }); }
    static { this.ɵmod = i0.ɵɵngDeclareNgModule({ minVersion: "14.0.0", version: "16.2.12", ngImport: i0, type: IMaskModule, imports: [IMaskDirective, IMaskPipe], exports: [IMaskDirective, IMaskPipe] }); }
    static { this.ɵinj = i0.ɵɵngDeclareInjector({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskModule }); }
}
i0.ɵɵngDeclareClassMetadata({ minVersion: "12.0.0", version: "16.2.12", ngImport: i0, type: IMaskModule, decorators: [{
            type: NgModule,
            args: [{
                    imports: [IMaskDirective, IMaskPipe],
                    exports: [IMaskDirective, IMaskPipe]
                }]
        }] });

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_IMASK_ELEMENT, DefaultImaskFactory, IMASK_FACTORY, IMaskDirective, IMaskModule, IMaskPipe, MASKEDINPUT_VALUE_ACCESSOR };

