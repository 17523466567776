import { BaseMultiLanguageModel } from '../domain-models/base-multi-language-model';
import { CollectionViewModel } from './collection-view-model';
import { MultiLanguageIdentityInterface } from '../domain-models/multi-language-identity.interface';
import { BaseMultiLanguageViewModel } from './base-multi-language-view-model';
import { Subject } from 'rxjs';
import { IdentityInterface } from '@nts/std/interfaces';

export abstract class MultiLanguageCollectionViewModel<
    TMlViewModel extends BaseMultiLanguageViewModel<TMlModel, TMlIdentity>,
    TMlModel extends BaseMultiLanguageModel<TMlIdentity>,
    TMlIdentity extends IdentityInterface & MultiLanguageIdentityInterface> extends CollectionViewModel<TMlViewModel, TMlModel, TMlIdentity> {

    languageChanged: Subject<void> = new Subject();

    override async postInit(): Promise<void> {
        await super.postInit();
        this.collectionChanged.subscribe(() => {
            this.languageChanged.next();
        });
    }

    // Deve rimanere almeno un elemento
    protected override canDeleteSelectedRows() {
        const can = super.canDeleteSelectedRows();
        return can && this.length > 1;
    }    
}
