import { NTSReflection } from '@nts/std/utility';
import { BaseIdentity } from '../domain-models/base-identity';
import { ClassConstructor } from '@nts/std/serialization';
import { IdentityInterface } from '@nts/std/interfaces';

const IDENTITY_TYPE_META_DATA_IDENTIFICATION_KEY = 'identityTypeDecoratorMetaDataKey';

export function IdentityTypeDecorator<T extends IdentityInterface>(identityType: ClassConstructor<T>) {
    return (target: any) => {
        if (
            !((identityType as any)?.prototype instanceof BaseIdentity)
        ) {
            throw new Error(
                'You used an invalid type (' +
                Object.getPrototypeOf((identityType as any).prototype)?.constructor?.name +
                ') in ' +
                IdentityTypeInspector.DECORATOR_NAME +
                ' with class ' +
                target.prototype?.constructor?.name +
                ': allowed types are derived class of BaseIdentity and BaseNMAssociationIdentity'
            );
        }
        Reflect.defineMetadata(IDENTITY_TYPE_META_DATA_IDENTIFICATION_KEY, identityType, target);
    };
}

export class IdentityTypeInspector {

    static META_DATA_KEY = IDENTITY_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@IdentityTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            IDENTITY_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            IDENTITY_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
