import { AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BigNumber } from '@nts/std/types';
import { LogService } from '@nts/std/utility';
import { AgEditorComponent } from 'ag-grid-angular';
import { NumericTextBoxComponent } from '../../../../../src/lib/components/controls/core/numeric-text-box/numeric-text-box.component';
import { BaseNumericPropertyViewModel } from '../../../../../src/lib/view-models/base-type/base-numeric-property-view-model';
import { GridComponent } from '../../grid.component';
import { ColumnCellEditorParams } from '../column_cell_editor_params.interface';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';

@Component({
    selector: 'nts-numeric-text-box-editor-cell',
    styleUrls: ['./numeric-cell-editor.component.scss'],
    standalone: true,
    imports: [
        NumericTextBoxComponent,
        AsyncPipe
    ],
    template: `
        <nts-numeric-text-box
            #textBox
            (keyDown)="onKeyDown($event)"
            (onInputChange)="onInputChange($event)"
            (onFinishEditing)="onFinishEditing()"
            [listenClickOutside]="true"
            [updateOnBlur]="false"
            [updateOnFocusOut]="false"
            [showErrorBorder]="false"
            [propertyViewModel]="propertyViewModel"
            [initialValue]="initialValue"
            [selectAllOnFocus]="false"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [modelUpdate]="false">
        </nts-numeric-text-box>
    `,
})
export class NumericCellEditorComponent implements AgEditorComponent, AfterViewInit, OnInit {

    @Input()
    params: ColumnCellEditorParams;

    @ViewChild('textBox', { static: true })
    textBox: NumericTextBoxComponent;

    returnedValue: BigNumber | null | '';
    highlightAllOnFocus = true;
    initialValue: BigNumber | null | ''

    private cancelBeforeStart = false;
    private internalPropertyViewModel: BaseNumericPropertyViewModel;

    get propertyViewModel(): BaseNumericPropertyViewModel {
        if (!this.internalPropertyViewModel) {
            this.internalPropertyViewModel = this.params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    return o[i];
                }, this.params.data) as BaseNumericPropertyViewModel;
        }
        return this.internalPropertyViewModel;
    }

    get cellWidth() {
        return this.params.eGridCell.clientWidth;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

    get gridContext(): GridComponent {
        return this.params.context;
    }

    ngOnInit(): void {
        if (this.params) {
            this.agInit(this.params);
        }
    }

    agInit(params: ColumnCellEditorParams): void {
        this.params = params;

        this.setInitialValue(params);

        // only start edit if key pressed is a number, not a letter or enter
        this.cancelBeforeStart =
            params.eventKey &&
            '1234567890'.indexOf(params.eventKey) < 0 &&
            params.eventKey !== KEY_ENTER &&
            params.eventKey !== KEY_F2 &&
            params.eventKey !== KEY_BACKSPACE &&
            params.eventKey !== KEY_DELETE;
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

        setTimeout(() => {
            this.textBox.focus();
            if (this.highlightAllOnFocus) {
                this.textBox.input.select();

                this.highlightAllOnFocus = false;
            } else {
                // when we started editing, we want the carot at the end, not the start.
                // this comes into play in two scenarios: a) when user hits F2 and b)
                // when user hits a printable character, then on IE (and only IE) the carot
                // was placed after the first character, thus 'apply' would end up as 'pplea'
                const length = this.textBox.input.value
                    ? this.textBox.input.value.length
                    : 0;
                if (length > 0) {
                    const radixPoint = this.propertyViewModel.decimalLimit > 0 ? length - this.propertyViewModel.decimalLimit - 1 : length;
                    this.textBox.input.setSelectionRange(radixPoint, radixPoint);
                }
            }

            this.textBox.focus();
        });
    }

    getValue(): any {
        return this.returnedValue;
    }

    setInitialValue(params: ColumnCellEditorParams): void {

        this.initialValue = null;
        let highlightAllOnFocus = true;

        if (params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE) {
            // Se spingo can o backspace

            // Passo stringa vuota come start value
            this.initialValue = '';

            // Non evidenzio la cella
            highlightAllOnFocus = true;

            this.returnedValue = this.initialValue;

        } else if (params.eventKey === KEY_ENTER || params.eventKey === KEY_F2) {
            // Se spingo invio o F2

            // Recupero il valore del pvm
            this.initialValue = null;

            // Evidenzio la cella
            highlightAllOnFocus = true;

            this.returnedValue = params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o[i];
                    }
                    return o[i];
                }, this.params.data
            )


        } else if (params.eventKey) {

            // Se passo un numero, inizio con quello
            this.initialValue = new BigNumber(params.eventKey);

            // Non evidenzio la cella
            highlightAllOnFocus = false;

            this.returnedValue = this.initialValue;
        } else {

            // Negli altri casi visualizzo quello che c'è gia nella cella
            this.initialValue = null;
            this.returnedValue = params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o[i];
                    }
                    return o[i];
                }, this.params.data
            )
        }
        this.highlightAllOnFocus = highlightAllOnFocus;
    }

    onInputChange(e: any) {
        this.returnedValue = e.target.value;
    }

    blur($event) {
        this.propertyViewModel.validate();
    }

    onFinishEditing() {
        this.params.stopEditing();
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    onKeyDown(event: KeyboardEvent): void {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (
            !this.finishedEditingPressed(event) &&
            !this.isKeyPressedNumeric(event) &&
            !event.ctrlKey
        ) {
            if (event.preventDefault) { event.preventDefault(); }
        }

        if (this.isUpOrDown(event)) {
            this.navigateToNextCell(event);
        }
    }

    // for testing
    async setValue(newValue: any) {
        this.textBox.input.value = newValue;
        (this.textBox.input as any).inputmask.refreshValue = true;
        this.textBox.onInput(null);
    }

    private isCharNumeric(charStr: string): boolean {
        return !!/\d/.test(charStr);
    }

    private isMinus(charStr: string): boolean {
        return charStr === '-';
    }

    private isNumberSeparator(charStr: string): boolean {
        return charStr === '.' || charStr === ',';
    }

    private isKeyPressedNumeric(event: KeyboardEvent): boolean {
        return this.isCharNumeric(event.key) || this.isMinus(event.key) || this.isNumberSeparator(event.key);
    }

    private deleteOrBackspace(event: KeyboardEvent) {
        return (
            [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1
        );
    }

    private isLeftOrRight(event: KeyboardEvent) {
        return [KEY_LEFT, KEY_RIGHT].indexOf(event.key) > -1;
    }

    private isUpOrDown(event: KeyboardEvent) {
        return [KEY_UP, KEY_DOWN].indexOf(event.key) > -1;
    }

    private finishedEditingPressed(event: KeyboardEvent) {
        return event.key === KEY_ENTER || event.key === KEY_TAB;
    }

    private navigateToNextCell(event: KeyboardEvent) {

        const fc = this.gridContext.api.getFocusedCell();
        LogService.debug('focused cell rowindex ' + fc.rowIndex, 'focused celll column ' + fc.column);
        if (event.key === KEY_DOWN) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex + 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (event.key === KEY_UP) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex - 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (event.key === KEY_LEFT) {
            this.gridContext.api.tabToPreviousCell();
        } else if (event.key === KEY_RIGHT) {
            this.gridContext.api.tabToNextCell();
        }
    }
}
