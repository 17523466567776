import { BaseMessageInterface } from "@nts/std/interfaces";
import { Expose } from "@nts/std/serialization";

export class BaseMessage implements BaseMessageInterface {

    @Expose()
    code!: string;

    @Expose()
    description!: string;

    @Expose()
    propertyName!: string;

    @Expose()
    objectName?: string;

    @Expose()
    uuid?: string;

    get type(): string {
        return '';
    }
}
