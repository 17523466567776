import { CoreModel } from './core-model';
import { InternalDecorator } from './decorators/internal.decorator';
import { LongOpResultTypeInspector } from '../decorators/long-op-result-type.decorator';
import { LongOpParametersTypeInspector } from '../decorators/long-op-parameters-type.decorator';
import { Expose, Type } from '@nts/std/serialization';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export abstract class BaseLongOpModel<
    TIdentity extends IdentityInterface,
    TParams extends ModelInterface<TIdentity>,
    TResult extends ModelInterface<TIdentity>>
    extends CoreModel<TIdentity> {

    private _params: TParams;

    private _longOpParametersType: any;

    @Expose()
    @InternalDecorator({ displayNameKey: 'BaseLongOpModel_Params' })
    @Type((options) => {
        return (options?.newObject as BaseLongOpModel<TIdentity, TParams, TResult>)?._longOpParametersType;
    })
    get params(): TParams {
        return this.getValueForInternal<TParams>(
            (value) => {
                this._params = value;
            },
            this._params,
            'params',
            this._longOpParametersType
        );
    }

    set params(value: TParams) {
        this.setValueForInternal(() => {
            this._params = value;
        }, value, 'params');
    }

    private _result: TResult;

    private _longOpResultType: any;

    @Expose()
    @InternalDecorator({ displayNameKey: 'BaseLongOpModel_Result' })
    @Type((options) => {
        return (options?.newObject as BaseLongOpModel<TIdentity, TParams, TResult>)?._longOpResultType;
    })
    get result(): TResult {
        return this.getValueForInternal<TResult>(
            (value) => {
                this._result = value;
            },
            this._result,
            'result',
            this._longOpResultType
        );
    }

    set result(value: TResult) {
        this.setValueForInternal(() => {
            this._result = value;
        }, value, 'result');
    }

    constructor() {
        super();

        this._longOpParametersType = LongOpParametersTypeInspector.getValue(this);
        if (this._longOpParametersType === undefined) {
            throw new Error(
                `MetaData ${LongOpParametersTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpParametersTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._longOpResultType = LongOpResultTypeInspector.getValue(this);
        if (this._longOpResultType === undefined) {
            throw new Error(
                `MetaData ${LongOpResultTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpResultTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }
    }
}
