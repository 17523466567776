import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { NumericMetaData } from '../../meta-data/numeric-meta-data';
import { NumeralService } from '@nts/std/utility';
import { takeUntil } from 'rxjs/operators';
import { NtsMaskedNumber } from '../../components/controls/core/base/base-numeric-box/base-numeric-box.component';
import { BaseError } from '../../messages/base-error';
import { BaseValidator } from '../../domain-models/decorators/commons/base-validator';
import { ValidationArguments } from 'class-validator';
import { NumberValidator } from '../../domain-models/decorators/number.decorator';
import { ValidationErrorCodes } from '../../resources/validation-error-codes';
import { RangeValidator } from '../../domain-models/decorators/validations/range-validation';
import { NumericPropertyViewModelInterface } from '@nts/std/interfaces';
import { BigNumber } from '@nts/std/types';
import { ModelInterface } from '@nts/std/interfaces';

export abstract class BaseNumericPropertyViewModel<TModelType = BigNumber|number> extends PropertyViewModel<TModelType> implements NumericPropertyViewModelInterface  {

    static MAX_DECIMAL_DIGIT = 28;
    static MAX_INTEGER_DIGIT = 28;
    static MAX_DIGIT = 28;
    static MIN_DIGIT_FOR_BIGNUMBER = 15;
    
    static MAX_VALUE_INT32 = 2147483647;
    static MIN_VALUE_INT32 = -2147483647;

    private internalMinValue: null | number | BigNumber = null;

    /**
     * Se impostato agisce direttamente sull'input senza consentire l'inserimento di un valore più basso di quello definito
     */
    get minValue(): number | BigNumber | null {
        return this.internalMinValue;
    }
    set minValue(value: number | BigNumber | null) {
        if (this.internalMinValue !== value) {
            this.internalMinValue = value;
            this.onPropertyChanged('minValue');
        }
    }

    protected bigNumber = null;
    
    private internalMinValueValidation: null | number | BigNumber = null;

    /**
     * Se impostato visualizza un errore se viene inserito un valore più basso di quello definito
     * Non viene considerato se è già stata impostata la property minValue
     */
    get minValueValidation(): number | BigNumber | null {
        return this.internalMinValueValidation;
    }
    set minValueValidation(value: number | BigNumber | null) {
        if (this.internalMinValueValidation !== value) {
            this.internalMinValueValidation = value;
            this.validate();
            this.onPropertyChanged('minValueValidation');
        }
    }

    private internalMaxValue: null | number | BigNumber = null;

    /**
     * Se impostato agisce direttamente sull'input senza consentire l'inserimento di un valore più alto di quello definito
     */
    get maxValue(): number | BigNumber | null {
        return this.internalMaxValue;
    }
    set maxValue(value: number | BigNumber | null) {
        if (this.internalMaxValue !== value) {
            this.internalMaxValue = value;
            this.onPropertyChanged('maxValue');
        }
    }

    private internalDecimalLimit: null | number = null;

    /**
     * Limite di decimali massimi
     */
    get decimalLimit(): number {
        return this.internalDecimalLimit;
    }
    set decimalLimit(value: number) {
        if (this.internalDecimalLimit !== value) {
            this.internalDecimalLimit = value;
            this.onPropertyChanged('decimalLimit');
        }
    }

    private internalIntegerLimit: null | number = null;

    /**
     * Limite di interi massimi
     */
    get integerLimit(): number {
        return this.internalIntegerLimit;
    }
    set integerLimit(value: number) {
        if (this.internalIntegerLimit !== value) {
            this.internalIntegerLimit = value;
            this.onPropertyChanged('integerLimit');
        }
    }

    private internalIsBigNumber: boolean = false;

    /**
     * E' un BigNumber
     */
    get isBigNumber(): boolean {
        return this.internalIsBigNumber;
    }
    set isBigNumber(value: boolean) {
        if (this.internalIsBigNumber !== value) {
            this.internalIsBigNumber = value;
            this.onPropertyChanged('isBigNumber');
        }
    }

    private internalMaxValueValidation: null | number | BigNumber = null;

    /**
     * Se impostato visualizza un errore se viene inserito un valore più basso di quello definito
     * Non viene considerato se è già stata impostata la property maxValue
     */
    get maxValueValidation(): number | BigNumber | null {
        return this.internalMaxValueValidation;
    }
    set maxValueValidation(value: number | BigNumber | null) {
        if (this.internalMaxValueValidation !== value) {
            this.internalMaxValueValidation = value;
            this.validate();
            this.onPropertyChanged('maxValueValidation');
        }
    }

    private internalFormat: null | string = null;

    /**
     * Formato utilizzato per il formattedValue senza BigNumber
     */
    get format(): null | string {
        return this.internalFormat;
    }
    set format(value: string | null) {
        if (this.internalFormat !== value) {
            this.internalFormat = value;
            this.onPropertyChanged('format');
        }
    }

    private internalUseThousandSeparator = false;

    override get value(): TModelType {
        return this.getValue();
    }
    override set value(updatedValue: TModelType) {
        this.setValueAsync(updatedValue);
    }

    override getValue(): TModelType {
        const value = super.getValue();
        return this.isBigNumber ? (value != null ? this.bigNumber(value): null) : (value ?? null);
    }

    get useThousandSeparator(): boolean {
        return this.internalUseThousandSeparator;
    }
    set useThousandSeparator(value: boolean) {
        if (this.internalUseThousandSeparator !== value) {
            this.internalUseThousandSeparator = value;
            this.onPropertyChanged('useThousandSeparator');
        }
    }
    static getDecimalDigits(propertyName: string, integerLimit: number | null, decimalLimit: number | null): number {
        if (integerLimit != null && decimalLimit != null && (integerLimit + decimalLimit) > this.MAX_DIGIT) {
            throw new Error("I metadati della propery " + propertyName + " superano i limite massimo di " + this.MAX_DIGIT);
        }
        if (integerLimit == null && decimalLimit != null) {
            return decimalLimit < this.MAX_DECIMAL_DIGIT ? decimalLimit : this.MAX_DECIMAL_DIGIT;
        }
        if (integerLimit != null && decimalLimit == null) {
            return 0;
        }
        if (integerLimit == null && decimalLimit == null) {
            return 0;
        }
        if (integerLimit != null && decimalLimit != null) {
            return decimalLimit < this.MAX_DECIMAL_DIGIT ? decimalLimit : this.MAX_DECIMAL_DIGIT;
        }
        return null;
    }

    static getIntegerDigits(propertyName: string, integerLimit: number, decimalLimit: number): number {
        if (integerLimit != null && decimalLimit != null && (integerLimit + decimalLimit) > this.MAX_DIGIT) {
            throw new Error("I metadati della propery " + propertyName + " superano i limite massimo di " + this.MAX_DIGIT);
        }
        if (integerLimit == null && decimalLimit != null) {
            return (this.MAX_DIGIT - decimalLimit) > this.MAX_INTEGER_DIGIT ? this.MAX_INTEGER_DIGIT : (this.MAX_DIGIT - decimalLimit);
        }
        if (integerLimit != null && decimalLimit == null) {
            return integerLimit < this.MAX_INTEGER_DIGIT ? integerLimit : this.MAX_INTEGER_DIGIT;
        }
        if (integerLimit == null && decimalLimit == null) {
            return this.MAX_INTEGER_DIGIT < this.MAX_DIGIT ? this.MAX_INTEGER_DIGIT : this.MAX_DIGIT;
        }
        if (integerLimit != null && decimalLimit != null) {
            return integerLimit < this.MAX_INTEGER_DIGIT ? integerLimit : this.MAX_INTEGER_DIGIT;
        }
        return null;
    }

    constructor(
        initInfo: PropertyViewModelInitializationInfo,
        valueIsNullable: boolean
    ) {
        super(initInfo, true);

        const metaData = initInfo.propertyMetaData as NumericMetaData;

        let decimalLimit = this.decimalLimit;
        let maxValue = this.maxValue;
        let minValue = this.minValue;
        let integerLimit = this.integerLimit;
        let useThousandSeparator = this.useThousandSeparator;

        if (metaData.isDecimal === false) {
            maxValue = BaseNumericPropertyViewModel.MAX_VALUE_INT32;
            minValue = BaseNumericPropertyViewModel.MIN_VALUE_INT32;
            integerLimit = metaData.maxIntegerPrecision > 0 ? metaData.maxIntegerPrecision : BaseNumericPropertyViewModel.MAX_VALUE_INT32.toString().length
            decimalLimit = 0;
        } else {
            integerLimit = BaseNumericPropertyViewModel.getIntegerDigits(this.propertyName, metaData.maxIntegerPrecision, metaData.maxDecimalPrecision);
            decimalLimit = BaseNumericPropertyViewModel.getDecimalDigits(this.propertyName, this.integerLimit, metaData.maxDecimalPrecision);
        }            

        if (decimalLimit > 0) {
            useThousandSeparator = true;
        }

        this.bigNumber = BigNumber.clone({
            EXPONENTIAL_AT: [-200, 200],
            DECIMAL_PLACES: decimalLimit ?? 0
        })

        this.isBigNumber = (decimalLimit + integerLimit) >= BaseNumericPropertyViewModel.MIN_DIGIT_FOR_BIGNUMBER
        this.minValueValidation = metaData.minValue;
        this.maxValueValidation = metaData.maxValue;
        this.useThousandSeparator = useThousandSeparator;
        this.decimalLimit = decimalLimit;
        this.maxValue = maxValue;
        this.minValue = minValue;
        this.integerLimit = integerLimit;        

        this.updateFormat();

        this.propertyChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe((args) => {
            if (args.propertyName == 'useThousandSeparator') {
                this.updateFormat();
            }
        });
    }

    override getModelErrors(propertyName: string): BaseError[] {

        const allPropertyErrors: BaseError[] = super.getModelErrors(propertyName);

        const minValue = this.minValueValidation ?? this.minValue;
        const maxValue = this.maxValueValidation ?? this.maxValue;

        // Se è valorizzatoa livello di pvm
        if (minValue != null || maxValue != null) {
            // Pulisco gli errori aggiunti dal domain model (quelli derivanti dai decoratori)
            const cleanedPropertyErrors = allPropertyErrors.filter(
                (propertyError) => propertyError.code !== ValidationErrorCodes.NumberMustBeGreater &&
                    propertyError.code !== ValidationErrorCodes.NumberMustBeLess &&
                    propertyError.code !== ValidationErrorCodes.NumberOutOfRange
            )

            const validator = new RangeValidator(minValue, maxValue, this.metadataShortDescription);
            if (!validator.validate(this.value)) {
                const e = new BaseError();
                e.description = validator.errorMessage;
                e.code = validator.messageCode;
                e.propertyName = this.propertyName;
                cleanedPropertyErrors.push(e);
            }

            return cleanedPropertyErrors;
        }

        return allPropertyErrors;
    }

    static getDecimalSeparator() {
        this.initCurrentNumericLocale();
        return (NumeralService.Current as any).localeData(NumeralService.Current.locale()).delimiters.decimal;
    }

    static initCurrentNumericLocale() {

        if (this.currentNumeralLocale != null) {
            return;
        }

        // load correct culture file
        const language = navigator.language;

        try {
            if (NumeralService.Current.locales[language.toLowerCase()] == null) {
                // Fallback su italiano
                NumeralService.Current.locale('it');
            } else {
                NumeralService.Current.locale(language.toLowerCase());
            }

        } catch (e) {
            // Fallback su italiano in caso di errrore
            NumeralService.Current.locale('it');
        }
    }

    static currentNumeralLocale = null;

    parseFromString(value: string): number {

        const mask = new NtsMaskedNumber({
            mask: Number,
            scale: this.decimalLimit ? this.decimalLimit : null,
            signed: false,
            padFractionalZeros: true,
            normalizeZeros: true,
            overwrite: 'shift',
            max: this.maxValue,
            min: this.minValue,
            thousandsSeparator: this.useThousandSeparator ? BaseNumericPropertyViewModel.getThousandSeparator() : '',
            radix: BaseNumericPropertyViewModel.getDecimalSeparator(),
            mapToRadix: [BaseNumericPropertyViewModel.getDecimalSeparator()],
            nullable: true
        } as any)

        mask.value = value;
        return mask.typedValue;
    }

    static getThousandSeparator() {
        this.initCurrentNumericLocale();
        return (NumeralService.Current as any).localeData(NumeralService.Current.locale()).delimiters.thousands;
    }

    static getNumericFormat(integerLimit: number, decimalLimit: number, useThousandSeparator: boolean, propertyName: string = ''): string {
        let format;
        if (decimalLimit === 0 || !decimalLimit) {
            // è un int
            format = useThousandSeparator ? '0,0' : '0';
        } else {
            // è un decimal
            format = useThousandSeparator ? '0,0.' : '0.';
            if (decimalLimit) {
                const digits = BaseNumericPropertyViewModel.getDecimalDigits(propertyName, integerLimit, decimalLimit);
                format += '0'.repeat(digits);
            }
        }
        return format;
    }

    updateFormat() {
        this.format = BaseNumericPropertyViewModel.getNumericFormat(this.integerLimit, this.decimalLimit, this.useThousandSeparator, this.propertyName);
    }

    override validateCustomPropertyViewModel(baseErrors: BaseError[]): boolean {
        let result = true;
        if (this.parent) {
            const propertyMetaData: NumericMetaData = BaseValidator.getPropertyMetaData(this.parent, this.propertyName);

            const args: Partial<ValidationArguments> = {
                constraints: [NumberValidator.getDecoratorDataFromPropertyMetaData(propertyMetaData)],
                object: this.parent,
                property: this.propertyName
            }

            const validator = new NumberValidator<TModelType>();
            result = validator.validate(this.value, args as any);

            if (result === false) {
                const messageError = new BaseError();
                messageError.propertyName = this.propertyName;
                messageError.objectName = this.parent.classType;
                messageError.description = validator.errorMessage;
                messageError.code = 'VALIDATION_ERROR';
                baseErrors.push(messageError);
            }
        }

        return result;
    }

    override async setValueAsync(updatedValue: TModelType) {
        if (this._isCustom && this.customSetter == null) {
            throw new Error('You must implement value setter');
        }
        if (!this.isNullable) {
            if (updatedValue == null) {
                updatedValue = this.faultBackValue;
            }
        }
        if (this.isBigNumber && updatedValue != null) {
            updatedValue = this.bigNumber(updatedValue);
        }        
        if (this.value !== updatedValue) {
            if (this.customSetter != null) {
                await this.customSetter(updatedValue);
                if (this.isCustom) {
                    this.validate()
                } else if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }
                this.onPropertyChanged(this.bindedValuePropertyName);
            } else {
                // quano faccio il sync model scatta onPropertyChanged partendo dal modello
                this.syncModel(updatedValue);
                this.validate();
            }
            // TODO verificare se si può rimuovere
            this.formattedValue$.next(this.formattedValue);
            
            // Aggiorno il valore per gli ascoltatori
            this.value$.next(this.value);
        }
    }

    override setModel(model: ModelInterface) {
        super.setModel(model);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        const resetValue = (useDefaultValue ? (this.isBigNumber ? new BigNumber(0) : 0) : this.faultBackValue) as TModelType
        await this.setValueAsync(resetValue);
    }

    setDefaultValueFromLayoutMetaData(defaultValue: string): void {
      if (typeof defaultValue === 'number') {
        this._layoutDefaultValue = defaultValue;
      }
    }
}

