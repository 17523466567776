import { NTSReflection } from '@nts/std/utility';
import { NotString } from '../types';


const LONG_OP_PARAMETERS_TYPE_META_DATA_IDENTIFICATION_KEY = 'longOpParametersTypeDecoratorMetaDataKey';

export function LongOpParametersTypeDecorator<T>(longOpParametersType: NotString<T>) {
    return (target: any) => {
        Reflect.defineMetadata(LONG_OP_PARAMETERS_TYPE_META_DATA_IDENTIFICATION_KEY, longOpParametersType, target);
    };
}

export class LongOpParametersTypeInspector {

    static META_DATA_KEY = LONG_OP_PARAMETERS_TYPE_META_DATA_IDENTIFICATION_KEY;
    static DECORATOR_NAME = '@LongOpParametersTypeDecorator';

    static isApplied(subject: any): boolean {
        return NTSReflection.hasClassMetadata(
            LONG_OP_PARAMETERS_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }

    static getValue(subject: any): any {
        return NTSReflection.getClassMetadata(
            LONG_OP_PARAMETERS_TYPE_META_DATA_IDENTIFICATION_KEY, subject);
    }
}
