import { StringDecorator } from '../decorators/string.decorator';
import { NumberDecorator } from '../decorators/number.decorator';
import { Expose } from "@nts/std/serialization";
import { AuthTokenDtoInterface } from '@nts/std/interfaces';

export class AuthTokenDto implements AuthTokenDtoInterface {

    @Expose()
    @StringDecorator({ displayNameKey: 'access_token', maxLength: 255, isRequired: true })
    access_token: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'refresh_token', maxLength: 255, isRequired: true })
    refresh_token: string;

    @Expose()
    @StringDecorator({ displayNameKey: 'token_type', maxLength: 255, isRequired: true })
    token_type: string;

    @Expose()
    @NumberDecorator({ displayNameKey: 'expires_in', maxValue: 10 })
    expires_in: number;

    // TODO da verificare
    // @Expose()
    // @StringDecorator({ displayNameKey: 'redirect_url', maxLength: 255 })
    // redirect_url: string;
}
