import { ModelTypeNameInspector } from './decorators/model-type-name.decorator';
import { EventEmitter } from '@angular/core';
import intersection from 'lodash-es/intersection';
import { DomainModelCollection } from './domain-model-collection';
import { DomainModelCollectionInterface } from './domain-model-collection.interface';
import { ValidatableModel } from './validatable-model';
import { ExternalInspector } from './decorators/external.decorator';
import { IdentityTypeInspector } from '../decorators/identity-type.decorator';
import { AssociationPropertyMetaData, ExternalMetaData, MetaDataUtils } from '../meta-data';
import { GenericsPropertiesTypeInterface, GenericsPropertiesTypeInspector } from '../decorators/generics-properties-type.decorator';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';
import { InternalInspector } from './decorators/internal.decorator';
import { classToPlain, ClassConstructor, Exclude, Expose, Type, TypeHelpOptions } from '@nts/std/serialization';
import { IdentityInterface, ModelInterface, ValidatableModelInterface } from '@nts/std/interfaces';
import { DomainModelState, GenericsPropertiesType } from '@nts/std/types';

@Exclude()
export abstract class CoreModel<TIdentity extends IdentityInterface> extends ValidatableModel
    implements ModelInterface<TIdentity>, GenericsPropertiesTypeInterface {

    // #region Vars
    private _deserializationInProgress = false;
    private _identityBase: TIdentity;
    private _currentState: DomainModelState;

    private readonly _genericsPropertiesType: GenericsPropertiesType<any>;

    isMock = false;

    /**
     * Utile se si vuole forzare il default value
     */
    forceDefaultValue = false;
    emitChanges = true;
    fixUpEnabled = true;

    // isMock = false;
    modelChanged = new EventEmitter<void>();
    propertyChanged = new EventEmitter<string>();
    propertyChanging = new EventEmitter<string>();
    cultureName: string;
    // #endregion

    private internalIdentityType: ClassConstructor<TIdentity>;

    get identityType(): ClassConstructor<TIdentity> {
        return this.internalIdentityType;
    }

    set identityType(value: ClassConstructor<TIdentity>)  {
        this.internalIdentityType = value;
    }

    @Expose()
    @Type((options) => {
        return (options?.newObject as ModelInterface<TIdentity>)?.identityType;
    })
    get currentIdentity(): TIdentity {
        return this.identityBase as TIdentity;
    }

    set currentIdentity(value: TIdentity) {
        if (value != null) {
            value.init(this);
        }
        this.identityBase = value;
    }

    static getGenericPropertyType(propertyName: string) {
        const decoratorData = GenericsPropertiesTypeInspector.getValue(this);
        if (!decoratorData || decoratorData[propertyName] == null) {
            throw new Error(
                `MetaData ${GenericsPropertiesTypeInspector.META_DATA_KEY} not defined. You must use ${GenericsPropertiesTypeInspector.DECORATOR_NAME} in ${this.constructor.name} and define ${propertyName}.`
            );
        } else if (decoratorData[propertyName] == null) {
            throw new Error(
                `Decorator ${GenericsPropertiesTypeInspector.DECORATOR_NAME} found in ${this.constructor.name} but yhou should and define ${propertyName}.`
            );
        }
        return decoratorData[propertyName];
    }

    static getGenericPropertyTypeInTypeDecorator(options: TypeHelpOptions, propertyName: string): any {
        let obj = options.newObject as ModelInterface<IdentityInterface>;
        if (!obj?.getGenericPropertyType) {
            obj = options.object as ModelInterface<IdentityInterface>;
        }
        return obj.getGenericPropertyType(propertyName);
    }

    protected createCurrentIdentity(): TIdentity {
        return new this.identityType(this) as TIdentity;
    }

    constructor() {
        super();

        this.internalIdentityType = IdentityTypeInspector.getValue(this);

        if (this.identityType === undefined) {
            throw new Error(
                `MetaData ${IdentityTypeInspector.META_DATA_KEY} not defined. You must use ${IdentityTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._genericsPropertiesType = GenericsPropertiesTypeInspector.getValue(this);

        this.setState(DomainModelState.New);
    }

    getGenericPropertyType(propertyName: string): GenericsPropertiesType<any> {
        if (!this._genericsPropertiesType || this._genericsPropertiesType[propertyName] == null) {

            const decoratorData = GenericsPropertiesTypeInspector.getValue(this);
            if (!decoratorData || decoratorData[propertyName] == null) {
                throw new Error(
                    `MetaData ${GenericsPropertiesTypeInspector.META_DATA_KEY} not defined. You must use ${GenericsPropertiesTypeInspector.DECORATOR_NAME} in ${this.constructor.name} and define ${propertyName}.`
                );
            } else if (decoratorData[propertyName] == null) {
                throw new Error(
                    `Decorator ${GenericsPropertiesTypeInspector.DECORATOR_NAME} found in ${this.constructor.name} but yhou should and define ${propertyName}.`
                );
            }
            return decoratorData[propertyName];
        }
        return this._genericsPropertiesType[propertyName];
    }

    // #region Equals
    equals(valueToCompare: ModelInterface<TIdentity>): boolean {
        const isEqual = this === valueToCompare;

        if (!isEqual && valueToCompare != null) {
            return this.currentIdentity.equals(valueToCompare.currentIdentity);
        } else {
            return isEqual;
        }
    }
    // #endregion

    // #region Constructor
    // constructor() {
    //     super();
    //
    // }
    // #endregion

    // #region Properties

    /**
     * Identity di tipo base.
     */
    get identityBase(): TIdentity {
        if (this._identityBase == null) {
            this._identityBase = this.createCurrentIdentity();
        }
        return this._identityBase;
    }

    set identityBase(value: TIdentity) {
        this._identityBase = value;
    }

    sessionCurrentCultureTwoLetterISOName(): string {
        return navigator.language.slice(0, 2); // || navigator.userLanguage;
    }

    primaryDefaultCulture(): string {
        return 'it';
    }

    secondaryDefaultCulture(): string {
        return 'en';
    }

    /**
     * Stato corrente del domainmodel
     */
    @Expose()
    get currentState(): DomainModelState {
        return this._currentState;
    }
    set currentState(value: DomainModelState) {
        if (this._deserializationInProgress) {
            this._currentState = value;
        } else {
            throw new Error('Use setState method to change currentState');
        }
    }
    // #endregion

    // #region Utility methods

    // #region ValueForIdentityValue (getter/setter)

    /**
     * Metodo da utilizzare all'interno delle property di un DomainModel che rappresentano l'identity
     * (Devono corrispondere a qulle dell'identity)
     *
     * @template TValue         Tipo della property
     * @param func              Implementazione del getter
     * @param propertyName      Nome della property
     *
     * @returns Valore da restituire nel getter
     */
    protected getValueForIdentityValue<TValue>(func: () => TValue, propertyName: string): TValue {
        return this.getValue(func, propertyName);
    }

    /**
     * Metodo da utilizzare all'interno dei property setter di un DomainModel che rappresentano l'identity
     * (Devono corrispondere a quelle della sua Identity)
     *
     * @template TValue         Tipo della property
     * @param action            Implementazione del setter
     * @param value             Nuovo valore
     * @param propertyName      Nome della property
     */
    protected setValueForIdentityValue<TValue>(
        action: () => void,
        value: TValue,
        propertyName: string
    ): void {
        // Usiamo l'equality comparer affinchè null sia uguale ad undefined
        if (this.getPropertyValue(propertyName) == value) {
            return;
        }
        if (this.emitChanges) {
            this.propertyChanging.emit(propertyName);
        }

        action();
        this.applyChangeState();

        if (this.fixUpEnabled) {
            this.fixUpInternalRelationAssociationPropsForNewDomainModel<TValue>(propertyName, value);
        }

        if (this.emitChanges) {
            this.propertyChanged.emit(propertyName);
        }

    }
    // #endregion

    // #region Value (getter/setter)

    /**
     * Metodo da utilzzare all'interno dei getter di property di tipo diverso da DomainModel o Collection di DomainModel
     *
     * @template TValue         Tipo della property
     * @param func              Implementazione del getter
     * @param propertyName      Nome della property
     *
     * @returns Valore da restituire nel getter
     */
    protected getValue<TValue>(func: () => TValue, propertyName: string) {
        return func();
    }

    /**
     * Metodo da utilizzare all'interno dei setter di property di tipo diverso da DomainModel o Collection di DomainModel
     *
     * @template TValue         Tipo della property
     * @param action            Implementazione del setter
     * @param value             Nuovo valore per la property
     * @param propertyName      Nome della property
     */
    protected setValue<TValue>(
        action: () => void,
        value: TValue,
        propertyName: string
    ): void {
        // Usiamo l'equality comparer affinchè null sia uguale ad undefined
        if (!(this.compareValues(this.getPropertyValue(propertyName), value))) {

            if (this.emitChanges) {
                this.propertyChanging.emit(propertyName);
            }

            try {
                action();
            } catch (ex) {
                if (ex instanceof RangeError) {
                    throw new Error(ex.message + ' nel setter ' + propertyName + ' della classe ' + ModelTypeNameInspector.getValue(this) + ' verifica di aver inserito l\'assegnazione del backing field');
                }
                else {
                    throw ex;
                }
            }

            if (
                !this._deserializationInProgress &&
                this.currentState === DomainModelState.Unchanged
            ) {
                this.setState(DomainModelState.Modified);
            }

            if (this.emitChanges) {
                this.propertyChanged.emit(propertyName);
            }
        }
    }
    // #endregion

    // #region Internal (getter/setter)

    // #region ValueForInternal (getter/setter)

    /**
     * Metodo da utilizzare all'interno dei getter
     *
     * @template TValue         Tipo della property
     * @param func              Implementazione del getter
     * @param value             BackingField che eventualmente sarà istanziato
     * @param propertyName      Nome della property
     * @param internalType      Tipo della property
     *
     * @returns Valore da restituire nel getter
     */
    protected getValueForInternal<
        TValue extends ModelInterface<TIdentity>
    >(
        func: (val: TValue) => void,
        value: TValue,
        propertyName: string,
        internalType: any,
    ): TValue {
        if (value == null) {
            let newValue = value;
            if (this.isMock) {
                newValue = new internalType();
                newValue.setParent(this, propertyName);
            }
            // Necessario per impostare il backing field del getter
            // ATTENZIONE se setValue viene spostato dopo fixUpInternalRelationAssociationPropsForNewEntity si genera un loop
            func(newValue);
            const prop = this.getPropertyNames().find((x) => x === 'parent');

            // TODO confrontarsi con Andrea per capire se è necessaria questa parte
            if (this.fixUpEnabled && prop && this.parent != null && newValue != null) {
                (this.parent as ModelInterface).fixUpInternalRelationAssociationPropsForNewDomainModel(
                    propertyName, newValue
                );
            }
            // FINE TODO
            return newValue;
        }
        return value;
    }
    protected getValueForInternalWithDifferentIdentity<
        TValue extends ModelInterface<TDifferentIdentity>,
        TDifferentIdentity extends IdentityInterface
    >(
        func: (val: TValue) => void,
        value: TValue,
        propertyName: string,
        internalType: any,
    ): TValue {
        if (value == null) {
            let newValue = value;
            if (this.isMock) {
                newValue = new internalType();
                newValue.setParent(this, propertyName);
            }
            // Necessario per impostare il backing field del getter
            // ATTENZIONE se setValue viene spostato dopo fixUpInternalRelationAssociationPropsForNewEntity si genera un loop
            func(newValue);
            const prop = this.getPropertyNames().find((x) => x === 'parent');
            if (prop && this.parent != null && newValue != null) {
                (this.parent as ModelInterface).fixUpInternalRelationAssociationPropsForNewDomainModel(
                    propertyName, newValue
                );
            }
            return newValue;
        }
        return value;
    }

    /**
     * Metodo da utilizzare all'interno dei setter di tipo domain model che fanno parte dell'aggregato
     *
     * @template TValue         Tipo della property
     * @param action            Implementazione del setter
     * @param value             Nuovo valore per la property
     * @param propertyName      Nome della property
     */
    protected setValueForInternal<
        TValue extends ModelInterface<TIdentity>
    >(
        action: () => void,
        value: TValue,
        propertyName: string
    ): void {
        if (value != null) {

            value.setParent(this, propertyName);
            const internalMetaData: InternalRelationMetaData = InternalInspector.getValue(this, propertyName);

            if (internalMetaData != null && internalMetaData.associationProperties.length > 0) {
                internalMetaData.associationProperties.forEach((ass) => {
                    this.associateInternalPrincipalAndDependant(
                        value,
                        MetaDataUtils.toCamelCase(ass.principalPropertyName),
                        MetaDataUtils.toCamelCase(ass.dependentPropertyName)
                    );
                });
            } else {
                const dependentIdentityProps = value.identityBase.getPropertyNames();

                // Recupero dalla classe solo le property che mi interessano, evito quelle senza expose, e le property con il modelTypeName
                const principalIdentityProps = Object.keys(
                    classToPlain(this.identityBase, { strategy: 'excludeAll' })
                );

                const identityProps = intersection(dependentIdentityProps, principalIdentityProps);
                for (const prop of identityProps) {
                    const propValue = this.getPropertyValue(prop);
                    value.setPropertyValue(prop, propValue);
                }
            }

        }

        if (this.emitChanges) {
            this.propertyChanging.emit(propertyName);
        }

        action();
        const entity = value as ModelInterface;
        if (entity?.parent !== undefined) {
            entity.setPropertyValue('parent', this);
        }

        if (this.emitChanges) {
            this.propertyChanged.emit(propertyName);
        }
    }
    protected setValueForInternalWithDifferentIdentity<
        TValue extends ModelInterface<TDifferentIdentity>,
        TDifferentIdentity extends IdentityInterface
    >(
        action: () => void,
        value: TValue,
        propertyName: string
    ): void {
        if (value != null) {

            value.setParent(this, propertyName);
            const internalMetaData: InternalRelationMetaData = InternalInspector.getValue(this, propertyName);

            if (internalMetaData != null && internalMetaData.associationProperties.length > 0) {
                internalMetaData.associationProperties.forEach((ass) => {
                    this.associateExternalPrincipalAndDependant(
                        value,
                        MetaDataUtils.toCamelCase(ass.principalPropertyName),
                        MetaDataUtils.toCamelCase(ass.dependentPropertyName)
                    );
                });
            } else {
                const dependentIdentityProps = value.identityBase.getPropertyNames();

                // Recupero dalla classe solo le property che mi interessano, evito quelle senza expose, e le property con il modelTypeName
                const principalIdentityProps = Object.keys(
                    classToPlain(this.identityBase, { strategy: 'excludeAll' })
                );

                const identityProps = intersection(dependentIdentityProps, principalIdentityProps);
                for (const prop of identityProps) {
                    const propValue = this.getPropertyValue(prop);
                    value.setPropertyValue(prop, propValue);
                };
            }
        }


        if (this.emitChanges) {
            this.propertyChanging.emit(propertyName);
        }

        action();
        const entity = value as ModelInterface;
        if (entity.parent !== undefined) {
            entity.setPropertyValue('parent', this);
        }

        if (this.emitChanges) {
            this.propertyChanged.emit(propertyName);
        }

    }
    // #endregion

    // #region ValueForInternalCollection (getter/setter)

    /**
     * Metodo da utilizzare all'interno dei setter di property di tipo collection di DomainModel
     *
     * @template TCollection    Tipo della collection
     * @template TItem          Tipo contenuto nella collection
     * @param func              Implementazione del getter
     * @param value             BackingField che eventualmente sarà istanziato
     * @param propertyName      Nome della property
     * @param internalType      Tipo contenuto nella collection
     *
     * @returns Valore da restituire nel getter
     */
    protected getValueForInternalCollection<
        TCollection extends DomainModelCollection<TItem, TIdentity>,
        TItem extends ModelInterface<TIdentity>
    >(
        func: (v: TCollection) => void,
        value: TCollection,
        propertyName: string,
        internalType: ClassConstructor<TCollection>,
    ): TCollection {
        if (value == null) {
            value = new internalType(this, propertyName);
            value.setParent(this, propertyName);
        }
        func(value);
        return value;
    }
    protected getValueForInternalCollectionWithDifferentIdentity<
        TCollection extends DomainModelCollection<TItemWithDifferentIdentity, TItemIdentity>,
        TItemWithDifferentIdentity extends ModelInterface<TItemIdentity>,
        TItemIdentity extends IdentityInterface
    >(
        func: (v: TCollection) => void,
        value: TCollection,
        propertyName: string,
        internalType: ClassConstructor<TCollection>,
    ): TCollection {
        if (value == null) {
            value = new internalType(this, propertyName);
            value.setParent(this, propertyName);
        }
        func(value);
        return value;
    }

    /**
     * Metodo da utilizzare all'interno dei setter di property di tipo collection di DomainModel
     *
     * @template TCollection    Tipo della collection
     * @template TItem          Tipo contenuto nella collection
     * @param action            Implementazione del setter
     * @param value             Nuovo valore del setter
     * @param propertyName      Nome della property
     */
    protected setValueForInternalCollection<
        TCollection extends DomainModelCollection<TItem, TIdentity>,
        TItem extends ModelInterface<TIdentity>
    >(
        action: () => void,
        value: TCollection,
        propertyName: string
    ): void {
        if (value != null) {
            value.setParent(this, propertyName);
            value.collectionItems.forEach((item) => {
                this.setValueForInternal(() => { }, item, propertyName);
            });
        }
        if (this.emitChanges) {
            this.propertyChanging.emit(propertyName);
        }

        action();
        this.applyChangeState();

        if (this.emitChanges) {
            this.propertyChanged.emit(propertyName);
        }
    }
    protected setValueForInternalCollectionWithDifferentIdentity<
        TCollection extends DomainModelCollection<TItemWithDifferentIdentity, TItemIdentity>,
        TItemWithDifferentIdentity extends ModelInterface<TItemIdentity>,
        TItemIdentity extends IdentityInterface
    >(
        action: () => void,
        value: TCollection,
        propertyName: string
    ): void {
        if (value != null) {
            value.setParent(this, propertyName);
            value.collectionItems.forEach((item) => {
                this.setValueForInternalWithDifferentIdentity<TItemWithDifferentIdentity, TItemIdentity>(() => { }, item, propertyName);
            });
        }

        if (this.emitChanges) {
            this.propertyChanging.emit(propertyName);
        }

        action();
        this.applyChangeState();

        if (this.emitChanges) {
            this.propertyChanged.emit(propertyName);
        }
    }
    // #endregion

    // #endregion

    // #region ValueForExternal (getter/setter)

    /**
     * Metodo da utilizzare all'interno dei getter di property relative ad External Domain Model
     *
     * @template TValue         Tipo della collection
     * @param func              Implementazione del getter
     * @param propertyName      Nome della property
     * @param externalType      Tipo della collection
     *
     * @returns External Domain Model
     */
    protected getValueForExternal<TValue>(
        func: () => TValue,
        propertyName: string,
        externalType: any
    ): TValue {
        return func();
    }

    getRootModel(model: ValidatableModelInterface = this.parent) {
        if (model?.parent != null) {
            return this.getRootModel(model.parent);
        } else if(model != null){
            return model
        } else{
            return this;
        }
    }

    getCurrentPath(model: ValidatableModelInterface) {
      // if (model?.parent != null) {
      //     return this.getRootModel(model.parent);
      // } else if(model != null){
      //     return model
      // } else{
      //     return this;
      // }
      let path = model.parentPropertyName;
      if (model.parent != null && path?.length > 0) {
        const parentPath = this.getCurrentPath(model.parent)
        if (parentPath?.length > 0) {
          path = [path,parentPath].join('.');
        }
      }
      return path;
    }

    /**
     * Metodo da utilizzare all'interno dei setter di property relative ad External Domain Model
     *
     * @template TValue     Tipo della property
     * @param action        Implementazione del setter
     * @param value         Nuovo valore della property
     * @param propertyName  Nome della property
     */
    protected setValueForExternal<
        TValue extends ModelInterface<TExternalIdentity>,
        TExternalIdentity extends IdentityInterface
    >(
        action: () => void,
        value: TValue,
        propertyName: string
    ): void {

        if (value != null) {
            value.setParent(this, propertyName);
        }


        // TODO: se è un clone non verificare equals
        const oldValue = this.getPropertyValue<TValue>(propertyName);
        if (value?.equals(oldValue) || (value == null && oldValue == null)) {
            return;
        }

        if (this.emitChanges) {
            this.propertyChanging.emit(propertyName);
        }

        action();
        this.applyChangeState();

        if (this.emitChanges) {
            this.propertyChanged.emit(propertyName);
        }

        const externalMetaData: ExternalMetaData = ExternalInspector.getValue(this, propertyName);
        let parentAssociation: AssociationPropertyMetaData[] = [];
        let parentPropertyName: string = null;

        if (externalMetaData?.parentIdentityPropertyPathName?.length > 0 && this._deserializationInProgress === false) {

            const checkPath = 'asd';

            // for (const element of object) {

            // }

            const rootModel = this.getRootModel();
            if(rootModel) {

              const currentPath = this.getCurrentPath(this)

              // internal1_1.propertyName
                // ok

              // sameCollection.propertyName

                // recupero fullPath da qui in sù
                // sameCollection.propertyName2
                // se togliendo l'ultima parte corrisponde con parentIdentityPropertyPathName posso usare il this

              // collection1.internal_1_1.propertyName
              // collection1.colection2.propertyName2


                // Recupero la parentIdentity

                // trasformo il parentIdentityPropertyPathName in camel case
                const camelCaseParentIdentityPropertyPathName =
                  externalMetaData?.parentIdentityPropertyPathName
                    .split('.')
                    .map((v) => MetaDataUtils.toCamelCase(v))
                    .join('.')

                let parentIdentity;

                // se camelCaseParentIdentityPropertyPathName inizia per currentPath vuol dire che posso navigarlo con this
                if (camelCaseParentIdentityPropertyPathName?.startsWith(currentPath)) {

                  // elimino il path in comune
                  const parentIdentityPathForThis = camelCaseParentIdentityPropertyPathName.replace(currentPath + '.', "")
                  parentIdentity = parentIdentityPathForThis.split('.').reduce(
                    (o, i) => {

                        if (i?.length > 0 && o != null) {

                            const res = o[i];

                            if (res?.parent == null) {
                                return i;
                            }

                            return res;
                        }
                        return null;

                    }, this);



                } else {
                  // camelCaseParentIdentityPropertyPathName non iniza per il path corrente quindi navigo partendo dal rootModel
                  parentIdentity = externalMetaData?.parentIdentityPropertyPathName.split('.').reduce(
                    (o, i) => {

                        if (i?.length > 0 && o != null) {

                            const res = o[MetaDataUtils.toCamelCase(i)];

                            if (res?.parent == null) {
                                return i;
                            }

                            return res;
                        }
                        return null;

                    }, rootModel);

                }

                if (parentIdentity != null && parentIdentity?.parent != null &&  parentIdentity?.parentPropertyName?.length > 0) {
                    const parentExternalMetaData: ExternalMetaData = ExternalInspector.getValue(parentIdentity.parent, parentIdentity.parentPropertyName);
                    if (parentExternalMetaData) {
                        parentAssociation = parentExternalMetaData.associationProperties;
                    }
                }
                if (typeof parentIdentity === 'string') {
                    parentPropertyName = parentIdentity;
                }
            }
        }

        if (externalMetaData != null) {

            for (const association of externalMetaData.associationProperties) {
                if (parentAssociation?.length > 0 && parentAssociation.find((pa) => pa.principalPropertyName === association.principalPropertyName) ) {
                    // se l'assocition è una property del parent non la tocco
                } else if (parentPropertyName?.length > 0 && association.principalPropertyName === parentPropertyName) {
                    // se l'assocition è una property del parent non la tocco
                } else {
                    this.associateExternalPrincipalAndDependant(
                        value,
                        MetaDataUtils.toCamelCase(association.principalPropertyName),
                        MetaDataUtils.toCamelCase(association.dependentPropertyName),
                    );
                }
            }
        }
    }
    // #endregion

    private associateExternalPrincipalAndDependant<TValue extends ModelInterface<TExternalIdentity>, TExternalIdentity extends IdentityInterface>(
        newValue: TValue,
        principal: string,
        dependant: string,
    ) {
        if (principal) {
            if (newValue == null) {
                this.setPropertyValue(principal, null);
            } else {
                const innerValue = newValue.getPropertyValue(dependant);
                this.setPropertyValue(principal, innerValue);
            }
        }
    }

    private associateInternalPrincipalAndDependant<TValue extends ModelInterface<TExternalIdentity>, TExternalIdentity extends IdentityInterface>(
        newValue: TValue, principal: string, dependant: string
    ) {
        if (principal) {
            const value = this.getPropertyValue(principal);
            newValue.setPropertyValue(dependant, value);
        }
    }

    /**
     * Metodo per indicare una modifica ed eventualmetne cambiare lo stato corrente.
     */
    private applyChangeState(): void {
        if (!this._deserializationInProgress && this.currentState === DomainModelState.Unchanged) {
            this.setState(DomainModelState.Modified);
        }
    }

    fixUpInternalRelationAssociationPropsForNewDomainModel<TValue>(propertyName: string, value: TValue): void {
        const internals = this.getInternalModelsPropertyNames();

        internals.forEach((propName) => {
            const model = this.getPropertyValue(propName) as ModelInterface<TIdentity>;
            if ((model != null) && (model.getPropertyNames().indexOf(propertyName) > -1)) {
                model.setPropertyValue(propertyName, value);
            }
        });

        const collections = this.getCollectionPropertyNames();
        collections.forEach((collectionName) => {
            const collection = this.getPropertyValue(collectionName) as DomainModelCollectionInterface;
            collection.collectionItems.forEach((item) => {
                item.setPropertyValue(propertyName, value);
            });
        });
    }

    setState(value: DomainModelState) {
        this._currentState = value;
    }

    onDeserializing() {
        this._deserializationInProgress = true;
    }

    onDeserialized() {
        this._deserializationInProgress = false;
    }

    // #endregion

    // TODO Tommy: da implementare
    // clearAllOriginalValues
    // getStringCurrentCultureValue
}
