import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { AutoCompleteRequest } from '../requests/auto-complete-request';
import { FindValuesResponse } from '../responses/find-values-response';
import { ActionApiClient } from './action-api-client';
import { ExternalReaderHttpCommunication } from './external-reader-http-communication';
import { ExternalReaderApiClientInterface } from './external-reader-api-client.interface';
import { FindValuesOptions } from '../domain-models/find-options/find-values-options';
import { RootModelTypeInspector } from './decorators/root-model-type.decorator';
import { WebApiServiceAgent } from './web-api-service-agent';
import { OnlineService } from '@nts/std/utility';
import { ClassConstructor } from '@nts/std/serialization';
import { IdentityInterface } from '@nts/std/interfaces';
import { ModelInterface } from '@nts/std/interfaces';

@Injectable()
export class ExternalReaderApiClient<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends ActionApiClient
    implements ExternalReaderApiClientInterface {

    rootModelType: ClassConstructor<any>;

    constructor(
        agent: WebApiServiceAgent,
        onlineService: OnlineService
    ) {
        super(agent, onlineService);
        this.rootModelType = RootModelTypeInspector.getValue(this);
        if (this.rootModelType === undefined) {
            throw new Error(
                `MetaData ${RootModelTypeInspector.META_DATA_KEY} not defined. You must use ${RootModelTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }
    }

    protected createExternalReaderHttpCommunication(): ExternalReaderHttpCommunication {
        return new ExternalReaderHttpCommunication(this.agent);
    }

    getExternal<TExternalModel extends ModelInterface<TExternalIdentity>, TExternalIdentity extends IdentityInterface>(
        request: GetByIdentityRequest<TExternalIdentity>,
        externalEntityTypeName: string,
        externalEntityType: any
    ): Observable<GetByIdentityResponse<TExternalModel, TExternalIdentity>> {
        const communication = this.createExternalReaderHttpCommunication();
        return communication.getExternal(request, externalEntityTypeName, externalEntityType);
    }

    autoCompleteExternal(
        request: AutoCompleteRequest,
        externalDomainModelTypeName: string,
    ): Observable<FindValuesResponse> {
        const communication = this.createExternalReaderHttpCommunication();
        return communication.autoCompleteExternal(request, externalDomainModelTypeName);
    }

    getMinimal(
        request: GetByIdentityRequest<TIdentity>
    ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
        const communication = this.createExternalReaderHttpCommunication();
        return communication.getMinimal(request, this.rootModelType);
    }

    override findValues(
        entityToLookUp: string, findOptions: FindValuesOptions, entityToLookUpFullName: string, rootModelName: string
    ): Observable<FindValuesResponse> {
        const communication = this.createExternalReaderHttpCommunication();
        return communication.findValues(entityToLookUp, findOptions, entityToLookUpFullName, rootModelName);
    }
}
