import { PropertyViewModel } from '../../../lib/view-models/property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { StringMetaData } from '../../meta-data/string-meta-data';
import { AllowedChars } from '../../meta-data/allowed-chars';
import { AccessMode } from '../../meta-data/access-mode.enum';
import IMask from 'imask';
import { BaseValidator } from '../../domain-models/decorators/commons/base-validator';
import { StringValidator } from '../../domain-models/decorators/string.decorator';
import { ValidationArguments } from 'class-validator';
import { BaseError } from '../../messages/base-error';
import { BaseTextBoxMaskSettings } from '../../components';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { BigNumber } from '@nts/std/types';

export enum CharacterCasing {
    Normal = 0,
    Lower = 1,
    Upper = 2
}

export class StringPropertyViewModel extends PropertyViewModel<string> {

    metadataMaxLength: number;
    trimSx = true;
    trimDx = true;
    metadataAllowedCharacters: AllowedChars;
    metadataCharacterCasing: CharacterCasing;
    maskSettings: BaseTextBoxMaskSettings;

    override get value(): string {
        // Casistica in cui abbiamo il decoratore custom property view model impostato
        if (this._isCustom && this.customGetter == null) {
            throw new Error(MessageResourceManager.Current.getMessage('MissingGetterError'));
        }
        // Casistica in cui viene ridefinito il custom getter
        // Di default viene definito in tutti gli external view model
        return this.getValue();
    }
    override set value(updatedValue: string) {
        this.setValueAsync(updatedValue);
    }

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);

        this.isNullable = true;
        this.metadataMaxLength = (initInfo.propertyMetaData as StringMetaData)?.maxLen;
        this.metadataAllowedCharacters = (initInfo.propertyMetaData as StringMetaData)?.allowedCharacters;

        this.metadataCharacterCasing = CharacterCasing.Normal;
        if (this.metadataAllowedCharacters === AllowedChars.UppercaseLettersOnly ||
            this.metadataAllowedCharacters === AllowedChars.UppercaseLettersOrNumbersOnly) {
            this.metadataCharacterCasing = CharacterCasing.Upper;
        } else if (this.metadataAllowedCharacters === AllowedChars.LowercaseLettersOnly ||
            this.metadataAllowedCharacters === AllowedChars.LowercaseLettersOrNumbersOnly) {
            this.metadataCharacterCasing = CharacterCasing.Lower;
        }
    }

    override validateCustomPropertyViewModel(baseErrors: BaseError[]): boolean {
        let result = true;
        if (this.parent) {
            const propertyMetaData: StringMetaData = BaseValidator.getPropertyMetaData(this.parent, this.propertyName);

            const args: Partial<ValidationArguments> = {
                constraints: [StringValidator.getDecoratorDataFromPropertyMetaData(propertyMetaData)],
                object: this.parent,
                property: this.propertyName
            }

            const validator = new StringValidator();
            result =  validator.validate(this.value, args as any);

            if (result === false) {
                const messageError = new BaseError();
                messageError.propertyName = this.propertyName;
                messageError.objectName = this.parent.classType;
                messageError.description = validator.errorMessage;
                messageError.code = 'VALIDATION_ERROR';
                baseErrors.push(messageError);
            }
        }

        return result;
    }

    override async setValueAsync(updatedValue: string) {
        if (this._isCustom && this.customSetter == null) {
            throw new Error(MessageResourceManager.Current.getMessage('MissingSetterError'));
        }

        if (updatedValue) {
            if (this.trimDx) {
                updatedValue = updatedValue.replace(/\s+$/, '');
            }
            if (this.trimSx) {
                updatedValue = updatedValue.replace(/^\s+/, '');
            }
        }

        // Check undefined and null
        if (this.value != updatedValue) {
            if (this.customSetter != null) {
                await this.customSetter(updatedValue);

                if (this.isCustom) {
                    this.validate()
                } else if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }

                this.onPropertyChanged(this.bindedValuePropertyName);
            } else {
                // quano faccio il sync model scatta onPropertyChanged partendo dal modello
                this.syncModel(updatedValue);

                if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }
            }
            this.formattedValue$.next(this.formattedValue);
            this.value$.next(this.value);
        }
    }

    setDefaultValueFromLayoutMetaData(value: string | number | BigNumber |boolean): void {
      if (typeof value === 'string') {
        this._layoutDefaultValue = value;
      }
    }

    private maskInstance = null;

    getMaskedValue(overrideValue = null) {
        if (this.maskSettings && this.maskInstance == null) {
            this.maskInstance = IMask.createMask(this.maskSettings as any);
        }
        if (this.maskSettings && this.maskInstance && (overrideValue != null || this.value != null)) {
            return this.maskInstance.resolve(overrideValue ?? this.value);
        }

        return overrideValue ?? '';
    }

    getFormattedValue(updatedValue: string) {
        if (this.securityAccess === AccessMode.Deny) {

            return MessageResourceManager.Current.getMessage('StringFormattedValue_Forbidden');
        }
        // Uso Nullish Coalescing - richiede Typescrypt >= 3.7
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#nullish-coalescing
        if (this.maskSettings) {
            return this.getMaskedValue(updatedValue);
        }

        return updatedValue ?? '';
    }
}
