import moment from 'moment';
import { TransformFnParams, TransformationType } from '@nts/std/serialization';

export class DateTime {

    static formatToString(value: Date): string {
        return value ? moment(value).format('YYYY-MM-DDTHH:mm:ss') : null;
    }

    static formatToStringLocalHumanReadable(value: DateTime, format = 'DD-MM-YYYY'): string {
        return value ? moment(value).format(format) : null;
    }

    static getLocalFormat(showTime = true) {
        let inputFormat = moment().localeData().longDateFormat('L')
        if (showTime === true) {
            inputFormat += ' HH:mm';
        }
        return inputFormat;
    }

    static formatFromString(value: string, customFormat = 'YYYY-MM-DD HH:mm:ss'): Date {

        if (value == null) {
            return null;
        }

        // Check if is a date
        if (value as any instanceof Date) {
            return value as any;
        }

        // 2018-09-27 10:00:00.0 +02:00 ma anche 2018-10-02T00:00:00-03:00"
        const dateString = value.substring(0, 18);
        return moment(dateString, customFormat).toDate();
    }

    static transform(params: TransformFnParams, propertyName?: string) {
        const property = propertyName ?? params.key;

        // 2018-09-27 10:00:00.0 +02:00
        if (params.type === TransformationType.CLASS_TO_PLAIN) {
            return DateTime.formatToString(params.obj[property]);
        } else {
            return DateTime.formatFromString(params.value);
        }
    }
}
