import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { BehaviorSubject, startWith, Subject, switchMap } from 'rxjs';
import { AccessMode } from '../../../../src/lib/meta-data/access-mode.enum';
import { MessageResourceManager } from '../../../../src/lib/resources/message-resource-manager';
import { CustomHeaderParamsInterface } from './custom-header-params.interface';

@UntilDestroy()
@Component({
    styleUrls: ['./custom-header.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        SvgIconComponent,
        NgIf,
        AsyncPipe
    ],
    selector: 'nts-custom-header',
    template: `
        <div class="ag-cell-label-container" role="presentation">
            <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" [attr.hidden]="(gridFilters$ | async) ? null : true" (click)="onMenuClick()" #filterButton>
              <span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>
            </span>

            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order" *ngIf="sortIndex != null">({{sortIndex + 1}})</span>

            <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"  [class.is-active]="descSort === 'active'" [attr.hidden]="(gridFilters$ | async) ? null : true" (click)="onSortRequested('desc', $event)">
                <svg-icon key="arrow-down">
                </svg-icon>
            </span>

            <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" [class.is-active]="ascSort === 'active'" [attr.hidden]="(gridFilters$ | async) ? null : true" (click)="onSortRequested('asc', $event)">
                <svg-icon key="arrow-up">
                </svg-icon>
            </span>

            <span data-ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon"></span>

            <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>

            <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span class="locked-field" *ngIf="params?.columnInfo?.securityAccess != null">
                    <svg-icon key="locked"
                        [popper]="securityTooltipDescription"
                        [popperDisabled]="!securityTooltipDescription || securityTooltipDescription?.length == 0"
                        [popperTrigger]="ngxPopperjsTriggers.hover"
                        [popperDelay]="1000"
                        [popperApplyClass]="securityTooltipClass"
                        [popperPlacement]="ngxPopperjsPlacements.TOP"
                        [popperPreventOverflow]="false"
                        [popperHideOnScroll]="true"
                        popperAppendTo="body">
                    </svg-icon>
                </span>

                <span data-ref="eText" class="ag-header-cell-text" role="columnheader" [style.color]="headerColor()">{{params?.displayName}}</span>
                <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
            </div>

        </div>
    `
})
export class CustomHeaderComponent implements IHeaderAngularComp, OnDestroy {
    params: CustomHeaderParamsInterface;
    // sorted: string;
    isGhost = false;
    isExternalCode = false;
    securityTooltipDescription: string;
    securityTooltipClass: string;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    eMenuButton: any;
    paramsRefreshed$ = new Subject<void>();
    gridFilters$ = new BehaviorSubject<boolean>(false);
    ascSort = 'inactive';
    descSort = 'inactive';
    noSort = 'inactive';
    sortIndex: number | null = null;

    @ViewChild('filterButton', { static: true }) filterButton: ElementRef;

    agInit(params: any): void {
        this.params = params;

        this.isGhost = params.columnInfo.isGhost;
        this.isExternalCode = params.columnInfo?.propertyTypeName && params.columnInfo.propertyTypeName.indexOf('ExternalCode') !== -1;

        this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));

        if (this?.params?.columnInfo?.securityAccess === AccessMode.ReadOnly) {
            this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_ReadOnly');
            this.securityTooltipClass = 'tooltip-content alert';
        } else {
            this.securityTooltipDescription = MessageResourceManager.Current.getMessage('std_Security_Access_Mode_Deny');
            this.securityTooltipClass = 'tooltip-content error';
        }

        this.paramsRefreshed$.pipe(untilDestroyed(this), startWith(null), switchMap(() => this.params.gridFilters$)).subscribe(this.gridFilters$);

        this.onSortChanged();
    }

    ngOnDestroy(): void {
        this.params?.column?.removeEventListener('sortChanged', this.onSortChanged.bind(this));
        this.params = null;
        this.ngxPopperjsTriggers = null;
        this.ngxPopperjsPlacements = null;
    }

    onMenuClick() {
        this.params.showColumnMenu(this.filterButton.nativeElement);
    }

    onSortChanged() {

        this.ascSort = this.descSort = this.noSort = 'inactive';
        if (this.params.column.isSortAscending()) {
            this.ascSort = 'active';
        } else if (this.params.column.isSortDescending()) {
            this.descSort = 'active';
        } else {
            this.noSort = 'active';
        }

        this.updateSortIndex();

    }

    onSortRequested(order: 'asc' | 'desc' | null, event: any) {
        this.params.setSort(order, event.shiftKey);
        this.updateSortIndex();
    }

    refresh(params: any): boolean {
        this.params = params;
        this.paramsRefreshed$.next();
        return true;
    }

    updateSortIndex() {
        if (this.params.column.getSort() != null) {
            this.sortIndex = this.params?.column?.getSortIndex();
        } else {
            this.sortIndex = null;
        }
    }

    headerColor(): string {
        if (this.isExternalCode) {
            return 'blue';
        } else if (this.isGhost) {
            return '#f9a12e';
        } else {
            return 'inherit';
        }
    }
}
