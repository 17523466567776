<div class='enum-text-wrapper'
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body">
    <p-dropdown #combo
        (onChange)="dropdownChange($event)"
        (onBlur)="blur($event)"
        (onShow)="onShow($event)"
        (onHide)="onHide($event)"
        [(ngModel)]="value"
        #popperInfo="popper"
        [popper]="title"
        [popperDisabled]="!title || title?.length === 0 || errorList?.length > 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        [popperDelay]="1000"
        popperApplyClass="info"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        [panelStyleClass]="panelClass"
        [showClear]="isNullable"
        [options]="simpleOptions$ | async"
        [appendTo]="appendTo"
        [disabled]="isDisabled"
        [optionLabel]="optionLabel"
        [overlayOptions]="overlayOptions"
        [dataKey]="dataKey"
        placeholder="{{placeholder}}">
        <ng-template let-option pTemplate="item">
            <div class="option-item"
                [popper]="option.descriptionExt"
                [popperDisabled]="!option?.descriptionExt || option?.descriptionExt?.length == 0"
                popperApplyClass="info"
                popperAppendTo="body"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDelay]="1000">
                {{ option.description }}
            </div>
        </ng-template>
    </p-dropdown>

    <div class="command-list-container" 
        *ngIf="customCommandList?.length > 0" 
        [class.is-disabled]="isDisabled">
        <ng-template ngFor let-command [ngForOf]="customCommandList">
            <nts-ribbon-button [title]="command.tooltip"
                [tabIndex]="0"
                [isLoading]="command.loading$ | async"
                [icon]="command.iconClass"
                [isDisabled]="!(command.canExecute$ | async)"
                (onClicked)="command.execute()">
            </nts-ribbon-button>
        </ng-template>
    </div>
</div>



<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>
