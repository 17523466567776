import { EnterpriseDataDtoInterface } from "@nts/std/interfaces";
import { Expose } from "@nts/std/serialization";

export class EnterpriseDataDto implements EnterpriseDataDtoInterface {
    
    /**
     * Azienda:
     * - è valorizzato a 0 se non è stato passato
     * - è valorizzato a -1 se non è stato trovato
     */
    @Expose()
    enterpriseId: number;
    
    /**
     * Ditta:
     * - è valorizzato a 0 se non è stato passato
     * - è valorizzato a -1 se non è stato trovato
     */
    @Expose()
    companyId: number;
}