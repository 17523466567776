import { NgModule } from "@angular/core";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { InternalServerErrorComponent } from "./internal-server-error/internal-server-error.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { OfflineModeComponent } from "./offline-mode/offline-mode.component";
import { UnauthorizedRequestComponent } from "./unauthorized-request/unauthorized-request.component";
import { BadRequestComponent } from "./bad-request/bad-request.component";

@NgModule({
    imports: [
        ForbiddenComponent,
        InternalServerErrorComponent,
        BadRequestComponent,
        NotFoundComponent,
        OfflineModeComponent,
        UnauthorizedRequestComponent
    ],
    exports: [
        ForbiddenComponent,
        InternalServerErrorComponent,
        BadRequestComponent,
        NotFoundComponent,
        OfflineModeComponent,
        UnauthorizedRequestComponent
    ]
})
export class ErrorsComponentModule {
}