import { EventEmitter } from '@angular/core';
import { LogService } from '@nts/std/utility';
import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import cloneDeep from 'lodash-es/cloneDeep';
import { firstValueFrom, map, Subject } from 'rxjs';
import { ZoomApiClient } from '../../api-clients/zoom/zoom-api-client';
import { ZoomApiClientInterface } from '../../api-clients/zoom/zoom-api-client.interface';
import { OrderByType } from '../../domain-models/autocomplete/auto-complete-options';
import { FindValuesOptions } from '../../domain-models/find-options/find-values-options';
import { ZoomAdvancedOptions } from '../../domain-models/find-options/zoom-advanced-options';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';
import { DateTimeMetaData, DomainModelMetaData, EnumResource, MetaDataUtils } from '../../meta-data';
import { ZoomMetaData } from '../../meta-data/zoom-meta-data';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { FindValuesResponse } from '../../responses/find-values-response';
import { BaseViewModel } from '../base-view-model';
import { BaseViewModelInterface } from '../base-view-model.interface';
import { SettingsType } from '../column-info';
import { UIResultCommand } from '../commands/ui-result-command';
import { ZoomFilterViewModel } from './filter-view-model/zoom-filter-view-model';
import { ZoomArgs } from './zoom-args';
import { ZoomColumnInfo } from './zoom-column-info';

export class ZoomResultsViewModel extends BaseViewModel {

    private _apiClient: ZoomApiClientInterface;

    private _filters: Array<ZoomFilterViewModel>;
    private _args: ZoomArgs;
    private _domainModelMetaData: DomainModelMetaData;
    private _enumDictionaries: Map<number, EnumResource[]>;
    private _zoomOption: FindValuesOptions;
    private _domainModelMetaDataList: DomainModelMetaData[];
    private _zoomMetaDataList: ZoomMetaData[];
    private _extDependentAssociationPropertiesDisplayName: Map<string, Map<string, string>>;
    private _callerDomainModelFullName: string;

    selectedResult: Subject<ZoomResult> = new Subject();
    resultsChanged: Subject<void> = new Subject();
    columns = new Array<ZoomColumnInfo>();

    results: number;

    forceAutoSize = new EventEmitter<void>();
    selectionChanged: Subject<void> = new Subject();
    resultChanged: Subject<void> = new Subject();
    isLastPage = true;
    isFirstPage = true;
    onSortChanged: EventEmitter<void> = new EventEmitter();
    datasourceUpdated: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Passa true se è il primo caricamento
     */
    paginatedCollectionItemsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    selection: object = null;
    pageChanged: EventEmitter<any> = new EventEmitter();

    destroy: EventEmitter<void> = new EventEmitter();

    private _rowDoubleClickCommand: UIResultCommand<ZoomResult>;
    get rowDoubleClickCommand(): UIResultCommand<ZoomResult> {
        if (this._rowDoubleClickCommand == null) {
            this._rowDoubleClickCommand = new UIResultCommand<ZoomResult>(
                () => this.selectionChanged.pipe(map(() => this.selection != null)),
                async (index) => {
                    const selected = this.setSelectedResult();
                    // if (this.activeIndex != null) {
                    // }
                    return selected;
                });
            this._rowDoubleClickCommand.closeModal = true;
            this._rowDoubleClickCommand.isDefault = true;
            this._rowDoubleClickCommand.description = MessageResourceManager.Current.getMessage("CMD_Select_Description");
            this._rowDoubleClickCommand.displayName = MessageResourceManager.Current.getMessage("CMD_Select_DisplayName");
        }

        return this._rowDoubleClickCommand;
    }

    get activeValue() {
        /*
        if (this.activeIndex != null) {
          return new ZoomResult(this.getJsonIdentity(this.results[this.activeIndex])).result;
        }
        */
        return this.setSelectedResult().result;
    }

    private buildEnumDictionaries(domainModelMetaData: DomainModelMetaData, sortedProperties: Array<ZoomMetaData>) {

        this._enumDictionaries = new Map<number, EnumResource[]>();

        this.checkEnums(domainModelMetaData, sortedProperties, '');
    }

    private checkEnums(domainModelMetaData: DomainModelMetaData, sortedProperties: Array<ZoomMetaData>, currentPath: string, processedExternals: string[] = []) {

        domainModelMetaData.enums.forEach(en => {
            const columnIndex = sortedProperties.indexOf(sortedProperties.filter(item => (item.rootPath ?? '') === currentPath && item.propertyMetadata?.name === en.name)[0]);

            if (columnIndex >= 0) {
                this._enumDictionaries.set(columnIndex, en.valuesResource);
            }
        });

        domainModelMetaData.internalCollections.forEach(internalCollection => {
            this.checkEnums(internalCollection.dependentMetaData, sortedProperties, currentPath.length > 0 ? (currentPath + '.' + internalCollection.principalPropertyName) : internalCollection.principalPropertyName, processedExternals);
        });

        domainModelMetaData.internalRelations.forEach(internalRelation => {
            this.checkEnums(internalRelation.dependentMetaData, sortedProperties, currentPath.length > 0 ? (currentPath + '.' + internalRelation.principalPropertyName) : internalRelation.principalPropertyName, processedExternals);
        });

        domainModelMetaData.externals.forEach(external => {
            if (processedExternals.indexOf(external.dependentAggregateMetaData.rootFullName) === -1) {
                processedExternals.push(external.dependentAggregateMetaData.rootFullName);
                this.checkEnums(external.dependentAggregateMetaData.rootMetaData, sortedProperties, currentPath.length > 0 ? (currentPath + '.' + external.principalPropertyName) : external.principalPropertyName, processedExternals);
            }
        });
    }

    /**
     * Recupera la lista ZoomMetaData ordinata in base a findOption.outputProperties
     * @param findOption
     */
    public async getZoomSortedPropertiesForOutput(findOption: FindValuesOptions): Promise<(ZoomMetaData & {
        isVisible?: boolean,
        orderBy?: OrderByType,
        orderByIndex?: number,
    })[]> {
        // Attenzione le output properties vengono precendentemente ordinati in base alla routine this.parameters.getCurrentOptions();
        // Verificare che si passi sempre prima per quella, altrimenti si potrebbe avere effetti indesiderati
        // Ricostruisce gli zoommetadata in base all'output properties, così hanno lo stesso ordine della visualizzazione dei parametri
        return findOption.outputProperties.map((o) => {

            let z = null;

            // Cerco tra i filtri se c'è la property con lo stesso propertyPath
            const f = this._filters.find((f) => f.propertyNameMap.propertyPath === o);

            if (f) {
                z = cloneDeep(
                    f.metaData
                ) as ZoomMetaData;
            }

            // se è una root identity devo nasconderla se non è stata selezionata
            if (z?.isRootIdentity) {

                // Se la trova
                if (f) {
                    // e non è selezionata o è nascasta
                    if (f.isSelected == false || f.isHidden) {

                        // nascondo la property
                        z.isVisible = false;
                        return z;
                    }
                } else {
                    // se non la trovo tra i filtri vuol dire che probabilmente è stata rimossa
                    // Verifico se esiste un external che viene utilizzato nella root come codice e utilizzo la sua visibilità
                    const f = this._filters.find((f) => f?.metaData?.external?.associationProperties.find((a) => a.principalPropertyName === z.propertyPath) != null);
                    if (f) {
                        z.isVisible = f.isSelected;
                    }
                }
            }

            // Se trova un orderBy lo imposta
            if (f && f.orderBy != null && z) {
                z.orderBy = f.orderBy;
                z.orderByIndex = f.orderByIndex;
            }

            return z;
        }).filter((md) => md != null);
    }


    columnInfoChanged = new Subject<void>();

    private async setColumnInfo() {
        const columns = [];
        const sortedProperties = await this.getZoomSortedPropertiesForOutput(
            this._zoomOption
        );

        this.buildEnumDictionaries(this._domainModelMetaData, sortedProperties);

        // costruzione delle colonne a partire dai metadati appena recuperati con l'ordine corretto
        for (const [index, item] of sortedProperties.entries()) {
            const column = new ZoomColumnInfo(item.propertyPath, index);
            column.header = item.displayName;
            column.isVisible = item.isVisible ?? true;
            column.position = item.position;
            column.orderIndex = item.position;
            column.orderByIndex = item.orderByIndex;
            column.orderBy = item.orderBy;
            column.propertyTypeName = item.propertyMetadata instanceof DateTimeMetaData && item.propertyMetadata?.isDateTimeOffset ?
                'DateTimeOffset' : (item.propertyMetadata?.getType() ?? 'External');
            column.settings = SettingsType.Default;

            if (this._args.zoomOptions.outputDataOrderList) {
                const found = this._args.zoomOptions?.outputDataOrderList.find(col => col.propertyName == column.propertyName);
                if (found) {
                    column.orderIndex = found.position;
                }
            }

            columns.push(column);
        }
        this.columns = columns;

        this.columnInfoChanged.next();
    }

    getZoomOptions(): ZoomAdvancedOptions {
        return this._args?.zoomOptions;
    }

    constructor(client: ZoomApiClient) {
        super();

        this._apiClient = client;
    }

    // devo lasciare il costruttore con i soli parametri di DI
    // e devo quindi creare questo metodo di init
    async init(parent: BaseViewModelInterface, args: ZoomArgs, filters: Array<ZoomFilterViewModel>) {

        this._args = args;
        this._domainModelMetaData = args.requestedDomainModelMetadata;
        this._filters = filters;
        this._zoomOption = args.zoomOptions;
        this._domainModelMetaDataList = args.domainModelMetaDataList;
        this._zoomMetaDataList = args.zoomMetaDataList;
        this._extDependentAssociationPropertiesDisplayName = args.extDependentAssociationPropertiesDisplayName;
        this._callerDomainModelFullName = args.callerDomainModelFullName;

        this.parent = parent;

    }

    // Esegue la query e torna il numero di righe trovate.
    // Ritorna il numero di righe trovate
    async find(options: ZoomAdvancedOptions): Promise<any[]> {

        if (this._apiClient.rootDomainModelName === undefined) {
            return [];
        }

        const observableResponse: Promise<FindValuesResponse> = firstValueFrom(
            this._apiClient.findValuesAsync(
                this._domainModelMetaData.name,
                options,
                this._domainModelMetaData.fullName
            )
        );

        const response = await observableResponse;
        if (response.operationSuccedeed) {

            // this.results.length = 0;

            const results = [];

            response.result.forEach(item => {
                // Mappo la singola riga (array) in un oggetto le cui property sono i campi dell'array: (0, 1, 2)
                // per poter sfruttare il binding della griglia e di conseguenza filtri e ordinamento.
                const o = {};
                item.forEach((prop, index) => {
                    o[index + ''] = prop;
                });
                this.normalizeRow(o);
                results.push(o);
            });
            return results;
        } else {
            let message = '';
            response.errors.forEach(e => message += e.description);
            throw new Error('ZoomException: ' + message);
        }
    }

    async resetResults() {
        this.columns.length = 0;
        this.results = 0;
        this.resultChanged.next();
    }

    async refreshColumns() {
        await this.resetResults();
        await this.setColumnInfo();
    }

    async getPaginatedCollectionItems(take: number, skip: number, params: IGetRowsParams): Promise<any[]> {
        return [];
    }

    getTakeParameter(params: IGetRowsParams): number {
        return params.endRow - params.startRow + 1;
    }

    getSkipParameter(params: IGetRowsParams): number {
        return params.startRow;
    }

    private _currentTake: number;
    private _currentSkip: number;

    datasource: IDatasource = {
        rowCount: null,
        getRows: async (params: IGetRowsParams): Promise<void> => {

            LogService.debug(
                'asking for ' + params.startRow + ' to ' + params.endRow
            );

            this._currentTake = this.getTakeParameter(params);
            this._currentSkip = this.getSkipParameter(params);

            const collectionsItems: any[] = await this.getPaginatedCollectionItems(
                this._currentTake,
                this._currentSkip,
                params
            );
            // Errore durante il recupero
            if (!collectionsItems) {

                LogService.warn(
                    'Pagination API failed!'
                );
                // this.paginatedCollectionItemsError.emit(params.startRow === 0); TODO
                // this.clear(false, false); TODO
                return params.successCallback(collectionsItems, 0);
            }

            this.paginatedCollectionItemsLoaded.emit(params.startRow === 0);

            let lastRow = -1; // Attenzione non è un indice zero based

            if (collectionsItems.length < this._currentTake) {
                lastRow = this._currentSkip + collectionsItems.length;
            }
            this.results = this._currentSkip + collectionsItems.length;
            this.resultChanged.next();

            params.successCallback(collectionsItems, lastRow);

        }
    };

    setSelectedResult(): ZoomResult {

        let result: string;
        let zoomResult: ZoomResult;
        if (this.selection != null) {
            result = this.getJsonIdentity(this.selection);
            zoomResult = new ZoomResult(result);
        } else {
            // TODO: Gestione Empty
            zoomResult = ZoomResult.empty();
        }
        this.selectedResult.next(zoomResult);
        return zoomResult;
    }

    private normalizeRow(row) {
        if (this._enumDictionaries != null) {
            this._enumDictionaries.forEach((value, colIndex) => {
                const enumResources = this._enumDictionaries.get(colIndex);
                if (enumResources) {
                    const enumResource = enumResources.find((e: EnumResource) => e.enumValue === row[colIndex]);
                    if (enumResource) {
                        row[colIndex] = enumResource.displayValue;
                    } else {
                        LogService.warn(`ERRORE: enumValue ${row[colIndex]} non trovato!`, enumResource, this._enumDictionaries);
                    }
                } else {
                    LogService.warn(`ERRORE: indice ${colIndex} non trovato in enumDictionaries!`, this._enumDictionaries);
                }
            });
        }
    }

    private getJsonIdentity(item: object) {
        const identity = {};
        this._domainModelMetaData.identityNames.forEach((propertyName: string) => {
            const propertyNameInCamelCase = MetaDataUtils.toCamelCase(propertyName);
            const columnIndex = this.columns.findIndex(column => column.propertyName === propertyName);
            const columnInfo = this.columns[columnIndex];
            let val = item[columnIndex];
            // se la property è di tipo enum, devo riconvertire in int la stringa
            if (this._enumDictionaries.has(columnInfo.orderIndex)) {
                const enumValues = this._enumDictionaries.get(columnInfo.orderIndex);
                enumValues.forEach((entry: EnumResource, k) => {
                    if (entry === val) {
                        val = k;
                    }
                });
            }
            identity[propertyNameInCamelCase] = val;
        });
        return JSON.stringify(identity);
    }
}
