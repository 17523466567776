
import cloneDeep from 'lodash-es/cloneDeep';
import { CrudApiClient } from "../../api-clients";
import { DomainModelCollection } from "../../domain-models";
import { NmOCCModel } from '../../domain-models/nm/nmocc-model';
import { ZoomAdvancedOptions } from "../../domain-models/find-options";
import { MetaDataUtils } from '../../meta-data';
import { AggregateMetaData } from "../../meta-data/aggregate-meta-data";
import { ServiceResponse } from "../../responses/service-response";
import { OrchestratorViewModel } from "../orchestrator-view-model";
import { ZoomUIStarterArgs } from "../zoom/zoom-ui-starter-args";
import { NmRootViewModel } from './nm-root-view-model';
import { ExternalViewModel } from '../external-view-model';
import { ItemViewModel } from '../item-view-model';
import { CollectionViewModel } from '../collection-view-model';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class NmOrchestratorViewModel<
    TViewModel extends NmRootViewModel<TModel, TIdentity, TRoot, TAssociationIdentity, TAssociation, TItems, TRootViewModel, TItemViewModel, TItemsViewModel>,
    TApiClient extends CrudApiClient<TModel, TIdentity>,
    TRoot extends ModelInterface<TIdentity>,
    TItems extends DomainModelCollection<TAssociation, TAssociationIdentity>,
    TAssociation extends ModelInterface<TAssociationIdentity>,
    TAssociationIdentity extends IdentityInterface,
    TRootViewModel extends ExternalViewModel<TRoot, TIdentity>, 
    TItemViewModel extends ItemViewModel<TAssociation, TAssociationIdentity>,
    TItemsViewModel extends CollectionViewModel<TItemViewModel, TAssociation, TAssociationIdentity>,
    TModel extends NmOCCModel<TIdentity, TRoot, TItems, TAssociation, TAssociationIdentity>,
    TIdentity extends IdentityInterface>
    extends OrchestratorViewModel<TViewModel, TApiClient, TModel, TIdentity> {

    
    public override async getByJsonIdentity(jsonIdentity: string): Promise<ServiceResponse> {
        const associationIdentityObject = JSON.parse(jsonIdentity);
        const identity = new this.identityType();
        const associationDiscriminatorPropertyNameList = this.domainModel.discriminatorPropertiesName;
        for (const [index, identityName] of this.metadata.rootMetaData.identityNames.entries()){
            const camelIdentityName = MetaDataUtils.toCamelCase(identityName);
            identity[camelIdentityName] = associationIdentityObject[MetaDataUtils.toCamelCase(associationDiscriminatorPropertyNameList[index])];
        }              
        return await this.getByIdentity(identity);
    }

    protected override getZoomUIStarterArgsFromFind(
        aggregateMetaData: AggregateMetaData,
        requestedDomainModelName: string,
        requestedDomainModelFullName: string,
        isRemote: boolean,
        callerAggregateMetaData: AggregateMetaData,
        rootDomainModelName: string,
        zoomOptions: ZoomAdvancedOptions
    ): ZoomUIStarterArgs {

        // Recupero objectAssociation AggregateMetaData
        const objectAssociationAggregateMetaData = cloneDeep(aggregateMetaData) as AggregateMetaData;

        const objectAssociationMetaData = objectAssociationAggregateMetaData.rootMetaData.internalCollections.find((internal) =>
            internal.principalPropertyName === 'Items'
        ).dependentMetaData;

        objectAssociationAggregateMetaData.domainModels = [objectAssociationMetaData];

        objectAssociationAggregateMetaData.rootMetaData = objectAssociationMetaData;
        objectAssociationAggregateMetaData.rootName = objectAssociationMetaData.name;
        objectAssociationAggregateMetaData.rootFullName = objectAssociationMetaData.fullName;

        return new ZoomUIStarterArgs(
            objectAssociationAggregateMetaData,
            objectAssociationAggregateMetaData.rootMetaData.name,
            objectAssociationAggregateMetaData.rootMetaData.fullName,
            false,
            aggregateMetaData,
            rootDomainModelName,
            zoomOptions
        );
    }
}
