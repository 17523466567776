// @ts-nocheck
/* eslint-disable */
import { stdAddFolderIcon } from './add-folder';
import { stdAddSelectionIcon } from './add-selection';
import { stdAlertIcon } from './alert';
import { stdArrowBackIcon } from './arrow-back';
import { stdArrowDownIcon } from './arrow-down';
import { stdArrowForwardIcon } from './arrow-forward';
import { stdArrowUpIcon } from './arrow-up';
import { stdCalendarIcon } from './calendar';
import { stdCancelCircledIcon } from './cancel-circled';
import { stdCancelIcon } from './cancel';
import { stdCheckCircledIcon } from './check-circled';
import { stdCheckIcon } from './check';
import { stdCloudDownloadIcon } from './cloud-download';
import { stdCloudErrorIcon } from './cloud-error';
import { stdCloudSyncIcon } from './cloud-sync';
import { stdCloudUploadIcon } from './cloud-upload';
import { stdCollapseIcon } from './collapse';
import { stdCompressIcon } from './compress';
import { stdCopyIcon } from './copy';
import { stdDeCompressIcon } from './de-compress';
import { stdDotIcon } from './dot';
import { stdDownloadIcon } from './download';
import { stdEditPencilIcon } from './edit-pencil';
import { stdEmptyPageIcon } from './empty-page';
import { stdEnlargeIcon } from './enlarge';
import { stdError400Icon } from './error-400';
import { stdError401Icon } from './error-401';
import { stdError403Icon } from './error-403';
import { stdError404Icon } from './error-404';
import { stdError500Icon } from './error-500';
import { stdExpandIcon } from './expand';
import { stdEyeAltIcon } from './eye-alt';
import { stdEyeCloseIcon } from './eye-close';
import { stdEyeEmptyIcon } from './eye-empty';
import { stdFilterIcon } from './filter';
import { stdFxRoundedIcon } from './fx-rounded';
import { stdGridAddIcon } from './grid-add';
import { stdGridRemoveIcon } from './grid-remove';
import { stdGroupIcon } from './group';
import { stdHelpCircleIcon } from './help-circle';
import { stdHelpSquareIcon } from './help-square';
import { stdHistoryIcon } from './history';
import { stdHomeIcon } from './home';
import { stdHorizIcon } from './horiz';
import { stdInfoIcon } from './info';
import { stdLayoutLeftIcon } from './layout-left';
import { stdLinkIcon } from './link';
import { stdLockedIcon } from './locked';
import { stdLogOutIcon } from './log-out';
import { stdMenuAlignIcon } from './menu-align';
import { stdMenuIcon } from './menu';
import { stdMinusIcon } from './minus';
import { stdMoveDownIcon } from './move-down';
import { stdMoveFolderIcon } from './move-folder';
import { stdMoveRowDownIcon } from './move-row-down';
import { stdMoveUpIcon } from './move-up';
import { stdOpenFolderIcon } from './open-folder';
import { stdOpenInWindowIcon } from './open-in-window';
import { stdOpenNewWindowIcon } from './open-new-window';
import { stdPageEditIcon } from './page-edit';
import { stdPanelIcon } from './panel';
import { stdPhoneIcon } from './phone';
import { stdPlusIcon } from './plus';
import { stdPrintIcon } from './print';
import { stdReduceIcon } from './reduce';
import { stdRefreshAnimatedIcon } from './refresh-animated';
import { stdRefreshDoubleIcon } from './refresh-double';
import { stdRefreshIcon } from './refresh';
import { stdReloadWindowIcon } from './reload-window';
import { stdRemoveSelectionIcon } from './remove-selection';
import { stdRestartIcon } from './restart';
import { stdSearchIcon } from './search';
import { stdSendMailIcon } from './send-mail';
import { stdSettingsIcon } from './settings';
import { stdSidebarCollapseIcon } from './sidebar-collapse';
import { stdSidebarExpandIcon } from './sidebar-expand';
import { stdSortDownIcon } from './sort-down';
import { stdSortUpIcon } from './sort-up';
import { stdSortIcon } from './sort';
import { stdStarFilledIcon } from './star-filled';
import { stdStarIcon } from './star';
import { stdSubmitDocumentIcon } from './submit-document';
import { stdTableRowsIcon } from './table-rows';
import { stdTaskListIcon } from './task-list';
import { stdTimezoneIcon } from './timezone';
import { stdTrashIcon } from './trash';
import { stdUndoActionIcon } from './undo-action';
import { stdUnknownIcon } from './unknown';
import { stdUserIcon } from './user';
import { stdVertIcon } from './vert';
import { stdViewGridIcon } from './view-grid';
import { stdWarningIcon } from './warning';
import { stdWebWindowSolidIcon } from './web-window-solid';
import { stdWebWindowIcon } from './web-window';
import { stdWifiOffIcon } from './wifi-off';
export const coreIcons = [stdAddFolderIcon, stdAddSelectionIcon, stdAlertIcon, stdArrowBackIcon, stdArrowDownIcon, stdArrowForwardIcon, stdArrowUpIcon, stdCalendarIcon, stdCancelCircledIcon, stdCancelIcon, stdCheckCircledIcon, stdCheckIcon, stdCloudDownloadIcon, stdCloudErrorIcon, stdCloudSyncIcon, stdCloudUploadIcon, stdCollapseIcon, stdCompressIcon, stdCopyIcon, stdDeCompressIcon, stdDotIcon, stdDownloadIcon, stdEditPencilIcon, stdEmptyPageIcon, stdEnlargeIcon, stdError400Icon, stdError401Icon, stdError403Icon, stdError404Icon, stdError500Icon, stdExpandIcon, stdEyeAltIcon, stdEyeCloseIcon, stdEyeEmptyIcon, stdFilterIcon, stdFxRoundedIcon, stdGridAddIcon, stdGridRemoveIcon, stdGroupIcon, stdHelpCircleIcon, stdHelpSquareIcon, stdHistoryIcon, stdHomeIcon, stdHorizIcon, stdInfoIcon, stdLayoutLeftIcon, stdLinkIcon, stdLockedIcon, stdLogOutIcon, stdMenuAlignIcon, stdMenuIcon, stdMinusIcon, stdMoveDownIcon, stdMoveFolderIcon, stdMoveRowDownIcon, stdMoveUpIcon, stdOpenFolderIcon, stdOpenInWindowIcon, stdOpenNewWindowIcon, stdPageEditIcon, stdPanelIcon, stdPhoneIcon, stdPlusIcon, stdPrintIcon, stdReduceIcon, stdRefreshAnimatedIcon, stdRefreshDoubleIcon, stdRefreshIcon, stdReloadWindowIcon, stdRemoveSelectionIcon, stdRestartIcon, stdSearchIcon, stdSendMailIcon, stdSettingsIcon, stdSidebarCollapseIcon, stdSidebarExpandIcon, stdSortDownIcon, stdSortUpIcon, stdSortIcon, stdStarFilledIcon, stdStarIcon, stdSubmitDocumentIcon, stdTableRowsIcon, stdTaskListIcon, stdTimezoneIcon, stdTrashIcon, stdUndoActionIcon, stdUnknownIcon, stdUserIcon, stdVertIcon, stdViewGridIcon, stdWarningIcon, stdWebWindowSolidIcon, stdWebWindowIcon, stdWifiOffIcon];
