import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaseMessage } from '../messages/base-message';
import { BaseViewModelInterface } from './base-view-model.interface';
import { PropertyViewModelInterface } from './property-view-model.interface';

export interface MessageInViewModelInterface {
    viewModel: BaseViewModelInterface;
    messages: BaseMessage[];
}

export interface ActionInProgressInterface {
    inProgress: boolean;
    loaderText?: string;
    loaderAnimation?: boolean;
}

export class ViewModelEventDispatcher {
    onActionInProgress: BehaviorSubject<boolean | ActionInProgressInterface> = new BehaviorSubject<boolean | ActionInProgressInterface>(false);
    onWingCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    onValidationBarCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    onNotificationBarCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    onSnapShotSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    onMasterAreaSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    onNavigationPanelVisibilityChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    onNavigationPanelCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    onRefreshMessageInViewModel: Subject<MessageInViewModelInterface> = new Subject<MessageInViewModelInterface>();
    onAddMessageInViewModel: Subject<MessageInViewModelInterface> = new Subject<MessageInViewModelInterface>();
    onRemovedMessageInViewModel: Subject<BaseViewModelInterface> = new Subject<BaseViewModelInterface>();
    onClearMessagesInViewModel: Subject<BaseViewModelInterface> = new Subject<BaseViewModelInterface>();
    onClearAllMessages: Subject<void> = new Subject();
    externalViewModelFocused: EventEmitter<PropertyViewModelInterface> = new EventEmitter<PropertyViewModelInterface>();
    externalModalExecuted: EventEmitter<void> = new EventEmitter();
    externalModalReturned: EventEmitter<void> = new EventEmitter();
    onInitializing: Subject<void> = new Subject();
    onMetaDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    beforeZoomOpen: Subject<void> = new Subject();
}
