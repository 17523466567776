import { EventEmitter } from '@angular/core';
import { BaseMessageInterface, ClassInformationInterface, MessageContainerInterface } from '@nts/std/interfaces';
import { ClassConstructor } from '@nts/std/serialization';
import { ClassInformationType, SourceMessage } from '@nts/std/types';
import { UUIDHelper } from '@nts/std/utility';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { DomainModelCollection } from '../domain-models/domain-model-collection';
import { DomainModelCollectionInterface } from '../domain-models/domain-model-collection.interface';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';
import { AccessMode } from '../meta-data/access-mode.enum';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { DomainModelMetaData, InternalCollectionMetaData } from '../meta-data/domain-model-meta-data';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { CodeValueMessageArg } from '../resources/code-value-message-arg';
import { MessageCodes } from '../resources/message-codes';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { BaseViewModelInterface } from './base-view-model.interface';
import { CollectionChangedAction, CollectionChangedEventArgs } from './collection-changed-event-args';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { ColumnInfo } from './column-info';
import { ColumnInfoCollection } from './column-info-collection';
import { CommandFactory } from './commands/command-factory';
import { CommandTypes } from './commands/command-types';
import { UICommandSettingsManager } from './commands/ui-command-settings-manager';
import { UICommandSettingsManagerInterface } from './commands/ui-command-settings-manager.interface';
import { UICommandInterface } from './commands/ui-command.interface';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { ItemViewModelTypeInspector } from './decorators/item-view-model-type.decorator';
import { ExternalRetrieverInterface } from './external-retriever.interface';
import { ItemViewModel } from './item-view-model';
import { MessageContainer } from './message-container';
import { MessageButton } from './modal/message-button';
import { MessageResult } from './modal/message-result';
import { NmRootViewModel } from './nm/nm-root-view-model';
import { PropertyViewModelPropertyChangedEventArgs } from './property-view-property-changed-event-args';
import { ViewModelStates } from './states';
import { ViewModelEventDispatcher } from './view-model-event-dispatcher';
import { ViewModelFactory } from './view-model-factory';

export class CollectionViewModel<
    TItemViewModel extends ItemViewModel<TItemModel, TItemIdentity>,
    TItemModel extends ModelInterface<TItemIdentity>,
    TItemIdentity extends IdentityInterface,
    TOrchestrator extends CoreOrchestratorViewModelInterface = CoreOrchestratorViewModelInterface>
    extends Array<TItemViewModel> implements CollectionViewModelInterface<TItemViewModel, TOrchestrator>, ClassInformationInterface {

    // Necessario per identificare univocamente un view model (vedi gestione errori)
    readonly uniqueId = UUIDHelper.generateUUID();

    classType = ClassInformationType.CollectionViewModel;

    private _securityAccess = null;
    private internalMetadataDescription: string = '';
    private internalMetadataShortDescription: string = '';
    private internalIsRequired: boolean = false;
    private internalWidth: string|null = null;
    private internalUserLayoutUniqueId: string|null =null;
    private internalIsVisible: boolean = true;

    isVisible$ = new BehaviorSubject<boolean>(this.internalIsVisible);
    isRequired$ = new BehaviorSubject<boolean>(this.internalIsRequired);

    get pagination(): boolean {
        return false
    }

    get bindedSelectedItemPropertyName(): string {
        return 'selectedItem';
    }

    get bindedSelectedItemOrMockedPropertyName(): string {
        return 'selectedItemOrMocked';
    }

    get isVisible(): boolean {
        return this.internalIsVisible;
    }

    set isVisible(value: boolean) {
        if (this.internalIsVisible !== value) {
            this.internalIsVisible = value;
            this.isVisible$.next(value);
            this.onPropertyChanged('isVisible', value);
        }
    }

    get isRequired(): boolean {
        return this.internalIsRequired;
    }

    set isRequired(value: boolean) {
        if (this.internalIsRequired !== value) {
            this.internalIsRequired = value;
            this.onPropertyChanged('isRequired');
            this.isRequired$.next(value);
        }
    }

    get width(): string {
        return this.internalWidth;
    }

    set width(value: string) {
        if (this.internalWidth !== value) {
            this.internalWidth = value;
            this.onPropertyChanged('width');
        }
    }

    get userLayoutUniqueId(): string {
        return this.internalUserLayoutUniqueId;
    }

    set userLayoutUniqueId(value: string) {
        if (this.internalUserLayoutUniqueId !== value) {
            this.internalUserLayoutUniqueId = value;
            this.onPropertyChanged('userLayoutUniqueId');
        }
    }

    get securityAccess(): AccessMode {
        return this._securityAccess;
    }

    onErrorStatusChanged: Subject<void> = new Subject();
    childCollections: Map<string, any> = new Map();
    errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    private internalGridFilters: boolean = false;

    gridFilters$ = new BehaviorSubject<boolean>(this.internalGridFilters);

    /**
     * Utilizzata per visualizzare/nascondere i filtri nella griglia. Il default è false.
     */
    get gridFilters(): boolean {
        return this.internalGridFilters;
    }

    set gridFilters(value: boolean) {
        if (this.internalGridFilters !== value) {
            this.internalGridFilters = value;
            this.gridFilters$.next(value);
            this.onPropertyChanged('gridFilters', value);
        }
    }

    private internalGridSearchFilterValue: string = '';

    gridSearchFilterValue$ = new BehaviorSubject<string>(this.internalGridSearchFilterValue);

    get gridSearchFilterValue(): string {
        return this.internalGridSearchFilterValue;
    }

    set gridSearchFilterValue(value: string) {
        if (this.internalGridSearchFilterValue !== value) {
            this.internalGridSearchFilterValue = value;
            this.gridSearchFilterValue$.next(value);
            this.onPropertyChanged('gridSearchFilterValue', value);
        }
    }

    protected _isEnabled = true;

    get isEnabled(): boolean {
        // TODO
        // if (this.isInternalKey) {
        //   // (this.parent.parent as EntityViewModelInterface).getProperty => this.parent.parent is EntityViewModelInterface
        //   if (this.behavior.isExternal && this.parent.parent && (this.parent.parent as EntityViewModelInterface).getProperty) {
        //     return this._isEnabled && (<EntityViewModelInterface>this.parent.parent).currentState === ViewModelStates.New;
        //   } else if (this.behavior.wrappedEntity != null) {
        //     return this._isEnabled && this.behavior.wrappedEntity.currentState === State.New;
        //   }
        //   return this._isEnabled;
        // } else {
        return this._isEnabled;
    }

    set isEnabled(value: boolean) {
        if (this._isEnabled !== value) {
            this._isEnabled = value;
            this.isEnabled$.next(value);
            this.onPropertyChanged('isEnabled', value);
        }
    }

    isEnabled$ = new BehaviorSubject<boolean>(this._isEnabled);
    metadataDescription$ = new BehaviorSubject<string>(this.internalMetadataDescription);

    get metadataDescription() {
        return this.internalMetadataDescription;
    }

    set metadataDescription(value: string) {
        if (this.internalMetadataDescription !== value) {
            this.internalMetadataDescription = value;
            this.onPropertyChanged('metadataDescription');
            this.metadataDescription$.next(value);
        }
    }

    metadataShortDescription$ = new BehaviorSubject<string>(this.internalMetadataShortDescription);

    get metadataShortDescription() {
        return this.internalMetadataShortDescription;
    }

    set metadataShortDescription(value: string) {
        if (this.internalMetadataShortDescription !== value) {
            this.internalMetadataShortDescription = value;
            this.onPropertyChanged('metadataShortDescription');
            this.metadataShortDescription$.next(value);
        }
    }

    private _removeItemCommandIsVisible = true;

    get removeItemCommandIsVisible(): boolean {
        return this._removeItemCommandIsVisible;
    }

    set removeItemCommandIsVisible(value: boolean) {
        if (this._removeItemCommandIsVisible !== value) {
            this._removeItemCommandIsVisible = value;
            this.onPropertyChanged('removeItemCommandIsVisible', value);
        }
    }

    private _addItemCommandIsVisible = true;

    get addItemCommandIsVisible(): boolean {
        return this._addItemCommandIsVisible;
    }

    set addItemCommandIsVisible(value: boolean) {
        if (this._addItemCommandIsVisible !== value) {
            this._addItemCommandIsVisible = value;
            this.onPropertyChanged('addItemCommandIsVisible', value);
        }
    }

    private _showGridFocusCommands = false;

    get showGridFocusCommands(): boolean {
        return this._showGridFocusCommands;
    }

    set showGridFocusCommands(value: boolean) {
        if (this._showGridFocusCommands !== value) {
            this._showGridFocusCommands = value;
            this.onPropertyChanged('showGridFocusCommands', value);
        }
    }

    private _showGridFiltersCommands = false;

    /**
     * Visualizza nasconde il comando per fare il toggle dei filtri della griglia. Il default è false.
     */
    get showGridFiltersCommands(): boolean {
        return this._showGridFiltersCommands;
    }

    set showGridFiltersCommands(value: boolean) {
        if (this._showGridFiltersCommands !== value) {
            this._showGridFiltersCommands = value;
            this.onPropertyChanged('showGridFiltersCommands', value);
        }
    }

    private _isMock = false;

    get isMock(): boolean {
        return this._isMock;
    }

    set isMock(value: boolean) {
        if (this._isMock !== value) {
            this._isMock = value;
            this.onPropertyChanged('isMock', value);
        }
    }

    private _showOpenGridSettingsCommands = true;

    get showOpenGridSettingsCommands(): boolean {
        return this._showOpenGridSettingsCommands;
    }

    set showOpenGridSettingsCommands(value: boolean) {
        if (this._showOpenGridSettingsCommands !== value) {
            this._showOpenGridSettingsCommands = value;
            this.onPropertyChanged('showOpenGridSettingsCommands', value);
        }
    }

    private _showExportCommands = true;

    get showExportCommands(): boolean {
        return this._showExportCommands;
    }

    set showExportCommands(value: boolean) {
        if (this._showExportCommands !== value) {
            this._showExportCommands = value;
            this.onPropertyChanged('showExportCommands', value);
        }
    }

    protected internalDefaultColor: string = null;
    protected internalActiveColor: string = null;
    protected internalHoverColor: string = null;

    get defaultColor() {
        return this.internalDefaultColor;
    }
    set defaultColor(value: string) {
        if (this.internalDefaultColor !== value) {
            this.internalDefaultColor = value;
            this.onPropertyChanged('defaultColor', value);
        }
    }

    get activeColor() {
        return this.internalActiveColor;
    }
    set activeColor(value: string) {
        if (this.internalActiveColor !== value) {
            this.internalActiveColor = value;
            this.onPropertyChanged('activeColor', value);
        }
    }

    get hoverColor() {
        return this.internalHoverColor;
    }
    set hoverColor(value: string) {
        if (this.internalHoverColor !== value) {
            this.internalHoverColor = value;
            this.onPropertyChanged('hoverColor', value);
        }
    }


    skipValidation: boolean;
    exportCsvRequested: Subject<void> = new Subject();
    openGridSettingsRequested: Subject<void> = new Subject();
    mockedItem: TItemViewModel;
    parent: BaseViewModelInterface;
    columns: ColumnInfoCollection;
    addItemCommand: UICommandInterface;
    removeItemCommand: UICommandInterface;
    focusGridCommand: UICommandInterface;
    exitFocusGridCommand: UICommandInterface;
    gridFiltersCommand: UICommandInterface;
    searchGridFilterCommand: UICommandInterface;
    exportCsvCommand: UICommandInterface;
    openGridSettingsCommand: UICommandInterface;
    collectionMetaData: InternalCollectionMetaData;
    destroySubscribers$: Subject<void> = new Subject();
    onFocusRequested: Subject<void> = new Subject();
    collection: DomainModelCollection<TItemModel, TItemIdentity>;
    commandList = new Map<string, UICommandInterface>();
    standardCommandList = new Map<string, UICommandInterface>();
    itemModelType: ClassConstructor<TItemModel>;

    columnsChanged = new EventEmitter<boolean>();
    collectionChanged: EventEmitter<CollectionChangedEventArgs<TItemViewModel>> =
        new EventEmitter<CollectionChangedEventArgs<TItemViewModel>>();
    selectionChanged = new EventEmitter<Array<TItemViewModel>>();
    visibleRowsChanged = new Subject<Array<TItemViewModel>>();

    overlayText = new Subject<string>();

    private _gridIsInFocus = false;

    get gridIsInFocus() {
        return this._gridIsInFocus;
    }

    private _selectedItem: TItemViewModel;

    get selectedItem() {
        return this._selectedItem;
    }

    private _reservedPath: string

    get reservedPath(): string {
        // if (this._propertyPath) {
        //     return this._propertyPath;
        // }
        // if (this.parent) {
        //     this._propertyPath = this.recursiveFindPropertyPath(this.parent).join('.');
        // } else {
        //     this._propertyPath = '';
        // }
        // return this._propertyPath;
        return this._reservedPath;
    }

    private _reservedName: string

    get reservedName(): string {
        // if (this._propertyPath) {
        //     return this._propertyPath;
        // }
        // if (this.parent) {
        //     this._propertyPath = this.recursiveFindPropertyPath(this.parent).join('.');
        // } else {
        //     this._propertyPath = '';
        // }
        // return this._propertyPath;
        return this._reservedName;
    }

    // private recursiveFindPropertyPath(viewmodel: BaseViewModelInterface, path = []): string[] {
    //     if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.RootViewModel)) {
    //         return path;
    //     }
    //     if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.ItemViewModel)) {
    //         path.unshift('selectedItem');
    //     } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.CollectionViewModel)) {
    //         path.unshift(MetaDataUtils.toCamelCase((viewmodel as CollectionViewModelInterface<any>).collectionMetaData.principalPropertyName));
    //     } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.InternalViewModel)) {
    //         path.unshift(MetaDataUtils.toCamelCase((viewmodel as InternalViewModelInterface).relationMetaData.principalPropertyName));
    //     } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.ExternalViewModel)) {
    //         path.unshift(MetaDataUtils.toCamelCase((viewmodel as ExternalViewModelInterface).externalMetaData.principalPropertyName));
    //     } else if (ClassInformationUtility.checkClassType(viewmodel, ClassInformationType.MasterViewModel)) {
    //         return path;
    //     }

    //     return this.recursiveFindPropertyPath(viewmodel.parent, path);
    // }

    onPropertyChanged(propertyName: string, value: any = undefined) {
        const args = new PropertyViewModelPropertyChangedEventArgs();
        args.propertyName = propertyName;
        if (value !== undefined) {
            args.value = value;
        }
        this.propertyChanged.emit(args);
    }

    propertyChanged: EventEmitter<PropertyViewModelPropertyChangedEventArgs> = new EventEmitter();

    get selectedIndex() {
        if (this._selectedItem) {
            return this.indexOf(this._selectedItem);
        } else {
            return -1;
        }
    }

    get selection() {
        return this._selection;
    }

    set selection(value) {
        this._selection = value;

        // nel selecteditem sempre il primo elemento della selection
        if (this.selection.length > 0) {
            this._selectedItem = this.selection[0];
        } else {
            this._selectedItem = null;
        }
        this.selectedItemOrMocked$.next(this._selectedItem ?? this.mockedItem)
        this.selectionChanged.emit(this._selection);
    }

    get messageContainerCollection(): Array<MessageContainerInterface> {
        return this._messageContainerCollection;
    }

    get hasErrors() {
        if (this.messageContainerCollection.length > 0) {
            return true;
        } else {
            for (let i = 0; i < this.length; i++) {
                if (this[i].hasErrors) {
                    return true;
                }
            }
            return false;
        }
    }

    get hasDecodeError() {
        for (let i = 0; i < this.length; i++) {
            if (this[i].hasDecodeError) {
                return true;
            }
        }
        return false;
    }

    get externalRetriever() {
        return this._externalRetriever;
    }

    get selectedItemOrMocked() {
        return this.selectedItem ? this.selectedItem : this.mockedItem;
    }

    selectedItemOrMocked$ = new BehaviorSubject<TItemViewModel>(null);

    protected orchestratorViewModel: TOrchestrator;

    private _itemViewModelType: any;
    private aggregateMetaData: AggregateMetaData;
    private _messageContainerCollection = new Array<MessageContainerInterface>();
    private _selection = new Array<TItemViewModel>();
    private _externalRetriever: ExternalRetrieverInterface;

    constructor() {
        super();

        // Typescript 2.1 BREAKING CHANGE
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, CollectionViewModel.prototype);

    }

    //#region Public methods
    getCommandColumns(): ColumnInfo[] {
        return [];
    }

    async preInit(itemViewModelType = null) {
        this._itemViewModelType = itemViewModelType || ItemViewModelTypeInspector.getValue(this);
        if (this._itemViewModelType === undefined) {
            throw new Error('You must use @ItemViewModelTypeDecorator.');
        }
    }

    async postInit() { }

    async init(
        entityCollection: DomainModelCollectionInterface,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: TOrchestrator,
        internalCollectionMetaData: InternalCollectionMetaData,
        path: string,
        isMockedViewModel: boolean,
        itemDomainModelType: ClassConstructor<TItemModel> = null
    ) {
        this.itemModelType = itemDomainModelType;
        this.collectionChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe(() => this.onCollectionChanged());
        this.isEnabled = true;
        this.isVisible = true;
        this.isMock = isMockedViewModel;
        this.collectionMetaData = internalCollectionMetaData;
        this._reservedPath = path;
        this._reservedName = MetaDataUtils.toCamelCase(internalCollectionMetaData.principalPropertyName);
        this._securityAccess = internalCollectionMetaData.userMetaData?.securityAccess;

        const entColl = entityCollection as DomainModelCollection<TItemModel, TItemIdentity>;

        // TODO external list
        // this._externalListMap = new Map<string, ExternalListPVMManagerInterface>();
        this._externalRetriever = orchestratorViewModel;
        //     messageDisplay = documentViewModel;

        if (internalCollectionMetaData !== undefined) {
            this.internalMetadataShortDescription = orchestratorViewModel?.useMessageResourceKey ? MessageResourceManager.Current.getMessage(
                internalCollectionMetaData.descriptions.displayNameKey) : internalCollectionMetaData.descriptions.displayName;

            this.internalMetadataDescription = orchestratorViewModel?.useMessageResourceKey ? MessageResourceManager.Current.getMessage(
                internalCollectionMetaData.descriptions.descriptionKey) : internalCollectionMetaData.descriptions.description;


        } else {
            // let entityMetadata = metadata.entityMetaDataList[entColl.GetType().Name];
            // this._metadataShortDescription = entityMetadata.DisplayName;
            // this._metadataDescription = entityMetadata.Description;
        }

        this.aggregateMetaData = aggregateMetadata;
        this.orchestratorViewModel = orchestratorViewModel;



        // TODO: Columns da generare in qualche modo
        // this.columns = documentViewModel.gridColumns[this.GetType().Name];

        // TODO: cosa fare se i metadata sono null?? sollevo una eccezione?
        if (aggregateMetadata !== undefined) {
            this.collection = entColl;
            // var entityType = typeof(TEntity);
            // syncInProgress = true;
            for (const entity of this.getCollection(entColl)) {
                const vm = await this.createByDomainModel(entity);

                // TODO unsubscribe
                // vm.viewModelChanged.subscribe(i => {
                //     this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.edit, vm));
                // });

                this.push(vm);
            }

            // syncInProgress = false;

            await this.initCommand();

            this.childCollections.clear();
            if (internalCollectionMetaData?.dependentMetaData?.internalCollections.length > 0) {
                await this.populateChildCollections(entityCollection, internalCollectionMetaData.dependentMetaData.internalCollections, this.isMock);
            }

            // this.EntityCollection.CollectionChanged += EntityCollection_CollectionChanged;
        }

        this.mockedItem = await this.createItem(undefined, true);
        this.selectedItemOrMocked$.next(this.mockedItem);
        this.mockedItem.isMock = true;
        this.recursiveforceMockValue(true, this.collectionMetaData.dependentMetaData, this.mockedItem.domainModel);
        this.mockedItem.isEnabled = false;
    }

    protected onCollectionChanged() {
        this.validateCollectionOnly();
        if (this.orchestratorViewModel?.eventDispatcher) {
            this.orchestratorViewModel.eventDispatcher.onRefreshMessageInViewModel.next(null);
        }
    }

    componentOptions: any;

    /**
     * Viene invocato quando il componente bindato è pronto
     */
    async onComponentReady<TComponentOptions>(componentOptions?: TComponentOptions): Promise<void> {
        this.componentOptions = componentOptions;
    }

    getDefaultCommandNames(): Array<string> {
        return ['addItemCommand', 'removeItemCommand'];
    }

    onDestroy() {
        if (this._messageContainerCollection?.length > 0) {
            this._messageContainerCollection = [];
            if (this['eventDispatcher']) {
                const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                ed.onClearMessagesInViewModel.next(this as any);
            }
        }
        this.destroySubscribers$.next();
    }

    validate() {
        this.forEach((item) => {
            item.validate();
        });
    }

    validateProperty(propertyName: string): void {

    }

    validateCollectionOnly() {
        this.parent.validateProperty(this.reservedName)
    }

    setSelectionLastRow() {
        if (this.length > 0) {
            this.selection = [this[this.length - 1]];
        }
    }

    private allCommandList: Map<string, UICommandInterface>;

    getCommand(commandName: string): UICommandInterface {
        if (!this.allCommandList) {
            this.allCommandList = new Map([...this.standardCommandList, ...this.commandList]);
        }
        return this.allCommandList.get(commandName);
    }

    setSelectionFirstRow() {
        if (this.length > 0) {
            this.selection = [this[0]];
        }
    }

    /**
     * Rimuove un elemento dalla collection corrente, pulisce gli errori e invoca il destroy dell'elemento.
     *
     * @param index                              indice dell'elemento da rimuovere
     * @param emitChanges                        deve sollevare l'evento CollectionChangedAction.remove per questa operazione?
     * @param notifyModifiedToOrchestrator       deve notificare l'orchestrator per questa operazione?
     */
    remove(index: number, emitChanges = true, notifyModifiedToOrchestrator = true) {

        const item: TItemViewModel = this[index];
        item.signObjectToRemove();

        if (index >= 0 && index < this.length) {
            this.removeItem(index, emitChanges, notifyModifiedToOrchestrator);

            // TODO refresh selection, si dovrebbe aggiornare la selezione in base all'index cancellato, verficare forse non è necessario
            if (this.length === 0) {
                this.selection = [];
            }
        }

    }

    /**
     * Rimuove un elemento dalla collection corrente, pulisce gli errori e invoca il destroy dell'elemento.
     *
     * @param identity                           identity per cercare l'elemento da eliminare
     * @param emitChanges                        deve sollevare l'evento CollectionChangedAction.clear per questa operazione?
     * @param notifyModifiedToOrchestrator       deve notificare l'orchestrator per questa operazione?
     */
    removeByIdentity(identity: TItemIdentity, emitChanges = true, notifyModifiedToOrchestrator = true) {
        const index = this.collection.collectionItems.findIndex((item) => item.currentIdentity?.equals(identity));
        if (index > -1) {
            this.remove(index, emitChanges, notifyModifiedToOrchestrator);
        }
    }

    /**
     * Pulisce la collection corrente, rimuovendo gli eventi/errori associati ad ogni elemento
     * Di default solleva la CollectionChangedAction.clear e notifica l'ovm
     *
     * @param emitChanges                        deve sollevare l'evento CollectionChangedAction.clear per questa operazione?
     * @param notifyModifiedToOrchestrator       deve notificare l'orchestrator per questa operazione?
     * @param fromIndex                          definisce il primo indice da tenere in considerazione per la pulizia
     * @param toIndex                            definisce l'ultimo indice da tenere in considerazione per la pulizia
     */
    clear(emitChanges = true, notifyModifiedToOrchestrator = true, fromIndex = 0, toIndex?: number): void {
        toIndex = toIndex || this.length - 1;

        for (let index = toIndex; index >= fromIndex; index--) {
            this.remove(index, false, false);
        }

        if (emitChanges) {
            this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.clear, null));
        }

        if (notifyModifiedToOrchestrator && this.orchestratorViewModel?.notifyModified) {
            // Notifico che la collection è cambiata
            this.orchestratorViewModel.notifyModified();
        }
    }

    /**
     * Crea un item view model partendo dal suo modello senza aggiungerlo alla collection corrente, senza parent e senza eventi collegati.
     * Se non viene passato il modello viene generato un item view model vuoto
     *
     * @param entity     modello
     * @returns          item view model creato
     */
    async createItem(entity?: TItemModel, isMockedViewModel = false): Promise<TItemViewModel> {
        entity = entity || this.collection.createTypedDomainModel();
        return await this.createByDomainModel(entity, false, undefined, isMockedViewModel);
    }

    /**
     * Aggiunge un item view model partendo dal modello alla collection corrente gestendo sia il parent che gli eventi
     *
     * @param entity     modello
     * @returns          nuovo item view model
     */
    async addFromEntity(
        entity: TItemModel,
        overrideWithDefaultValue = false,
        selectNewItem = false,
        emitChanges = true,
        notifyModifiedToOrchestrator = true,
        validateItem = true
    ): Promise<TItemViewModel> {
        const item = await this.createByDomainModel(entity, false, overrideWithDefaultValue);
        this.addCollectionItem(item, selectNewItem, emitChanges, notifyModifiedToOrchestrator, validateItem);
        return item;
    }

    /**
     * Pulisce la collection corrente e aggiunge nuovi item in base ai modelli passati.
     * Oltre ad inserire gli items view model alla collection corrente, associa il parent e i suoi eventi.
     *
     * @param entities                         modelli
     * @param emitChanges                      deve sollevare l'evento CollectionChangedAction.set per questa operazione?
     * @param notifyModifiedToOrchestrator     deve notificare l'orchestrator per questa operazione?
     */
    async setFromEntities(entities: TItemModel[], emitChanges = true, notifyModifiedToOrchestrator = true) {
        this.clear(false, false);
        const indexList: number[] = [];
        for (const entity of entities) {
            const item = await this.createByDomainModel(entity, false);
            const index = this.addCollectionItem(item, false, false, false, false);
            indexList.push(index);
        }
        if (emitChanges) {
            this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.set, indexList.map((i) => this[i]), indexList));
        }
        if (notifyModifiedToOrchestrator && this.orchestratorViewModel?.notifyModified) {
            // Notifico che la collection è cambiata
            this.orchestratorViewModel.notifyModified();
        }
    }

    clearErrors() {

        if (this.hasErrors) {

            this.messageContainerCollection.length = 0;

            this.forEach((item) => {
                if (item.hasErrors) {
                    item.clearErrors();
                }
            });

        }
        this.errors$.next([]);
        this.onErrorStatusChanged.next();
    }

    updateCurrentErrors() {
        const errors = this.messageContainerCollection.filter(x => x.isErrorMessage).map(x => x.message);
        this.errors$.next(errors);
    }

    addError(sourceMessage: SourceMessage, error: BaseMessageInterface) {
        let add = true;
        this.messageContainerCollection.forEach((mc) => {
            if (mc.contains(error)) {
                add = false;
            }
        });
        if (add) {
            this.messageContainerCollection.splice(0, 0, new MessageContainer(error, sourceMessage, this.uniqueId));
        }
        this.updateCurrentErrors();
        this.onErrorStatusChanged.next();
    }

    removeError(item: MessageContainerInterface) {
        this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
        if (this.orchestratorViewModel?.eventDispatcher) {
            const ed = this.orchestratorViewModel?.eventDispatcher as ViewModelEventDispatcher;
            ed.onRemovedMessageInViewModel.next(this);
        }
        // this.propertyViewModelChanged.emit();
        this.updateCurrentErrors();
        this.onErrorStatusChanged.next();
    }

    removeMessage(item: MessageContainerInterface) {
        this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
        if (this.orchestratorViewModel?.eventDispatcher) {
            const ed = this.orchestratorViewModel?.eventDispatcher as ViewModelEventDispatcher;
            ed.onRemovedMessageInViewModel.next(this);
        }
    }

    /**
     * E' usata?
     * TODO verificare se funziona senza fixup
     *
     * @param entities
     * @param emitChanges
     * @param clearUnusedSpace
     */
    async overrideFromEntities(entities: TItemModel[], emitChanges = true, clearUnusedSpace = false) {
        for (const [index, entity] of entities.entries()) {
            const item = await this.createByDomainModel(entity);
            this.setCollectionItem(index, item, false);
        }
        if (clearUnusedSpace && entities.length <= this.length) {
            this.clear(false, false, entities.length);
        }
        if (emitChanges) {
            this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.add, null));
            // Notifico che la collection è cambiata
            if (this.orchestratorViewModel?.notifyModified) {
                this.orchestratorViewModel.notifyModified();
            }
        }
    }
    //#endregion Public methods

    //#region Protected methods
    protected getCollection(domainModels: DomainModelCollection<TItemModel, TItemIdentity>): Array<TItemModel> {
        return domainModels.collectionItems;
    }

    /**
     * Chiamata dal pulsante rimuovi
     */
    protected async removeSelectedItem(): Promise<void> {
        if (await this.confirmRemoveAsync()) {
            this.deleteSelectedRows();
            if (this.orchestratorViewModel?.notifyModified) {
                // Notifico che la collection è cambiata
                this.orchestratorViewModel.notifyModified();
            }
        }
    }

    /**
     * Chiamata dal pulsante aggiungi
     */
    protected async addNew(): Promise<void> {
        const entity: TItemModel = this.collection.createTypedDomainModel();
        await this.addFromEntity(entity, true, true);
    }

    async executeFocusGridCommand(): Promise<void> {
        this._gridIsInFocus = true;
        this.onPropertyChanged('gridIsInFocus', this._gridIsInFocus);
    }

    async executeExitFocusGridCommand(): Promise<void> {
        this._gridIsInFocus = false;
        this.onPropertyChanged('gridIsInFocus', this._gridIsInFocus);
    }

    protected async populateChildCollections(
        entityCollection: DomainModelCollectionInterface,
        internalCollectionsMetaData: InternalCollectionMetaData[],
        isMockedViewModel = false
    ) {
        const dm = entityCollection.createDomainModel() as TItemModel;
        const vm = await this.createByDomainModel(dm, undefined, undefined, isMockedViewModel);
        vm.relationViewModels.forEach((relation, key) => {
            if (internalCollectionsMetaData.find((m) => MetaDataUtils.toCamelCase(m.principalPropertyName) === key)) {
                const r = relation as CollectionViewModelInterface<any>;
                r.isMock = true;
                this.childCollections.set(key, relation);
            }
        });
    }

    protected async confirmRemoveAsync(): Promise<boolean> {
        if (this.orchestratorViewModel?.modalService) {
            const warningMessage = MessageResourceManager.Current.getMessage(MessageCodes.Warning);
            const args: Array<CodeValueMessageArg> = [];
            const arg = new CodeValueMessageArg();
            arg.code = 'NEWLINE';
            arg.value = '<br>';
            args.push(arg);
            const confirmModalMessage = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.Remove, args);
            const result = await this.orchestratorViewModel.modalService.showMessageAsync(
                warningMessage, confirmModalMessage, MessageButton.YesNo);

            return result === MessageResult.Yes;
        } else {
            return true;
        }

    }

    private _uiCommandDefaultSettingsManager: UICommandSettingsManagerInterface;

    protected getUICommandDefaultSettingsManager(): UICommandSettingsManagerInterface {
        if (!this._uiCommandDefaultSettingsManager) {
            this._uiCommandDefaultSettingsManager = new UICommandSettingsManager();
        }
        return this._uiCommandDefaultSettingsManager;
    }

    protected async initCommand() {
        const manager = this.getUICommandDefaultSettingsManager();
        this.addItemCommand = manager.setUICommand(CommandTypes.CreateItem,
            CommandFactory.createUICommand(
                (x) => this.executeAddItemCommand(),
                () => this.canAddItemCommand(),
                () => this.isHighlightedAddItemCommand(),
                () => this.isVisibleAddItemCommand()
            )
        );
        this.standardCommandList.set('addItemCommand', this.addItemCommand);

        this.removeItemCommand = manager.setUICommand(CommandTypes.RemoveItem,
            CommandFactory.createUICommand(
                (x) => this.executeRemoveItemCommand(),
                () => this.canRemoveItemCommand(),
                () => this.isHighlightedRemoveItemCommand(),
                () => this.isVisibleRemoveItemCommand()
            )
        );
        this.standardCommandList.set('removeItemCommand', this.removeItemCommand);

        this.focusGridCommand = manager.setUICommand(CommandTypes.FocusGrid,
            CommandFactory.createUICommand(
                (x) => this.executeFocusGridCommand(),
                () => this.canFocusGridCommand(),
                () => this.isHighlightedFocusGridCommand(),
                () => this.isVisibleFocusGridCommand()
            )
        );
        this.standardCommandList.set('focusGridCommand', this.focusGridCommand);

        this.exitFocusGridCommand = manager.setUICommand(CommandTypes.ExitFocusGrid,
            CommandFactory.createUICommand(
                (x) => this.executeExitFocusGridCommand(),
                () => this.canExitFocusGridCommand(),
                () => this.isHighlightedExitFocusGridCommand(),
                () => this.isVisibleExitFocusGridCommand()
            )
        );

        this.standardCommandList.set('exitFocusGridCommand', this.exitFocusGridCommand);

        this.searchGridFilterCommand = manager.setUICommand(CommandTypes.SearchGridFilters,
            CommandFactory.createUICommand(
                (x, valueTosearch) => this.executeSearchFilterCommand(valueTosearch),
                () => this.canOpenGridFiltersCommand(),
                () => this.isHighlightedOpenGridFiltersCommand(),
                () => this.isVisibleSearchFilterCommand())
        );
        this.searchGridFilterCommand.isTextbox = true;

        this.standardCommandList.set('searchGridFilterCommand', this.searchGridFilterCommand);

        this.gridFiltersCommand = manager.setUICommand(CommandTypes.ToggleGridFilters,
            CommandFactory.createUICommand(
                (x) => this.executeOpenGridFiltersCommand(),
                () => this.canOpenGridFiltersCommand(),
                () => this.isHighlightedOpenGridFiltersCommand(),
                () => this.isVisibleOpenGridFiltersCommand())
        );

        this.standardCommandList.set('gridFiltersCommand', this.gridFiltersCommand);

        this.exportCsvCommand = manager.setUICommand(CommandTypes.ExportCSV,
            CommandFactory.createUICommand(
                (x) => this.executeExportCsvCommand(),
                () => this.canExportCsvCommand(),
                () => this.isHighlightedExportCsvCommand(),
                () => this.isVisibleExportCsv()
            )
        );

        this.standardCommandList.set('exportCsvCommand', this.exportCsvCommand);

        this.openGridSettingsCommand = manager.setUICommand(CommandTypes.OpenGridSettings,
            CommandFactory.createUICommand(
                (x) => this.executeOpenGridSettingsCommand(),
                () => this.canOpenGridSettingsCommand(),
                () => this.isHighlightedOpenGridSettingsCommand(),
                () => this.isVisibleOpenGridSettingsCommand())
        );

        this.standardCommandList.set('openGridSettingsCommand', this.openGridSettingsCommand);

    }

    protected isVisibleExportCsv(): Observable<boolean> {
        return this.propertyChanged.pipe(
            startWith(null),
            map(() => this.showExportCommands)
        );
    }

    protected isVisibleOpenGridSettingsCommand(): Observable<boolean> {
        if (this.orchestratorViewModel?.canAccessToUserLayout) {
            return this.orchestratorViewModel.canAccessToUserLayout()
                .pipe(
                    switchMap((canAccessToUserLayout) => merge(
                        this.propertyChanged,
                    ).pipe(
                        startWith(null),
                        map(() => canAccessToUserLayout && this.showOpenGridSettingsCommands)
                    ))
                )
        } else {
            return of(false);
        }
    }

    protected async executeExportCsvCommand(): Promise<void> {
        this.exportCsvRequested.next();
    }

    protected isHighlightedExportCsvCommand(): Observable<boolean> {
        return of(false);
    }

    protected canExportCsvCommand(): Observable<boolean> {
        return this.collectionChanged.pipe(
            startWith(null),
            map(() => this.collection?.length > 0)
        );
    }

    protected async executeOpenGridSettingsCommand(): Promise<void> {
        this.openGridSettingsRequested.next();
    }

    // #region Filters
    protected async executeOpenGridFiltersCommand(): Promise<void> {
        this.toggleGridFilters();
    }

    protected async executeSearchFilterCommand(valueToSearch: string): Promise<void> {
        this.gridSearchFilterValue = valueToSearch;
    }

    protected toggleGridFilters() {
        this.gridFilters = !this.gridFilters;
    }

    protected canOpenGridFiltersCommand(): Observable<boolean> {
        return of(true);
    }

    protected isHighlightedOpenGridFiltersCommand(): Observable<boolean> {
        return this.gridFilters$;
    }

    protected isVisibleSearchFilterCommand(): Observable<boolean> {
        if (this.propertyChanged) {
            return this.propertyChanged
                .pipe(
                    startWith(null),
                    map(() => this.gridFilters)
                )
        } else {
            return of(false);
        }
    }

    protected isVisibleOpenGridFiltersCommand(): Observable<boolean> {
        if (this.propertyChanged) {
            return this.propertyChanged
                .pipe(
                    startWith(null),
                    map(() => this.showGridFiltersCommands)
                )
        } else {
            return of(false);
        }
    }
    // #endregion Filters

    protected canOpenGridSettingsCommand(): Observable<boolean> {
        if (this.orchestratorViewModel?.rootViewModelChanged) {
            return this.orchestratorViewModel.rootViewModelChanged.pipe(
                switchMap(() => this.orchestratorViewModel.currentStateChanged.pipe(map(() => {
                    return this.orchestratorViewModel.currentState.value === ViewModelStates.Unchanged;
                }))))
        } else {
            return of(false);
        }
    }

    protected isHighlightedOpenGridSettingsCommand(): Observable<boolean> {
        return of(false);
    }

    protected isHighlightedFocusGridCommand(): Observable<boolean> {
        return of(false);
    }

    protected isVisibleFocusGridCommand(): Observable<boolean> {
        return this.propertyChanged.pipe(
            startWith(null),
            map(() => !this.gridIsInFocus && this.showGridFocusCommands)
        );
    }

    protected isVisibleExitFocusGridCommand(): Observable<boolean> {
        return this.propertyChanged.pipe(
            startWith(null),
            map(() => this.gridIsInFocus && this.showGridFocusCommands)
        );
    }

    /**
     * Rimuove un elemento dalla collection corrente, pulisce gli errori e invoca il destroy dell'elemento.
     *
     * @param index                              indice dell'elemento da rimuovere
     * @param emitChanges                        deve sollevare l'evento CollectionChangedAction.clear per questa operazione?
     * @param notifyModifiedToOrchestrator       deve notificare l'orchestrator per questa operazione?
     */
    protected removeItem(index: number, emitChanges = true, notifyModifiedToOrchestrator = true) {
        // Cancello tutti gli errori dall'item
        // TODO: questo metodo viene chiamato anche dalla Move, e in tal caso non sono certo
        // che sia corretto fare la cancellaizone degli errori. Valutare bene questo scenario
        const item: TItemViewModel = this[index];
        item.clearErrors();

        // effettuo la cancellazione dell'item
        this.collection.removeAt(index);
        this.splice(index, 1);
        item.onDestroy();

        this.updateCurrentErrors();
        this.onErrorStatusChanged.next();

        if (emitChanges) {
            this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.remove, [item], [index]));
        }

        if (notifyModifiedToOrchestrator && this.orchestratorViewModel?.notifyModified) {
            // Notifico che la collection è cambiata
            this.orchestratorViewModel.notifyModified();
        }
    }

    /**
     * Inserisce un item view model senza parent e eventi collegati nella collection corrente.
     * Oltre ad inserire l'item view model alla collection corrente, associa il parent e i suoi eventi.
     *
     * @param index             indice dove inserire il nuovo elemento
     * @param item              nuovo item view model
     * @param selectNewItem     selezionare il nuovo elemento aggiunto?
     * @returns                 indice dove è stato inserito il nuovo elemento
     */
    protected insertItem(index: number, item: TItemViewModel, selectNewItem = false): number {

        const itemDomainModel = item.getDomainModel();

        // Forzo emitChanges a false così non scattano gli eventi di property changed
        itemDomainModel.emitChanges = false;

        this.collection.insert(index, itemDomainModel);

        if (itemDomainModel.getPropertyNames().indexOf('parent') !== undefined) {
            itemDomainModel.setPropertyValue('parent', this.collection.parent);
        }

        // Ripristino emitChanges
        itemDomainModel.emitChanges = true;

        this.splice(index, 0, item);
        this.manageDiscriminatorOnNMWithRoot(index);
        if (selectNewItem) {
            this.selection = [this[index]];
        }

        item.viewModelChanged.pipe(takeUntil(item.destroySubscribers$)).subscribe(i => {
            this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.edit, [item]));
        });
        return index;
    }

    /**
     * Aggiunge un item view model alla collection corrente
     *
     * @param item                            item view model da aggiungere
     * @param selectNewItem                   selezionare l'item dopo l'aggiunta?
     * @param emitChanges                     deve sollevare l'evento CollectionChangedAction.add per questa operazione?
     * @param notifyModifiedToOrchestrator    deve notificare l'orchestrator per questa operazione?
     * @param validateItem                    deve validare l'item view model aggiunto?
     * @returns                               ritorna l'indice dell'elemento creato
     */
    protected addCollectionItem(item: TItemViewModel, selectNewItem = false, emitChanges = true, notifyModifiedToOrchestrator = true, validateItem = true): number {
        // passa dopo la add
        const index = this.insertItem(this.length, item, selectNewItem);

        if (validateItem) {
            // eseguo la validaizone per ottenere lo stato di errore dell'elemento quando viene aggiunto alla collection e alla griglia
            // comportamento coerente con la new
            item.validate();
        }

        if (emitChanges) {
            this.collectionChanged.emit(new CollectionChangedEventArgs(CollectionChangedAction.add, [item], [this.length]));
        }

        if (notifyModifiedToOrchestrator && this.orchestratorViewModel?.notifyModified) {
            // Notifico che la collection è cambiata
            this.orchestratorViewModel.notifyModified();
        }

        return index;
    }

    /**
     * E' usata?
     *
     * @param index
     * @param item
     * @param selectNewItem
     */
    protected setItem(index: number, item: TItemViewModel, selectNewItem = true) {
        this.collection.set(index, item.getDomainModel());
        if (this[index]) {
            this[index] = item;
        } else {
            this.splice(index, 0, item);
        }
        this.manageDiscriminatorOnNMWithRoot(index);
        if (selectNewItem) {
            this.selection = [this[index]];
        }
    }

    /**
     * E' usata?
     *
     * @param index
     * @param item
     * @param selectNewItem
     */
    protected setCollectionItem(index: number, item: TItemViewModel, selectNewItem = true) {
        // passa dopo la add
        this.setItem(index, item, selectNewItem);
    }

    protected manageDiscriminatorOnNMWithRoot(index: number): void {
        const parent = this.parent;
        if (parent instanceof NmRootViewModel && parent?.domainModel?.discriminatorPropertiesName?.length > 0) {
            const discriminatorPropertiesName = parent.domainModel.discriminatorPropertiesName[0];
            const discriminatorPvm = parent.getProperty(MetaDataUtils.toCamelCase(discriminatorPropertiesName));

            let collectionRefPropertyName: string;
            const internalCollectionMetaData = this.aggregateMetaData.rootMetaData.internalCollections.find(x => x.principalPropertyName == 'Items');
            for (const externalMetaData of internalCollectionMetaData.dependentMetaData.externals) {
                const association = externalMetaData.associationProperties.find(x => x.principalPropertyName == discriminatorPropertiesName);
                if (association) {
                    collectionRefPropertyName = MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName);
                    break;
                }
            }

            this[index][collectionRefPropertyName].setCodeValue(discriminatorPvm.value);
        }
    }

    /**
     * Rimuove la riga selezionata
     */
    protected deleteSelectedRows() {
        let index = -1;
        this.selection.forEach(item => {
            if (item != null) {
                index = this.indexOf(item);
                if (index >= 0 && index < this.length) {
                    this.remove(index, true, true)
                }
            }
        });

        // riposizionamento selezione
        if (this.length > 0 && index >= 0) {
            if (index >= this.length) {
                this.selection = [this[this.length - 1]];
            } else {
                this.selection = [this[index]];
            }
        } else if (this.length === 0) {
            this.selection = [];
        }
    }

    protected canDeleteSelectedRows() {
        return this.selectedItem != null && this.isEnabled === true && this.gridFilters === false;
    }

    protected async executeRemoveItemCommand(): Promise<void> {
        await this.removeSelectedItem();
    }

    protected canRemoveItemCommand(): Observable<boolean> {
        return merge(
            this.propertyChanged,
            this.selectionChanged
        ).pipe(
            startWith(null),
            map(() => this.canDeleteSelectedRows())
        );
    }

    protected isHighlightedAddItemCommand(): Observable<boolean> {
        return of(false);
    }

    protected isHighlightedRemoveItemCommand(): Observable<boolean> {
        return of(false);
    }

    protected isVisibleRemoveItemCommand(): Observable<boolean> {
        return this.propertyChanged.pipe(
            startWith(null),
            map(() => this.removeItemCommandIsVisible && this.gridFilters === false)
        );
    }

    protected async executeAddItemCommand(): Promise<void> {
        await this.addNew();
    }

    protected canAddItemCommand(): Observable<boolean> {
        return merge(
            this.propertyChanged,
            this.collectionChanged,
            this.onErrorStatusChanged
        ).pipe(
            startWith(null),
            map(() => !this.hasErrors && !this.isMock && this.isEnabled === true && this.gridFilters === false)
        );
    }

    protected isVisibleAddItemCommand(): Observable<boolean> {
        return this.propertyChanged.pipe(
            startWith(null),
            map(() => this.addItemCommandIsVisible && this.gridFilters === false)
        );
    }

    protected canFocusGridCommand(): Observable<boolean> {
        return of(true);
    }

    protected canExitFocusGridCommand(): Observable<boolean> {
        return of(true);
    }

    protected isHighlightedExitFocusGridCommand(): Observable<boolean> {
        return of(false);
    }

    /**
     * Crea un nuovo item view model senza parent e eventi collegati
     * @returns l'item view model senza eventi collegati e senza parent
     */
    protected async createAsync(): Promise<TItemViewModel> {
        const entity: TItemModel = this.collection.createTypedDomainModel();
        return await this.createByDomainModel(entity, false);
    }

    private recursiveforceDefaultValue(
        newValue: boolean,
        currentDomainModelMetadata: DomainModelMetaData,
        currentDomainModel: ModelInterface,
        processedDomainModels = []
    ) {
        if (processedDomainModels.find((element) => element === currentDomainModel) === undefined) {
            processedDomainModels.push(currentDomainModel);
            currentDomainModel.forceDefaultValue = newValue;
            for (const relation of currentDomainModelMetadata.internalRelations) {
                const subDomainModel = currentDomainModel.getPropertyValue(
                    MetaDataUtils.toCamelCase(relation.principalPropertyName)) as ModelInterface;
                if (subDomainModel != null) {
                    const subCurrentEntityMetadata = relation.dependentMetaData;
                    // if (relation.multiplicity === PrincipalToDependentMultiplicity.OneToMany ||
                    //     relation.multiplicity === PrincipalToDependentMultiplicity.ManyToMany) {
                    //     const entityCollection = (subEntity as any) as EntityCollectionInterface;
                    //     for (const item of entityCollection.collectionItems) {
                    //         item.isMock = true;
                    //         this.recursiveMockEntity(metadata, subCurrentEntityMetadata, item, processedDomainModels);
                    //     }
                    // } else {
                    subDomainModel.forceDefaultValue = newValue;
                    this.recursiveforceDefaultValue(newValue, subCurrentEntityMetadata, subDomainModel, processedDomainModels);
                }
            }
            for (const relation of currentDomainModelMetadata.externals) {
                const subDomainModel = currentDomainModel.getPropertyValue(
                    MetaDataUtils.toCamelCase(relation.principalPropertyName)) as ModelInterface;
                if (subDomainModel != null) {
                    const subCurrentEntityMetadata = relation.dependentAggregateMetaData.rootMetaData;
                    // if (relation.multiplicity === PrincipalToDependentMultiplicity.OneToMany ||
                    //     relation.multiplicity === PrincipalToDependentMultiplicity.ManyToMany) {
                    //     const entityCollection = (subEntity as any) as EntityCollectionInterface;
                    //     for (const item of entityCollection.collectionItems) {
                    //         item.isMock = true;
                    //         this.recursiveMockEntity(metadata, subCurrentEntityMetadata, item, processedDomainModels);
                    //     }
                    // } else {
                    subDomainModel.forceDefaultValue = newValue;
                    this.recursiveforceDefaultValue(newValue, subCurrentEntityMetadata, subDomainModel, processedDomainModels);
                }
            }
        }
    }

    private recursiveforceMockValue(
        newValue: boolean,
        currentDomainModelMetadata: DomainModelMetaData,
        currentDomainModel: ModelInterface,
        processedDomainModels = []
    ) {
        if (processedDomainModels.find((element) => element === currentDomainModel) === undefined) {
            processedDomainModels.push(currentDomainModel);
            currentDomainModel.isMock = newValue;
            for (const relation of currentDomainModelMetadata.internalRelations) {
                const subDomainModel = currentDomainModel.getPropertyValue(
                    MetaDataUtils.toCamelCase(relation.principalPropertyName)) as ModelInterface;
                if (subDomainModel != null) {
                    const subCurrentEntityMetadata = relation.dependentMetaData;
                    // if (relation.multiplicity === PrincipalToDependentMultiplicity.OneToMany ||
                    //     relation.multiplicity === PrincipalToDependentMultiplicity.ManyToMany) {
                    //     const entityCollection = (subEntity as any) as EntityCollectionInterface;
                    //     for (const item of entityCollection.collectionItems) {
                    //         item.isMock = true;
                    //         this.recursiveMockEntity(metadata, subCurrentEntityMetadata, item, processedDomainModels);
                    //     }
                    // } else {
                    subDomainModel.isMock = newValue;
                    this.recursiveforceMockValue(newValue, subCurrentEntityMetadata, subDomainModel, processedDomainModels);
                }
            }
            for (const relation of currentDomainModelMetadata.externals) {
                const subDomainModel = currentDomainModel.getPropertyValue(
                    MetaDataUtils.toCamelCase(relation.principalPropertyName)) as ModelInterface;
                if (subDomainModel != null) {
                    const subCurrentEntityMetadata = relation.dependentAggregateMetaData.rootMetaData;
                    // if (relation.multiplicity === PrincipalToDependentMultiplicity.OneToMany ||
                    //     relation.multiplicity === PrincipalToDependentMultiplicity.ManyToMany) {
                    //     const entityCollection = (subEntity as any) as EntityCollectionInterface;
                    //     for (const item of entityCollection.collectionItems) {
                    //         item.isMock = true;
                    //         this.recursiveMockEntity(metadata, subCurrentEntityMetadata, item, processedDomainModels);
                    //     }
                    // } else {
                    subDomainModel.isMock = newValue;
                    this.recursiveforceMockValue(newValue, subCurrentEntityMetadata, subDomainModel, processedDomainModels);
                }
            }
        }
    }

    /**
     * Crea un nuovo item view model partendo dal modello
     *
     * @param domainModel      modello
     * @param addOwner         deve aggiungere la collection corrente come padre?
     * @returns                item view model creato
     */
    protected async createByDomainModel(
        domainModel: TItemModel,
        addOwner = true,
        overrideWithDefaultValue = false,
        isMockedViewModel = null,
        skipPostInit = false,
    ): Promise<TItemViewModel> {

        isMockedViewModel = isMockedViewModel != null ? isMockedViewModel : this.isMock;

        if (overrideWithDefaultValue) {
            this.recursiveforceDefaultValue(true, this.collectionMetaData.dependentMetaData, domainModel);
        }

        domainModel.fixUpEnabled = false;
        // Forzo l'entity con mock = true per gestire le casistiche delle internal
        this.recursiveforceMockValue(true, this.collectionMetaData.dependentMetaData, domainModel);


        const vm: TItemViewModel = await ViewModelFactory.createItemViewModel<TItemViewModel, TItemModel, TItemIdentity>(
            this._itemViewModelType,
            domainModel,
            this.aggregateMetaData,
            this.orchestratorViewModel,
            addOwner ? this : null,
            true,
            [this.reservedPath, this.reservedName].filter((w) => w?.length > 0).join('.') + '.selectedItem',
            isMockedViewModel,
            skipPostInit,
            this.itemModelType
        );

        // WeakEventManager<ObservableCollection<MessageContainer>, NotifyCollectionChangedEventArgs>.AddHandler
        // (vm.MessageContainerCollection, nameof(ObservableCollection<MessageContainer>.CollectionChanged), MessageContainerCollection_CollectionChanged);
        // vm.MessageContainerCollection.CollectionChanged += MessageContainerCollection_CollectionChanged;

        // registrazione parent;
        vm.parent = this;

        // if (loadAllExternalList) {
        //     await this.loadAllExternalList();
        // }

        this.recursiveforceMockValue(false, this.collectionMetaData.dependentMetaData, domainModel);
        domainModel.fixUpEnabled = true;
        if (overrideWithDefaultValue) {
            this.recursiveforceDefaultValue(false, this.collectionMetaData.dependentMetaData, domainModel);
        }

        return vm;
        /*return this.loadAllExternalList().map(response => {
          return vm;
        });*/
    }
    //#endregion Protected methods

    getErrors(): Array<string> {
        return this.errors$.value;
    }


    // if (this.messageContainerCollection.length > 0) {
    //     return true;
    // } else {
    //     for (let i = 0; i < this.length; i++) {
    //         if (this[i].hasErrors) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }
}
