import { GenericArrayServiceResponse } from './generic-array-service-response';
import { ResponseResultTypeDecorator } from './decorators/response-result-type.decorator';
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';
import { BigNumber } from '@nts/std/types';


@ResponseResultTypeDecorator(FindValuesResponse)
@ResponseCachedDecorator({
    onlyOffline: true
})
export class FindValuesResponse extends GenericArrayServiceResponse<Array<string | number | BigNumber>> {

}
