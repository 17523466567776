import { Component, OnInit } from '@angular/core';
import { ModalComponentInterface } from '../modal/modal-component.interface';
import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { FieldsetModule } from 'primeng/fieldset';
import { ExceptionPopupViewModel } from '../../view-models/modal/exception-popup-view-model';
import { ExpandableComponent } from '../shared/expandable/expandable.component';
import { LabelBoxComponent } from '../controls/core/base/label-box/label-box.component';
import { AuthService } from '../../auth/auth.service';
import { NTSTranslatePipe } from '../pipe';

@Component({
    selector: 'nts-exception-popup',
    templateUrl: 'exception-popup-container.component.html',
    styleUrls: ['exception-popup-container.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FieldsetModule,
        NgFor,
        JsonPipe,
        ExpandableComponent,
        AsyncPipe,
        LabelBoxComponent,
        NTSTranslatePipe
    ]
})

export class ExceptionPopupContainerComponent implements OnInit, ModalComponentInterface {
    
    viewModel: ExceptionPopupViewModel;

    constructor(private authService: AuthService) {

    }

    async ngOnInit(): Promise<void> {
        this.viewModel.userName$.next(await this.authService.getUserName());
        this.viewModel.tenantProfile$.next(await this.authService.getTenantProfile());

        if (this.viewModel.tenantProfile$.value?.id > 0) {
            this.viewModel.currentEnterprise$.next(await this.authService.getCurrentEnterpriseInformation(this.viewModel.tenantProfile$.value.id));
            this.viewModel.currentCompany$.next(await this.authService.getCurrentCompanyInformation(this.viewModel.tenantProfile$.value.id));
        }
        
    }
}
