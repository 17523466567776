<input 
    #dateBox 
    type="text"
    unmask="typed"
    [style.borderColor]="errorList?.length > 0 ? null : (overrideBorderColor ? overrideBorderColor : null)"
    [class.has-error]="errorList?.length > 0"
    [disabled]="isDisabled"
    [imask]="mask"
    [ngModel]="formattedValueDate"
    (keydown)="onKeyDown($event)" 
    (focus)="onFocus($event)"
    (blur)="onBlur()"
    (mouseenter)="mouseEnter($event)"  
    (mouseleave)="mouseLeave($event)"
    (ngModelChange)="onModelChange($event)">