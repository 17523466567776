import { NTSReflection, UUIDHelper } from '@nts/std/utility';
import { InternalInspector } from './decorators/internal.decorator';
import { BaseError } from '../messages/base-error';
import { validateSync } from 'class-validator';
import { ExternalInspector } from './decorators/external.decorator';
import { ModelTypeNameInspector } from './decorators/model-type-name.decorator';
import { IdentityTypeNameInspector } from './decorators/identity-type-name.decorator';
import { LogService } from '@nts/std/utility';
import { classToPlain } from '@nts/std/serialization';
import { BigNumber } from '@nts/std/types';
import { ValidatableModelInterface } from '@nts/std/interfaces';


export abstract class ValidatableModel implements ValidatableModelInterface {

    // Necessario per identificare univocamente un model
    readonly uniqueId = UUIDHelper.generateUUID();

    private _parentPropertyName: string;
    private _parent: ValidatableModelInterface;

    get parent(): ValidatableModelInterface {
        return this._parent;
    }

    get parentPropertyName(): string {
        return this._parentPropertyName;
    }

    setParent(parent: ValidatableModelInterface, parentPropertyName: string) {
        this._parent = parent;
        this._parentPropertyName = parentPropertyName;
    }

    private _modelTypeName: string;

    get modelTypeName(): string {
        return this._modelTypeName;
    }

    constructor(isIdentity?: boolean) {

        this._modelTypeName = isIdentity ? IdentityTypeNameInspector.getValue(this) : ModelTypeNameInspector.getValue(this);

        if (this.modelTypeName === undefined) {
            const message = isIdentity ?
                `MetaData ${IdentityTypeNameInspector.META_DATA_KEY} not defined. You must use ${IdentityTypeNameInspector.DECORATOR_NAME} in ${this.constructor.name}.` :
                `MetaData ${ModelTypeNameInspector.META_DATA_KEY} not defined. You must use ${ModelTypeNameInspector.DECORATOR_NAME} in ${this.constructor.name}.`;
            LogService.error(message);
            throw new Error(message);
        }
    }

    getPropertyNames(): string[] {
        return NTSReflection.getPropertyNames(this);
    }

    getScalarPropertyNames(): string[] {
        return [];
        // {
        //     List<string> propNames = new List<string>();

        //     foreach (var propInfo in GetType().GetProperties(BindingFlags.Instance | BindingFlags.Public | BindingFlags.FlattenHierarchy)
        //             .Where((pi) => !pi.PropertyType.IsAssignableFrom(typeof(ValidatableModel))))
        //     {
        //         propNames.Add(propInfo.Name);
        //     }
        //     return propNames;
        // }
    }

    getInternalModelsPropertyNames(): string[] {
        return this.getAllInternalModelsPropertyNames()
            .filter((propertyName) =>
                !InternalInspector.isCollection(this, propertyName)
            );
    }

    getAllInternalModelsPropertyNames(): string[] {
        const propNames = this.getPropertyNames();
        return propNames.filter(propertyName =>
            InternalInspector.isApplied(this, propertyName));
    }

    getCollectionPropertyNames(): string[] {
        return this.getAllInternalModelsPropertyNames()
            .filter((propertyName) =>
                InternalInspector.isCollection(this, propertyName)
            );
    }

    getExternalModelsPropertyNames(): string[] {
        return [];
        // List<string> propNames = new List<string>();

        //     foreach (var propInfo in GetType().GetProperties(BindingFlags.Instance | BindingFlags.Public | BindingFlags.FlattenHierarchy)
        //             .Where((pi) => pi.PropertyType.IsAssignableFrom(typeof(ValidatableModel))
        //                 && (pi.GetCustomAttributes<ExcludeFromDomainModelAttribute>().Count() == 0)
        //                 && (pi.GetCustomAttributes<ExternalDomainModelAttribute>().Count() > 0)
        //                 )
        //             )
        //     {
        //         propNames.Add(propInfo.Name);
        //     }
        //     return propNames;
    }

    getPropertyValue<TValue>(propertyName: string): TValue {
        return NTSReflection.getTypedPropertyValue<TValue>(propertyName, this) as TValue;
    }

    setPropertyValue<TValue>(propertyName: string, newValue: any): void {
        if (this.compareValues(this.getPropertyValue(propertyName), newValue)) {
            return;
        }
        NTSReflection.setPropertyValue<TValue>(propertyName, this, newValue);
    }

    validate(
        errorList: BaseError[] = new Array<BaseError>()
    ): boolean {
        const validationErrors = validateSync(this, { skipMissingProperties: true });

        if (validationErrors.length > 0) {
            validationErrors.forEach(value => {
                BaseError.create(value, this.modelTypeName).forEach(messageError => {
                    if (
                        errorList.find(e => e.description === messageError.description && e.objectName === messageError.objectName) == null
                    ) {
                        errorList.push(messageError);
                    }
                });
            });
        }

        return errorList.length === 0;
    }

    validateProperty(
        propertyName: string,
        baseErrors: BaseError[] = new Array<BaseError>()
    ): boolean {
        let propertyIsValid = true;
        const validationErrors = validateSync(this, { skipMissingProperties: true });
        validationErrors.forEach(ve => {
            if (ve.property === propertyName) {
                propertyIsValid = false;
                BaseError.create(ve, this.modelTypeName).forEach(e => {
                    baseErrors.push(e);
                });
            }
        });
        return propertyIsValid;
    }

    validateAllProperties(errorList: BaseError[], recursive: boolean, traversedEntities: Array<ValidatableModel> = []): boolean {
        if (errorList == null) {
            throw Error('validationResults could not be null.');
        }
        if (traversedEntities.indexOf(this) > -1) {
            return errorList.length > 0;
        }

        const nonInternalPropNames = this.getPropertyNames().filter(propertyName => {
            return !NTSReflection.hasPropertyMetadata('associationPropertiesMetaDataKey', this, propertyName);
        });

        for (const propName of nonInternalPropNames) {
            this.validateProperty(propName, errorList);
        }
        traversedEntities.push(this);

        if (recursive) {
            const internalOrExternalPropNames = this.getPropertyNames().filter(propertyName => {
                return NTSReflection.hasPropertyMetadata('associationPropertiesMetaDataKey', this, propertyName)
                    || ExternalInspector.isApplied(this, propertyName);
            });

            for (const propName of internalOrExternalPropNames) {
                const entity = this.getPropertyValue(propName) as ValidatableModel;
                if (entity != null && entity.validateAllProperties != null) {
                    entity.validateAllProperties(errorList, recursive, traversedEntities);
                }

                // const collection = this.getPropertyValue(propName);
                // if (collection != null && collection instanceof CoreModelCollection) {
                //   for (const item of collection.collectionItems) {
                //     if (item != null && item.validateAllProperties != null) {
                //       item.validateAllProperties(errorList, recursive, traversedEntities);
                //     }
                //   }
                // }
            }
        }

        return errorList.length > 0;
    }

    toString(): string {
        const res = JSON.stringify(classToPlain(this, { strategy: 'excludeAll' }));
        return res;
    }

    compareValues(v1: any, v2: any, operator: string = '==') {
        if (BigNumber.isBigNumber(v1) || BigNumber.isBigNumber(v2)) {
            return BigNumber.compareValues(v1, v2, operator)
        }

        switch (operator) {
            case '==':
                return v1 == v2;
            default:
                throw new Error(`not implemented sign ${operator} in compareValues`)
        }
    }
}
