import { Component } from '@angular/core';
import { BasePropertyComponent } from './base-property-component';
import moment from 'moment';
import IMask from 'imask';
import { DateTimeOffset } from '@nts/std/types';

@Component({
    template: ''
})
export abstract class BaseDatePropertyComponent<T extends DateTimeOffset | Date> extends BasePropertyComponent<T> {

    protected getSettingForInputMask(nullable: boolean) {

        let inputFormat = moment().localeData().longDateFormat('L');
        if (this.propertyViewModel['showTime'] === true) {
            inputFormat += ' HH:mm';
        }
        
        const mask =  {
            mask: Date,
            pattern: inputFormat,
            lazy: false,
            //min: new Date(1970, 0, 1),
            //max: new Date(2030, 0, 1),        
            format: function (date) {
                return moment(date).format(inputFormat);
            },
            parse: function (str) {
                return moment(str, inputFormat);
            },        
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: 9999
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31
                },
                HH: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23
                },
                mm: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 59
                }
            }
        }
        return mask;
    }
}
