import { BigNumber } from '@nts/std/types';
import { PropertyViewModel } from '../../../lib/view-models/property-view-model';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';

export class VersionPropertyViewModel extends PropertyViewModel<string> {

    override get value(): string {
        // Casistica in cui abbiamo il decoratore custom property view model impostato
        if (this._isCustom && this.customGetter == null) {
            throw new Error(MessageResourceManager.Current.getMessage('MissingGetterError'));
        }
        // Casistica in cui viene ridefinito il custom getter
        // Di default viene definito in tutti gli external view model
        return this.getValue();
    }

    override set value(updatedValue: string) {
        this.setValueAsync(updatedValue);
    }

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
    }

    override async setValueAsync(updatedValue: string) {
        if (this._isCustom && this.customSetter == null) {
            throw new Error(MessageResourceManager.Current.getMessage('MissingSetterError'));
        }

        // Check undefined and null
        if (this.value != updatedValue) {
            if (this.customSetter != null) {
                await this.customSetter(updatedValue);

                if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }

                this.onPropertyChanged(this.bindedValuePropertyName);
            } else {
                // quano faccio il sync model scatta onPropertyChanged partendo dal modello
                this.syncModel(updatedValue);

                if (this.model != null) {
                    this.validate();
                } else if (this.parent != null && this.parent.validate) {
                    this.parent.validate();
                }
            }
            this.formattedValue$.next(this.formattedValue);
            this.value$.next(this.value);
        }
    }

    setDefaultValueFromLayoutMetaData(value: string | number | BigNumber | boolean) {
        // quando è un version non esiste la gestione del default value da template
    }

    getFormattedValue(updatedValue: string) {
        // Uso Nullish Coalescing - richiede Typescrypt >= 3.7
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#nullish-coalescing
        return updatedValue ?? '';
    }
}
