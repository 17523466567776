import { Expose } from "@nts/std/serialization";
import { PropertyMetaData } from './property-meta-data';
import { BigNumber } from "@nts/std/types";
import { NumericMetaDataInterface } from "@nts/std/interfaces";

export class NumericMetaData extends PropertyMetaData implements NumericMetaDataInterface {

    constructor() {
        super();
    }

    /**
     * Eventuale valore minimo ammesso
     */
    @Expose()
    minValue?: BigNumber | number;

    /**
     * Eventuale valore massimo ammesso
     */
    @Expose()
    maxValue?: BigNumber | number;

    /**
     * Eventuale valore minimo
     */
    @Expose()
    minDecimalValue?: BigNumber | number;;

    /**
     * Eventuale valore massimo
     */
    @Expose()
    maxDecimalValue?: BigNumber|number;;

    /**
     * Numero massimo cifre per la parte decimale
     */
    @Expose()
    maxDecimalPrecision?: number

    /**
     * Numero massimo cifre per la parte intera
     */
    @Expose()
    maxIntegerPrecision?: number;

    /**
     * Viene calcolata automaticamete? utile per mettere in readonly le identity
     */
    @Expose()
    isAutoComputed?: number;

    /**
     * Il metadata rappresenta un numero decimale
     */
    @Expose()
    isDecimal: boolean;

    protected override _type = 'Numeric';

    override set(data: any) {
        super.set(data);
        if (data?.minValue != null) {
            this.minValue = data.minValue;
        }
        if (data?.maxValue != null) {
            this.maxValue = data.maxValue;
        }
        if (data?.minDecimalValue != null) {
            this.minDecimalValue = data.minDecimalValue;
        }
        if (data?.maxDecimalValue != null) {
            this.maxDecimalValue = data.maxDecimalValue;
        }
        if (data?.maxDecimalPrecision != null) {
            this.maxDecimalPrecision = data.maxDecimalPrecision;
        }
        if (data?.maxIntegerPrecision != null) {
            this.maxIntegerPrecision = data.maxIntegerPrecision;
        }
        if (data?.isAutoComputed != null) {
            this.isAutoComputed = data.isAutoComputed;
        }
    }
}
