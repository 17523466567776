import { RootViewModel } from './root-view-model';
import { VersionPropertyViewModel } from './base-type/version-property-view-model';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class OCCRootViewModel<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends RootViewModel<TModel, TIdentity>  {

    get occ(): VersionPropertyViewModel {
        return this.getVersionPropertyViewModel((value) => { this._occ = value; }, this._occ, 'occ');
    }

    private _occ: VersionPropertyViewModel;
    override async postInit() {

        await super.postInit();
    }
}
