import { Expose } from "@nts/std/serialization";
import { BaseIdentity } from "../base-identity";
import { IdentityTypeNameDecorator } from "../decorators/identity-type-name.decorator";
import { ModelInterface } from '@nts/std/interfaces';

@IdentityTypeNameDecorator('UserZoomIdentity')
export class UserZoomIdentity extends BaseIdentity {

    // tslint:disable-next-line: variable-name
    private _userId: number;

    @Expose()
    public get userId(): number {
        return this.getValue<number>(() => this._userId, 'userId');
    }
    public set userId(value: number) {
        this.setValue<number>(() => { this._userId = value; }, value, 'userId');
    }

    // tslint:disable-next-line: variable-name
    private _zoomId: number;

    @Expose()
    public get zoomId(): number {
        return this.getValue<number>(() => this._zoomId, 'zoomId');
    }
    public set zoomId(value: number) {
        this.setValue<number>(() => { this._zoomId = value; }, value, 'zoomId');
    }

    constructor(
        parentModel: ModelInterface = null, 
        userId: number = undefined,
        zoomId: number = undefined
    ) {
        super(parentModel);
        this.zoomId = zoomId;
        this.userId = userId;
    }
}