import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ToastMessage, ToastMessageType } from './toast-message';
import { ServiceResponse } from '../../../responses/service-response';
import { UUIDHelper } from '@nts/std/utility';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { MessageCodes } from '../../../resources/message-codes';
import { UICommandInterface } from '../../../view-models/commands/ui-command.interface';
import { FilledButtonType } from '../../shared/buttons/filled-button/filled-button.component';
import { CommandFactory } from '../../../view-models/commands/command-factory';
import { ModalService } from '../../../view-models/modal/modal.service';


@Injectable()
export class ToastMessageService {

  constructor(
    private messageService: MessageService,
    private modalService: ModalService
  ) {

  }

  /**
   * Visualizza un toast utilizzando una lista di comandi per popolare le sue azioni
   * 
   * @param toastMessage                        example of ToastMessage: 
   *                                            {
   *                                              type: ToastMessageType.info,
   *                                              data: {
   *                                                summary$: new BehaviorSubject<string>('summay string'),
   *                                                commands$: new BehaviorSubject<{
   *                                                  command: UICommandInterface,
   *                                                  data: any,
   *                                                  type: FilledButtonType
   *                                                }[]>(
   *                                                  [
   *                                                    {
   *                                                      command: customCommand,
   *                                                      data: dataToPassToCommand,
   *                                                      type: FilledButtonType.Info
   *                                                    }
   *                                                  ]
   *                                                )
   *                                              }
   *                                            }
   * @param key                                 key per definire il template del toast da utilizzare
   * @param clearPreviousMessagesWithSameKey    pulisco i toast precedenti con lo stesso key
   * @param styleClass                          stile custom per il toast
   * @returns ritorna una promessa che viene risolta con il command che viene eseguito o null se viene chiuso il toast
   */
  showToastWithCommand(
    toastMessage: ToastMessage,
    key = 'with-commands-top-center',
    clearPreviousMessagesWithSameKey = true,
    styleClass = 'toast-with-command-top-center'
  ): Observable<UICommandInterface> {
    const commandExecuted = new Subject<UICommandInterface>();
    const id = UUIDHelper.generateUUID();
    if (clearPreviousMessagesWithSameKey) {
      this.clear(key);
    }

    this.messageService.add({
      key,
      severity: toastMessage.type,
      summary: toastMessage.title,
      detail: toastMessage.message,
      id,
      data: { ...toastMessage.data, commandExecuted },
      life: toastMessage.time,
      sticky: true,
      closable: toastMessage.closable,
      styleClass
    });
    return commandExecuted.asObservable()
      .pipe(
        takeUntil(this.messageService.clearObserver.pipe(filter(k=> k=== key))),
        take(1)
      )
  }

  // TODO fare porting alla versione con i comandi
  showUpdateConfirmToast(
    toastMessage: ToastMessage, 
    confirmLabel = 'Ok', 
    confirmIcon = 'refresh',
    key = 'update',
    clearPreviousMessagesWithSameKey = true
  ): Observable<Message> {

    if (clearPreviousMessagesWithSameKey) {
      this.clear(key);
    }

    const id = UUIDHelper.generateUUID();
    const confirmEvent = new Subject<Message>();

    this.messageService.add({
      key,
      severity: toastMessage.type,
      summary: toastMessage.title,
      detail: toastMessage.message,
      id,
      data: { ...toastMessage.data, confirmLabel, confirmIcon, confirmEvent },
      life: toastMessage.time,
      sticky: true,
      closable: toastMessage.closable
    });
    return confirmEvent.asObservable()
      .pipe(
        takeUntil(this.messageService.clearObserver.pipe(filter(k => k === key))),
        take(1)
      )
  }

  clear(key?: string) {
    this.messageService.clear(key);
  }

  showToast(toastMessage: ToastMessage) {

    this.messageService.add({
      severity: toastMessage.type,
      summary: toastMessage.title,
      detail: toastMessage.message,
      id: toastMessage.id,
      data: toastMessage.data,
      life: toastMessage.time,
      key: toastMessage.key,
      sticky: toastMessage.sticky,
      closable: toastMessage.closable
    });
  }

  showToastsFromResponse(response: ServiceResponse, id?: string) {

    const showMoreCommand = CommandFactory.createUICommand(async (error) => {
      this.modalService.showExceptionPopUpFromBaseErrors({
        errors: [error]
      })
    })
    showMoreCommand.displayName = 'Maggiori Informazioni'

    if (response?.errors instanceof Array) {
      for (const error of response?.errors) {

        const toast = {
          message: error.description,
          type: ToastMessageType.error
        } as ToastMessage;

        if (id != null) {
          toast.id = id;
        }

        if (error.stackTrace?.length > 0 || error.requestInfo != null) {
          
            toast.data = {
              commands$: new BehaviorSubject<{
                command: UICommandInterface,
                data: any,
                type: FilledButtonType
              }[]>(
                [
                  {
                    command: showMoreCommand,
                    data: error,
                    type: FilledButtonType.Info
                  }
                ]
              )
            }
          this.showToastWithCommand(toast, undefined, true);
        } else {
          this.showToast(toast);
        }
        
      }
    }

    if (response?.warnings instanceof Array) {
      for (const warning of response?.warnings) {

        const toast = {
          title: MessageResourceManager.Current.getMessage(MessageCodes.Warning),
          message: warning.description,
          type: ToastMessageType.error
        } as ToastMessage;

        if (id != null) {
          toast.id = id;
        }

        this.showToast(toast);
      }
    }

    if (response?.informations instanceof Array) {
      for (const information of response?.informations) {

        const toast = {
          title: MessageResourceManager.Current.getMessage(MessageCodes.Information),
          message: information.description,
          type: ToastMessageType.info
        } as ToastMessage;

        if (id != null) {
          toast.id = id;
        }

        this.showToast(toast);
      }
    }
  }

  /*
  showAvviso(avviso: Avviso) {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: `[${avviso.strCodice}] ${avviso.strNomeProp}, ${avviso.strMessaggio}`
    });
  }

  showErrore(errore: Errore) {
    this.messageService.add({
      severity: 'error',
      summary: 'Errore',
      detail: `[${errore.strCodice}] ${errore.strNomeProp}, ${errore.strMessaggio}`
    });
  }

  showEccezione(eccezione: Eccezione) {
    this.messageService.add({
      severity: 'error',
      summary: 'Eccezione',
      detail: `[${eccezione.strCodice}] ${eccezione.strEccezione}, ${eccezione.strNomeComp} - ${eccezione.strStackTrace}`
    });
  }
  */
}
