<div class="aside-left-control-top-content" *ngIf="masterViewModel.search.isVisible$ | async">
    <div class="search-container">
        <nts-text-button [icon]="'search'"
            (onClicked)="masterViewModel.search.execSearch(masterViewModel.search.value)"></nts-text-button>
        <nts-search-text-box [propertyViewModel]="masterViewModel.search" placeholder="Search">
        </nts-search-text-box>
    </div>
</div>
<div
    [ngClass]="{'aside-left-control-bottom-content': masterViewModel.search.isVisibleExtendedFilterTitle$ | async, 'aside-left-control-bottom-content-without-title': !(masterViewModel.search.isVisibleExtendedFilterTitle$ | async)}">
    <div class="filter-pane-content">

        <div *ngIf="masterViewModel.showExtendedFilter">

            <div class="caption-panel">
                <nts-label-box *ngIf="masterViewModel.search.isVisibleExtendedFilterTitle$ | async"
                    [label]="masterViewModel.search.extendedFilterTitle"></nts-label-box>
            </div>
            <div class="">
                <div class="filter-lines-container">
                    <div class="advanced-list-filter-line" *ngFor="let filter of masterViewModel.extendedFiltersList">
                        <div class="header">
                            <div class="caption-container">
                                <nts-label-box [title]="filter.property.metadataDescription" [label]="filter.property.metadataShortDescription"></nts-label-box>
                            </div>
                        </div>

                        <nts-string-text-box *ngIf="isString(filter.property)" [multiline]="false"
                            (keyDown)="onDown($event, filter.property)" [propertyViewModel]="filter.property" [updateOnBlur]="false">
                        </nts-string-text-box>

                        <nts-numeric-text-box *ngIf="isNumeric(filter.property)" (keyDown)="onDown($event, filter.property)"
                            [propertyViewModel]="filter.property" [updateOnBlur]="false">
                        </nts-numeric-text-box>

                        <nts-enum-text-box [appendTo]="'body'" *ngIf="isEnum(filter.property)"
                            (keyDown)="onDown($event, filter.property)" [propertyViewModel]="filter.property">
                        </nts-enum-text-box>

                        <nts-toggle-text-box *ngIf="isBool(filter.property)" (keyDown)="onDown($event, filter.property)"
                            [propertyViewModel]="filter.property">
                        </nts-toggle-text-box>

                        <nts-date-text-box *ngIf="isDate(filter.property)" (keyDown)="onDown($event, filter.property)"
                            [propertyViewModel]="filter.property">
                        </nts-date-text-box>

                        <nts-date-offset-text-box *ngIf="isDateTimeOffset(filter.property)" (keyDown)="onDown($event, filter.property)"
                            [propertyViewModel]="filter.property">
                        </nts-date-offset-text-box>

                        <nts-enum-text-box *ngIf="isOffset(filter.property)" (keyDown)="onDown($event, filter.property)"
                            [propertyViewModel]="filter.property">
                        </nts-enum-text-box>

                        <nts-ext-new-autocomplete-text-box
                            *ngIf="isExternal(filter.property)"
                            [appendTo]="'body'"
                            (keyDown)="onDown($event, filter.property)"
                            [externalPropertyViewModel]="filter.property"
                            [decodeProperties]="filter.property.decodeProperties"
                            [searchProperties]="filter.property.searchProperties"
                            [fieldSeparator]="filter.property.fieldSeparator"
                            [showCodeInDescription]="filter.property.showCode"
                            [updateOnBlur]="true">
                        </nts-ext-new-autocomplete-text-box>

                    </div>
                </div>
            </div>
        </div>

        <div class="grid-container">
            <nts-loader [isVisible]="masterViewModel.actionInProgress$ | async">
            </nts-loader>
            <nts-grid class="full-column" (rowDoubleClick)="selectRow()" (keydown.enter)="selectRow()"
                (onGridOptionsReady)="onGridOptionsReady($event)" [showErrorsInCells]="false"
                [collectionViewModel]="masterViewModel.searchResult" [isDisabled]="true" [autoCreateRow]="false"
                [minRowNumberHeight]="0" [pagination]="true" [defaultCommandsName]="[]"
                [columns]="masterViewModel.searchResultColumns"
                (onColumnsChanged)="masterViewModel.onSearchColumnsChanged($event)"
                [customViewCommandsName]="['toggleExtendedFilterCommand']">
            </nts-grid>
        </div>

    </div>
</div>
