import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { UICommandInterface } from "../../../../../view-models/commands/ui-command.interface";
import _Inputmask from 'inputmask';
import { AsyncPipe, NgClass, NgFor } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PopperHelper } from "@nts/std/utility";
import { TimeSpan } from "@nts/std/types";

const InputmaskConstructor = (_Inputmask as any).default || _Inputmask;

@Component({
    selector: 'nts-base-time-span-text-box',
    templateUrl: './base-time-span-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./base-time-span-text-box.component.scss'],
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgClass,
        FormsModule,
        NgFor,
        AsyncPipe
    ]
})
export class BaseTimeSpanTextBoxComponent implements OnInit, OnDestroy, OnChanges {
    @Input() value: TimeSpan|null|'' = null;
    @Input() isRequired = false;
    @Input() isDisabled = false;
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandList: UICommandInterface[] = [];
    @Input() min: TimeSpan;
    @Input() max: TimeSpan;
    @Input() showDays: boolean = false;
    @Input() showSeconds: boolean = true;
    @Input() initialChar: string | null = null;
    @Input() listenClickOutside = false;
    @Input() tabIndex = -1;
    @Input() customClasses = '';
    @Input() placeholder = '';
    @Input() primaryColor = null;
    
    @Output() onValueChange = new EventEmitter<TimeSpan>();
    @Output() onFocus = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @Output() onFinishEditing = new EventEmitter();

    @Output() onTimeZoneChange = new EventEmitter();
    @Output() onTimeZoneBlur = new EventEmitter();
    @Output() keyDown = new EventEmitter();
    
    @ViewChild('textBox', { static: true }) textBox: ElementRef;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError?: NgxPopperjsDirective;

    inputMask = null;
    stringValue = null;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;

    get input(): HTMLInputElement {
        return this.textBox.nativeElement;
    }

    private documentClickListener: any;

    constructor(
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['value']) {
            this.setValueFromOutside(changes['value'].currentValue);
        }
        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    valueChange(value: string): void {
        if (value?.length > 0) {
            this.onValueChange.emit(TimeSpan.formatFromString(value));
        } else {
            this.onValueChange.emit(null);
        }
        
    }

    ngAfterViewInit(): void {

        const inputMaskSettings = this.getSettingForInputMask(!this.isRequired);
        const mask = new InputmaskConstructor(inputMaskSettings);
        mask.mask(this.input);

        // this.inputMask = createMask('99:99:99AAA-999');

        if (this.initialChar && this.initialChar.length > 0) {
            this.value = null;

            setTimeout(() => {
                this.input.value = this.initialChar;
                // this.input.dispatchEvent(new KeyboardEvent('keydown', {'key': this.initialChar}));
                if (parseInt(this.initialChar) > 1) {
                    if (this.initialChar === this.input.value.charAt(0)) {
                        this.input.setSelectionRange(1, 2);
                    } else {
                        this.input.setSelectionRange(3, 4);
                    }
                } else {
                    this.input.setSelectionRange(1, 2);
                }
            }, 10);
        }
    }

    ngOnDestroy() {
        this.unbindDocumentClickListener();
    }

    blur(e): void {
        this.popperError?.hide();
        this.onBlur.emit(e);
    }

    focus(e): void {
        this.onFocus.emit(e);
    }

    private setValueFromOutside(newValue): void {
        if (newValue != null) {
            this.stringValue = TimeSpan.formatToString(newValue, this.showDays, this.showSeconds, false);
        } else {
            this.stringValue = null
        }
        this.cd.detectChanges();
    }

    private getSettingForInputMask(nullable: boolean): any {

        let mask = '99:99';
        if (this.showSeconds) {
            mask = mask + ':99';
        }
        if (this.showDays) {
            mask = '9.' +  mask;
        }

        let placeholder = 'hh:mm';
        if (this.showSeconds) {
            placeholder = placeholder + ':ss';
        }
        if (this.showDays) {
            placeholder = 'd.' +  placeholder;
        }

        let inputFormat = 'HH:MM';
        if (this.showSeconds) {
            inputFormat = inputFormat + ':ss';
        }
        if (this.showDays) {
            inputFormat = 'd.' +  inputFormat;
        }

        return {
            nullable,
            mask,
            positionCaretOnClick: 'lvp',
            rightAlign: false,
            showMaskOnHover: false,
            showMaskOnFocus: true,
            clearMaskOnLostFocus: true,
            positionCaretOnTab: false,
            placeholder,
            insertMode: false,
            shiftPositions: false,
            keepStatic: false,
            inputmode: "numeric",
            preValidation: this.customPrevalidation.bind(this),
            postValidation: this.customPostValidation.bind(this),
            inputFormat,
            prefillSeconds: true,
            prefillMinutes: true,            

        };
    }

    private bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    private isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    // #region  INPUTMASK UTILITY
    private formatCode = { //regex, valueSetter, type, displayformatter, #entries (optional)
        d: ["[1-9]|[12][0-9]|3[01]", Date.prototype.setDate, "day", Date.prototype.getDate], //Day of the month as digits; no leading zero for single-digit days.
        dd: ["0[1-9]|[12][0-9]|3[01]", Date.prototype.setDate, "day", () => {
            return this.pad(Date.prototype.getDate.call(this), 2);
        }], //Day of the month as digits; leading zero for single-digit days.
        ddd: [""], //Day of the week as a three-letter abbreviation.
        dddd: [""], //Day of the week as its full name.
        m: ["[1-9]|1[012]", (val) => {
            let mval = val ? parseInt(val) : 0;
            if (mval > 0) mval--;
            return Date.prototype.setMonth.call(this, mval);
        }, "month", () => {
            return Date.prototype.getMonth.call(this) + 1;
        }], //Month as digits; no leading zero for single-digit months.
        mm: ["0[1-9]|1[012]", (val) => {
            let mval = val ? parseInt(val) : 0;
            if (mval > 0) mval--;
            return Date.prototype.setMonth.call(this, mval);
        }, "month", () => {
            return this.pad(Date.prototype.getMonth.call(this) + 1, 2);
        }], //Month as digits; leading zero for single-digit months.
        mmm: [""], //Month as a three-letter abbreviation.
        mmmm: [""], //Month as its full name.
        yy: ["[0-9]{2}", Date.prototype.setFullYear, "year", () => {
            return this.pad(Date.prototype.getFullYear.call(this), 2);
        }], //Year as last two digits; leading zero for years less than 10.
        yyyy: ["[0-9]{4}", Date.prototype.setFullYear, "year", () => {
            return this.pad(Date.prototype.getFullYear.call(this), 4);
        }],
        h: ["[1-9]|1[0-2]", Date.prototype.setHours, "hours", Date.prototype.getHours], //Hours; no leading zero for single-digit hours (12-hour clock).
        hh: ["0[1-9]|1[0-2]", Date.prototype.setHours, "hours", () => {
            return this.pad(Date.prototype.getHours.call(this), 2);
        }], //Hours; leading zero for single-digit hours (12-hour clock).
        hx: [(x) => {
            return `[0-9]{${x}}`;
        }, Date.prototype.setHours, "hours", (x) => {
            return Date.prototype.getHours;
        }], //Hours; no limit; set maximum digits
        H: ["1?[0-9]|2[0-3]", Date.prototype.setHours, "hours", Date.prototype.getHours], //Hours; no leading zero for single-digit hours (24-hour clock).
        HH: ["0[0-9]|1[0-9]|2[0-3]", Date.prototype.setHours, "hours", () => {
            return this.pad(Date.prototype.getHours.call(this), 2);
        }], //Hours; leading zero for single-digit hours (24-hour clock).
        Hx: [(x) => {
            return `[0-9]{${x}}`;
        }, Date.prototype.setHours, "hours", (x) => {
            return () => {
                return this.pad(Date.prototype.getHours.call(this), x);
            };
        }], //Hours; no limit; set maximum digits
        M: ["[1-5]?[0-9]", Date.prototype.setMinutes, "minutes", Date.prototype.getMinutes], //Minutes; no leading zero for single-digit minutes. Uppercase M unlike CF timeFormat's m to avoid conflict with months.
        MM: ["0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]", Date.prototype.setMinutes, "minutes", () => {
            return this.pad(Date.prototype.getMinutes.call(this), 2);
        }], //Minutes; leading zero for single-digit minutes. Uppercase MM unlike CF timeFormat's mm to avoid conflict with months.
        s: ["[1-5]?[0-9]", Date.prototype.setSeconds, "seconds", Date.prototype.getSeconds], //Seconds; no leading zero for single-digit seconds.
        ss: ["0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]", Date.prototype.setSeconds, "seconds", () => {
            return this.pad(Date.prototype.getSeconds.call(this), 2);
        }], //Seconds; leading zero for single-digit seconds.
        l: ["[0-9]{3}", Date.prototype.setMilliseconds, "milliseconds", () => {
            return this.pad(Date.prototype.getMilliseconds.call(this), 3);
        }, 3], //Milliseconds. 3 digits.
        L: ["[0-9]{2}", Date.prototype.setMilliseconds, "milliseconds", () => {
            return this.pad(Date.prototype.getMilliseconds.call(this), 2);
        }, 2], //Milliseconds. 2 digits.
        o: [""], //GMT/UTC timezone offset, e.g. -0500 or +0230.
        S: [""] //The date's ordinal suffix (st, nd, rd, or th).
    }

    private customPostValidation(buffer, pos, c, currentResult, opts, maskset, strict, fromCheckval) {
        // var result, dateParts = this.analyseMask(buffer.join(""), opts.inputFormat, opts);
        if (this.showSeconds && !this.showDays && opts.prefillSeconds && currentResult && pos == 4) {
            return this.prefillSecondsHHMMSS(opts, currentResult, buffer);
        } else if(!this.showSeconds && !this.showDays && opts.prefillMinutes && currentResult) {
            return this.prefillMinutesHHMM(opts, currentResult, buffer);
        }
        return true
    }

    private customPrevalidation(buffer, pos, c, isSelection, opts, maskset, caretPos, strict) {
        c = c == '.' ? ':' : c;
        if (strict) return true;
        if (isNaN(c) && buffer[pos] !== c) {
            var tokenMatch = this.getTokenMatch(pos, opts);
            if (tokenMatch.nextMatch && tokenMatch.nextMatch[0] === c && tokenMatch.targetMatch[0].length > 1) {
                var validator = this.formatCode[tokenMatch.targetMatch[0]][0];
                if (new RegExp(validator).test("0" + buffer[pos - 1])) {
                    buffer[pos] = buffer[pos - 1];
                    buffer[pos - 1] = "0";
                    return {
                        fuzzy: true,
                        buffer: buffer,
                        refreshFromBuffer: {start: pos - 1, end: pos + 1},
                        pos: pos + 1
                    };
                }
            }
        }
        return true;
    }

    private prefillSecondsHHMMSS(opts, currentResult, buffer) {
        
        if (buffer[buffer.length - 2] !== 's' || buffer[buffer.length - 1] !== 's') {
            return false;
        }

        currentResult.insert = [{
            pos: 6,
            c: 0
        }, {
            pos: 7,
            c: 0
        }];
        return currentResult;

    }

    private prefillMinutesHHMM(opts, currentResult, buffer) {
        
        if (buffer[buffer.length - 2] !== 'm' || buffer[buffer.length - 1] !== 'm') {
            return currentResult;
        }

        currentResult.insert = [{
            pos: 3,
            c: 0
        }, {
            pos: 4,
            c: 0
        }];
        return currentResult;

    }

    private pad(val, len, right?) {
        val = String(val);
        len = len || 2;
        while (val.length < len) val = right ? val + "0" : "0" + val;
        return val;
    }

    private getTokenizer(opts) {
        if (!opts.tokenizer) {
            var tokens = [], dyntokens = [];
            for (var ndx in this.formatCode) {
                if (/\.*x$/.test(ndx)) {
                    var dynToken = ndx[0] + "\\d+";
                    if (dyntokens.indexOf(dynToken) === -1) {
                        dyntokens.push(dynToken);
                    }
                } else if (tokens.indexOf(ndx[0]) === -1) {
                    tokens.push(ndx[0]);
                }
            }
            opts.tokenizer = "(" + (dyntokens.length > 0 ? dyntokens.join("|") + "|" : "") + tokens.join("+|") + ")+?|.";
            opts.tokenizer = new RegExp(opts.tokenizer, "g");
        }
    
        return opts.tokenizer;
    }
    
    private getTokenMatch(pos, opts) {
        var calcPos = 0, targetMatch, match, matchLength = 0;
        this.getTokenizer(opts).lastIndex = 0;
        while ((match = this.getTokenizer(opts).exec(opts.inputFormat))) {
            var dynMatches = new RegExp("\\d+$").exec(match[0]);
            matchLength = dynMatches ? parseInt(dynMatches[0]) : match[0].length;
            calcPos += matchLength;
            if (calcPos >= pos + 1) {
                targetMatch = match;
                match = this.getTokenizer(opts).exec(opts.inputFormat);
                break;
            }
        }
        return {
            targetMatchIndex: calcPos - matchLength,
            nextMatch: match,
            targetMatch: targetMatch
        };
    }
    // #endregion  INPUTMASK UTILITY

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
        // if (this.popperInfo && this.errorList?.length > 0) {
        //     PopperHelper.hide(this.popperInfo);
        // }
    }
}