import { AfterViewInit, ChangeDetectionStrategy, Component, OnChanges, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { BaseExternalPropertyTextBox } from '../base-external-property-text-box';
import { NumericPropertyViewModel } from '../../../../../lib/view-models/base-type/numeric-property-view-model';
import { NNumericPropertyViewModel } from '../../../../../lib/view-models/base-type/nnumeric-property-view-model';
import { ExternalViewModelInterface } from '../../../../../lib/view-models/external-view-model.interface';
import { NumericTextBoxComponent } from '../numeric-text-box/numeric-text-box.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { BigNumber } from '@nts/std/types';

@UntilDestroy()
@Component({
    selector: 'nts-ext-numeric-text-box',
    templateUrl: './ext-numeric-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NumericTextBoxComponent
    ]
})
export class ExtNumericTextBoxComponent extends BaseExternalPropertyTextBox<BigNumber | number>
    implements OnInit, OnChanges, AfterViewInit, OnChanges {

    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;

    @ViewChild(NumericTextBoxComponent, { static: true }) numericTextBox: NumericTextBoxComponent;

    override propertyViewModel: NumericPropertyViewModel | NNumericPropertyViewModel;
    override externalPropertyViewModel: ExternalViewModelInterface;

    get input() {
        return this.numericTextBox.input;
    }

    get inputValue() {
        return this.numericTextBox.inputValue;
    }

    constructor(cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
        if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel!'); }
    }

    ngAfterViewInit(): void {
        this.init();
    }

    showErroTooltip(): void {
        this.numericTextBox.showErroTooltip();    
    }
    
    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }
}
