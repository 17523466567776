import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { UICommandInterface } from "../../../../../view-models/commands/ui-command.interface";
import { AppendFlags, ChangeDetails, ExtractFlags, Masked, MaskedNumber, MaskedNumberOptions, MaskedOptions, TailDetails } from 'imask';
import { IMaskDirective } from "angular-imask";
import { BaseNumericPropertyViewModel } from '../../../../../view-models/base-type/base-numeric-property-view-model';
import { FormsModule } from "@angular/forms";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { RibbonButtonComponent } from "../../../../shared/buttons/ribbon-button/ribbon-button.component";
import { PopperHelper } from "@nts/std/utility";
import { BehaviorSubject } from "rxjs";
import { Direction, DIRECTION, escapeRegExp } from "imask/esm/core/utils";
import { BigNumber } from "@nts/std/types";

export
type MaskedBigNumberOptions = MaskedOptions<NtsMaskedBigNumber,
  | 'radix'
  | 'thousandsSeparator'
  | 'mapToRadix'
  | 'scale'
  | 'min'
  | 'max'
  | 'normalizeZeros'
  | 'padFractionalZeros'
  | 'nullable'
>;

// import _Inputmask from 'inputmask';
// const InputmaskConstructor = (_Inputmask as any).default || _Inputmask;
export interface NumericMaskSettings {
    useThousandSeparator?: boolean,
    decimalLimit?: number;
    integerLimit?: number;
    min?: BigNumber|number;
    max?: BigNumber|number;
    nullable: boolean;
    isBigNumber?: boolean;
    faultBackValueFunc?: () => string;

}

export class NtsMaskedBigNumber extends Masked<BigNumber> {
  static UNMASKED_RADIX = '.';
  static override EMPTY_VALUES: Array<null | undefined | string | number> = [...Masked.EMPTY_VALUES, 0];
  static override DEFAULTS: Partial<MaskedBigNumberOptions> = {
    ...Masked.DEFAULTS,
    mask: Number,
    radix: ',',
    thousandsSeparator: '',
    mapToRadix: [
      MaskedNumber.UNMASKED_RADIX
    ],
    min: new BigNumber(Number.MIN_SAFE_INTEGER.toString()),
    max: new BigNumber(Number.MAX_SAFE_INTEGER.toString()),
    scale: 2,
    normalizeZeros: true,
    padFractionalZeros: false,
  };

  declare mask: NumberConstructor;
  /** Single char */
  declare radix: string;
  /** Single char */
  declare thousandsSeparator: string;
  /** Array of single chars */
  declare mapToRadix: Array<string>;
  /** */
  declare min: BigNumber;
  /** */
  declare max: BigNumber;
  /** Digits after point */
  declare scale: number;
  /** Flag to remove leading and trailing zeros in the end of editing */
  declare normalizeZeros: boolean;
  /** Flag to pad trailing zeros after point in the end of editing */
  declare padFractionalZeros: boolean;
  /** Enable characters overwriting */
  declare overwrite?: boolean | 'shift' | undefined;
  /** */
  declare eager?: boolean | 'remove' | 'append' | undefined;
  /** */
  declare skipInvalid?: boolean | undefined;
  /** */
  declare autofix?: boolean | 'pad' | undefined;
  /** Format typed value to string */
  declare format: (value: BigNumber, masked: Masked) => string;
  /** Parse string to get typed value */
  declare parse: (str: string, masked: Masked) => BigNumber;

  declare _numberRegExp: RegExp;
  declare _thousandsSeparatorRegExp: RegExp;
  declare _mapToRadixRegExp: RegExp;
  declare _separatorsProcessed: boolean;

  nullable = false;

  constructor (opts?: MaskedBigNumberOptions) {
    super({
      ...NtsMaskedBigNumber.DEFAULTS,
      ...opts,
    });
    this.nullable = opts.nullable;
  }

  override updateOptions (opts: Partial<MaskedNumberOptions>) {
    super.updateOptions(opts);
  }

  override _update (opts: Partial<MaskedNumberOptions>) {
    super._update(opts);
    this._updateRegExps();
  }

  _updateRegExps () {
    const start = '^' + (this.allowNegative ? '[+|\\-]?' : '');
    const mid = '\\d*';
    const end = (this.scale ?
      `(${escapeRegExp(this.radix)}\\d{0,${this.scale}})?` :
      '') + '$';

    this._numberRegExp = new RegExp(start + mid + end);
    this._mapToRadixRegExp = new RegExp(`[${this.mapToRadix.map(escapeRegExp).join('')}]`, 'g');
    this._thousandsSeparatorRegExp = new RegExp(escapeRegExp(this.thousandsSeparator), 'g');
  }

  _removeThousandsSeparators (value: string): string {
    return value.replace(this._thousandsSeparatorRegExp, '');
  }

  _insertThousandsSeparators (value: string): string {
    // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    const parts = value.split(this.radix);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);
    return parts.join(this.radix);
  }

  override doPrepareChar (ch: string, flags: AppendFlags={}): [string, ChangeDetails] {
    const [prepCh, details] = super.doPrepareChar(this._removeThousandsSeparators(
      this.scale && this.mapToRadix.length && (
        /*
          radix should be mapped when
          1) input is done from keyboard = flags.input && flags.raw
          2) unmasked value is set = !flags.input && !flags.raw
          and should not be mapped when
          1) value is set = flags.input && !flags.raw
          2) raw value is set = !flags.input && flags.raw
        */
        flags.input && flags.raw ||
        !flags.input && !flags.raw
      ) ? ch.replace(this._mapToRadixRegExp, this.radix) : ch
    ), flags);
    if (ch && !prepCh) details.skip = true;

    if (prepCh && !this.allowPositive && !this.value && prepCh !== '-') details.aggregate(this._appendChar('-'));

    return [prepCh, details];
  }

  _separatorsCount (to: number, extendOnSeparators: boolean=false): number {
    let count = 0;

    for (let pos = 0; pos < to; ++pos) {
      if (this._value.indexOf(this.thousandsSeparator, pos) === pos) {
        ++count;
        if (extendOnSeparators) to += this.thousandsSeparator.length;
      }
    }

    return count;
  }

  _separatorsCountFromSlice (slice: string=this._value): number {
    return this._separatorsCount(this._removeThousandsSeparators(slice).length, true);
  }

  override extractInput (fromPos: number=0, toPos: number=this.displayValue.length, flags?: ExtractFlags): string {
    [fromPos, toPos] = this._adjustRangeWithSeparators(fromPos, toPos);

    return this._removeThousandsSeparators(super.extractInput(fromPos, toPos, flags));
  }


  override _appendCharRaw (ch: string, flags: AppendFlags={}): ChangeDetails {

    const prevBeforeTailValue = flags.tail && flags._beforeTailState ?
      flags._beforeTailState._value :
      this._value;
    const prevBeforeTailSeparatorsCount = this._separatorsCountFromSlice(prevBeforeTailValue);
    this._value = this._removeThousandsSeparators(this.value);

    const oldValue = this._value;

    this._value += ch;

    const num = this.number;
    let accepted = !(new BigNumber(num).isNaN())
    let skip = false;

    if (accepted) {
      let fixedNum;
      if (this.min != null && this.number.toString().includes('-')) {
        if (this.number.toString().length > this.min.toString().length) {
          fixedNum = this.min
        }
      }
      if (this.max != null && !this.number.toString().includes('-')) {
        if (this.number.toString().length > this.max.toString().length) {
          fixedNum = this.max
        }
      }

      if (fixedNum != null) {
        if (this.autofix) {
          this._value = this.format(fixedNum, this).replace(MaskedNumber.UNMASKED_RADIX, this.radix);
          skip ||= oldValue === this._value && !flags.tail; // if not changed on tail it's still ok to proceed
        } else {
          accepted = false;
        }
      }
      accepted &&= Boolean(this._value.match(this._numberRegExp));
    }

    let appendDetails;
    if (!accepted) {
      this._value = oldValue;
      appendDetails = new ChangeDetails();
    } else {
      appendDetails = new ChangeDetails({
        inserted: this._value.slice(oldValue.length),
        rawInserted: skip ? '' : ch,
        skip,
      });
    }

    this._value = this._insertThousandsSeparators(this._value);
    const beforeTailValue = flags.tail && flags._beforeTailState ?
      flags._beforeTailState._value :
      this._value;
    const beforeTailSeparatorsCount = this._separatorsCountFromSlice(beforeTailValue);

    appendDetails.tailShift += (beforeTailSeparatorsCount - prevBeforeTailSeparatorsCount) * this.thousandsSeparator.length;
    return appendDetails;

    // if (!this.thousandsSeparator) return super._appendCharRaw(ch, flags);

    // const prevBeforeTailValue = flags.tail && flags._beforeTailState ?
    //   flags._beforeTailState._value :
    //   this._value;
    // const prevBeforeTailSeparatorsCount = this._separatorsCountFromSlice(prevBeforeTailValue);
    // this._value = this._removeThousandsSeparators(this.value);

    // const appendDetails = super._appendCharRaw(ch, flags);

    // this._value = this._insertThousandsSeparators(this._value);
    // const beforeTailValue = flags.tail && flags._beforeTailState ?
    //   flags._beforeTailState._value :
    //   this._value;
    // const beforeTailSeparatorsCount = this._separatorsCountFromSlice(beforeTailValue);

    // appendDetails.tailShift += (beforeTailSeparatorsCount - prevBeforeTailSeparatorsCount) * this.thousandsSeparator.length;
    // appendDetails.skip = !appendDetails.rawInserted && ch === this.thousandsSeparator;
    // return appendDetails;
  }

  get typedNullableValue() {
      if (this.nullable === true && this.value === '') {
          return null;
      }
      return this.typedValue;
  }

  set typedNullableValue(val) {
      if (!(this.nullable === true && this.value === '')) {
          this.typedValue = val;
      }
  }

//   override _appendCharRaw (ch: string, flags: AppendFlags={}): ChangeDetails {
//     const prevBeforeTailValue = flags.tail && flags._beforeTailState ?
//       flags._beforeTailState._value :
//       this._value;
//     const prevBeforeTailSeparatorsCount = this._separatorsCountFromSlice(prevBeforeTailValue);
//     this._value = this._removeThousandsSeparators(this.value);

//     const oldValue = this._value;

//     this._value += ch;

//     const num = this.number;
//     let accepted = BigNumber.isBigNumber(BigNumber(num));
//     let skip = false;

//     if (accepted) {
//       let fixedNum;
//       if (this.min != null && this.min < 0 && this.number < this.min) fixedNum = this.min;
//       if (this.max != null && this.max > 0 && this.number > this.max) fixedNum = this.max;

//       if (fixedNum != null) {
//         if (this.autofix) {
//           this._value = this.format(fixedNum, this).replace(MaskedNumber.UNMASKED_RADIX, this.radix);
//           skip ||= oldValue === this._value && !flags.tail; // if not changed on tail it's still ok to proceed
//         } else {
//           accepted = false;
//         }
//       }
//       accepted &&= Boolean(this._value.match(this._numberRegExp));
//     }

//     let appendDetails;
//     if (!accepted) {
//       this._value = oldValue;
//       appendDetails = new ChangeDetails();
//     } else {
//       appendDetails = new ChangeDetails({
//         inserted: this._value.slice(oldValue.length),
//         rawInserted: skip ? '' : ch,
//         skip,
//       });
//     }

//     this._value = this._insertThousandsSeparators(this._value);
//     const beforeTailValue = flags.tail && flags._beforeTailState ?
//       flags._beforeTailState._value :
//       this._value;
//     const beforeTailSeparatorsCount = this._separatorsCountFromSlice(beforeTailValue);

//     appendDetails.tailShift += (beforeTailSeparatorsCount - prevBeforeTailSeparatorsCount) * this.thousandsSeparator.length;
//     return appendDetails;
//   }

  _findSeparatorAround (pos: number): number {
    if (this.thousandsSeparator) {
      const searchFrom = pos - this.thousandsSeparator.length + 1;
      const separatorPos = this.value.indexOf(this.thousandsSeparator, searchFrom);
      if (separatorPos <= pos) return separatorPos;
    }

    return -1;
  }

  _adjustRangeWithSeparators (from: number, to: number): [number, number] {
    const separatorAroundFromPos = this._findSeparatorAround(from);
    if (separatorAroundFromPos >= 0) from = separatorAroundFromPos;

    const separatorAroundToPos = this._findSeparatorAround(to);
    if (separatorAroundToPos >= 0) to = separatorAroundToPos + this.thousandsSeparator.length;
    return [from, to];
  }


  override remove (fromPos: number=0, toPos: number=this.displayValue.length): ChangeDetails {
    [fromPos, toPos] = this._adjustRangeWithSeparators(fromPos, toPos);

    const valueBeforePos = this.value.slice(0, fromPos);
    const valueAfterPos = this.value.slice(toPos);

    const prevBeforeTailSeparatorsCount = this._separatorsCount(valueBeforePos.length);
    this._value = this._insertThousandsSeparators(this._removeThousandsSeparators(valueBeforePos + valueAfterPos));
    const beforeTailSeparatorsCount = this._separatorsCountFromSlice(valueBeforePos);

    return new ChangeDetails({
      tailShift: (beforeTailSeparatorsCount - prevBeforeTailSeparatorsCount) * this.thousandsSeparator.length,
    });
  }

  override nearestInputPos (cursorPos: number, direction?: Direction): number {
    if (!this.thousandsSeparator) return cursorPos;

    switch (direction) {
      case DIRECTION.NONE:
      case DIRECTION.LEFT:
      case DIRECTION.FORCE_LEFT: {
        const separatorAtLeftPos = this._findSeparatorAround(cursorPos - 1);
        if (separatorAtLeftPos >= 0) {
          const separatorAtLeftEndPos = separatorAtLeftPos + this.thousandsSeparator.length;
          if (cursorPos < separatorAtLeftEndPos ||
            this.value.length <= separatorAtLeftEndPos ||
            direction === DIRECTION.FORCE_LEFT
          ) {
            return separatorAtLeftPos;
          }
        }
        break;
      }
      case DIRECTION.RIGHT:
      case DIRECTION.FORCE_RIGHT: {
        const separatorAtRightPos = this._findSeparatorAround(cursorPos);
        if (separatorAtRightPos >= 0) {
          return separatorAtRightPos + this.thousandsSeparator.length;
        }
      }
    }

    return cursorPos;
  }

  override doCommit () {
    if (this.value != null && this.value != '') {
      const number = this.number;
      let validnum = number;

      // check bounds
      if (this.min != null && validnum.toString().includes('-')) validnum = validnum.toString().length <= this.min.toString().length ? validnum : this.min;
      if (this.max != null && !validnum.toString().includes('-')) validnum = validnum.toString().length <= this.max.toString().length ? validnum : this.max;


      if (!new BigNumber(validnum).isEqualTo(number)) this.unmaskedValue = validnum.toString();

      let formatted = this.value;

      if (this.normalizeZeros) formatted = this._normalizeZeros(formatted);
      if (this.padFractionalZeros && this.scale > 0) formatted = this._padFractionalZeros(formatted);

      this._value = formatted;
    }

    super.doCommit();

    if (this.nullable === false && this.value === '') {
      this.value = '0';
    }
  }

  _normalizeZeros (value: string): string {
    const parts = this._removeThousandsSeparators(value).split(this.radix);

    // remove leading zeros
    parts[0] = parts[0].replace(/^(\D*)(0*)(\d*)/, (match, sign, zeros, num) => sign + num);
    // add leading zero
    if (value.length && !/\d$/.test(parts[0])) parts[0] = parts[0] + '0';

    if (parts.length > 1) {
      parts[1] = parts[1].replace(/0*$/, '');  // remove trailing zeros
      if (!parts[1].length) parts.length = 1;  // remove fractional
    }

    return this._insertThousandsSeparators(parts.join(this.radix));
  }

  _padFractionalZeros (value: string): string {
    if (!value) return value;

    const parts = value.split(this.radix);
    if (parts.length < 2) parts.push('');
    parts[1] = parts[1].padEnd(this.scale, '0');
    return parts.join(this.radix);
  }

  override doSkipInvalid (ch: string, flags: AppendFlags={}, checkTail?: TailDetails): boolean {
    const dropFractional = this.scale === 0 && ch !== this.thousandsSeparator && (
      ch === this.radix ||
      ch === MaskedNumber.UNMASKED_RADIX ||
      this.mapToRadix.includes(ch)
    )
    return super.doSkipInvalid(ch, flags, checkTail) && !dropFractional;
  }

  // formato numerico con il punto
  override get unmaskedValue (): string {
    return this._removeThousandsSeparators(this._normalizeZeros(this.value))
      .replace(this.radix, MaskedNumber.UNMASKED_RADIX);
  }

  override set unmaskedValue (unmaskedValue: string) {
    super.unmaskedValue = unmaskedValue;
  }

  override get typedValue (): BigNumber {
    if (this.nullable && this.unmaskedValue == '') {
      return null;
    } else if (this.nullable && this.unmaskedValue == null) {
      return null;
    } else if (!this.nullable && this.unmaskedValue == null) {
      return new BigNumber(0);
    } else if (!this.nullable && this.unmaskedValue == '') {
      return new BigNumber(0);
    } else {
      return new BigNumber(this.unmaskedValue);
    }
  }

  override set typedValue (n: BigNumber) {
    this.rawInputValue = n.toString().replace(MaskedNumber.UNMASKED_RADIX, this.radix);
  }

  get number (): BigNumber {
    return this.typedValue;
  }

  set number (number: BigNumber) {
    this.typedValue = number;
  }

  get allowNegative (): boolean {
    return (this.min != null && this.min.toString().includes('-')) || (this.max != null && this.max.toString().includes('-'));
  }

  get allowPositive (): boolean {
    return (this.min != null && !this.min.toString().includes('-')) || (this.max != null && !this.max.toString().includes('-'));
  }

  override typedValueEquals (value: any): boolean {
    // handle  0 -> '' case (typed = 0 even if value = '')
    // for details see https://github.com/uNmAnNeR/imaskjs/issues/134

    if (BigNumber.isBigNumber(value)) {
      value = value.toFormat(BigNumber.getFormat(BaseNumericBoxComponent.thousandSeparator, BaseNumericBoxComponent.decimalSeparator, this.thousandsSeparator?.length > 0));
    }

    return (
      super.typedValueEquals(value) ||
      MaskedNumber.EMPTY_VALUES.includes(value) && MaskedNumber.EMPTY_VALUES.includes(this.typedValue.toString())
    ) && !(value === 0 && this.value === '');
  }
}

export class NtsMaskedNumber extends MaskedNumber {

    nullable = false;
    faultBackValue = '0';
    faultBackValueFunc: null|(() => string) = null;
    // override _value = null;
    // element: ElementRef;

    constructor(opts: any) {
        super(opts)
        this.nullable = opts.nullable;
        this.faultBackValueFunc =  opts.faultBackValueFunc;
    }

    // override doPrepare (str: string, flags: any): any {
    //     if (this.nullable === false && str === '') {
    //         str = '0';
    //     }
    //     const doPrepareResult = super.doPrepare(str, flags) as [string, any];
    //     if (str === this.thousandsSeparator && this.mapToRadix.indexOf(this.thousandsSeparator) > -1 ) {
    //         if (this.element?.nativeElement?.value?.indexOf(this.radix) > -1) {
    //             doPrepareResult[0] = this.thousandsSeparator;
    //         }
    //     }
    //     return doPrepareResult;
    // }

    override doCommit () {

        const res = super.doCommit()

        if (this.nullable === false && this.value === '') {
            this.value = this.faultBackValueFunc ? this.faultBackValueFunc() : this.faultBackValue;
        }
        return res;
    }

    get typedNullableValue() {
        if (this.nullable === true && this.value === '') {
            return null;
        }
        return this.typedValue;
    }

    set typedNullableValue(val) {
        if (!(this.nullable === true && this.value === '')) {
            this.typedValue = val;
        }
    }
}
@Component({
    selector: 'nts-base-numeric-box',
    templateUrl: './base-numeric-box.component.html',
    styleUrls: ['./base-numeric-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        IMaskDirective,
        FormsModule,
        NgClass,
        RibbonButtonComponent,
        NgIf,
        AsyncPipe,
        NgFor
    ]
})

export class BaseNumericBoxComponent<T = BigNumber|number> implements AfterViewInit, OnChanges, OnInit, OnDestroy {

    @Input() minValue: T;
    @Input() maxValue: T;
    @Input() maskSettings: NumericMaskSettings;
    @Input() inputMode = null;
    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() value: T | '' | null = null;
    @Input() isReadonly = false;
    @Input() placeholder = "";
    @Input() customClasses = "";
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() primaryColor = null;
    @Input() customCommandList: UICommandInterface[] = [];
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;
    @Input() listenClickOutside = false;

    @Output() onFinishEditing = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @Output() onFocus = new EventEmitter();
    @Output() onFocusOut = new EventEmitter();
    @Output() onKeyDown = new EventEmitter();
    @Output() valueChange = new EventEmitter();
    @Output() inputChange = new EventEmitter();

    @ViewChild('numericBox', { static: true }) numericBox: ElementRef;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError: NgxPopperjsDirective;
    @ViewChild(IMaskDirective, { static: true }) iMask: IMaskDirective<any>;

    separatorLimit: string = '';
    nullable = true;
    currentMask = 'separator';
    currentValue : string = "";
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    static thousandSeparator = BaseNumericPropertyViewModel.getThousandSeparator();
    static decimalSeparator = BaseNumericPropertyViewModel.getDecimalSeparator();
    mask = null;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;
    documentClickListener: any;
    computedInputMode$ = new BehaviorSubject<null|string>('');

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly zone: NgZone,
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
    ) {

    }

    ngAfterViewInit(): void {

    }

    ngOnInit(): void {
        this.updateInputMode();

        this.handleOverridesColors();

        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
        }
    }

    updateInputMode() {
      this.computedInputMode$.next(this.inputMode ?? ((this.maskSettings?.decimalLimit != null && this.maskSettings?.decimalLimit > 0) ? 'decimal' : 'numeric'))
    }

    ngOnDestroy() {
        this.unbindDocumentClickListener();
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    initMaskSettings(maskSettings: NumericMaskSettings): void {
        if (maskSettings) {
            const mask = BaseNumericBoxComponent.getMask(maskSettings, this.numericBox);
            this.mask = { mask };
        }
    }

    /**
     * Ritorna l'oggetto mask corretto sia che sia number che bignumber
     *
     * @param maskSettings
     * @param numericBox
     * @returns
     */
    static getMask(maskSettings: NumericMaskSettings, numericBox?: ElementRef) {
      let mask: NtsMaskedBigNumber|NtsMaskedNumber;
      if (maskSettings.isBigNumber) {
          mask = new NtsMaskedBigNumber(
            BaseNumericBoxComponent.getMaskedNumberOptions(maskSettings, numericBox)
          );
      } else {
          mask = new NtsMaskedNumber(
            BaseNumericBoxComponent.getMaskedNumberOptions(maskSettings, numericBox)
          );
      }
      return mask;
    }

    static getMaskedValue(maskSettings: NumericMaskSettings, value: number|BigNumber): number|BigNumber {
      if (maskSettings) {
        const mask = BaseNumericBoxComponent.getMask(maskSettings);

        let valueToResolve = value?.toString() ?? '';
        if (maskSettings.isBigNumber && value?.toString()?.length > 0) {
          if (BigNumber.isBigNumber(value)) {
            valueToResolve = value.toFormat({decimalSeparator: BaseNumericPropertyViewModel.getDecimalSeparator(), groupSeparator: ''});
          };
        }
        mask.resolve(valueToResolve);
        return mask.typedNullableValue;
      }
      return value;
    }

    static getMax(maskSettings: NumericMaskSettings) {
        let max = null;
        let maxString = '';
        if (maskSettings.integerLimit > 0) {
            for (let i = 0; i < maskSettings.integerLimit; i++) {
                maxString += '9';
            }
            max = parseFloat(maxString);
        }

        if (maskSettings.max != null) {
            max = (max != null) ?
                (maskSettings.max > max ? max : maskSettings.max) :
                 maskSettings.max;
        }
        return max;
    }

    static getMaskedNumberOptions(maskSettings: NumericMaskSettings, numericBox?: ElementRef): any {
      if (maskSettings.isBigNumber) {
        return {
          scale:  maskSettings.decimalLimit ? maskSettings.decimalLimit : null,
          signed: true,
          normalizeZeros: true,
          overwrite: 'shift',
          max: new BigNumber(maskSettings.max),
          min: new BigNumber(maskSettings.min),
          thousandsSeparator: maskSettings.useThousandSeparator ? this.thousandSeparator : '' ,
          radix: this.decimalSeparator,
          mapToRadix: maskSettings.useThousandSeparator ? [] : [this.thousandSeparator],
          nullable: maskSettings.nullable,
          faultBackValueFunc: maskSettings?.faultBackValueFunc ? maskSettings?.faultBackValueFunc : null
        }
      } else {
        return {
          mask: Number,
          scale: maskSettings.decimalLimit ? maskSettings.decimalLimit : null,
          signed: true,
          padFractionalZeros: true,
          normalizeZeros: true,
          overwrite: 'shift',
          max: this.getMax(maskSettings),
          min: maskSettings?.min,
          thousandsSeparator: maskSettings.useThousandSeparator ? this.thousandSeparator : '' ,
          radix: this.decimalSeparator,
          mapToRadix: maskSettings.useThousandSeparator ? [] : [this.thousandSeparator],
          nullable: maskSettings.nullable,
          element: numericBox,
          faultBackValueFunc: maskSettings?.faultBackValueFunc ? maskSettings?.faultBackValueFunc : null
        };
      }
    }

    applyMaskLogic(val: T | ''): T | '' {
        if(this.maskSettings){
          if (this.maskSettings.isBigNumber) {
            // if(val?.toString()?.length > 0){
            //     val = (new BigNumber(val)).toFormat(BigNumber.getFormat(BaseNumericBoxComponent.thousandSeparator , BaseNumericBoxComponent.decimalSeparator, this.maskSettings.useThousandSeparator));
            // } else if (!this.maskSettings.nullable) {
            //   val = new BigNumber('0');
            // }
          } else {
            if(val || !this.maskSettings.nullable){
              // if (typeof val == 'number') {
              //     val = val.toString().replace('.', BaseNumericBoxComponent.decimalSeparator);
              // }
            }
          }
     }
     return val;
   }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }

        if (changes['maskSettings'] && changes['maskSettings'].currentValue){
            this.initMaskSettings(this.maskSettings);
            this.value = this.applyMaskLogic(this.value)
            this.updateInputMode();
        }

        if (changes['value']) {
            let value = changes['value'].currentValue == null ? '' : changes['value'].currentValue
            this.value = this.applyMaskLogic(value)
        }

        if (changes['errorList']) {
            this.checkPopper();
        }
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }

    blur(e): void {
        this.isActive = false;
        this.handleOverridesColors();
        this.popperError?.hide();
        this.onBlur.emit(e);
    }

    focus(e): void {
        this.isActive = true;
        this.handleOverridesColors();
        this.onFocus.emit(e);
    }

    onInputChange(value): void {
        this.inputChange.emit(value);
    }

    onModelChange(value: any): void {
        // NON MODIFICARE onModelChange IN INPUT, altrimenti il mask non funziona correttamente
        let stringedValue = this.numericBox.nativeElement.value === undefined ? null : this.numericBox.nativeElement.value;
        if (this.maskSettings?.nullable === true && stringedValue === '') {
            value = null;
        }
        this.valueChange.emit(value);
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }
    }
}
