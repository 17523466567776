import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { FilterVariablesType } from '../../../domain-models/find-options/filter-variables-type.enum';
import { DateTimeOffsetZoomPropertyViewModel } from '../property-view-model/date-time-offset-zoom-property-view-model';
import { DateTimeZoomPropertyViewModel } from '../property-view-model/date-time-zoom-property-view-model';
import { ZoomPropertyViewModelInterface } from '../property-view-model/zoom-property-view-model.interface';
import { FilterOperators } from '../../../domain-models/find-options/filter';
import { DateTimeOffset } from '@nts/std/types';

export class DateTimeZoomFilterViewModel extends ZoomFilterViewModel {
  constructor(metadata: ZoomMetaData, private modifiedSubscriber: ModifiedSubscriberInterface) {
    super(metadata);
    this.operator = new ZoomOperatorViewModelProperty(
      metadata.propertyMetadata.isRequired ? ZoomOperatorViewModelProperty.operatorsForDateTime : ZoomOperatorViewModelProperty.operatorsForNDateTime,
      modifiedSubscriber);
    if (metadata.dateTimeMetaData.isDateTimeOffset) {
      this.filterValue = new DateTimeOffsetZoomPropertyViewModel(metadata.dateTimeMetaData, modifiedSubscriber);
      this.filterValue2 = new DateTimeOffsetZoomPropertyViewModel(metadata.dateTimeMetaData, modifiedSubscriber);
      this.internalDateTimeZoomPropertyViewModelValues = [new DateTimeOffsetZoomPropertyViewModel(metadata.dateTimeMetaData, modifiedSubscriber)]
    } else {
      this.filterValue = new DateTimeZoomPropertyViewModel(metadata.dateTimeMetaData, modifiedSubscriber);
      this.filterValue2 = new DateTimeZoomPropertyViewModel(metadata.dateTimeMetaData, modifiedSubscriber);
      this.internalDateTimeZoomPropertyViewModelValues = [new DateTimeZoomPropertyViewModel(metadata.dateTimeMetaData, modifiedSubscriber)]
    }
    this.filterVariablesType = FilterVariablesType.DateFilter;
    
    this.init();
  }

  override addNewFilterInValues()  {
    let vm: ZoomPropertyViewModelInterface<DateTimeOffset|Date>;
    vm = this.getEmptyFilter();
    vm.setEnabled(true);
    if (this.filterDateTimeValues?.length > 0) {
      this.setDateTimeZoomPropertyViewModelValues([...this.filterDateTimeValues, vm]);
    } else {
      this.setDateTimeZoomPropertyViewModelValues([vm]);
    }    
  }

  override deleteFilterInValues(inToRemove: ZoomPropertyViewModelInterface<DateTimeOffset|Date>) {
    const indexToRemove = this.filterDateTimeValues.indexOf(inToRemove);
    
    if (this.filterDateTimeValues?.length > 0 && indexToRemove > -1) {
      this.filterDateTimeValues.splice(indexToRemove, 1);
      this.modifiedSubscriber.notifyModified();
    }
  }

  getEmptyFilter(): DateTimeOffsetZoomPropertyViewModel|DateTimeZoomPropertyViewModel {
    if (this.metaData.dateTimeMetaData.isDateTimeOffset) {
      return new DateTimeOffsetZoomPropertyViewModel(this.metaData.dateTimeMetaData, this.modifiedSubscriber);
    } else {
      return new DateTimeZoomPropertyViewModel(this.metaData.dateTimeMetaData, this.modifiedSubscriber);
    } 
  }

  private internalDateTimeZoomPropertyViewModelValues!: Array<ZoomPropertyViewModelInterface<DateTimeOffset|Date>>|null;

  setDateTimeZoomPropertyViewModelValues(pvmToSet: Array<ZoomPropertyViewModelInterface<DateTimeOffset|Date>>) {
      if (this.internalDateTimeZoomPropertyViewModelValues != pvmToSet) {
          this.internalDateTimeZoomPropertyViewModelValues = pvmToSet;
          this.modifiedSubscriber.notifyModified();
      }   
  }

  setDateTimeZoomPropertyViewModelFromEntities(entities: Array<DateTimeOffset|Date>) {
    this.setDateTimeZoomPropertyViewModelValues(entities.map((e:DateTimeOffset|Date) => {
      const vm: ZoomPropertyViewModelInterface<DateTimeOffset|Date> = this.getEmptyFilter();
      vm.setValue(e);
      return vm;
    }))
  }

  get filterDateTimeValues(): Array<ZoomPropertyViewModelInterface<DateTimeOffset|Date>>|null {
      return this.internalDateTimeZoomPropertyViewModelValues;
  }

  protected override async onOperatorChanged(value: FilterOperators): Promise<void> {
    await super.onOperatorChanged(value);
    if (this.OperatorDoesNotRequireValue) {
        for (const filter of this.filterDateTimeValues) {
            filter.setEnabled(false);
            await filter.resetValue();
        }
    } else {
        for (const filter of this.filterDateTimeValues) {
            filter.setEnabled(true);
            // await filter.resetValue();
        }
    }
  }
}