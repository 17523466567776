<div class="exception-popup" *ngIf="viewModel">

  <div class="error-container">
    <div class="code-wrapper" [class.is-selected]="(viewModel.selectedError$ | async) === error"  *ngFor="let error of viewModel.errors">
      <pre (click)="viewModel.selectedError$.next(error)" class="code code-description" *ngIf="error?.description"><code>{{error.description}}</code></pre>
    </div>
  </div>
  
  <nts-expandable *ngIf="viewModel.selectedError$ | async" [title]="'std_Expandable_Main_DisplayName' | NTSTranslate" [isCollapsed]="true">
    <nts-label-box *ngIf="(viewModel.selectedError$ | async)?.uuid?.length > 0" [label]="'ExceptionPopup_TraceCode_Label' | NTSTranslate:([(viewModel.selectedError$ | async).uuid])"></nts-label-box>
    <nts-label-box [label]="'ExceptionPopup_User_Label' | NTSTranslate:([viewModel.userName$ | async])"></nts-label-box>
    <nts-label-box [label]="'ExceptionPopup_Company_Label' | NTSTranslate:([(viewModel.currentCompany$ | async)?.name])"></nts-label-box>
    <nts-label-box [label]="'ExceptionPopup_Enterprise_Label' | NTSTranslate:([(viewModel.currentEnterprise$ | async)?.name])"></nts-label-box>
    <nts-label-box [label]="'ExceptionPopup_Tenant_Label' | NTSTranslate:([(viewModel.tenantProfile$ | async)?.businessName])"></nts-label-box>
  </nts-expandable>

  <nts-expandable *ngIf="((viewModel.selectedError$ | async)?.requestInfo) as requestInfo" [title]="'ExceptionPopup_HTTP_Request' | NTSTranslate" [isCollapsed]="true">
    <div class="code-wrapper" [class.dark]="viewModel.darkMode$ | async">
      <pre class="code code-html" *ngIf="requestInfo?.method"><label>{{'ExceptionPopup_URL'| NTSTranslate}}</label><code>{{requestInfo.method}} {{requestInfo.url}}</code></pre>
      <pre class="code code-html" *ngIf="requestInfo?.headers"><label>{{ 'ExceptionPopup_Headers' | NTSTranslate}}</label><code>{{requestInfo.headers | json}}</code></pre>
      <pre class="code code-html" *ngIf="requestInfo?.tokenInfo"><label>{{ 'ExceptionPopup_Token' | NTSTranslate}}</label><code>{{requestInfo.tokenInfo | json}}</code></pre>
      <pre class="code code-html" *ngIf="requestInfo?.payload"><label>{{ 'ExceptionPopup_Payload' | NTSTranslate}}</label><code>{{requestInfo.payload | json}}</code></pre>
    </div>
  </nts-expandable>

  <nts-expandable *ngIf="((viewModel.selectedError$ | async)?.responseInfo) as responseInfo" [title]="'ExceptionPopup_HTTP_Response' | NTSTranslate" [isCollapsed]="true">
    <div class="code-wrapper" [class.dark]="viewModel.darkMode$ | async">
      <pre class="code code-html" *ngIf="responseInfo?.status"><label>{{ 'ExceptionPopup_HTTP_Status' | NTSTranslate}}</label><code>{{responseInfo.status}}</code></pre>
      <pre class="code code-html" *ngIf="responseInfo?.headers"><label>{{ 'ExceptionPopup_Headers' | NTSTranslate}}</label><code>{{responseInfo.headers | json}}</code></pre>
      <pre class="code code-html" *ngIf="responseInfo?.response"><label>{{ 'ExceptionPopup_Response' | NTSTranslate}}</label><code>{{responseInfo.response | json}}</code></pre>
    </div>
  </nts-expandable>
    
  <nts-expandable *ngIf="((viewModel.selectedError$ | async)?.stackTrace) as stackTrace" [title]="'ExceptionPopup_StackTrace' | NTSTranslate" [isCollapsed]="true"  [commands]="[viewModel.switchModeCommand]">
    <div class="code-wrapper" [class.dark]="viewModel.darkMode$ | async">
      <pre class="code code-html" *ngIf="stackTrace"><label>{{'ExceptionPopup_StackTrace' | NTSTranslate}}</label><code>{{stackTrace}}</code></pre>
    </div>
  </nts-expandable>

</div>
