import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StaticProvider } from '@angular/core';
import { EndPointResolver } from './end-point-resolver';
import { WebApiServiceAgent } from './web-api-service-agent';
import { ResponseCacheService } from '../responses/response-cache.service';
import { AuthService } from '../auth/auth.service';
import { CurrentSessionService } from '../auth/current-session.service';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { OnlineService } from '@nts/std/utility';
import { CurrentRouteService } from '../routing/current-route.service';
import { BigNumberInterceptor } from '../auth/big-number-interceptor.service';
import { AuthHttpInterceptor } from '../auth/auth-http-interceptor.service';

export class ProvidersForServiceAgent {

    static allStatic(
        httpClient: HttpClient,
        environmentConfiguration: EnvironmentConfiguration,
        authService: AuthService,
        onlineService: OnlineService,
        responseCacheService: ResponseCacheService,
        webApiServiceAgent: WebApiServiceAgent
    ): StaticProvider[] {

        const providers: StaticProvider[] = [
            {
                provide: HttpClient,
                useValue: httpClient,
            },
            {
                provide: EnvironmentConfiguration,
                useValue: environmentConfiguration,
            },
            {
                provide: OnlineService,
                useValue: onlineService,
            },
            {
                provide: AuthService,
                useValue: authService
            },
            {
                provide: CurrentSessionService,
                deps: [AuthService]
            },
            {
                provide: HTTP_INTERCEPTORS,
                useValue: BigNumberInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthHttpInterceptor,
                multi: true,
                deps: [AuthService, CurrentRouteService]
            },
            {
                provide: EndPointResolver,
                deps: [EnvironmentConfiguration]
            },  
            {
                provide: ResponseCacheService,
                useValue: responseCacheService,
            },
            {
                provide: WebApiServiceAgent,
                useValue: webApiServiceAgent,
            }
        ];

        return providers;
    }
}
