import { Expose, Type } from '@nts/std/serialization';
import { MetaDataDescriptions } from './meta-data-descriptions';
import { UserMetaData } from './user-meta-data';
import { CoreMetaDataInterface, MetaDataDescriptionsInterface, UserMetaDataInterface } from '@nts/std/interfaces';

export abstract class CoreMetaData implements CoreMetaDataInterface {

    constructor(
        name?: string,
        descriptions?: MetaDataDescriptionsInterface,
        userMetaData?: UserMetaDataInterface,
    ) {
        this.name = name;
        this.descriptions = descriptions ?? new MetaDataDescriptions();
        this.userMetaData = userMetaData;
    }

    context?: any;

    /**
     * Property name in Pascal Case
     */
    @Expose()
    name: string;

    @Expose()
    @Type(() => MetaDataDescriptions)
    descriptions: MetaDataDescriptionsInterface;

    /**
     * MetaDati che dipendono dall'utente corrente
     */
    @Expose()
    @Type(() => UserMetaData)
    userMetaData?: UserMetaDataInterface;

    set(data: any): void {
        if (data?.name != null) {
            this.name = data.name;
        }
        if (data?.userMetaData != null) {
            this.userMetaData = data.userMetaData;
        }
    }
}
