import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { merge } from 'rxjs';
import { BaseZoomFilterPropertyComponent } from '../base-zoom-filter-property-component';
import { NDateTimeOffsetPropertyViewModel } from '../../../../view-models/base-type/date-time-offset-property-view-model';
import { DateTimeOffsetZoomPropertyViewModel } from '../../../../view-models/zoom/property-view-model/date-time-offset-zoom-property-view-model';
import { TimeZoneData } from '@nts/std/timezone';
import { BaseDateTextBoxComponent } from '../../../controls/core/base/base-date-text-box/base-date-text-box.component';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTimeZoomFilterViewModel } from '../../../../view-models/zoom/filter-view-model/date-time-zoom-filter-view-model';
import { DateTimeOffset } from '@nts/std/types';

@UntilDestroy()
@Component({
  selector: 'nts-zoom-filter-date-offset-text-box',
  templateUrl: './zoom-filter-date-offset-text-box.component.html',
  styleUrls: ['./zoom-filter-date-offset-text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BaseDateTextBoxComponent
  ]
})
export class ZoomFilterDateOffsetTextBoxComponent extends BaseZoomFilterPropertyComponent<DateTimeOffset> implements OnInit, OnChanges, AfterViewInit {

  @Input() filter: DateTimeZoomFilterViewModel;
  @Input() showOffset = false;
  @Input() appendTo: string | HTMLDivElement = 'body';
  

  @Output() onFinishEditing = new EventEmitter();
  
  @ViewChild('baseDateTextBox', { static: false }) baseDateTextBox!: BaseDateTextBoxComponent;
  
  valueDate: Date | null = null;
  todayMidnight = moment().startOf('day').toDate();
  override propertyViewModel!: DateTimeOffsetZoomPropertyViewModel;

  valueTimeZone!: TimeZoneData|null;
  showButtonBar = false;

  get isNullable() {
    return this.propertyViewModel instanceof NDateTimeOffsetPropertyViewModel;
  }

  get value(): DateTimeOffset|null {
    if (this.valueDate == null && this.valueTimeZone == null) {
      return null;
    }
    return new DateTimeOffset(this.valueDate, this.valueTimeZone?.timeSpan);
  }

  set value(value: DateTimeOffset|null) {
    if (value == null) {
      this.valueDate = null;
      this.valueTimeZone = null;
    } else {
      this.valueDate = value.dateTime;
      this.valueTimeZone = value.timeZoneData;
    }
  }

  // Date
  get input(): HTMLInputElement {
    return this.baseDateTextBox.dateMaskBox.dateBox.nativeElement;
  }

  get inputTimeZone(): HTMLInputElement {
    return this.baseDateTextBox?.baseTimeZoneCombo?.combo.el.nativeElement.children[0].children[0].children[0];
  }

  private _lastPropertyViewModelValueDate!: Date|null;

  constructor(
    private readonly http: HttpClient, 
    private readonly cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }

    this.filter.isEnableStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });
  } 

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
        
    if (changes['propertyViewModel']) {
      this.setValueFromOutside();
      
      await this.propertyViewModel.populateTimeZonesAsync(this.http);

      this.initTimezone();

      this.destroyOnChange$.next(true);

       // Rimane in ascolto quando cambia il pvm e il valore
       merge(
          this.propertyViewModel.propertyViewModelChanged,
      ).pipe(
          takeUntil(this.destroy$),
          takeUntil(this.destroyOnChange$)
      ).subscribe(() => {
          this.initTimezone();

          this.setValueFromOutside();
          // if (this.propertyViewModel.value != null && this.propertyViewModel.value.dateTime !== this.valueDate
          //     && this.propertyViewModel.value.dateTime !== this._lastPropertyViewModelValue) {
          //     this.setValueFromOutside();
          // }
          this.cd.detectChanges();
      });
    }
  }

  ngAfterViewInit() {
    // remove tab index from button
    const button = this.baseDateTextBox.calendar.el.nativeElement.querySelector('button');
    button.setAttribute('tabindex', -1);
  }

  
  onDateSelect($event: any) {
    this.valueDate = $event
    this.propertyViewModel.typedValue = this.value;
    this.cd.detectChanges();
  }

  onDateValueChange($event: any): void {
    this.valueDate = $event
    
    this.onDateBlur();
    //this.onFinishEditing.emit(this.valueDate);
    
  }

  onDateFocus($event: any): void {
    this.input.select();
  }

  onDateBlur() {
    if (this.value == null) {
      this.propertyViewModel.typedValue = null;
    } else {
      this.propertyViewModel.typedValue = this.value;
    }
  }

  // timeZone
  onTimeZoneChange(valueTimeZone: TimeZoneData) {
    this.valueTimeZone = valueTimeZone;
    this.propertyViewModel.setValue(this.value);
  }

  onTimeZoneBlur() {
    // if (this.propertyViewModel.value != null && (<DateTimeOffset>this.propertyViewModel.value).timeZoneData != null) {
    //   this.valueTimeZone = this.propertyViewModel.timeZones.find(x => x.timeSpan === (<DateTimeOffset>this.propertyViewModel.value).timeZoneData.timeSpan);
    // }
  }

  // timeZone
  private initTimezone(): void {
    if (this.propertyViewModel.value != null) {
        this.valueTimeZone = this.propertyViewModel.timeZones.find(x => x.timeSpan === this.propertyViewModel.value.offset) ?? null;
    } else {
        this.valueTimeZone = this.propertyViewModel.timeZones.find(x => x.timeSpan === this.propertyViewModel.currentOffset) ?? null;
    }
  }

  private setValueFromOutside() {
    if (this.propertyViewModel.value != null) {
      this._lastPropertyViewModelValueDate = this.valueDate = (<DateTimeOffset>this.propertyViewModel.value).dateTime;
    } else if (this.valueDate != null) {
      this.valueDate = null;
    }
  }
}
