import { ExternalReaderHttpCommunication } from './external-reader-http-communication';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { Observable } from 'rxjs';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { FindResponse } from '../responses/find-response';
import { AutoCompleteModelRequest } from '../requests/auto-complete-model-request';
import { GetByIdentityCachedResponse } from '../responses/get-by-identity-cached-response';
import { CacheOptionsInterface } from './web-api-service-agent';
import { ModelInterface } from '@nts/std/interfaces';
import { IdentityInterface } from '@nts/std/interfaces';

export class ReaderHttpCommunication extends ExternalReaderHttpCommunication {

    private static readonly getByIdentityRequestUri = 'GetByIdentity';
    private static readonly autoCompleteModelRequestUri = 'AutoCompleteModel';

    getByIdentity<TIdentity extends IdentityInterface, TModel extends ModelInterface<TIdentity>>(
        request: GetByIdentityRequest<TIdentity>, 
        modelType: any,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
        const responseInstance = new GetByIdentityResponse<TModel, TIdentity>(modelType);
        return this.agent.invokePostWithResponseWithInstance<
            GetByIdentityRequest<TIdentity>, GetByIdentityResponse<TModel, TIdentity>>(
                ReaderHttpCommunication.getByIdentityRequestUri,
                request, 
                responseInstance,
                false,
                null,
                null,
                null,
                cacheOptions
            );
    }

    getByIdentityCached<TIdentity extends IdentityInterface, TModel extends ModelInterface<TIdentity>>(
        request: GetByIdentityRequest<TIdentity>, 
        modelType: any,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<GetByIdentityCachedResponse<TModel, TIdentity>> {
        const responseInstance = new GetByIdentityCachedResponse<TModel, TIdentity>(modelType);
        return this.agent.invokePostWithResponseWithInstance<
            GetByIdentityRequest<TIdentity>, GetByIdentityCachedResponse<TModel, TIdentity>>(
                ReaderHttpCommunication.getByIdentityRequestUri, 
                request, 
                responseInstance,
                false,
                null,
                null,
                null,
                cacheOptions
            );
    }

    autoCompleteModel<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>(
        request: AutoCompleteModelRequest, 
        modelType: any,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,                                                                  
        },
    ): Observable<FindResponse<TModel, TIdentity>> {

        // Default for autoCompleteModel
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: true,
            tenantBarrier: true,
            userBarrier: true,
            ...cacheOptions
        };

        const responseInstance = new FindResponse<TModel, TIdentity>(modelType);

        return this.agent.invokePostWithResponseWithInstance<
            AutoCompleteModelRequest, FindResponse<TModel, TIdentity>>(
                ReaderHttpCommunication.autoCompleteModelRequestUri,
                request,
                responseInstance,
                false,
                null,
                null,
                null,
                cacheOptions
            );
    }
}
