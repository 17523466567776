<ng-template *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent"></ng-template>

<ng-template #baseComponent>
    <nts-base-date-text-box 
        #baseDateTextBox 
        (onDateValueChange)="onDateValueChange($event)"
        (onDateFocus)="onDateFocus($event)" 
        (onDateSelect)="onDateSelect($event)"
        (onDateBlur)="onDateBlur($event)"
        (onFinishEditing)="onFinishEditing.emit($event)" 
        [valueDate]="valueDate" 
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [appendTo]="appendTo"
        [listenClickOutside]="listenClickOutside" 
        [isRequired]="propertyViewModel.isRequired" 
        [isNullable]="isNullable"
        [showTime]="propertyViewModel.showTime"
        [showButtonBar]="showButtonBar"
        [initialChar]="initialChar"
        [isDisabled]="isDisabled || !(propertyViewModel.isEnabled$ | async) || propertyViewModel?.securityAccess != null"
        [errorList]="propertyViewModel?.errors$ | async"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [maskSettings]="maskSettings" [title]="propertyViewModel.formattedValue$ | async"
        [customCommandList]="customCommandList"></nts-base-date-text-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box #baseSecurityTextBox [accessMode]="propertyViewModel.securityAccess"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"></nts-base-security-text-box>
</ng-template>
