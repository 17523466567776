import { MetaDataDescriptions } from '../meta-data/meta-data-descriptions';
import { Expose, Transform, TransformFnParams, Type } from '@nts/std/serialization';
import { FieldTypes } from './field-types.enum';
import { DefaultValueAwareInterface } from './simple-field-meta-data';
import { FieldInterface } from './field.interface';
import { DateTimeOffset } from '@nts/std/types';

export abstract class FieldMetaData implements DefaultValueAwareInterface, FieldInterface {

    constructor() {
        this.descriptions = new MetaDataDescriptions();
        this.customDescriptions = new MetaDataDescriptions();
    }

    @Expose()
    name: string;

    @Expose()
    path: string;

    @Expose()
    isFullColumn: boolean;

    @Expose()
    position: number;

    @Expose()
    isAdditionalField: boolean;

    @Expose()
    isVisible: boolean;

    @Expose()
    isDisabled: boolean;

    @Expose()
    @Type(() => MetaDataDescriptions)
    descriptions: MetaDataDescriptions;

    @Expose()
    @Type(() => MetaDataDescriptions)
    customDescriptions: MetaDataDescriptions;

    @Expose()
    fullPathName?: string;

    @Expose()
    isAdditionalRequired?: boolean;

    @Expose()
    isOriginalRequired?: boolean;

    @Expose()
    stringDefaultValue?: string;

    @Expose()
    numericDefaultValue?: number;

    @Expose()
    boolDefaultValue?: boolean;

    @Expose()
    @Type(() => DateTimeOffset)
    @Transform(DateTimeOffset.transform)
    dateTimeOffsetDefaultValue?: DateTimeOffset;

    @Expose()
    dateTimeDefaultValue?: Date;

    @Expose()
    readonly shortName: string;

    @Expose()
    readonly displayName: string;

    @Expose()
    readonly description: string;

    abstract getFieldType(): FieldTypes;

    get fieldType(): FieldTypes {
      return this.getFieldType();
    }
}
