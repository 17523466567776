import { TransformationType, TransformFnParams } from "@nts/std/serialization";
import { BigNumber as BN } from "bignumber.js";


export class BigNumber extends BN {

    static MIN_DIGIT_FOR_BIGNUMBER = 15;

    static transform (params: TransformFnParams) {
        const value = params.obj[params.key];

        if (params.type === TransformationType.CLASS_TO_PLAIN) {
            return BigNumber.serialize(value);
        } else {
            if (BigNumber.isBigNumber(value)) {
                return value;
            } else if (typeof value == "string" && value.indexOf('<bignumber>') > -1) {
                return BigNumber.deserialize(value);
            } else {
                return value;
            }
        }
    }

    static serialize(value: BigNumber) {
        return `<bignumber>${value ?? null}</bignumber>`
    }

    static deserialize(value: string) {
        return new BigNumber(BigNumber.dequoteString(value));
    }

    static getFormat(integerSeparator: string, decimalSeparator: string, useThousandSeparator: boolean = false) : BN.Format{
        return {
            decimalSeparator,
            groupSeparator: useThousandSeparator ? integerSeparator : '',
            groupSize: useThousandSeparator ? 3 : 0
        }
    }

    /**
     * Returns `true` if `value` is a BigNumber instance, otherwise returns `false`.
     *
     * If `BigNumber.DEBUG` is `true`, throws if a BigNumber instance is not well-formed.
     *
     * ```ts
     * x = 42
     * y = new BigNumber(x)
     *
     * BigNumber.isBigNumber(x)             // false
     * y instanceof BigNumber               // true
     * BigNumber.isBigNumber(y)             // true
     *
     * BN = BigNumber.clone();
     * z = new BN(x)
     * z instanceof BigNumber               // false
     * BigNumber.isBigNumber(z)             // true
     * ```
     *
     * @param value The value to test.
     */
    static override isBigNumber(value: any): value is BigNumber {
        return BN.isBigNumber(value)
    }

    static parseFromLocaleString(
        localeIntegerSeparator: string, 
        localeDecimalSeparator: string, 
        localeNumberString: string
    ) {

        // rimuovo il separatore delle migliagia
        localeNumberString = localeNumberString.split(localeIntegerSeparator).join('');

        // sostituisco il separatore decimale
        localeNumberString = localeNumberString.split(localeDecimalSeparator).join('.');
        return new BigNumber(localeNumberString);
    }

    /**
     * Normalizza la stringa json per il backend rimuovendo i segnalatori dei BigNumber
     * 
     * @param jsonString 
     */
    static dequoteString(jsonString: string): string {
        return jsonString.replace(/"*<\/*bignumber>"*/g, '');
    }

    /**
     * Verifica se la stringa passata è un possibile BigBumber
     * 
     * @param stringedNumber 
     * @returns 
     */
    static isBigNumberFromString(stringedNumber: string): boolean {
        return  stringedNumber != null && 
            typeof stringedNumber == 'string' && 

            // Rimuove dalla stringa tutto ciò che non è numero
            stringedNumber?.replace(/[^\d]+/g,'')?.length >= this.MIN_DIGIT_FOR_BIGNUMBER
    }

    /**
     * Aggiunge i segnalatori dei BigNumber nel testo passato se riconosce dei BigNumber
     */
    static enquoteString(jsonString: string): string {
        return jsonString
            .replaceAll(
                // Cerca tutti i numeri nella stinga che non sono racchiusi tra apici e che hanno come carattere precedente o i due punti o lo spazio
                /(?<=[:\s])(-?\d+(\.\d+)?)(?=\s*(,|\}))/g,
                (matchingSubstr) => this.isBigNumberFromString(matchingSubstr)
                    ? `"<bignumber>${matchingSubstr}</bignumber>"`
                    : matchingSubstr
            )
    }

    /**
     * Esegue il parse di un json e trasforma i numeri sopra le 14 cifre in oggetti BigNumber
     * 
     * @param jsonString stringa json da parsare
     * @returns json
     */
    static parseJson(jsonString: string): Object {
        if (!jsonString) {
            return {};
        } else {
            try {
                // Utilizza una customizzazione del JSON parse per trasformare tutti i numeri che hanno almeno 15 cifre (interi+decimali) in BigNumber
                const output = JSON.parse(
                    this.enquoteString(jsonString),
                    (key, value) =>
                        !isNaN(value) && typeof(value) === 'string' && value.length > 1 && value.indexOf('<bignumber>') > -1
                            ? new BigNumber(this.dequoteString(value))
                            : value
                )        
                return output;
            } catch (e) {
                return {error: `unable to parse json: ${jsonString}`};
            }
        }
    }

    static compareValues(v1: BigNumber|number|string, v2: BigNumber|number|string, operator: string = '==') {

        // uniform values
        const value1 = BigNumber.isBigNumber(v1) ? v1 : new BigNumber(v1 as number|string)
        const value2 = BigNumber.isBigNumber(v2) ? v2 : new BigNumber(v2 as number|string)

        switch (operator) {
            case '>':
                return value1.gt(value2);
            case '>=':
                return value1.gte(value2);
            case '<':
                return value1.lt(value2);
            case '<=':
                return value1.lte(value2);
            case '==':
                return value1.isEqualTo(value2);
            default:
                throw new Error(`not implemented sign ${operator} in compareValues`)
        }
    }

    constructor(n: BN.Value, base?: number) {
        super(n, base);
        BN.set({
            DECIMAL_PLACES: 200,
            ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
            EXPONENTIAL_AT: [-200, 200],
            RANGE: 1E9,
            CRYPTO: true,
            MODULO_MODE: BigNumber.ROUND_FLOOR,
            POW_PRECISION: 80,
            FORMAT: {
                decimalSeparator: ',',
                groupSeparator: '.',
                groupSize: 3,
                secondaryGroupSize: 0,
                fractionGroupSeparator: '',
                fractionGroupSize: 0
            },
            ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$_'
        })
    }

    override valueOf(): string {
        throw Error('valueOf called! You must use BigNumber method!')
    }

    override multipliedBy(n: BN.Value, base?: number): BigNumber {
        return super.multipliedBy(n, base) as BigNumber
    }

    ceil(): BigNumber {
        return this.integerValue(BigNumber.ROUND_CEIL) as BigNumber;
    };

    override plus(n, base?: number): BigNumber {
        return super.plus(n, base) as BigNumber;
    }

}
// This part is augmenting the module for the rest of your app
// declare module "bignumber.js" {
//     export interface BigNumber {
//         ceil(): BigNumber;
//         floor(): BigNumber;
//         round(): BigNumber;
//         trunc(): BigNumber;
        
//     }
// }

// BigNumber.set({
//     DECIMAL_PLACES: 200,
//     ROUNDING_MODE: BigNumber.ROUND_HALF_UP,
//     EXPONENTIAL_AT: [-200, 200],
//     RANGE: 1E9,
//     CRYPTO: true,
//     MODULO_MODE: BigNumber.ROUND_FLOOR,
//     POW_PRECISION: 80,
//     FORMAT: {
//       decimalSeparator: ',',
//       groupSeparator: '.',
//       groupSize: 3,
//       secondaryGroupSize: 0,
//       fractionGroupSeparator: '',
//       fractionGroupSize: 0
//     },
//     ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$_'
// });

// BigNumber.prototype.valueOf = function () {
//     throw Error('valueOf called! You must use BigNumber method!')
// }

// BigNumber.prototype.ceil = function () {
//     return this.integerValue(BigNumber.ROUND_CEIL);
//   };
  
// BigNumber.prototype.floor = function () {
//     return this.integerValue(BigNumber.ROUND_FLOOR);
// };

// BigNumber.prototype.round = function () {
//     return this.integerValue(BigNumber.ROUND_HALF_CEIL);
// };

// BigNumber.prototype.trunc = function () {
//     return this.integerValue(BigNumber.ROUND_DOWN);
// };

// BigNumber.prototype.trunc = function () {
//     return this.integerValue(BigNumber.ROUND_DOWN);
// };



// export {BigNumber};