import { GenericServiceResponse } from './generic-service-response';
import { ResponseCachedDecorator } from './decorators/response-cached.decorator';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';


@ResponseCachedDecorator()
export class GetByIdentityCachedResponse<
    TModel extends ModelInterface<TIdentity>,
    TIdentity extends IdentityInterface>
    extends GenericServiceResponse<TModel> {

}
