<p-toast [baseZIndex]="2000">
    <ng-template let-message pTemplate="message">
        <svg-icon *ngIf="message.severity == 'info'" key="info"></svg-icon>
        <svg-icon *ngIf="message.severity == 'warn'" key="alert"></svg-icon>
        <svg-icon *ngIf="message.severity == 'success'" key="check-circled"></svg-icon>
        <svg-icon *ngIf="message.severity == 'error'" key="warning"></svg-icon>

        <div class="toast-content" [attr.data-test]="message.id">
            <div class="toast-title" *ngIf="message?.summary?.length > 0">{{message.summary}}</div>
            <div class="toast-message" *ngIf="message?.detail?.length > 0">{{message.detail}}</div>
        </div>
    </ng-template>
</p-toast>

<!-- template toast per update -->
<p-toast [baseZIndex]="2000" key="update" (onClose)="onReject('update')">
    <ng-template let-message pTemplate="message">
        <svg-icon *ngIf="message.severity == 'info'" key="info"></svg-icon>
        <svg-icon *ngIf="message.severity == 'warn'" key="alert"></svg-icon>
        <svg-icon *ngIf="message.severity == 'success'" key="check-circled"></svg-icon>
        <svg-icon *ngIf="message.severity == 'error'" key="warning"></svg-icon>

        <div class="toast-content" [attr.data-test]="message.id">
            <div class="toast-title" *ngIf="message?.summary?.length > 0">{{message.summary}}</div>
            <div class="toast-message" *ngIf="message?.detail?.length > 0">{{message.detail}}</div>
            <div class="commands-container">
                <nts-filled-button 
                    [title]="message.data.confirmLabel"
                    [label]="message.data.confirmLabel"
                    [icon]="message.data.confirmIcon"
                    [type]="3"
                    (onClicked)="onConfirmUpdate(message, 'update')"></nts-filled-button>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast [baseZIndex]="2000" key="with-commands-top-center" position="top-center" (onClose)="onReject('with-commands-top-center')">
    <ng-template let-message pTemplate="message">
        <svg-icon *ngIf="message.severity == 'info'" key="info"></svg-icon>
        <svg-icon *ngIf="message.severity == 'warn'" key="alert"></svg-icon>
        <svg-icon *ngIf="message.severity == 'success'" key="check-circled"></svg-icon>
        <svg-icon *ngIf="message.severity == 'error'" key="warning"></svg-icon>

        <div class="toast-content" [attr.data-test]="message.id">
            <div class="toast-title"  *ngIf="message?.data?.summary$ || message?.summary?.length > 0">{{ (message?.data?.summary$ | async) || message.summary  }}</div>
            <div class="toast-message" *ngIf="message?.data?.detail$ || message?.detail?.length > 0">{{ (message?.data?.detail$ | async) || message.detail }}</div>
            <div class="commands-container">
                <nts-filled-button *ngFor="let cmd of message.data.commands$ | async"
                    [title]="cmd.command.tooltip$ | async"
                    [icon]="cmd.command.iconClass$ | async"
                    [type]="cmd.type"
                    (onClicked)="onCommandExecute(message, 'with-commands-top-center', cmd)"
                    [label]="cmd.command.displayName$ | async">
                </nts-filled-button>
            </div>
        </div>
    </ng-template>
</p-toast>
<!-- fine template toast per update -->