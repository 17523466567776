import { MasterDetailRootViewModel } from './master-detail-root-view-model';
import { VersionPropertyViewModel } from './base-type/version-property-view-model';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class OCCMasterDetailRootViewModel<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends MasterDetailRootViewModel<TModel, TIdentity> {

    private _occ: VersionPropertyViewModel;

    get occ(): VersionPropertyViewModel {
        return this.getVersionPropertyViewModel((value) => { this._occ= value; }, this._occ, 'occ');
    }
}
