import { Expose } from "@nts/std/serialization";
import { ServiceRequest } from './service-request';
import { IdentityInterface } from '@nts/std/interfaces';

export class GetAllSnapShotsFrameRequest<TIdentity extends IdentityInterface> extends ServiceRequest {

  @Expose()
  identity: TIdentity;

  @Expose()
  take: number;

  @Expose()
  skip: number;

}
