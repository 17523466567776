import { EventEmitter } from '@angular/core';
import { UUIDHelper } from '@nts/std/utility';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RelayCommandInterface } from './relay-command.interface';

export class RelayCommand implements RelayCommandInterface {

    private internalHiddenOnMobile = false;
    private internalHiddenOnDesktop = false;

    name: string;
    tag: object;
    executed: EventEmitter<any> = new EventEmitter<any>();
    displayName$ = new BehaviorSubject<string>('');
    tooltip$ = new BehaviorSubject<string>('');
    iconClass$ = new BehaviorSubject<string>('');
    loading$ = new BehaviorSubject<boolean>(false);
    showDisplayName$ = new BehaviorSubject<boolean>(true);
    isVisible$ = new BehaviorSubject<boolean>(true);
    canExecute$ = new BehaviorSubject<boolean>(true);
    hiddenOnMobile$ = new BehaviorSubject<boolean>(this.internalHiddenOnMobile);
    hiddenOnDesktop$ = new BehaviorSubject<boolean>(this.internalHiddenOnDesktop);
    data: any;
    isCancel = false;
    uid: string;

    protected readonly _execute: (x: any, y?: any) => void;

    constructor(
        canExecute: () => Observable<boolean>,
        execute: (x: any) => void,
        isVisible: () => Observable<boolean> = null
    ) {
        if (execute == null) {
            throw new Error('ArgumentNullException: execute');
        }

        this._execute = execute;
        if (isVisible != null) {
            isVisible().subscribe({
                next: (isVisible) => {
                    this.isVisible$.next(isVisible)
                },
                error: (err) => {
                    this.isVisible$.error(err)
                },
                complete: () => {
                    // this.isVisible$.complete()
                },
            })
        }
        if (canExecute != null) {
            canExecute().subscribe({
                next: (canExecute) => {
                    this.canExecute$.next(canExecute)
                },
                error: (err) => {
                    this.canExecute$.error(err)
                },
                complete: () => {
                    // this.canExecute$.complete()
                },
            })
        }
        this.uid = UUIDHelper.generateUUID()
    }

    execute(parameter: any, otherParam?: any) {
        this._execute(parameter, otherParam);
        this.executed.emit(parameter);
    }

    get hiddenOnMobile(): boolean {
        return this.internalHiddenOnMobile;
    }

    set hiddenOnMobile(value: boolean) {
        this.internalHiddenOnMobile = value;
        this.hiddenOnMobile$.next( this.internalHiddenOnMobile);
    }

    get hiddenOnDektop(): boolean {
        return this.internalHiddenOnDesktop;
    }

    set hiddenOnDesktop(value: boolean) {
        this.internalHiddenOnDesktop = value;
        this.hiddenOnDesktop$.next( this.internalHiddenOnDesktop);
    }
}
