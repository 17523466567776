import { GenericServiceResponse } from './generic-service-response';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';


export class GetModelBySnapShotIdentityResponse<
    TModel extends ModelInterface<TIdentity>,
    TIdentity extends IdentityInterface>
    extends GenericServiceResponse<TModel> {

}
