import { Expose } from "@nts/std/serialization";
import { BaseRootModel } from './base-root-model';
import { OCCModelInterface } from './occ-model.interface';
import { IdentityInterface } from "@nts/std/interfaces";


export class OCCModel<TIdentity extends IdentityInterface> extends BaseRootModel<TIdentity> implements OCCModelInterface {

    @Expose()
    occ: string;

    @Expose()
    reservedData: string;

    // @Expose()
    // oCCV: string;

    // @Expose()
    // oCCVN: number;

}
