import { BehaviorSubject, of, Subject, takeUntil } from 'rxjs';
import { ViewModelTypeDecorator } from '../../decorators/view-model-type.decorator';
import { BaseError } from '../../messages/base-error';
import { CommandFactory } from '../commands/command-factory';
import { UIResultCommandInterface } from '../commands/ui-result-command.interface';
import { ModalViewModelInterface } from './modal-view-model.interface';
import { UICommandInterface } from '../commands/ui-command.interface';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { TooltipCommandUtility } from '../../components/shared/buttons/text-button/text-button.component';
import { ModalCommandWrapper } from '../commands/modal-command-wrapper';
import { CompanyInformationDtoInterface, EnterpriseInformationDtoInterface, TenantProfileInterface } from '@nts/std/interfaces';

@ViewModelTypeDecorator(ExceptionPopupViewModel)
export class ExceptionPopupViewModel implements ModalViewModelInterface<any, void> {

    modalTitle: string;
    modalSubtitle = '';
    showFooter = true;
    userName$ = new BehaviorSubject<string>('');
    tenantProfile$ = new BehaviorSubject<TenantProfileInterface>(null);
    currentCompany$ = new BehaviorSubject<CompanyInformationDtoInterface>(null);
    currentEnterprise$ = new BehaviorSubject<EnterpriseInformationDtoInterface>(null);
    modalCommands = [];
    closeDebugInfoCommand: UIResultCommandInterface<any>;
    copyDebugInfoCommand: UIResultCommandInterface<any>;
    switchModeCommand: UICommandInterface;
    darkMode$ = new BehaviorSubject<boolean>(false);
    destroySubscribers$ = new Subject<void>();

    private internalErrors = null;

    get errors() {
        return this.internalErrors;
    }
    set errors(errors: BaseError[]) {
        this.internalErrors = errors;
        if (errors?.length > 0) {
            this.selectedError$.next(errors[0]);
        }
    }
    
    selectedError$ = new BehaviorSubject<BaseError>(null);

    constructor() {
        this.setupCommands();
    }

    async initialize(args: any): Promise<any> {
    }

    private setupCommands() {

        this.closeDebugInfoCommand = CommandFactory.createResultUICommand(async () => {}, () => of(true));
        this.closeDebugInfoCommand.displayName = MessageResourceManager.Current.getMessage('CMD_ExceptionPopup_Close_DisplayName');
        this.closeDebugInfoCommand.isDefault = true;
        this.closeDebugInfoCommand.closeModal = true;
        this.modalCommands.push(this.closeDebugInfoCommand);

        this.copyDebugInfoCommand = CommandFactory.createResultUICommand(async ({event, command}) => this.copyDebugInfoToClipboard(event, command), () => of(true));
        this.copyDebugInfoCommand.closeModal = false;
        this.copyDebugInfoCommand.displayName = MessageResourceManager.Current.getMessage('CMD_ExceptionPopup_Copy_DisplayName');
        this.modalCommands.push(this.copyDebugInfoCommand);

        this.switchModeCommand = CommandFactory.createUICommand(async () => this.switchMode(), () => of(true));
        this.switchModeCommand.description = MessageResourceManager.Current.getMessage('CMD_ExceptionPopup_ChangeTheme_Tooltip');
        this.switchModeCommand.tooltip = MessageResourceManager.Current.getMessage('CMD_ExceptionPopup_ChangeTheme_Tooltip');
        this.checkSwitchModeIcon();

        this.darkMode$.pipe(takeUntil(this.destroySubscribers$)).subscribe(() => {
           this.checkSwitchModeIcon();
        })       
    }

    switchMode() {
        this.darkMode$.next(!this.darkMode$.value);
    }

    checkSwitchModeIcon() {
        this.switchModeCommand.iconClass = this.darkMode$.value === true ? 'web-window' : 'web-window-solid';
    }

    private clearTimeout;

    copyDebugInfoToClipboard(
        e: {event: any, tooltipCommandUtility: TooltipCommandUtility}, 
        command: ModalCommandWrapper<any>
    ) {

        let response = null;
        let textToCopy = '';

        for (const [key, error] of this.errors.entries()) {
            if (error.responseInfo?.response != null) {
                try {
                    response = JSON.stringify(error.responseInfo?.response, null, 4);
                } catch(err) {
                    response = error.responseInfo?.response;
                }
            }
    
            textToCopy = textToCopy + (key != 0 ? '\n\n' : '') + `${key+1}# ` + MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_Error_Label', error.description);
    
            textToCopy = textToCopy + `\n\n${MessageResourceManager.Current.getMessage('std_Expandable_Main_DisplayName')}\n`;

            if (error.uuid?.length > 0) {
                textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_TraceCode_Label', error.uuid)}`;
            }
    
            if (this.userName$.value?.length > 0) {
                textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_User_Label', this.userName$.value)}`;
            }
    
            if (this.currentCompany$.value?.name?.length > 0) {
                textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_Company_Label', this.currentCompany$.value?.name)}`;
            }
    
            if (this.currentEnterprise$.value?.name?.length > 0) {
                textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_Enterprise_Label', this.currentEnterprise$.value?.name)}`;
            }
    
            if (this.tenantProfile$.value?.businessName?.length > 0) {
                textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_Tenant_Label', this.tenantProfile$.value.businessName)}`;
            }
    
            if (error.stackTrace?.length > 0) {
                textToCopy = textToCopy + `\n\n${MessageResourceManager.Current.getMessage('ExceptionPopup_StackTrace')}\n${error.stackTrace}`;
            }
    
            if (error.requestInfo) {
                textToCopy = textToCopy + `\n\n${MessageResourceManager.Current.getMessage('ExceptionPopup_HTTP_Request')}\n`;
                if (error.requestInfo?.url?.length > 0) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_URL_Label', error.requestInfo?.url)}`;
                }
                if (error.requestInfo?.headers?.length > 0) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessage('ExceptionPopup_Headers')}: ${JSON.stringify(error.requestInfo?.headers, null, 4)}`;
                }
                if (error.requestInfo?.tokenInfo) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessage('ExceptionPopup_Token')}: ${JSON.stringify(error.requestInfo?.tokenInfo, null, 4)}`;
                }
                if (error.requestInfo?.payload) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessage('ExceptionPopup_Payload')}: ${JSON.stringify(error.requestInfo?.payload, null, 4)}`;
                }
            }
    
            if (error.responseInfo) {
                textToCopy = textToCopy + `\n\n${MessageResourceManager.Current.getMessage('ExceptionPopup_HTTP_Response')}\n`;
                if (error.responseInfo?.status != null) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessageWithStrings('ExceptionPopup_HTTP_Status_Label', error.responseInfo?.status?.toString())}`;
                }
                if (error.responseInfo?.headers?.length > 0) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessage('ExceptionPopup_Headers')}: ${JSON.stringify(error.responseInfo?.headers, null, 4)}`;
                }
                if (error.responseInfo?.response != null) {
                    textToCopy = textToCopy + `\n${MessageResourceManager.Current.getMessage('ExceptionPopup_Response')}: ${response}`;
                }
            }
        }   

        navigator.clipboard.writeText(
            textToCopy
        );

        const copiedMessage = 'Copiato!';

        if (e?.tooltipCommandUtility?.showTooltip) {

            command.internalCommand.tooltip = copiedMessage;
            e?.tooltipCommandUtility?.showTooltip();

            if (this.clearTimeout) {
                clearTimeout(this.clearTimeout);
            }

            this.clearTimeout = setTimeout(() => command.internalCommand.tooltip = '', 1000)
        }
    }
}
