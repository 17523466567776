import { BaseNumericPropertyViewModel } from './base-numeric-property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { NumeralService } from '@nts/std/utility';
import { BigNumber } from '@nts/std/types';

export class NumericPropertyViewModel<TModelType = BigNumber|number> extends BaseNumericPropertyViewModel<TModelType> {

    getFormattedValue(value: TModelType) {
        if (value == null || (this.isBigNumber && typeof value !== 'number' && !BigNumber.isBigNumber(value))) {
            // TODO: we should throw an exception
            return '';
        }
        if (this.isBigNumber && BigNumber.isBigNumber(value)) {

            const formatted = this.bigNumber(this.bigNumber(value).toString(10)).toFormat(BigNumber.getFormat(
                BaseNumericPropertyViewModel.getThousandSeparator(),
                BaseNumericPropertyViewModel.getDecimalSeparator(),
                this.useThousandSeparator)
            )
            return formatted;
        }
        return NumeralService.Current(value).format(this.format);
    }

    protected compareValues() {
        
    }

    constructor(
        initInfo: PropertyViewModelInitializationInfo,
    ) {
        super(initInfo, false);
        if (this.isBigNumber) {
            this.setFaultBackValue(new BigNumber(0) as TModelType);
        } else {
            this.setFaultBackValue(0 as TModelType);
        }
        
    }
}
