import { RootViewModel } from './root-view-model';
import { RelatedRootViewModelInterface } from './related-root-view-model.interface';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { PropertyViewModelInterface } from './property-view-model.interface';
import { timeout } from 'rxjs/operators';
import { OrchestratorViewModelInterface } from './orchestrator-view-model.interface';
import { LogService } from '@nts/std/utility';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { firstValueFrom } from 'rxjs';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class RelatedRootViewModel<TEntity extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends RootViewModel<TEntity, TIdentity>
    implements RelatedRootViewModelInterface {

    async postInitForStartedAsRelatedClient(isNew: boolean, identity: IdentityInterface): Promise<void> {
        // -------------------------------------------------------------------------------------
        // Disabilito le property della Identity.
        // -------------------------------------------------------------------------------------
        // questo tipo di ViewModel è sempre un master-detail dove il master è una external che rappresenta la chiave del chiamante.
        // Devo ricavarmi dai metadati la External che rappresenta il chiamante, e su questa disabiliterò tutti i campi

        // Cerco una External associata tramite FK ai campi chiave
        const external = MetaDataUtils.getExternalRelationFromIdentityProperties(this.domainModelMetaData);
        if (external === undefined) {
            LogService.warn('ExternalRelations related to Identity not found in RootViewModel<' + this.domainModelName + '>.PostInitForStartedAsRelatedClient');
            throw new Error('ExternalRelations related to Identity not found in RootViewModel<' + this.domainModelName + '>.PostInitForStartedAsRelatedClient');
        }

        const externalPropertyName = MetaDataUtils.toCamelCase(external.principalPropertyName);

        // Cerco su questa RootViewModel una property di tipo ExternalViewModel costruita sulla External trovata sopra
        let extVM: ExternalViewModelInterface;
        if (this[externalPropertyName] !== undefined) {
            extVM = <ExternalViewModelInterface>this[externalPropertyName];
        }

        if (extVM === undefined) {
            LogService.warn('ExternalViewModel ' + externalPropertyName + ' not found in ' + this.domainModelName + ' RootViewModel');
            throw new Error('ExternalViewModel ' + externalPropertyName + ' not found in ' + this.domainModelName + ' RootViewModel');
        }

        extVM.switchIsEnabledForPropertyViewModels(false);

        // Disabilitiamo la notifica di cambio stato documento per le property code dell'external che abbiamo disabilitato
        // altrimenti la maschera passa allo stato modificato anche se non abbiamo modificato niente #1454
        extVM.codeProperties.forEach((value, key) => {
            value.canNotifyModified = false;
        });

        this.domainModelMetaData.identityNames.forEach((identityName: string) => {
            const identityToDisable = this.getProperty(MetaDataUtils.toCamelCase(identityName));
            identityToDisable.isEnabled = false;
        });

        // -------------------------------------------------------------------------------------
        // se non esiste un'aggregato per l'identity arrivata come argomento, allora la form
        // parte automaticamente in new (come tutte le root) ma si deve settare il valore sulla
        // chiave della external per far scattare la decodifica
        // -------------------------------------------------------------------------------------
        if (isNew) {

            // Se è stata impostata un'identity precedentemente (ad esempio override postInit, ecc)
            // setto il codice dell'extVm solo se l'identity è differente
            if (extVM?.getDomainModel()?.currentIdentity?.equals(identity)) {
                return;
            }
            await this.setExternalVMCode(extVM, identity);

            const timeoutMs = 30000;

            // Wait Decode
            await firstValueFrom(extVM.decodeCompleted.pipe(timeout(timeoutMs))).catch((err) => {
                LogService.warn(`ATTENZIONE: Tempo scaduto (${timeoutMs} millisecondi) nell'attesa della risoluzione dell'external ${extVM.domainModelMetaData.name}`)
            });

            (this.externalRetriever as OrchestratorViewModelInterface).currentState.create();

        }
    }

    protected async setExternalVMCode(extVM: ExternalViewModelInterface, identity: IdentityInterface) {
        const pvm = extVM.codeProperties.values().next().value as PropertyViewModelInterface;
        if (pvm !== undefined) {
            // pvm.setValue(identity.getPropertyValue(pvm.fieldInfo.fieldName));
            pvm.setValue(identity.getPropertyValue(pvm.propertyName));
        }
    }

    // In una related si accede sempre tramite un'identity e quindi si va sempre nel giro dello showRead che lancia sempre la postInitForStartedAsRelatedClient
    // quindi non ha senso fare prima questo, l'unico caso non coperto dalla postInitForStartedAsRelatedClient è quando si apre la related senza identity 
    // ma non dovrebbe mai succedere
    // Ogni volta che si fa uno store si riesegue la post init, è necessario ridisabilitare i campi external se sono in una related
    override async postInit(): Promise<void> {
        await super.postInit();
        // Cerco una External associata tramite FK ai campi chiave
        const external = MetaDataUtils.getExternalRelationFromIdentityProperties(this.domainModelMetaData);
        if (external === undefined) {
            LogService.warn('ExternalRelations related to Identity not found in RootViewModel<' + this.domainModelName + '>.PostInitForStartedAsRelatedClient');
            throw new Error('ExternalRelations related to Identity not found in RootViewModel<' + this.domainModelName + '>.PostInitForStartedAsRelatedClient');
        }

        const externalPropertyName = MetaDataUtils.toCamelCase(external.principalPropertyName);

        // Cerco su questa RootViewModel una property di tipo ExternalViewModel costruita sulla External trovata sopra
        let extVM: ExternalViewModelInterface;
        if (this[externalPropertyName] !== undefined) {
            extVM = this[externalPropertyName] as ExternalViewModelInterface;
        }

        if (extVM === undefined) {
            LogService.warn('ExternalViewModel ' + externalPropertyName + ' not found in ' + this.domainModelName + ' RootViewModel');
            throw new Error('ExternalViewModel ' + externalPropertyName + ' not found in ' + this.domainModelName + ' RootViewModel');
        }

        const pvm = extVM.codeProperties.values().next().value as PropertyViewModelInterface;
        if (pvm.getValue() != null) {
            extVM.switchIsEnabledForPropertyViewModels(false);
        }
    }
}
