import { registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { BaseValidator } from './commons/base-validator';
import { BaseValidationDecoratorInterface } from './commons/base-validation-decorator.interface';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';
import { TimeSpanMetaData } from '../../meta-data/time-span-meta-data';
import { RangeValidation } from './validations/range-validation';
import { TimeSpan } from '@nts/std/types';

export interface TimeSpanDecoratorInterface extends BaseValidationDecoratorInterface {
    minValue?: TimeSpan;
    maxValue?: TimeSpan;
    isAutoComputed?: boolean;
}

@ValidatorConstraint({ name: 'timeSpanValidator', async: false })
export class TimeSpanValidator extends BaseValidator<TimeSpan> implements ValidatorConstraintInterface {

    override validate(value: TimeSpan, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as TimeSpanDecoratorInterface;
        const result = super.validate(value, args) &&
            this.validateRangeTimeSpan(value, decorator.minValue, decorator.maxValue, args);
        return result;
    }

    protected static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new TimeSpanMetaData();
        propertyMetaData.isRequired = (decoratorData as TimeSpanDecoratorInterface).isRequired;
        propertyMetaData.isAutoComputed = (decoratorData as TimeSpanDecoratorInterface).isAutoComputed;
        propertyMetaData.minValue = (decoratorData as TimeSpanDecoratorInterface).minValue;
        propertyMetaData.maxValue = (decoratorData as TimeSpanDecoratorInterface).maxValue;
        propertyMetaData.descriptions.descriptionKey = (decoratorData as TimeSpanDecoratorInterface).descriptionKey;
        propertyMetaData.descriptions.displayNameKey = (decoratorData as TimeSpanDecoratorInterface).displayNameKey;
        propertyMetaData.name = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }

    private validateRangeTimeSpan(value: TimeSpan, minValue: TimeSpan, maxValue: TimeSpan, args: ValidationArguments) {
        const rangeValidation = new RangeValidation(minValue, maxValue);
        if (!rangeValidation.validate(value, args)) {
            this.errorMessage = rangeValidation.errorMessage;
            return false;
        }
        return true;
    }
}

export function TimeSpanDecorator(decoratorInterface: TimeSpanDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        TimeSpanValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        TimeSpanValidator.buildPropertyMetaData<TimeSpanDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        TimeSpanValidator.buildPropertyMetaData<TimeSpanDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: TimeSpanValidator
        });
    };
}
