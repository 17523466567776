import { Inject, Injectable, Optional } from '@angular/core';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MessageCodes } from '../../resources/message-codes';
import { ModalService, ShowExceptionPopUpFromBaseErrorInterface, ShowCustomModalWithResulInterface, ShowExceptionPopUpInterface, ShowExternalModalInterface, ShowMessageInterface, ShowPopUpModalInterface, ShowStandardModalInterface } from '../../view-models/modal/modal.service';
import { MessageButton } from '../../view-models/modal/message-button';
import { MessageResult } from '../../view-models/modal/message-result';
import { ModalViewModelInterface } from '../../view-models/modal/modal-view-model.interface';
import { ModalResult } from '../../view-models/modal/modal-result';
import { Information } from '../../messages/information';
import { BaseError } from '../../messages/base-error';
import { PopupViewModel } from '../../view-models/modal/popup-view-model';
import { ModalContainerComponent } from './modal-container.component';
import { ExternalModalViewModel } from '../../view-models/modal/external-modal-view-model';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';
import { ZoomOrchestratorViewModel } from '../../view-models/zoom/zoom-orchestrator-view-model';
import { ZoomUIStarterArgs } from '../../view-models/zoom/zoom-ui-starter-args';
import { IFrameGetter } from '../../starter/ui-starter';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT } from '@nts/std/environments';
import { EnvironmentHelper } from '@nts/std/environments';
import { MetaDataDescriptions } from '../../meta-data/meta-data-descriptions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RoutingService } from '../../routing/routing.service';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalModalViewModelInterface } from '../../view-models/modal/external-modal-view-model.interface';
import { ExceptionPopupViewModel } from '../../view-models/modal/exception-popup-view-model';

@Injectable()
export class ModalComponentService extends ModalService {

  constructor(
    public dialog: MatDialog,
    // private readonly bsModalService: BsModalService,
    private routingService: RoutingService,
    public override route: ActivatedRoute,
    private readonly environment: EnvironmentConfiguration,
    private readonly authService: AuthService,
    @Optional()
    @Inject(UNPROXIED_ENVIRONMENT) private readonly unproxiedEnvironment: EnvironmentConfiguration
  ) {
    super();
  }

  async showStandardModalAsync(
    buttons: MessageButton|ShowStandardModalInterface,
    title: string = '',
    viewModel: ModalViewModelInterface<any, void> = undefined,
    config: MatDialogConfig<any> = {
      disableClose: true
    },
    metaDataDescriptionsButtons: MetaDataDescriptions[] = [],
    isCentered = false
  ): Promise<MessageResult> {

    if (typeof buttons != "number") {
      // Utilizzo "var" al posto di "let" o "const" perche' la visibilita' sarebbe stata limitata al "if"
      var {
        title = title,
        viewModel = viewModel,
        config = config,
        metaDataDescriptionsButtons = metaDataDescriptionsButtons,
        isCentered = isCentered,
      } = buttons;
      var buttons: MessageButton|ShowStandardModalInterface = buttons.buttons;
    }

    if(this.ovm){
      this.ovm.isModalOpen = true;
    }

    if(this.routingService.inIframe) {
      config.backdropClass = 'in-iframe';
    }

    if(this.routingService.inIframe) {
      const inIframeDialogClass = 'in-iframe';
      config = {...config, panelClass: (config?.panelClass?.length > 0) ? [inIframeDialogClass, ...config.panelClass] : [inIframeDialogClass]}
    }

    if (isCentered === false) {
      const top = `${this.routingService.inIframe ? 50 : 100}px`;
      if (config?.position?.top == null) {
        const position =  {...(config?.position ?? {top: null}), top};
        config = {...config, position};
      }
    }

    const dialogRef = this.dialog.open(ModalContainerComponent, config);

    const promise = dialogRef.componentInstance.showStandardModalAsync(
      buttons,
      title,
      viewModel,
      metaDataDescriptionsButtons
    );
    promise.then(async () => {
      if(this.ovm){
        this.ovm.isModalOpen = false;
      }
    });
    return promise;
  }

  async showZoomAsync(
    viewModel: ZoomOrchestratorViewModel,
    maximizable = true,
    panelClass: string = 'zoom-container',
    width: string|undefined = undefined
  ): Promise<ModalResult<ZoomResult>> {
    viewModel.modalTitle = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.Zoom) + ' -> ' + viewModel.rootDomainModelDisplayName;

    return await this.showCustomModalWithResultAsync<ZoomUIStarterArgs, ZoomResult>({
      viewModel,
      showCancel: false,
      maximizable,
      maximized: true,
      config: {
        disableClose: true,
        panelClass,
        width
      },
      isCentered: true
    } as ShowCustomModalWithResulInterface<ZoomResult>);
  }

  async showMessageAsync(
    title: string | ShowMessageInterface,
    message: string = '',
    buttons: MessageButton = MessageButton.Ok,
    metaDataDescriptionsButtons: MetaDataDescriptions[] = [],
    panelClass = 'message-modal',
    width: string|undefined = undefined
  ): Promise<MessageResult> {

    if (typeof title != "string") {
      // Utilizzo "var" al posto di "let" o "const" perche' la visibilita' sarebbe stata limitata al "if"
      var {
        message = message,
        buttons = buttons,
        metaDataDescriptionsButtons = metaDataDescriptionsButtons,
        panelClass = panelClass,
        width = width,
      } = title;
      var title: string | ShowMessageInterface = title.title;
    }

    const top = `${this.routingService.inIframe ? 50 : 100}px`;

    const viewModel = new PopupViewModel();
    viewModel.list = [{
      message,
      detail: null
    }];
    return await this.showStandardModalAsync({
      buttons,
      title,
      viewModel,
      config:  {
        position: {
          top
        },
        width,
        panelClass
      },
      metaDataDescriptionsButtons
    });
  }

  /**
   * Utilizzare per visualizzare popup partendo da un base error
   */
  async showExceptionPopUpFromBaseErrors(
    params: ShowExceptionPopUpFromBaseErrorInterface,
  ) {

    const viewModel = new ExceptionPopupViewModel();
    viewModel.modalTitle = params.title ? params.title : this.errorCaption;
    viewModel.errors = params.errors;

    return await this.showCustomModalWithResultAsync({
      viewModel,
      showCancel: false,
      config: {
        disableClose: true,
        panelClass: params.panelClass,
        width: params.width ? params.width : '90%',
      },
    });
  }

  /**
   * Utilizzare per visualizzare popup partendo da un errore
   */
  async showExceptionPopUp(
    error: Error|ShowExceptionPopUpInterface,
    caption = '',
    message = '',
    metaDataDescriptionsButton: MetaDataDescriptions = undefined,
    panelClass = 'exception-modal',
    width: string|undefined = undefined
  ) {

    if (!(error instanceof Error)) {
      var {
        caption = caption,
        message = message,
        metaDataDescriptionsButton = metaDataDescriptionsButton,
        panelClass = panelClass,
        width = width,
      } = error as ShowExceptionPopUpInterface;
      error = (error as ShowExceptionPopUpInterface).error;
    }

    const viewModel = new PopupViewModel();
    viewModel.list = [{
      message: message ? message : error.message,
      detail: this.getExceptionDetail(error)
    }];

    viewModel.caption = caption ? caption : this.errorCaption;

    return await this.showStandardModalAsync({
      buttons: MessageButton.Ok,
      title: viewModel.caption,
      viewModel,
      config: {
        disableClose: true,
        panelClass,
        width
      },
      metaDataDescriptionsButtons: metaDataDescriptionsButton ? [metaDataDescriptionsButton] : []
    });
  }

  async showCustomModalWithResultAsync<TInputArgs, TResult>(
    viewModel: ModalViewModelInterface<TInputArgs, TResult>|ShowCustomModalWithResulInterface<TResult>,
    showCancel = true,
    maximizable = false,
    maximized = false,
    config: MatDialogConfig<any> = {},
    isCentered = false
    ): Promise<ModalResult<TResult>> {

    if (
      (viewModel as ShowCustomModalWithResulInterface<TResult>).viewModel !== undefined
    ) {
      var {
        showCancel = showCancel,
        maximizable = maximizable,
        maximized = maximized,
        config = config,
        isCentered = isCentered,
      } = viewModel as ShowCustomModalWithResulInterface<TResult>;
      viewModel = (viewModel as ShowCustomModalWithResulInterface<TResult>).viewModel;
    }

    if(this.ovm){
      this.ovm.isModalOpen = true;
    }

    if(this.routingService.inIframe) {
      config.backdropClass = 'in-iframe';
    }

    if (maximized) {
      const fullscreenDialogClass = 'fullscreen-dialog';

      if (config?.panelClass && typeof config?.panelClass === 'string' && config?.panelClass?.length > 0) {
        config = {...config, panelClass: [config?.panelClass, fullscreenDialogClass]}
      } else {
        config = {...config, panelClass: (config?.panelClass?.length > 0) ? [fullscreenDialogClass, ...config.panelClass] : [fullscreenDialogClass]}
      }

    }

    if(this.routingService.inIframe) {
      const inIframeDialogClass = 'in-iframe';

      if (config?.panelClass && typeof config?.panelClass === 'string' && config?.panelClass?.length > 0) {
        config = {...config, panelClass: [config?.panelClass, inIframeDialogClass]}
      } else {
        config = {...config, panelClass: (config?.panelClass?.length > 0) ? [inIframeDialogClass, ...config.panelClass] : [inIframeDialogClass]}
      }
    }

    if (isCentered === false) {
      const top = `${this.routingService.inIframe ? 50 : 100}px`;
      if (config?.position?.top == null) {
        const position =  {...(config?.position ?? {top: null}), top};
        config = {...config, position};
      }
    }

    if (maximized === true) {
      const top = `${this.routingService.inIframe ? 25 : 55}px`;
      const position =  {...(config?.position ?? {top: null}), top};
      config = {...config, position};
    }

    const dialogRef = this.dialog.open(ModalContainerComponent, config);

    const promise = dialogRef.componentInstance.showCustomModalWithResultAsync<TResult>(
      viewModel as ModalViewModelInterface<any, TResult>,
      showCancel,
      maximizable,
      maximized
    );
    promise.then(async () => {
      if(this.ovm){
        this.ovm.isModalOpen = false;
      }
    });
    return promise;
  }

  async showExternalModal<TResult>(
    baseUrl: string|ShowExternalModalInterface<TResult>,
    jsonIdentity: string|undefined = undefined,
    additionalQueryParams: URLSearchParams = new URLSearchParams(),
    modalTitle: string = '',
    externalReturn = true,
    supportRemoteClosingCheck = false,
    customExternalModalViewModel: ExternalModalViewModelInterface<any, TResult>|undefined = undefined,
    supportRemoteStatus = false,
    panelClass = 'external-modal-container',
    width: string|undefined = undefined
  ): Promise<ModalResult<TResult>> {

    if (typeof baseUrl != 'string') {
      var {
        jsonIdentity = jsonIdentity,
        additionalQueryParams = additionalQueryParams,
        modalTitle = modalTitle,
        externalReturn = externalReturn,
        supportRemoteClosingCheck = supportRemoteClosingCheck,
        customExternalModalViewModel = customExternalModalViewModel,
        supportRemoteStatus = supportRemoteStatus,
        panelClass = panelClass,
        width = width,
      } = baseUrl as ShowExternalModalInterface<TResult>;
      var baseUrl: string|ShowExternalModalInterface<TResult> = (baseUrl as ShowExternalModalInterface<TResult>).baseUrl;
    }

    const tenantId = await this.authService.getTenantId();
    const enterpriseData = await this.authService.getEnterpriseData(tenantId);

    const viewModel = customExternalModalViewModel ?? new ExternalModalViewModel(this);
    viewModel.baseUrl = EnvironmentHelper.getProxiedUrl(
      baseUrl, this.environment, this.unproxiedEnvironment
    );

    viewModel.jsonIdentity = jsonIdentity;
    viewModel.additionalQueryParams = additionalQueryParams ?? new URLSearchParams();

    if (enterpriseData?.enterpriseId != null && enterpriseData?.enterpriseId > -1) {
      viewModel.additionalQueryParams.set(AuthService.ENTERPRISE_ID_QUERY_KEY, enterpriseData.enterpriseId?.toString());
    }

    if (enterpriseData?.companyId != null && enterpriseData?.companyId > -1) {
      viewModel.additionalQueryParams.set(AuthService.COMPANY_ID_QUERY_KEY, enterpriseData.companyId?.toString());
    }

    viewModel.modalTitle = modalTitle;
    viewModel.externalReturn = externalReturn;
    viewModel.supportRemoteClosingCheck = supportRemoteClosingCheck;
    viewModel.supportRemoteStatus = supportRemoteStatus;
    const res = (this.showCustomModalWithResultAsync<IFrameGetter, TResult>({
      viewModel,
      showCancel: true,
      maximizable: false,
      maximized: false,
      config: {
        panelClass,
        width
      },
      isCentered: true
    } as  ShowCustomModalWithResulInterface<TResult>));

    return await res;
  }

  /**
   * Utilizzare per visualizzare popup partendo da una response
   */
  async showPopUp(
    informations: Array<Information>|ShowPopUpModalInterface,
    errors: Array<BaseError> = [],
    caption = '',
    metaDataDescriptionsButton: MetaDataDescriptions|undefined = undefined,
    panelClass = 'popup-modal',
    width: string|undefined = undefined
  ) {

    if (!Array.isArray(informations)) {
      // Utilizzo "var" al posto di "let" o "const" perche' la visibilita' sarebbe stata limitata al "if"
      var {
        errors = errors,
        caption = caption,
        metaDataDescriptionsButton = metaDataDescriptionsButton,
        panelClass = panelClass,
        width = width,
      } = informations;
      var informations: Array<Information>|ShowPopUpModalInterface = informations.informations;
    }

    const viewModel = new PopupViewModel();

    if (errors.length > 0) {
      viewModel.caption = caption ? caption : this.errorCaption;

      const errorList = errors.map((e) => {
        let detail = '';
        detail = this.visitErrors(detail, e);

        let message = e.description;
        if (e.code && e.code?.length > 0) {
          message += `<br>Codice: ${e.code}`;
        }
        if (e.propertyName && e.propertyName?.length > 0) {
          message += `<br>PropertyName: ${e.propertyName}`;
        }
        return {
          message,
          detail
        }
      })

      // TODO aggiungere warning/exception

      viewModel.list = errorList;

    } else if (informations.length > 0) {
      // Non ci sono errori, solo informazioni, allora il messaggio principale è quello della prima informazione e lo stile è di tipo Information
      viewModel.caption = caption ? caption : this.informationCaption;

      const infoList = informations.map((i) => {
        const sb = '';
        this.visitInformations(sb, i);
        return {
          message: `Information: ${i.description}<br> Code: ${i.code}<br>PropertyName: ${i.propertyName}<br>`,
          detail: sb
        }
      })

      viewModel.list = infoList;
    }

    return await this.showStandardModalAsync({
      buttons: MessageButton.Ok,
      title: viewModel.caption,
      viewModel,
      metaDataDescriptionsButtons: metaDataDescriptionsButton ? [metaDataDescriptionsButton] : [],
      config: {
        panelClass,
        disableClose: true,
        width
      }
    });
  }

  private get informationCaption() {
    return MessageResourceManager.Current.getMessage(MessageCodes.Information);
  };

  private get warningCaption() {
    return MessageResourceManager.Current.getMessage(MessageCodes.Warning);
  }

  private get errorCaption() {
    return MessageResourceManager.Current.getMessage(MessageCodes.Error);
  }

  private visitErrors(sb: string, error: BaseError): string {
    if (error != null) {
      if (error.objectName && error.objectName?.length > 0) {
        sb += `${error.objectName}<br>`;
      }

      if (error.stackTrace && error.stackTrace?.length > 0) {
        sb += `${error.stackTrace}<br>`;
      }
      return this.visitErrors(sb, error.innerError);
    }
    return sb;
  }

  private visitInformations(sb: string, information: Information) {
    if (information != null) {
      const errorFormat = `DMName: ${information.objectName}<br>`;
      sb += errorFormat;
    }
  }

  private getExceptionDetail(exception: Error) {
    let detail = '';

    if (exception != null) {
      let sb = '';
      sb += `Message: ${exception.message} <br>`;
      sb += `Stack trace: ${exception.stack} <br>`;

      detail = sb;
    }

    return detail;
  }
}

