import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ExternalViewModelInterface } from '../../../../../src/lib/view-models/external-view-model.interface';
import { StringPropertyViewModel } from '../../../../../src/lib/view-models/base-type/string-property-view-model';
import { GridComponent } from '../../grid.component';
import { ExternalColumnCellEditorParams } from './../ext_column_cell_editor_params.interface';
import { take } from 'rxjs/operators';
import { ZoomResult } from '../../../../../src/lib/domain-models/zoom/zoom-result';
import { ExtAutocompleteTextBoxComponent } from '../../../../../src/lib/components/controls/core/ext-autocomplete-text-box/ext-autocomplete-text-box.component';
import { PropertyViewModelInterface } from '../../../../../src/lib/view-models/property-view-model.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LogService } from '@nts/std/utility';
import { StringTextBoxComponent } from '../../../../../src/lib/components/controls/core/string-text-box/string-text-box.component';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { ExtNewAutocompleteTextBoxComponent } from '../../../../../src/lib/components/controls/core/ext-new-autocomplete-text-box/ext-new-autocomplete-text-box.component';
import { BigNumber } from '@nts/std/types';

// create your cellEditor as a Angular component
const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 9;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_LEFT = 37;
const KEY_RIGHT = 39;

@UntilDestroy()
@Component({
    selector: 'nts-ext-string-text-box-editor-cell',
    styleUrls: ['./external-string-cell-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        StringTextBoxComponent,
        ExtAutocompleteTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent,
        NgIf,
        AsyncPipe
    ],
    template: `
        <!-- <nts-string-text-box
            *ngIf="!isAutocomplete"
            #textBox
            (keyDown)="onKeyDown($event)"
            (valueChange)="valueChange($event)"
            (onFinishEditing)="onFinishEditing()"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [propertyViewModel]="propertyViewModel"
            [showErrorBorder]="false"
            [modelUpdate]="false"
            [updateOnBlur]="false"
            [initialValue]="value.description"
            [selectAllOnFocus]="false">
        </nts-string-text-box> -->

        <!-- <nts-ext-autocomplete-text-box  
            *ngIf="isAutocomplete"
            #autocomplete
            (keydown)="onKeyDown($event)"
            (valueChange)="valueChange($event)"
            (onFinishEditing)="onFinishEditing()"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [decodeDescription]="description"
            [propertyViewModel]="propertyViewModel"
            [enableOutsideClickListener]="true"
            [showErrorBorder]="false"
            [initialValue]="value"
            [modelUpdate]="false"
            [updateOnBlur]="true"
            [code]="propertyViewModel"
            [externalPropertyViewModel]="externalPropertyViewModel">
        </nts-ext-autocomplete-text-box> -->

        <nts-ext-new-autocomplete-text-box
            *ngIf="isAutocomplete"
            #autocomplete
            [isDisabled]="!(externalPropertyViewModel.isEnabled$ | async)" 
            (valueChange)="onExternalBoxValueChange($event)"
            [enableOutsideClickListener]="true"
            (onFinishEditing)="onFinishEditing()"
            [decodeProperties]="properties"
            [showCodeInDescription]="showCode"
            [searchProperties]="searchProperties"
            (keyDown)="onKeyDown($event)"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [showErrorBorder]="false"
            [initialValue]="value"
            [modelUpdate]="false"
            [updateOnBlur]="true"
            [externalPropertyViewModel]="externalPropertyViewModel"
            ></nts-ext-new-autocomplete-text-box>
    `
})

export class ExternalStringCellEditorComponent implements AfterViewInit, OnDestroy {

    @ViewChild('textBox', { static: false }) textBox: StringTextBoxComponent;
    @ViewChild('autocomplete', { static: false }) autocomplete: ExtNewAutocompleteTextBoxComponent;

    value: { identity: { [key: string]: string | number | BigNumber }, description?: string, isValid?: boolean };
    highlightAllOnFocus = true;
    element: ElementRef;
    zoomPending = false;
    isAutocomplete = false;
    isCancelled = true;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    properties = [];
    showCode = false;
    searchProperties = [];

    private _code: StringPropertyViewModel;

    get code(): StringPropertyViewModel {
        if (!this._code) {
            this._code = this.params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    return o[i];
                }, this.params.data);
        }
        return this._code;
    }

    get cellWidth() {
        return this.params.eGridCell.clientWidth;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

    get input(): any {
        if (this.element) {
            return this.element.nativeElement;
        } else {
            return null;
        }
    }

    get errorList(): string[] {
        return this.externalPropertyViewModel?.errors$.value;
    }

    get description(): PropertyViewModelInterface {
        return this.externalPropertyViewModel.descriptionProperties.values().next().value;
    }

    get gridContext(): GridComponent {
        return this.params.context;
    }

    get externalPropertyViewModel(): ExternalViewModelInterface {
        // return this.params.node.data[this.params.columnInfo.path];
        if(this.params.columnInfo.path){
            let composed = this.params.columnInfo.path.split('.');
            let ext = this.params.node.data;
            for (const shortPath of composed) {
                ext = ext[shortPath];
            }
            return ext;
            // return this.params.node.data[this.params.columnInfo.path]
        }
        return this.params.node.data[this.params.columnInfo.fieldName];
        // return this.params.columnInfo.path ? this.params.node.data[this.params.columnInfo.path] : this.params.node.data[this.params.columnInfo.fieldName];
    }

    private params: ExternalColumnCellEditorParams;
    private cancelBeforeStart = false;

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly el: ElementRef
    ) {

    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

        if (this.isAutocomplete) {
            this.element = this.autocomplete.baseExternalBox.inputRef.searchInput;
        } else {
            this.element = this.textBox.element;
        }

        window.setTimeout(() => {
            this.input.focus();
            if (this.highlightAllOnFocus) {
                this.input.select();

                this.highlightAllOnFocus = false;
            } else {
                // when we started editing, we want the carot at the end, not the start.
                // this comes into play in two scenarios: a) when user hits F2 and b)
                // when user hits a printable character, then on IE (and only IE) the carot
                // was placed after the first character, thus 'apply' would end up as 'pplea'
                const length = this.input.value
                    ? this.input.value.length
                    : 0;
                if (length > 0) {
                    this.input.setSelectionRange(length, length);
                }
            }

            this.input.focus();
        });
    }

    agInit(params: ExternalColumnCellEditorParams): void {
        this.params = params;
        this.setInitialState(this.params);

        this.cancelBeforeStart = false;

        this.isAutocomplete = this.params.columnInfo.isAutocomplete;


        if(this.params.columnInfo.isRef && this.externalPropertyViewModel.decodeProperties && this.externalPropertyViewModel.decodeProperties.length !=0){
            this.properties = this.externalPropertyViewModel.decodeProperties 
        } else if (this.params.columnInfo.isRef && this.params.columnInfo.decodeProperties && this.params.columnInfo.decodeProperties.length != 0){
            this.properties = this.params.columnInfo.decodeProperties;
        } else if (this.params.columnInfo.isRef){
            this.properties = [this.description.propertyMetaData.name];
        } else {
            this.properties = null;
        }

        this.showCode = this.params.columnInfo.showCodeInDescription;

        if(this.params.columnInfo.searchProperties && this.params.columnInfo.searchProperties.length != 0){
            this.searchProperties =  this.params.columnInfo.searchProperties;
        } else if(this.externalPropertyViewModel.searchProperties && this.externalPropertyViewModel.searchProperties.length != 0){
            this.searchProperties = this.externalPropertyViewModel.searchProperties;
        } else {
            this.searchProperties = null;
        }

        this.externalPropertyViewModel.externalDomainModelChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.decodeCompleted.pipe(untilDestroyed(this)).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    ngOnDestroy() {
        // Reset backing field
        // if (this.isCancelled) {
        //     this.propertyViewModel.setValue(this.code.getValue());
        // }
    }

    isPopup(): boolean {
        return false;
    }

    blur($event) {
        this.externalPropertyViewModel.validate();
    }

    private navigateToNextCell(event: any) {

        const key = this.getCharCodeFromEvent(event);

        const fc = this.gridContext.api.getFocusedCell();
        LogService.debug('focused cell rowindex ' + fc.rowIndex, 'focused celll column ' + fc.column);
        if (key === KEY_DOWN) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex + 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_UP) {
            // set selected cell on current cell + 1
            this.gridContext.api.forEachNode((node) => {
                if (fc.rowIndex - 1 === node.rowIndex) {
                    this.gridContext.api.setFocusedCell(node.rowIndex, fc.column);
                    this.gridContext.api.startEditingCell({
                        rowIndex: node.rowIndex,
                        colKey: fc.column
                    });
                }
            });
        } else if (key === KEY_LEFT) {
            this.gridContext.api.tabToPreviousCell();
        } else if (key === KEY_RIGHT) {
            this.gridContext.api.tabToNextCell();
        }
    }

    setInitialState(params: any) {
        let startValue;
        let highlightAllOnFocus = true;

        if (params.eventKey === KEY_BACKSPACE || params.eventKey === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (params.eventKey) {
            // if a letter was pressed, we start with the letter
            startValue = params.eventKey;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o[i];
                    }
                    return o[i];
                }, this.params.data
            )
            if (params.eventKey === KEY_F2) {
                highlightAllOnFocus = false;
            }
        }

        this.value = startValue;
        this.highlightAllOnFocus = highlightAllOnFocus;
    }

    // for testing
    setValue(newValue: any) {
        this.textBox.input.value = newValue;
        this.textBox.onInput(null);
    }

    valueChange(value: any) {
        if (this.value != value) {
            this.value = value;
        }
    }

    onExternalBoxValueChange(value: any) {
        if (this.value != value) {
            this.value = value;
        }
    }   

    getValue(): any {
        // Se si esce con il tasto ESC questa funzione non viene chiamata
        this.isCancelled = false;
        return this.value;
    }

    onFinishEditing() {
        this.params.stopEditing();
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    async zoom() {
        if (!this.zoomPending) {
            this.zoomPending = true;
            if (this.externalPropertyViewModel.decodeInProgress) {
                this.externalPropertyViewModel.decodeCompleted.pipe(take(1)).subscribe(async res => {
                    await this.showZoom();
                });
            } else {
                await this.showZoom();
            }
        }
    }

    private async showZoom() {
        const zoomResult = await this.externalPropertyViewModel.findExternal();
        if (zoomResult.result === ZoomResult.empty().result && this.externalPropertyViewModel.hasDecodeError) {
        }
        this.zoomPending = false;

        this.input.focus();
        // this.decodeTooltip.hide();
    }

    onKeyDown(event: any): void {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (event.key === 'F6' && event.ctrlKey && this.externalPropertyViewModel.isEnabled && !this.zoomPending) {
            if (this.isAutocomplete) {
                this.autocomplete.zoom();
            } else {
                this.zoom();
            }
        } else if (event.key === 'F8' && event.ctrlKey) {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
            this.externalPropertyViewModel.startPresentation();
        }

        if (
            !this.finishedEditingPressed(event) &&
            !this.isKeyPressedString(event)
        ) {
            if (event.preventDefault) { event.preventDefault(); }
        }

        if (this.isUpOrDown(event)) {
            this.navigateToNextCell(event);
        }
    }

    private isKeyPressedString(event: any): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return true;
    }

    private isString(charStr: string): boolean {
        return !!/^[a-z\s]{0,255}$/i.test(charStr);
    }

    private isSymbol(charStr: string): boolean {
        return !!/[^\p{L}\d\s@#]/u.test(charStr);
    }

    private isSpace(charStr: string): boolean {
        return !!/\s/u.test(charStr);
    }

    private isCharNumeric(charStr: string): boolean {
        return !!/\d/.test(charStr);
    }

    private getCharCodeFromEvent(event: any): any {
        event = event || window.event;
        return typeof event.which == 'undefined' ? event.keyCode : event.which;
    }

    private deleteOrBackspace(event: any) {
        return (
            [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1
        );
    }

    private isLeftOrRight(event: any) {
        return [KEY_LEFT, KEY_RIGHT].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private isUpOrDown(event: any) {
        return [KEY_UP, KEY_DOWN].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private finishedEditingPressed(event: any) {
        const charCode = this.getCharCodeFromEvent(event);
        return charCode === KEY_ENTER || charCode === KEY_TAB;
    }
}
