import { ExternalReaderApiClient } from './external-reader-api-client';
import { ReaderHttpCommunication } from './reader-http-communication';
import { ReaderApiClientInterface } from './reader-api-client.interface';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { Observable } from 'rxjs';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { AutoCompleteModelRequest } from '../requests/auto-complete-model-request';
import { FindResponse } from '../responses/find-response';
import { GetByIdentityCachedResponse } from '../responses/get-by-identity-cached-response';
import { CacheOptionsInterface } from './web-api-service-agent';
import { Injectable } from '@angular/core';
import { ModelInterface } from '@nts/std/interfaces';
import { IdentityInterface } from '@nts/std/interfaces';

@Injectable()
export class ReaderApiClient<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends ExternalReaderApiClient<TModel, TIdentity>
    implements ReaderApiClientInterface {

    protected createReaderHttpCommunication(): ReaderHttpCommunication {
        return new ReaderHttpCommunication(this.agent);
    }

    getByIdentity(
        request: GetByIdentityRequest<TIdentity>,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
    ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
        const communication = this.createReaderHttpCommunication();
        return communication.getByIdentity<TIdentity, TModel>(
            request, 
            this.rootModelType,
            cacheOptions
        );
    }

    getByIdentityCached(
        request: GetByIdentityRequest<TIdentity>,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
    ): Observable<GetByIdentityCachedResponse<TModel, TIdentity>> {

        // Default for getByIdentityCached
        cacheOptions = {
            bypass: false,                         
            expirationTime: undefined,                  
            force: false,
            enterpriseBarrier: true,
            tenantBarrier: true,
            userBarrier: true,
            ...cacheOptions
        };

        const communication = this.createReaderHttpCommunication();
        return communication.getByIdentityCached<TIdentity, TModel>(
            request, 
            this.rootModelType,
            cacheOptions
        );
    }

    autoCompleteModel(
        request: AutoCompleteModelRequest,
        cacheOptions: CacheOptionsInterface = {
            bypass: false,
            expirationTime: undefined,
            force: false,
        },
    ): Observable<FindResponse<TModel, TIdentity>> {
        const communication = this.createReaderHttpCommunication();
        return communication.autoCompleteModel<TModel, TIdentity>(
            request, this.rootModelType, cacheOptions
        );
    }
}
