import { BaseSecurityTextBoxComponent } from './../base/base-security-text-box/base-security-text-box.component';
import { ChangeDetectionStrategy, Component, Input, ViewChild, OnInit, OnChanges, ChangeDetectorRef, SimpleChanges, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { PopperHelper } from '@nts/std/utility';
import { BaseTimeSpanTextBoxComponent } from '../base/base-time-span-text-box/base-time-span-text-box.component';
import { BasePropertyComponent } from '../base-property-component';
import { NTimeSpanPropertyViewModel, TimeSpanPropertyViewModel } from '../../../../view-models/base-type/time-span-property-view-model';
import { Observable, filter, merge, takeUntil } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TimeSpan } from '@nts/std/types';

@UntilDestroy()
@Component({
    selector: 'nts-time-span-text-box',
    templateUrl: './time-span-text-box.component.html',
    styleUrls: ['./time-span-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        BaseTimeSpanTextBoxComponent,
        BaseSecurityTextBoxComponent,
        AsyncPipe
    ]
})
export class TimeSpanTextBoxComponent extends BasePropertyComponent<TimeSpan> implements OnInit, OnChanges, OnChanges {
    
    @Input() isDisabled = false;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandsName = [];
    @Input() tabIndex: number;
    @Input() initialChar: string | null = null;
    @Input() listenClickOutside = false;
    @Input() placeholder = null;
    @Input() primaryColor = null;
    
    @Output() onFinishEditing = new EventEmitter();
    @Output() keyDown = new EventEmitter();

    @ViewChild('baseTimeSpanBox', { static: false }) baseTimeSpanBox: BaseTimeSpanTextBoxComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;

    override propertyViewModel!: NTimeSpanPropertyViewModel | TimeSpanPropertyViewModel;
    
    get isNullable(): boolean {
        return this.propertyViewModel instanceof NTimeSpanPropertyViewModel;
    }

    get value(): TimeSpan | null {
        return this.valueTimeSpan;
    }
    set value(value: TimeSpan | null) {
        if (value && value instanceof TimeSpan) {
            this.valueTimeSpan = new TimeSpan(value.totalMilliSeconds);
        } else {
            this.valueTimeSpan = null;
        }
    }

    get input(): HTMLInputElement {
        return (this.propertyViewModel?.securityAccess == null ? this.baseTimeSpanBox.textBox.nativeElement : this.baseSecurityTextBox.securityTextBox.nativeElement);
    }

    private valueTimeSpan: TimeSpan;

    constructor(
        cd: ChangeDetectorRef
    ) {
        super(cd);
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {

        if (changes['propertyViewModel']) {
            this.setValueFromOutside();

            this.destroyOnChange$.next(true);

            // Rimane in ascolto quando cambia il pvm e il valore
            merge(
                this.propertyViewModel.customGetterValueChanged,
                this.propertyViewModel.propertyViewModelChanged,
                this.propertyViewModel.modelChanged,
                this.propertyViewModel.propertyChanged.pipe(filter((args) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {

                this.setValueFromOutside();
            });

            // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
            this.propertyViewModel.propertyChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$),
                filter((args) => args.propertyName !== this.propertyViewModel.bindedValuePropertyName)
            ).subscribe(() => {
                this.cd.detectChanges();
            });

            this.propertyViewModel.onFocusRequested.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.focus(this.propertyViewModel.hasErrors);
                this.input.select();
            });
        }
    }

    ngOnInit(): void {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    }

    showErroTooltip(): void {
        if (this.baseTimeSpanBox?.popperError) {
            PopperHelper.show(this.baseTimeSpanBox?.popperError)
        }        
    }

    async onBlur(event): Promise<void> {
        if (!this.isDisabled && this.propertyViewModel.isEnabled) {
            if (this.value == null) {
                this.valueChange.emit(null);
                if (this.modelUpdate) {
                    await this.propertyViewModel.resetValue(false);

                }
            } else {
                this.valueChange.emit(this.value);
                if (this.modelUpdate) {
                    await this.propertyViewModel.setValueAsync(this.value);
                }
            }
        }
    }

    onFocus($event): void {
        this.input.select();
    }

    onValueChange(e): void {
        if (e && e instanceof TimeSpan) {
            this.valueTimeSpan = new TimeSpan(e.totalMilliSeconds);
        } else {
            this.valueTimeSpan = null;
        }        
    }

    private setValueFromOutside(): void {
        if (this.propertyViewModel.value != null) {
            this.value = this.propertyViewModel.value;
        } else if (this.value != null) {
            this.value = null;
        }
        this.cd.detectChanges();
    }
}
