import { Expose } from "@nts/std/serialization";
import { ServiceRequest } from './service-request';
import { IdentityInterface } from '@nts/std/interfaces';

export class GetByIdentityRequest<TIDentity extends IdentityInterface> extends ServiceRequest {

    @Expose()
    identity: TIDentity;

    constructor(identity?: TIDentity) {
        super();
        this.identity = identity;
    }

}
