import { LongOpParametersTypeInspector } from './../../decorators/long-op-parameters-type.decorator';
import { LongOpResultTypeInspector } from '../../decorators/long-op-result-type.decorator';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';
import { RootViewModel } from '../root-view-model';
import { ClassConstructor } from '@nts/std/serialization';
import { InternalViewModel } from '../internal-view-model';
import { InternalViewModelTypeDecorator } from '../decorators/internal-view-model-type.decorator';
import { LongOpRootViewModelInterface } from './long-op-root-view-model.interface';
import { ClassInformationInterface } from '@nts/std/interfaces';

export class LongOpRootViewModel<
    TModel extends ModelInterface<TIdentity>,
    TIdentity extends IdentityInterface,
    TParams extends InternalViewModel<TParamsModel, TIdentity>,
    TResult extends InternalViewModel<TResultModel, TIdentity>,
    TParamsModel extends ModelInterface<TIdentity>,
    TResultModel extends ModelInterface<TIdentity>>
    extends RootViewModel<TModel, TIdentity> implements LongOpRootViewModelInterface, ClassInformationInterface {

    private _paramsType: ClassConstructor<TParams>;

    private _resultType: ClassConstructor<TResult>;

    @InternalViewModelTypeDecorator((target: any, propertyKey: string) => {
        return target.paramsType;
    })
    params: TParams;

    @InternalViewModelTypeDecorator((target: any, propertyKey: string) => {
        return target.resultType;
    })
    result: TResult;

    get paramsType(): ClassConstructor<TParams> {
        return this._paramsType;
    }

    get resultType(): ClassConstructor<TResult> {
        return this._resultType;
    }

    constructor() {

        super();

        this._paramsType = LongOpParametersTypeInspector.getValue(this);
        if (this._paramsType === undefined) {
            throw new Error(
                `MetaData ${LongOpParametersTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpParametersTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._resultType = LongOpResultTypeInspector.getValue(this);
        if (this._resultType === undefined) {
            throw new Error(
                `MetaData ${LongOpResultTypeInspector.META_DATA_KEY} not defined. You must use ${LongOpResultTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

    }
}
