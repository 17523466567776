import isEqual from 'lodash-es/isEqual';
import { ValidatableModel } from './validatable-model';
import { IdentityTypeNameInspector } from './decorators/identity-type-name.decorator';
import { classToPlain, Expose } from '@nts/std/serialization';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export abstract class BaseIdentity extends ValidatableModel implements IdentityInterface {

    private _identityType: string = null;

    protected parentModel: ModelInterface;

    constructor(parentModel: ModelInterface = null) {
        super(true);
        this.parentModel = parentModel;
        this.identityType = this.modelTypeName;
    }

    protected getValue<TValue>(func: () => TValue, propertyName: string): TValue {
        return this.parentModel != null ?
            this.parentModel.getPropertyValue<TValue>(propertyName) : func();
    }

    protected setValue<TValue>(action: () => void, newValue: TValue, propertyName: string): void {
        if (this.parentModel != null) {
            this.parentModel.setPropertyValue(propertyName, newValue);
        } else {
            action();
        }
    }

    init(owner: ModelInterface) {
        this.parentModel = owner;
    }

    equals(valueToCompare: IdentityInterface): boolean {

        const isEqualState = this === valueToCompare;

        if (!isEqualState && valueToCompare != null) {
            // let plainIdentity = classToPlain(this, { excludePrefixes: ['_'] });
            // let plainValueToCompare = classToPlain(valueToCompare, { excludePrefixes: ['_'] });

            const plainIdentity = classToPlain(this, { strategy: 'excludeAll' });
            const plainValueToCompare = classToPlain(valueToCompare, { strategy: 'excludeAll' });
            return isEqual(plainIdentity, plainValueToCompare);
        } else {
            return isEqualState;
        }
    }

    @Expose()
    get identityType(): string {
        if (this._identityType == null) {
            const identityTypeName = IdentityTypeNameInspector.getValue(this);
            this._identityType = identityTypeName; // aggiungere questa successivamente
        }
        return this._identityType;
    }

    set identityType(value) {
    }
}
