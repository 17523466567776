import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BigNumber } from "@nts/std/types";
import { catchError, map, Observable } from "rxjs";

@Injectable()
export class BigNumberInterceptor implements HttpInterceptor {

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    try {
      let stringifiedBody = JSON.stringify(httpRequest.body);
      if (stringifiedBody?.length > 0) {
        const headers = httpRequest.headers.set('Content-Type', 'application/json; charset=utf-8');
        const body = BigNumber.dequoteString(stringifiedBody);

        httpRequest = httpRequest.clone({
          body,
          headers
        });
      }
    } catch(err) {
     console.log(err);
    }

    return httpRequest.responseType === "json"
      ? this.handleJsonResponses(httpRequest, next)
      : next.handle(httpRequest);
  }

  private handleJsonResponses(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(httpRequest.clone({ responseType: "text" }))
      .pipe(
        map(response => this.parseResponse(response)),
        catchError(response => this.handleErrorResponse(response))
      );
  }

  private parseResponse(response: HttpEvent<any>): HttpEvent<any> {
    if (response instanceof HttpResponse) {
      const res = response.clone({ body: BigNumber.parseJson(response.body) })
      if (response['requestInfo']) {
        res['requestInfo'] = response['requestInfo'];
      }      
      return res;
    } else {
      return response;
    }
  }

  private handleErrorResponse(response: any): Observable<HttpEvent<any>> {
    if (response instanceof HttpErrorResponse) {
      if (response.error == null) {
        throw response;
      } else {
        const errorResponse = new HttpErrorResponse({
          error: BigNumber.parseJson(response.error),
          headers: response.headers,
          status: response.status,
          statusText: response.statusText,
          url: response.url ?? undefined
        });
        if (response['requestInfo']) {
          errorResponse['requestInfo'] = response['requestInfo'];
        }
        throw errorResponse;
      }
    } else {
      throw response;
    }
  }
}