import { Component } from '@angular/core';
import { BaseNumericPropertyViewModel } from '../../../view-models/base-type/base-numeric-property-view-model';
import { NumericMaskSettings } from './base/base-numeric-box/base-numeric-box.component';
import { NewBasePropertyTextBox } from './new-base-property-text-box';
import { BigNumber } from '@nts/std/types';

@Component({
    template: ''
})

export abstract class BaseNumericPropertyTextBox<T = BigNumber|number> extends NewBasePropertyTextBox<T> {
    override propertyViewModel: BaseNumericPropertyViewModel<T>;
    
    /**
     * Calcola il valore massimo partendo dai numeri interi e decimali massimi
     * 
     * @param decimalDigits numero di decimali massimi
     * @param integerDigits numero di interi massimi
     * @returns il numero massimo in formato numerico se inferiore a 14 cifre (decimalDigits + integerDigits) altrimenti ritorna un BigNumber
     */
    calculateMaxValue(decimalDigits: number, integerDigits: number): number|BigNumber {
        const integerDigitsString = "9".repeat(integerDigits);
        let decimalDigitsString = "";
        if (decimalDigits > 0) {
            decimalDigitsString = "9".repeat(decimalDigits);
        }
        const stringWithSeparator = integerDigitsString + (decimalDigitsString?.length > 0 ? ('.' + decimalDigitsString) : '');

        if ((integerDigitsString?.length + decimalDigitsString?.length) >= BaseNumericPropertyViewModel.MIN_DIGIT_FOR_BIGNUMBER) { 
            return new BigNumber(stringWithSeparator)
        }
        return parseFloat(stringWithSeparator);
    }

    /**
     * Calcola il valore minimo partendo dai numeri interi e decimali massimi
     * 
     * @param decimalDigits numero di decimali massimi
     * @param integerDigits numero di interi massimi
     * @returns il numero minimo in formato numerico se inferiore a [BaseNumericPropertyViewModel.MIN_DIGIT_FOR_BIGNUMBER] cifre (decimalDigits + integerDigits) altrimenti ritorna un BigNumber
     */
    calculateMinValue(decimalDigits: number, integerDigits: number): number|BigNumber {
        const maxValue = this.calculateMaxValue(decimalDigits, integerDigits);
        return BigNumber.isBigNumber(maxValue) ?
            maxValue.multipliedBy(new BigNumber(-1)) :
            maxValue * -1;
    }

    protected getMaskSettings(): NumericMaskSettings {
        const nullable = this.propertyViewModel.isNullable;
        const digits = BaseNumericPropertyViewModel.getDecimalDigits(this.propertyViewModel.propertyName, this.propertyViewModel.integerLimit, this.propertyViewModel.decimalLimit);
        const integerDigits = BaseNumericPropertyViewModel.getIntegerDigits(this.propertyViewModel.propertyName, this.propertyViewModel.integerLimit, this.propertyViewModel.decimalLimit);
        const max = this.propertyViewModel.maxValue != null ? this.propertyViewModel.maxValue : this.calculateMaxValue(digits, integerDigits);
        const min = this.propertyViewModel.minValue != null ? this.propertyViewModel.minValue : this.calculateMinValue(digits, integerDigits);

        const isBigNumber = (integerDigits + digits) >= BaseNumericPropertyViewModel.MIN_DIGIT_FOR_BIGNUMBER

        return {
            nullable,
            decimalLimit: digits,
            integerLimit: integerDigits,
            useThousandSeparator: this.propertyViewModel.useThousandSeparator,
            min,
            max,
            isBigNumber,
            faultBackValueFunc: () => this.propertyViewModel.faultBackValue?.toString()

            // alias: 'numeric',
            // nullable,
            // positionCaretOnClick: 'none' as any,
            // allowMinus: true,
            // allowPlus: false,
            // unmaskAsNumber: true,
            // radixPoint: BaseNumericPropertyViewModel.getDecimalSeparator(),
            // groupSeparator: this.propertyViewModel.useThousandSeparator ? BaseNumericPropertyViewModel.getThousandSeparator() : '',
            // SetMaxOnOverflow: false,
            // showMaskOnHover: false,
            // digits,
            // digitsOptional: true,
            // min,
            // max,
            // placeholder: nullable ? '' : '0',
            // rightAlign: true,
            // clearMaskOnLostFocus: true
        };
    }
}
