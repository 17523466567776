import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ZoomParametersViewModel } from '../../../view-models/zoom/zoom-parameters-view-model';
import { StringZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/string-zoom-property-view-model';
import { ZoomPropertyViewModelInterface } from '../../../view-models/zoom/property-view-model/zoom-property-view-model.interface';
import { NumericZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/numeric-zoom-property-view-model';
import { ZoomFilterViewModel } from '../../../view-models/zoom/filter-view-model/zoom-filter-view-model';
import { trigger, transition, style, animate } from '@angular/animations';
import { BoolZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/bool-zoom-property-view-model';
import { DateTimeZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/date-time-zoom-property-view-model';
import { DateTimeOffsetZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/date-time-offset-zoom-property-view-model';
import { EnumZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/enum-zoom-property-view-model';
import { FilterOperators } from '../../../domain-models/find-options/filter';
import { CollapsableFilterViewModelInterface } from '../../../view-models/zoom/filter-view-model/collapsable-filter-view-model.interface';

import split from 'split.js';
import { GuidZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/guid-zoom-property-view-model';
import { FilledButtonType } from '../../shared/buttons/filled-button/filled-button.component';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';
import { AsyncPipe, CommonModule, JsonPipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { NTSTranslatePipe } from '../../pipe';
import { BaseCheckboxTextBoxComponent } from '../../controls/core/base/base-checkbox-text-box/base-checkbox-text-box.component';
import { LabelBoxComponent } from '../../controls/core/base/label-box/label-box.component';
import { ZoomFilterOperatorTextBoxComponent } from './zoom-filter-operator-text-box.component';
import { ZoomFilterStringTextBoxComponent } from '../core/zoom-filter-string-text-box';
import { ZoomFilterNumericTextBoxComponent } from '../core/zoom-filter-numeric-text-box';
import { ZoomFilterBoolTextBoxComponent } from '../core/zoom-filter-bool-text-box';
import { ZoomFilterDateTextBoxComponent } from '../core/zoom-filter-date-text-box';
import { ZoomFilterDateOffsetTextBoxComponent } from '../core/zoom-filter-date-offset-text-box';
import { ZoomFilterEnumTextBoxComponent } from '../core/zoom-filter-enum-text-box';
import { BaseEnumTextBoxComponent } from '../../controls';
import { ZoomParametersSortingGroupsComponent } from '../zoom-parameters-sorting-groups/zoom-parameters-sorting-groups.component';
import { ZoomAvailablesFilterPipe } from '../zoom-availables-filter.pipe.ts';
import { ZoomFilterExternalTextBoxComponent } from '../core/zoom-filter-external-text-box/zoom-filter-external-text-box.component';
import { ExternalZoomPropertyViewModel } from '../../../view-models/zoom/property-view-model/external-zoom-property-view-model';
import { BigNumber, DateTimeOffset } from '@nts/std/types';

@Component({
  selector: 'nts-zoom-parameters',
  templateUrl: './zoom-parameters.component.html',
  styleUrls: ['zoom-parameters.component.scss'],
  standalone: true,
  imports: [
    TextButtonComponent,
    AsyncPipe,
    NgIf,
    NTSTranslatePipe,
    BaseCheckboxTextBoxComponent,
    NgFor,
    NgClass,
    LabelBoxComponent,
    ZoomFilterOperatorTextBoxComponent,
    ZoomFilterStringTextBoxComponent,
    ZoomFilterNumericTextBoxComponent,
    ZoomFilterExternalTextBoxComponent,
    ZoomFilterBoolTextBoxComponent,
    ZoomFilterDateTextBoxComponent,
    ZoomFilterDateOffsetTextBoxComponent,
    ZoomFilterEnumTextBoxComponent,
    BaseEnumTextBoxComponent,
    ZoomParametersSortingGroupsComponent,
    ZoomAvailablesFilterPipe,
    JsonPipe,
    CommonModule,
    SlicePipe

  ],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ])
    ])
  ],
})
export class ZoomParametersComponent implements OnInit, AfterViewInit {

  @Input()
  zoomParametersViewModel: ZoomParametersViewModel;

  splitter: any;
  private _rowToSetFocus = -1;
  filledButtonTypeEnum = FilledButtonType;

  guidMaskSettings = {
    mask: '\\{********-****-****-****-************\\}', 
    placeholderChar: 'X', 
    lazy: false, 
    hideMaskOnBlur: true, 
    overwrite: true,
    validateMaskOnBlurFunc: (valueToValidate: string) => {
      return /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(valueToValidate);
    }
  }

  @ViewChild('parameterLabels', { static: true }) parameterLabels: ElementRef;
  @ViewChild('parameterOperators', { static: true }) parameterOperators: ElementRef;
  @ViewChild('parameterValues', { static: true }) parameterValues: ElementRef;
  

  constructor() {
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.target.dispatchEvent(new Event('blur'));  // (<HTMLInputElement>event.target).blur();
      this.zoomParametersViewModel.findEmitted.next();
    }
  }

  ngOnInit() {
    if (!this.zoomParametersViewModel) { throw new Error('Missing viewModel!'); }

    this._rowToSetFocus = this.zoomParametersViewModel.rowToSetFocus;
    this.zoomParametersViewModel.paramtersUpdated.subscribe(() => {
      this.setFocus();
    });

    // this.zoomNotifierService.startZoomRequested.subscribe((args: ZoomUIStarterArgs) => {
    //   this.setFocus();
    // });
  }

  isExpandableFilter(filter: ZoomFilterViewModel) {
    return filter.children?.length > 0;
  }

  toggleExpandableFilter(filter: CollapsableFilterViewModelInterface) {
    filter.toggleCollapsed();
  }

  isFilterString(filterValue: ZoomPropertyViewModelInterface<string>) {
    return filterValue instanceof StringZoomPropertyViewModel;
  }

  isFilterGuid(filterValue: ZoomPropertyViewModelInterface<string>) {
    return filterValue instanceof GuidZoomPropertyViewModel;
  }

  isFilterNumeric(filterValue: ZoomPropertyViewModelInterface<number>) {
    return filterValue instanceof NumericZoomPropertyViewModel;
  }

  isFilterExternal(filterValue: ZoomPropertyViewModelInterface<{ identity: {[key:string]: string | number | BigNumber}; description?: string; isValid?: boolean; }>) {
    return filterValue instanceof ExternalZoomPropertyViewModel;
  }

  isFilterBool(filterValue: ZoomPropertyViewModelInterface<boolean>) {
    return filterValue instanceof BoolZoomPropertyViewModel;
  }

  isFilterDate(filterValue: ZoomPropertyViewModelInterface<Date>) {
    return filterValue instanceof DateTimeZoomPropertyViewModel;
  }

  isFilterDateTimeOffset(filterValue: ZoomPropertyViewModelInterface<DateTimeOffset>) {
    return filterValue instanceof DateTimeOffsetZoomPropertyViewModel;
  }

  isFilterEnum(filterValue: ZoomPropertyViewModelInterface<number>) {
    return filterValue instanceof EnumZoomPropertyViewModel;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setFocus();
    }, 700);
    this.initSplitter();
  }

  findExternal(event, filter: ZoomFilterViewModel) {
    if (filter.externalDomainModelNameToZoom !== '') {
      this.parameterFocus(event, filter);
      filter.operator.currentValue = FilterOperators.Equals;
      if (this.zoomParametersViewModel.canFindExternal()) {
        this.zoomParametersViewModel.findExternal();
      }
    }
  }

  setFocus() {
    this._rowToSetFocus = this.zoomParametersViewModel.rowToSetFocus;
    if (this._rowToSetFocus >= 0 && this.zoomParametersViewModel.filters.length > this._rowToSetFocus) {
      // Focus cell
      // setTimeout(() => {
      //   this.filters.toArray()[this._rowToSetFocus].nativeElement.firstElementChild.firstElementChild.focus();
      // });
      // if ((this.zoomParametersViewModel.filters[this._rowToSetFocus].filterValue as ZoomPropertyViewModel<any>).enabled) {
      //   this.zoomParametersViewModel.filters[this._rowToSetFocus].filterValue.setFocus();
      // } else {
        if (this.zoomParametersViewModel.findCommand.isVisible$.value) {
          this.zoomParametersViewModel.findCommandFocus.next();
        }
      // }
    }
  }

  parameterFocus(ev: Event, filter: ZoomFilterViewModel) {
    this.zoomParametersViewModel.selectedZoomFilterViewModel = filter;
  }

  keyDown(ev: any) {
    if (ev.key === 'F6' && ev.ctrlKey) {
      if (this.zoomParametersViewModel.isActiveF6) {
        this.zoomParametersViewModel.findExternal();
      }
    }
  }

  initSplitter() {
    if (this.splitter == null) {
      this.splitter = split([this.parameterLabels.nativeElement, this.parameterOperators.nativeElement, this.parameterValues.nativeElement], {
        sizes: [40, 20, 40],
        direction: 'horizontal',
        snapOffset: 0,
        gutterSize: 3,
        elementStyle: (dimension, size, gutterSize) => {
          return {
            'flex-basis': 'calc(' + size + '% - ' + gutterSize + 'px)',
          };
        },
        gutterStyle: (dimension, gutterSize) => {
          return {
            'flex-basis': gutterSize + 'px'
          };
        }
      });
    }
  }
}
