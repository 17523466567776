import { IdentityTypeDecorator } from "../../decorators/identity-type.decorator";
import { Expose } from "@nts/std/serialization";
import { ModelTypeNameDecorator } from "../decorators/model-type-name.decorator";
import { StringDecorator } from "../decorators/string.decorator";
import { User } from "./user";
import { UserIdentity } from "./user.identity";
import { UserProfileInterface } from "@nts/std/interfaces";

@IdentityTypeDecorator(UserIdentity)
@ModelTypeNameDecorator('UserProfile')
export class UserProfile extends User implements UserProfileInterface {

    private _base64Image: string;

    /**
     * Immagine in base64 dell'utente
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'base64Image' })
    get base64Image(): string {
        return this.getValue<string>(() => this._base64Image, 'base64Image');
    }
    set base64Image(value: string) {
        this.setValue<string>(() => { this._base64Image = value; }, value, 'base64Image');
    }

}
