import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';
import { DomainModelCollection } from './domain-model-collection';


export abstract class DomainModelSerialCollection<TItem extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends DomainModelCollection<TItem, TIdentity> {

    /**
     * Nome della property del modello da usare come Serial
     */
    abstract serialPropertyName: string;

    /**
     * Aggiunge un item alla fine della collection.
     * Oltre ad inserire l'item si preoccupa di fare la fixUpIdentityValues e gestisce il serial.
     *
     * @param item     item da inserire
     * @returns        indice dell'item inserito
     */
    override add(item: TItem): number {
        if (!this.serialPropertyName) {
            throw new Error(
                'serialPropertyName not defined. You must set serialPropertyName property  in ' + this.constructor.name);
        }
        const currentValue = item.getPropertyValue<number>(this.serialPropertyName);
        if (currentValue == null || currentValue === 0) {
            const currentMax = this.getMaxCollectionSerialValueOfEntityCollection();
            item.setPropertyValue(this.serialPropertyName, currentMax + 1);
        }
        return super.add(item);
    }

    /**
     * Calcola il valore massimo all'interno della DomainModel Collection tenendo in considerazione anche gli elementi rimossi.
     *
     * @returns valore massimo
     */
    private getMaxCollectionSerialValueOfEntityCollection(): number {
        let ret: number;
        const temp1 = this.getMaxSerial(this.collectionItems, this.serialPropertyName);
        const temp2 = this.getMaxSerial(this.removedItems, this.serialPropertyName);
        if (temp1 > temp2) {
            ret = temp1;
        } else {
            ret = temp2;
        }
        return ret;
    }

    /**
     * Calcola il valore massimo della property serial nella collection
     *
     * @param collection             collection
     * @param serialPropertyName     nome property serial
     * @returns                      valore massimo
     */
    private getMaxSerial(collection: ModelInterface<TIdentity>[], serialPropertyName: string): number {
        let ret = 0;
        collection.forEach((model) => {
            const temp = this.getSerialValue(model, serialPropertyName);
            if (temp > ret) {
                ret = temp;
            }
        });

        return ret;
    }

    /**
     * Recupera il valore della property che rappresenta il serial
     *
     * @param model                  modello
     * @param serialPropertyName     nome property serial
     * @returns                      valore property serial
     */
    private getSerialValue(model: ModelInterface<TIdentity>, serialPropertyName: string): number {
        let ret = -1;
        const temp = model.getPropertyValue<number>(serialPropertyName);
        if (temp != null) {
            ret = temp;
        }
        return ret;
    }
}
