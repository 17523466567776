import { InjectionToken, PipeTransform } from "@angular/core";
// import { RoutingServiceInterface } from "../routing/routing-service.interface";
import { CurrentRouteServiceInterface } from "../routing/current-route-service.interface";
import { AuthServiceInterface } from "../auth/auth-service.interface";
// import { FrameworkServiceApiClientInterface } from "../api-clients/framework-service-api-client.interface";
// import { TokenServiceInterface } from "../auth/token-service.interface";
// import { ZoomApiClientInterface } from "../api-clients/zoom/zoom-api-client.interface";
// import { ResponseCacheServiceInterface } from "../responses/response-cache-service.interface";
// import { WebApiServiceAgentInterface } from "../api-clients/web-api-service-agent.interface";

// export const ROUTING_SERVICE = new InjectionToken<RoutingServiceInterface>('routing.service');
export const CURRENT_ROUTE_SERVICE = new InjectionToken<CurrentRouteServiceInterface>('current.route.service');
export const AUTH_SERVICE = new InjectionToken<AuthServiceInterface>('auth.service');
// export const AUTH_MOCK_MODE = new InjectionToken<boolean>('bypass.auth.singleton.check');
// export const FRAMEWORK_SERVICE_API_CLIENT = new InjectionToken<FrameworkServiceApiClientInterface>('framework.service.api.client');
// export const TOKEN_SERVICE = new InjectionToken<TokenServiceInterface>('token.service');
// export const TOAST_MESSAGE_SERVICE = new InjectionToken<FrameworkServiceApiClientInterface>('toast.message.service');
// export const ZOOM_API_CLIENT = new InjectionToken<ZoomApiClientInterface>('zoom.api.client');
// export const ZOOM_AVAILABLE_FILTER_PIPE = new InjectionToken<PipeTransform>('zoom.available.fitler.pipe');
// export const RESPONSE_CACHE_SERVICE = new InjectionToken<ResponseCacheServiceInterface>('response.cache.service');
// export const WEB_API_SERVICE_AGENT = new InjectionToken<WebApiServiceAgentInterface>('web.api.service.agent');
