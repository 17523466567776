import { Component, Input } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MetaDataUtils } from '../../../../src/lib/meta-data/meta-data-utils';
import { BasePropertyViewModel } from '../../../../src/lib/view-models/base-property-view-model';
import { ExternalViewModelInterface } from '../../../../src/lib/view-models/external-view-model.interface';
import { ColumnCellRendererParams } from './column_cell_renderer_params.interface';
import { ExternalColumnCellRendererParams } from './ext_column_cell_renderer_params.interface';

@Component({ template: '' })
export abstract class BaseExternalCellRendererComponent implements AgRendererComponent {

    @Input()
    params: ICellRendererParams;

    // Supporto dei componenti usati anche inclusi in altri componenti di template vedi dynamic
    ngOnInit(): void {
        if (this.params) {
            this.agInit(this.params);
        }
    }

    private _propertyViewModel: BasePropertyViewModel;
    private _externalPropertyViewModel: ExternalViewModelInterface;

    get externalPropertyViewModel(): ExternalViewModelInterface {
        if (!this._externalPropertyViewModel) {
            //se non c'è il path probabilmente è un ref, perciò uso il fieldname per estrare il view model
            const params = (this.params as ExternalColumnCellRendererParams);
            if (params.columnInfo.path) {
                const composed = params.columnInfo.path.split('.');
                let ext = this.params.data;
                for (const shortPath of composed) {
                    ext = ext[shortPath];
                }
                return ext;
                // return this.params.data[this.params.columnInfo.path]
            }
            return this.params.data[params.columnInfo.fieldName];
        }
        return this._externalPropertyViewModel;
    }

    get propertyViewModel(): BasePropertyViewModel {
        if (!this._propertyViewModel) {
            const params = (this.params as ColumnCellRendererParams);
            if (params.columnInfo.propertyTypeName.indexOf('ExternalCodeRef') > -1) {
                this._propertyViewModel = null;
                this._externalPropertyViewModel = this.externalPropertyViewModel;
            } else
                if (params.columnInfo.propertyTypeName.indexOf('ExternalCode') > -1) {

                    // Campo Code
                    const code = params.columnInfo.fieldName.split('.').reduce(
                        (o, i) => {
                            if (i === 'value') {
                                return o;
                            }
                            return o[i];
                        }, this.params.data) as BasePropertyViewModel;
                    const associationMetaData = this.externalPropertyViewModel.externalMetaData.associationProperties.find((ass) =>
                        MetaDataUtils.toCamelCase(ass.dependentPropertyName) === code.propertyName
                    );
                    const backingFieldPropertyName = MetaDataUtils.toCamelCase(associationMetaData.principalPropertyName);
                    const backingField = this.params.data[backingFieldPropertyName];
                    if (!backingField) {
                        throw new Error(`ATTENZIONE: il backing field ${backingFieldPropertyName} non è stato trovato nella classe ${this.params.data.constructor.name}, questo comporta dei malfunzionamenti nella griglia!`)
                    }
                    this._propertyViewModel = backingField;
                    this._externalPropertyViewModel = code.parent as ExternalViewModelInterface;
                } else {
                    // Campo decode
                    this._propertyViewModel = params.columnInfo.fieldName.split('.').reduce(
                        (o, i) => {
                            if (i === 'value') {
                                return o;
                            }
                            return o[i];
                        }, this.params.data);
                }
        }
        return this._propertyViewModel;
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    getValue() {
        return this.params.value;
    }

    refresh(params: any): boolean {
        return true;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

}
