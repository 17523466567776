import { empty, from, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelper, LogService } from '@nts/std/utility';
import { AUTH_SERVICE, AuthServiceInterface, CURRENT_ROUTE_SERVICE, CurrentRouteServiceInterface, DecodedTokenInterface, EnterpriseDataDtoInterface } from '@nts/std/interfaces';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(
    @Inject(AUTH_SERVICE) public authService: AuthServiceInterface,
    @Inject(CURRENT_ROUTE_SERVICE) public currentRouteService: CurrentRouteServiceInterface,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.authService.getAccessToken()).pipe(switchMap((accessToken: string) => {

      return from(this.authService.getTenantId(accessToken)).pipe(switchMap((tenantId: number) => {
        
        return from(this.authService.getEnterpriseData(tenantId)).pipe(switchMap((enterpriseData: EnterpriseDataDtoInterface) => {
          
          if (accessToken != null && this.currentRouteService.isAuthenticatedRoute) {
            // Get the auth token from the service.
            const authToken = 'Bearer ' + accessToken
      
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            // Se l'autenticazione è già presente nella richiesta non la sovrascrive
            req = req.clone({
              headers: req.headers.has(this.authService.AUTHORIZAZION_HEADER_NAME) ? req.headers : req.headers
                .set(this.authService.AUTHORIZAZION_HEADER_NAME, authToken)
            });
          }
  
          if (enterpriseData != null && this.currentRouteService.isAuthenticatedRoute) {
            
            // se la company id non è stata passata la rimuovo prima di passarla al server o non è stata trovata
            if (enterpriseData.companyId === 0 || enterpriseData.companyId === -1) {
              delete enterpriseData.companyId;
            }

            if (enterpriseData.enterpriseId > 0) {
              req = req.clone({
                headers: req.headers.has(this.authService.ENTERPRISE_DATA_HEADER_NAME) ? req.headers : req.headers
                  .set(this.authService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseData))
              });
            }            
          }
  
          return next.handle(req)
            .pipe(
              map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse && ~~(event.status / 100) > 3) {
                //   console.info('HttpResponse::event =', event, ';');
                // } else console.info('event =', event, ';');
                if (event instanceof HttpResponse && event.body && typeof(event.body !== 'string')) {
                  this.addRequestInfo(req, event);
                } else if (event instanceof HttpErrorResponse) {
                  this.addRequestInfo(req, event);
                }
                
                return event;
              }),
              catchError(
                (error) => {
                  if (this.isAuthenticationError(error)) {
                    this.authService.notifySessionExpired();
                    // STOP Observable chain
                    return empty();
                  } else if (error instanceof HttpErrorResponse) {
                    this.addRequestInfo(req, error);
                  }
                  throw error;
                }
              )
            );
        }))
      }))
    }))
  }

  private isAuthenticationError(error: any): boolean {
    return (error instanceof HttpErrorResponse && error.status === 401);
  }

  private processHeaders(headers: HttpHeaders): [{[key:string]: string}[], DecodedTokenInterface] {
    let tokenInfo = null;
    const processedHeaders = headers.keys().map(
      (key: string) => {
        if (key?.toLowerCase() === 'authorization') {
          const [keyword,token] = headers.get(key).split('Bearer ');
          if (keyword === '' && token?.length > 0) {
            try {
              tokenInfo = JwtHelper.decodeToken(token)
              return{[key] : `Bearer ****`}
            } catch(err) {
              LogService.warn('Unable to decode token in interceptor!')
            }
          }
          return{[key] : headers.get(key)}
        } else {
          let headerValue = headers.get(key);
          try {
            headerValue = JSON.parse(headerValue);
            return{[key] : headerValue}
          } catch (err) {
            return{[key] : headers.get(key)};
          }
        }
      }
    ).filter((v)=>v)

    return [processedHeaders, tokenInfo]
  }

  addRequestInfo(req: HttpRequest<any>, response: HttpResponseBase) {
    const [requestHeaders, tokenInfo] = this.processHeaders(req.headers);
    response['requestInfo'] = {
      headers: requestHeaders,
      url: req.urlWithParams,
      method: req.method
    }
    if (tokenInfo) {
      response['requestInfo']['tokenInfo'] = tokenInfo;
    }

    if (req.method.toLowerCase() === 'post') {
      let payload = req.body
      try {
        response['requestInfo']['payload'] = JSON.parse(payload);
      } catch(err) {
        response['requestInfo']['payload'] = payload;
      }                    
    }
  }

}
