import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import moment from 'moment';
import { ZoomColumnInfo } from '../../../view-models/zoom/zoom-column-info';
import { ZoomResultsColumnCellRendererParams } from './zoom-results-column-cell-renderer-params.interface';


// create your cellEditor as a Angular component
@Component({
    template: `
        <div *ngIf="this.params?.data; else loading">
            {{ value }}
        </div>
        <ng-template #loading><div class="lds-ring"><div></div><div></div><div></div><div></div></div></ng-template>
    `,
    standalone: true,
    selector: 'nts-zoom-result-datetime-cell-renderer',
    imports: [
        NgIf
    ]
})
export class ZoomResultsDateTimeCellRendererComponent implements AgRendererComponent {
    params: ZoomResultsColumnCellRendererParams;
    private internalValue = null;

    refresh(params: any): boolean {
        return true;
    }
    agInit(params: ZoomResultsColumnCellRendererParams): void {
        this.params = params;
    }

    get value() {
        if (this.internalValue == null) {
            const currentValue = this.params.data[Number.parseInt(this.params.colDef.field)];
            if (currentValue != null) {
                const columnInfo: ZoomColumnInfo = this.params.columnInfo;
                this.internalValue = columnInfo.propertyName === 'creationDate' || columnInfo.propertyName === 'updateDate' ?
                    moment(currentValue).format('L LT') : moment(currentValue).format('L');
            } else this.internalValue = '';
        }
        return this.internalValue;
    }
}
