<div class="content">
    <svg-icon key="error-403"></svg-icon>
    <div class="error-title">{{errorTitle}}</div>
    <div class="error-description">{{errorDescription}}</div>
    <div class="more-info">
        <h3>{{'std_PageError_DetailTitle' | NTSTranslate}}:&nbsp;</h3>
        <p>{{errorDetailDescription}}<span *ngIf="showDetailMore && !expandDetailMore">...</span><span
                *ngIf="expandDetailMore">{{errorDetailMoreDescription}}</span></p>
        <nts-text-button *ngIf="showDetailMore" [icon]="expandDetailMore ? 'eye-close' : 'eye-alt'"
            (onClicked)="toggleShowMore()"></nts-text-button>
    </div>

    <nts-text-button [title]="'std_PageError_AdditionalInfoTitle' | NTSTranslate"
        [label]="'std_PageError_AdditionalInfoTitle' | NTSTranslate" (onClicked)="showAdditionalInfo()">
    </nts-text-button>

    <nts-filled-button [type]="3" [title]="'std_ChangeAccountRequest' | NTSTranslate"
        [label]="'std_ChangeAccountRequest' | NTSTranslate" (onClicked)="logOut()">
    </nts-filled-button>
</div>
