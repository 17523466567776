import { NTSReflection } from '@nts/std/utility';

const META_DATA_IDENTIFICATION_KEY = 'CustomCollectionViewModelKey';

export interface CustomCollectionViewModelDecoratorInterface {
    domainPropertyName: string;
}

export function CustomCollectionViewModelDecorator(collectionViewModelType: CustomCollectionViewModelDecoratorInterface) {
    return (target: any, propertyKey: any) => {
        Reflect.defineMetadata(META_DATA_IDENTIFICATION_KEY, collectionViewModelType, target, propertyKey);
    };
}

export class CustomCollectionViewModelInspector {
    static isApplied(subject: any, propertyName: string): boolean {
        return NTSReflection.hasPropertyMetadata(
            META_DATA_IDENTIFICATION_KEY, subject, propertyName);
    }

    static getValue(subject: any, propertyName: string): CustomCollectionViewModelDecoratorInterface {
        let value = NTSReflection.getPropertyMetadata(
            META_DATA_IDENTIFICATION_KEY, subject, propertyName);

        // Verifica che sia una funzione con due parametri
        if (typeof value === 'function' && value.length === 2) {
            value = value(subject, propertyName);
        }
        return value;
    }

}
