import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { ZoomPropertyViewModelInterface } from '../property-view-model/zoom-property-view-model.interface';
import { FilterOperators } from '../../../domain-models/find-options/filter';
import { ExternalZoomPropertyViewModel } from '../property-view-model/external-zoom-property-view-model';
import { BigNumber } from '@nts/std/types';

export class ExternalZoomFilterViewModel extends ZoomFilterViewModel<{identity: {[key:string]: string | number | BigNumber}, description?: string, isValid?: boolean}> {

    parentExternalFilter: ZoomFilterViewModel<any>|ExternalZoomFilterViewModel = null;

    constructor(metadata: ZoomMetaData, private modifiedSubscriber: ModifiedSubscriberInterface) {
        super(metadata);
        this.operator = new ZoomOperatorViewModelProperty(
            ZoomOperatorViewModelProperty.operatorsForExternal,
            modifiedSubscriber
        );
        this.filterValue = new ExternalZoomPropertyViewModel(metadata.external, modifiedSubscriber);
        this.filterValue2 = new ExternalZoomPropertyViewModel(metadata.external, modifiedSubscriber);
        this.init();
    }

    // protected override init() {
    //     super.init();
    // }

    protected override async onOperatorChanged(value: FilterOperators): Promise<void> {
        await super.onOperatorChanged(value);
        if (this.OperatorDoesNotRequireValue) {
            for (const filter of this.filterValues) {
                filter.setEnabled(false);
                await filter.resetValue();
            }
        } else {
            for (const filter of this.filterValues) {
                filter.setEnabled(true);
            }
        }
    }

    

    getEmptyFilter(): ExternalZoomPropertyViewModel {
        return new ExternalZoomPropertyViewModel(this.metaData.external, this.modifiedSubscriber);
    }

    get filterValues(): Array<ZoomPropertyViewModelInterface<{identity: {[key:string]: string | number | BigNumber}, description?: string, isValid?: boolean}>> {
        return this.internalZoomPropertyViewModelValues;
    }

    private internalZoomPropertyViewModelValues: Array<ZoomPropertyViewModelInterface<{identity: {[key:string]: string | number | BigNumber}, description?: string, isValid?: boolean}>> = [];

    setZoomPropertyViewModelValues(pvmToSet: Array<ZoomPropertyViewModelInterface<{identity: {[key:string]: string | number | BigNumber}, description?: string, isValid?: boolean}>>) {
        if (this.internalZoomPropertyViewModelValues != pvmToSet) {
            this.internalZoomPropertyViewModelValues = pvmToSet;
            this.modifiedSubscriber.notifyModified();
        }   
    }
}
