<div class="content">
    <svg-icon key="error-500"></svg-icon>
    <div class="error-title">{{errorTitle}}</div>
    <div class="error-description">{{errorDescription}}</div>
    <div class="more-info">
        <h3 *ngIf="errors && errors[0]?.uuid">{{'std_PageError_Uiid' | NTSTranslate}}:&nbsp;</h3><p *ngIf="errors[0]?.uuid">{{errors[0]?.uuid}}<br></p>
        <h3>{{'std_PageError_DetailTitle' | NTSTranslate}}:&nbsp;</h3>
        <p>{{errorDetailDescription}}<span *ngIf="showDetailMore && !expandDetailMore">...</span><span
                *ngIf="expandDetailMore">{{errorDetailMoreDescription}}</span></p>
    </div>

    <nts-text-button [title]="'std_PageError_AdditionalInfoTitle' | NTSTranslate"
        [label]="'std_PageError_AdditionalInfoTitle' | NTSTranslate" (onClicked)="showAdditionalInfo()">
    </nts-text-button>

    <nts-filled-button [type]="1" [title]="'std_GoBack_Dashboard' | NTSTranslate"
        [label]="'std_GoBack_Dashboard' | NTSTranslate" (onClicked)="redirectToDashBoard()">
    </nts-filled-button>
</div>
