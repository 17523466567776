import { HttpClient } from '@angular/common/http';
import moment from 'moment';
import { PropertyViewModel } from '../property-view-model';
import { TimeZoneDataCollectionProvider } from '@nts/std/timezone';;
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { TimeZoneData } from '@nts/std/timezone';
import { BigNumber } from '@nts/std/types';
import { DateTimeOffset } from '@nts/std/types';

export abstract class BaseDateTimeOffsetPropertyViewModel extends PropertyViewModel<DateTimeOffset>  {

  private readonly MASKDATEANDTIME: string = 'g'; // General date/time pattern(short time)
  private readonly MASKDATE_DEFAULT: string = 'd'; // d	Short date pattern

  metadataMaxValue: Date;
  metadataMinValue: Date;
  showTime = false;
  showTimeZone = false;
  advancingCaret = true;
  timeZones: Array<TimeZoneData>;
  currentOffset: number;

  get isTimeZoneEnabled(): boolean {
    return this._isTimeZoneEnabled && this.isEnabled;
  }
  set isTimeZoneEnabled(value: boolean) {
    if (this._isTimeZoneEnabled !== value) {
      this.isTimeZoneEnabled = value;
    }
  }

  get editMask(): string {
    if (this._editMask) {
      return this._editMask;
    }

    if (this.showTime) {
      return this.MASKDATEANDTIME;
    } else {
      return this.MASKDATE_DEFAULT;
    }
  }
  set editMask(value: string) {
    this._editMask = value;
  }

  private _editMask: string;
  private _isTimeZoneEnabled = true;

  constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
    super(initInfo, valueIsNullable);

    this.timeZones = new Array<TimeZoneData>();

    this.currentOffset = - (new Date()).getTimezoneOffset();
  }

  override setValue(value: DateTimeOffset) {

    // LogService.debug('corrente: ', DateTimeOffset.formatToString(value));
    // LogService.debug('old: ', DateTimeOffset.formatToString(this.value));
    // LogService.debug('toUTCString è:', value.dateTime.toUTCString());
    // LogService.debug('offset è:', value.offset);
    // LogService.debug('old offset è:', this.value.offset);
    if (DateTimeOffset.formatToString(value) !== DateTimeOffset.formatToString(this.value)) {
      super.setValue(value);
    }

  }

  setDefaultValueFromLayoutMetaData(value: string | number | BigNumber |boolean) {
    // TODO
  }

  findTimeZone(timeSpan: number) {
    return this.timeZones.find(t =>
      t.timeSpan === timeSpan);
  }

  async populateTimeZonesAsync(http: HttpClient) {
    const timeZones = await TimeZoneDataCollectionProvider.getTimeZoneDataCollection(http);

    timeZones.forEach(tz => {
      this.timeZones.push(new TimeZoneData(tz.key, tz.timeZoneInfoId, tz.timeSpan, tz.description));
    });
  }

  protected getFormattedValue(
    value?: DateTimeOffset,
    showTime?: boolean,
    showTimeZone?: boolean
  ): string {

    // Attenzione value.dateTime arriva con l'orario indicato dal server ma con il timezone locale

    value = value ?? this.value;
    showTime = showTime ?? this.showTime;
    showTimeZone = showTimeZone ?? this.showTimeZone;

    let formattedValue: string;

    if (value?.dateTime != null) {
      if (showTimeZone) {

        // Se visualizzo il time zone stampo direttamente a video quello che mi da il server
        formattedValue = moment(value.dateTime).clone().utcOffset(value.offset).format('L');
        if (showTime) {
          formattedValue += ' ' + moment(value.dateTime).clone().utcOffset(value.offset).format('LT');
        }
        formattedValue += ' ' + moment().utcOffset(value.offset as number).format('Z');
        return formattedValue;

      } else {

        // Se non voglio il timezone devo calcolare l'ora locale
        const momentDate = moment(value.dateTime).clone();

        formattedValue = momentDate.local().format('L');
        if (showTime) {
          formattedValue += ' ' + moment(momentDate).local().format('LT');
        }
        return formattedValue;
      }
    } else {
      return '';
    }
  }
}

export class DateTimeOffsetPropertyViewModel extends BaseDateTimeOffsetPropertyViewModel {

  constructor(initInfo: PropertyViewModelInitializationInfo) {
    super(initInfo, true);
  }

  override setValue(value: DateTimeOffset | null) {
    if (value?.dateTime != null) {
      // Se uno seleziona solo la data, imposto timezone corrente di default
      value.timeZoneData = this.findTimeZone(value.offset != null ? value.offset : this.getOffset());
    } else {
      value = null;
    }

    super.setValue(value);
  }

  private getOffset() {
    return this.currentOffset;
  }
}

export class NDateTimeOffsetPropertyViewModel extends BaseDateTimeOffsetPropertyViewModel {

  constructor(initInfo: PropertyViewModelInitializationInfo) {
    super(initInfo, true);
    this.isNullable = true;
  }

  override setValue(value: DateTimeOffset | null) {
    if (value?.dateTime != null) {
      if (value.timeZoneData == null) {
        // Se uno seleziona solo la data, imposto timezone corrente di default
        value.timeZoneData = this.findTimeZone(value.offset != null ? value.offset : this.getOffset());
      }
    } else {
      value = null;
    }

    super.setValue(value);
  }

  private getOffset() {
    return this.currentOffset;
  }
}
