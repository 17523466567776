import { ColumnInfoCollection } from './column-info-collection';
import { ColumnInfo } from './column-info';
import { ExtColumnInfo } from './ext-column-info';
import { ExternalColumnMapInfo } from './external_column_map_info';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { PropertyMetaData } from '../meta-data/property-meta-data';
import { DateTimeMetaData, DomainModelMetaData, ExternalMetaData, InternalCollectionMetaData, MetaDataUtils } from '../meta-data';
import { ColumnsProviderInterface } from './columns_provider.interface';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';
import { LogService } from '@nts/std/utility';
import { ExternalFieldInputMode } from '../layout-meta-data/external-field-meta-data';
import cloneDeep from 'lodash-es/cloneDeep';

export class ColumnsUtils {

    static getGridColumns(
        columnsProvider: ColumnsProviderInterface,
        entityTypeName: string,

        // accetta la lista delle property da rendere visibili.
        // Le property di collegamento con il parent saranno sempre invisibili. (non vengono inserite nella lista delle colonne disponibili)
        // Le property serial saranno sempre invisibili. <- TODO
        // Default tutto invisibile.
        visibilityDefinition: string[] = null,

        // imposta l'abilitazione delle property passate, in alternativa se viene passato false, tyutto viene disabilitato.
        // Le property di collegamento con il parent saranno sempre disabilitate. (non vengono inserite nella lista delle colonne disponibili)
        // Le property serial saranno sempre disabilitate. <- TODO
        // Le property row number saranno sempre disabilitate. <- TODO
        // Le ulteriori property identity possono essere abilitate solo se la riga è nuova. <- TODO
        // Le decode degli external saranno sempre disabilitate.
        // Default tutto abilitato tranne le eccezioni definite precedentemente.
        editableDefinition: string[] | boolean = null,

        // imposta lo stesso ordinamento delle property passate.
        // Le property non elencate avranno tutte la stessa position superiore all'ultima dichiarata.
        // Tutte le property che saranno sempre invisibili avrannno position 0.
        // Default stesso ordinamento delle visibilityDefinition altrimenti ordine casuale in base alla lettura dei meta dati.
        orderDefinition: string[] = null,

        // imposta la dimensione delle colonne
        widthDefinition: { fullPropertyName: string, isAutoSize: boolean, width: number }[] = null,

        // imposta la definizine per gli autocomplete delle colonne
        autocompleteDefinition: {
            fullPropertyName: string,           // Percorso completo della code property con incluso il suo nome separata da '.'
            inputMode?: ExternalFieldInputMode  // Default ExternalFieldInputMode.Autocomplete
            showCodeInDescription?: boolean     // Default true se inputMode=ExternalFieldInputMode.Autocomplete, Default false se inputMode=ExternalFieldInputMode.ExternalList
            decodeProperties?: string[], // Properties che vogliamo visualizzare 
            fieldSeparator?: string; // simbolo di separazione tra piu properties
            searchProperties?: string[], // Properties su cui fare la ricerca
        }[] = null,

        // eventuali colonne custom da aggiungere
        customColumns?: ColumnInfo[]

    ): ColumnInfoCollection {

        const columnInfoCollection: ColumnInfoCollection = new ColumnInfoCollection();
        customColumns = customColumns ?? cloneDeep(columnsProvider.customGridColumns.get(entityTypeName));
        const allColumns = cloneDeep(columnsProvider.gridColumns.get(entityTypeName));

        if (customColumns?.length > 0) {
            allColumns.push(...customColumns);
        }

        columnInfoCollection.metadataDescription = allColumns?.metadataDescription;
        columnInfoCollection.metadataShortDescription = allColumns?.metadataShortDescription;

        // Mi salvo la definizione degli editable per usi futuri
        columnInfoCollection.editableDefinition = editableDefinition;

        const columnsToAdd = new Array<ColumnInfo>();

        // Gestisce la visibility
        if (allColumns?.length > 0) {


            columnsToAdd.push(
                ...allColumns
                    .filter((column) => visibilityDefinition.find((v) => {
                        //se esiste il path ed è composto e il fieldName è uguale a quello della visibility
                        //es se un ref è presente sia nel root che in un external, il fieldName è lo stesso, ma il column path no
                        const ext = v.split(".")
                        if (column.path && column.path.split(".").length > 1 && ext[ext.length - 1] === column.fieldName.replace('.value', '')) {
                            //controllo che se sto lavorando una property con path composto (es. ref o property di ref e/o external)
                            //controllo che la property di visibility sia composta anche quella 
                            if (ext && ext.length > 1) {
                                //se property del visibility è uguale al path
                                if(v === column.path){
                                    return true
                                }
                            }
                            return false;
                        }
                        //controllo che le property base siano nell visibilityDefinition
                        if (v === column.fieldName.replace('.value', '')) {
                            return true;
                        }

                        return false;
                    }) != null)
                    .map((column) => {
                        column.isVisible = true;
                        return column;
                    })
            );
        }

        // Gestisce l'abilitazione
        if (editableDefinition === false) {
            for (const column of columnsToAdd) {
                column.isEnabled = false;
            }
        } else if ((editableDefinition as string[])?.length > 0) {
            for (const column of columnsToAdd) {
                // Se è una decode non posso abilitarla
                if ((column as ExtColumnInfo)?.isDecodeDescriptionProperty) {
                    continue
                }
                column.isEnabled = (editableDefinition as string[]).indexOf(column.fieldName.replace('.value', '')) === -1
            }
        }

        // Sistemo la order definition
        if (!orderDefinition) {
            orderDefinition = visibilityDefinition;
        }

        if (!(columnsToAdd?.length == orderDefinition?.length)) {

            LogService.warn(`Nella griglia basata sul modello ${entityTypeName} non sono state trovate una o più colonne nella lista delle colonne disponibili:`)
            // Rimuovo le colonne non presenti in columnsToAdd da orderDefinition
            orderDefinition = orderDefinition.filter(
                (d) => {
                    const res = columnsToAdd.filter(c => c.fieldName.replace('.value', '') == d || c.path == d).length != 0;
                    if (!res) {
                        LogService.warn(`La colonna ${d} non è stata trovata tra le colonne disponibili!`)
                    }
                    return res;
                })

            // Aggiungo in coda le colonne non presenti in orderDefinition
            columnsToAdd.map((column) => column.fieldName.replace('.value', '')).forEach((column) => {
                if (orderDefinition.indexOf(column) === -1) {
                    orderDefinition.push(column);
                }
            })

        }
        ColumnsUtils.sortColumnByArray(columnsToAdd, orderDefinition);

        // Gestisco la dimensione delle colonne
        if (widthDefinition?.length > 0) {
            for (const column of columnsToAdd) {
                const foundWidthDefinition = widthDefinition.find((wd) => wd.fullPropertyName === column.fieldName.replace('.value', ''));
                if (foundWidthDefinition) {
                    column.width = foundWidthDefinition.width;
                    column.isAutoSize = foundWidthDefinition.isAutoSize;
                }
            }
        }

        // cambaire in external definition
        // Gestisco la definizione degli autocomplete delle colonne
        if (autocompleteDefinition?.length > 0) {
            for (const column of columnsToAdd) {

                if (column.isExternal === true) {

                    // Di default imposto il campo con inputmode autocomplete se è un ExternalCodeRef
                    (column as ExtColumnInfo).inputMode = (column.propertyTypeName === 'ExternalCodeRef') ? ExternalFieldInputMode.Autocomplete : ExternalFieldInputMode.Simple;

                    // Se è un ExternalCodeRef
                    if (column.propertyTypeName === 'ExternalCodeRef') {

                        // Di default imposto la visibility del codice a true visto che l'input mode default è un autocomplete
                        (column as ExtColumnInfo).showCodeInDescription = null;

                        // Ricerco nelle definizioni passate se c'è ne una che ha lo stesso full property name
                        const foundAutocompleteDefinition = autocompleteDefinition.find((wd) => wd.fullPropertyName === column.fieldName.replace('.value', ''));

                        // Se la trovo gli associo quello che è stato definito o i suoi defaults
                        if (foundAutocompleteDefinition) {

                            // Il parametro inputMode è facoltativo e se non viene passato il default è ExternalFieldInputMode.Autocomplete
                            (column as ExtColumnInfo).inputMode = foundAutocompleteDefinition.inputMode ?? ExternalFieldInputMode.Autocomplete;

                            (column as ExtColumnInfo).decodeProperties = foundAutocompleteDefinition.decodeProperties;
                            (column as ExtColumnInfo).fieldSeparator = foundAutocompleteDefinition.fieldSeparator;
                            (column as ExtColumnInfo).searchProperties = foundAutocompleteDefinition.searchProperties;

                            // Imposto la showCodeInDescription prendendola dalla sua definizione o dai defaults
                            (column as ExtColumnInfo).showCodeInDescription = foundAutocompleteDefinition.showCodeInDescription
                        }
                    }
                }
            }
        }

        columnInfoCollection.push(...columnsToAdd);

        return columnInfoCollection;
    }

    static getExcludedSystemProperties() {
        return ['CurrentState', 'OCC'];
    }

    /**
     * Restituisce la lista delle property da escludere per le griglie passando il metadato internal collection
     * TODO sarebbero da escludere anche le property serial
     *
     * @param internalCollection
     * @returns
     */
    static getGridExcludedPropertyListForInternalCollection(internalCollection: InternalCollectionMetaData): string[] {
        let dependentPropertyNameList = [];

        // Escludo le property di collegamento
        if (internalCollection.associationProperties?.length > 0) {
            dependentPropertyNameList = internalCollection.associationProperties.map((a) => a.dependentPropertyName);
        } else {
            dependentPropertyNameList = internalCollection.dependentMetaData.identityNames.filter((i) => internalCollection.principalMetaData.identityNames.indexOf(i) > -1)
        }

        const childDependentPropertyNameList = [];

        // Escludo le property di collegamento con figli degli external
        for (const external of internalCollection.dependentMetaData.externals) {

            if (external.associationProperties?.length > 0) {
                childDependentPropertyNameList.push(...external.associationProperties.map((a) => a.dependentPropertyName));
            } else {
                childDependentPropertyNameList.push(...internalCollection.dependentMetaData.identityNames.filter((i) => external.dependentAggregateMetaData.rootMetaData.identityNames.indexOf(i) > -1));
            }
        }

        // Escludo le property di collegamento con figli delle internal
        for (const internal of internalCollection.dependentMetaData.internalRelations) {

            if (internal.associationProperties?.length > 0) {
                childDependentPropertyNameList.push(...internal.associationProperties.map((a) => a.dependentPropertyName));
            } else {
                childDependentPropertyNameList.push(...internalCollection.dependentMetaData.identityNames.filter((i) => internal.dependentMetaData.identityNames.indexOf(i) > -1));
            }
        }

        return [...childDependentPropertyNameList, ...dependentPropertyNameList, ...this.getExcludedSystemProperties()];
    }

    /**
     * TODO
     * @param internalRelation
     * @returns
     */
    static getGridExcludedPropertyListForInternalRelation(internalRelation: InternalRelationMetaData): string[] {
        return [...this.getExcludedSystemProperties()];
    }

    /**
     * TODO
     * @param externalRelation
     * @returns
     */
    static getGridExcludedPropertyListForExternalRelation(externalRelation: ExternalMetaData): string[] {
        return [...this.getExcludedSystemProperties()];
    }

    static getGridColumnsFromDomainModelMetaData(
        domainModelMetaData: DomainModelMetaData,
        aggregateMetaData: AggregateMetaData,
        pathName: string = '',
        excludedPropertyList: string[] = [],
        baseLabelPath: string = '',
        positionOffset = 0
    ): ColumnInfoCollection {

        const domainModelTypeName = domainModelMetaData.name;
        const metadataUnion = MetaDataUtils.getPropertiesForBaseTypes(domainModelTypeName, aggregateMetaData, excludedPropertyList);
        const columnInfos = new ColumnInfoCollection();

        // vengono create tante colonne quante sono le prop sulla entity e non sul viewmodel
        metadataUnion.forEach((value: PropertyMetaData, key: string) => {
            columnInfos.push(ColumnsUtils.createInfraColumnInfo(
                aggregateMetaData,
                value,
                domainModelTypeName,
                pathName,
                baseLabelPath,
                positionOffset
            ));
        });

        return columnInfos;
    }

    static getGridColumnsFromExternal(
        external: ExternalMetaData,
        aggregateMetaData: AggregateMetaData,
        pathName: string = '',
        excludedPropertyList: string[] = [],
        baseLabelPath: string = '',
        layoutCustom: boolean = false
    ): ColumnInfoCollection {

        const domainModelTypeName = external.dependentAggregateMetaData.rootMetaData.name;
        const metadataUnion = MetaDataUtils.getPropertiesForBaseTypes(domainModelTypeName, aggregateMetaData, excludedPropertyList);
        const columnInfos = new ColumnInfoCollection();

        // vengono create tante colonne quante sono le prop sulla entity e non sul viewmodel
        metadataUnion.forEach((value: PropertyMetaData, key: string) => {
            columnInfos.push(ColumnsUtils.createInfraExtColumnInfo(
                aggregateMetaData,
                value,
                external,
                domainModelTypeName,
                pathName,
                baseLabelPath,
                layoutCustom
            ));
        });

        columnInfos.push(ColumnsUtils.createRefColumnInfo(
            aggregateMetaData,
            external,
            pathName,
        ));

        return columnInfos;
    }

    /**
     * TODO da verificare se si riesce a rimuovere
     * Al momento è utilizzata nella master view modell e snap shot list view model
     *
     * @param domainModelTypeName
     * @param aggregateMetaData
     * @returns
     */
    static getGridColumnsForBaseTypes(domainModelTypeName: string, aggregateMetaData: AggregateMetaData): ColumnInfoCollection {

        // PropertyViewModel "Standard"
        const metadataUnion = MetaDataUtils.getPropertiesForBaseTypes(domainModelTypeName, aggregateMetaData);

        const columnInfos = new ColumnInfoCollection();

        // vengono create tante colonne quante sono le prop sulla entity e non sul viewmodel
        metadataUnion.forEach((value: PropertyMetaData, key: string) => {
            columnInfos.push(ColumnsUtils.createInfraColumnInfo(
                aggregateMetaData,
                value,
                domainModelTypeName
            ));
        });

        // TODO
        //     //PropertyViewModel "Custom"
        //     var infraColumnInfosCustom = entityVMProperties.Where(
        //             x => x.PropertyType.GetInterface(typeof(IPropertyViewModel).Name) != null
        //                   && x.GetCustomAttribute(typeof(CustomPropertyViewModelAttribute)) == null)
        //         //filtrate solo le proprietà IPropertyViewModel
        //         .Select(prop =>
        //         {
        //             EntityInspector ei = new EntityInspector();
        //             var propertyMetaData = ei.GetPropertyMetaData(prop);
        //             return CreateInfraColumnInfo(propertyMetaData, prop, entityType);
        //         });


        //     var infraCommandsColumnInfos = entityVMProperties.Where(
        //             x =>
        //                 x.PropertyType == typeof(IUICommand) ||
        //                 x.PropertyType.GetInterface(typeof(IUICommand).Name) != null)
        //         .Select(prop =>
        //         {
        //             EntityInspector ei = new EntityInspector();
        //             var res = new CommandInfraColumnInfo(prop.Name)
        //             {
        //                 PropertyType = prop.PropertyType,
        //                 Header = ei.GetPropDisplayName(prop),
        //                 Position = ei.GetPosition(prop)
        //             };

        //             return res;
        //         });

        //     return new ColumnInfoCollection(infraColumnInfos.Concat(infraDecodeColumnInfos)
        //         .Concat(infraExternalListColumnInfos)
        //         .Concat(infraCommandsColumnInfos)
        //         .OrderByDescending(y => y.Position.HasValue)
        //         .ThenBy(c => c.Position));

        return columnInfos;
    }

    static createInfraExtColumnInfo(
        aggregateMetaData: AggregateMetaData,
        propertyMetaData: PropertyMetaData,
        externalMetaData: ExternalMetaData,
        domainModelTypeName: string,
        pathName: string,
        baseLabelPath: string = '',
        layoutCustom: boolean = false
    ): ExtColumnInfo {

        const baseProperties = MetaDataUtils.getPropertiesForBaseTypes(aggregateMetaData.rootMetaData.name, externalMetaData.dependentAggregateMetaData);
        const loweredName = MetaDataUtils.toCamelCase(propertyMetaData.name);
        const column = new ExtColumnInfo(loweredName, pathName);
        const isCode = externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.indexOf(propertyMetaData.name) > -1;


        if (!isCode) {
            column.isEnabled = false;
        }
        column.propertyTypeName = (isCode ? 'ExternalCode-' : 'ExternalDecode-') + baseProperties.get(loweredName).getType();
        column.securityAccess = externalMetaData.userMetaData?.securityAccess ?? null;
        column.isDecodeDescriptionProperty = !isCode;
        column.header = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(propertyMetaData.descriptions.displayNameKey) : propertyMetaData.descriptions.displayName;
        column.headerTooltip = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(propertyMetaData.descriptions.descriptionKey) : propertyMetaData.descriptions.description;

        // Solo se non sono in un layout custom (o non ho già inserito la stringa '->' nella colonna) calcolo la breadcrump per le property
        if (baseLabelPath?.length > 0 && (!layoutCustom || column.header.indexOf('->') === -1)) {
            column.header = baseLabelPath + '->' + column.header;
        }
        // }

        column.isVisible = false;

        return column;

    }

    static createRefColumnInfo(
        aggregateMetaData: AggregateMetaData,
        externalMetaData: ExternalMetaData,
        pathName: string,
    ): ExtColumnInfo {

        // const baseProperties = MetaDataUtils.getPropertiesForBaseTypes(externalMetaData.name, externalMetaData.dependentAggregateMetaData);
        const loweredName = MetaDataUtils.toCamelCase(externalMetaData.name);
        const column = new ExtColumnInfo(loweredName, pathName);
        // const isCode = externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.indexOf(propertyMetaData.name) > -1;
        column.fieldName = loweredName;
        // column.path = column.fieldName;
        column.isRef = true;

        column.inputMode = ExternalFieldInputMode.Autocomplete;
        column.propertyTypeName = 'ExternalCodeRef';
        column.securityAccess = externalMetaData.userMetaData?.securityAccess ?? null;
        column.isDecodeDescriptionProperty = false;
        column.header = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(externalMetaData.descriptions.displayNameKey) : externalMetaData.descriptions.displayName;
        column.headerTooltip = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(externalMetaData.descriptions.descriptionKey) : externalMetaData.descriptions.description;


        column.isVisible = false;

        return column;

    }

    static createInfraColumnInfo(
        aggregateMetaData: AggregateMetaData,
        propertyMetadata: PropertyMetaData,
        domainModelTypeName: string,
        pathName: string = '',
        baseLabelPath: string = '',
        positionOffset = 0
    ): ColumnInfo {

        const loweredName = MetaDataUtils.toCamelCase(propertyMetadata.name);

        let res: ColumnInfo;

        // TODO come controllo al meglio is EnumPropertyMetaData
        if (propertyMetadata['valuesDescriptions'] !== undefined) {
            // res = new ComboEnumInfraColumnInfo(propertyMetadata.Name,
            //     ((EnumPropertyMetaData)propertyMetadata).ValueDescriptions);
        } else {
            res = new ColumnInfo(loweredName, pathName);
        }

        res.position = propertyMetadata.position + positionOffset;
        res.isVisible = false;
        res.securityAccess = propertyMetadata.userMetaData?.securityAccess ?? null;
        res.propertyTypeName = propertyMetadata.getType();

        res.header = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(propertyMetadata.descriptions.displayNameKey) : propertyMetadata.descriptions.displayName;

        if (baseLabelPath?.length > 0) {
            res.header = baseLabelPath + '->' + res.header;
        }

        res.headerTooltip = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(propertyMetadata.descriptions.descriptionKey) : propertyMetadata.descriptions.description;
        // TODO prendere la position dal server quando disponibile
        // res.position = propertyMetadata.position;

        if (res.propertyTypeName === new DateTimeMetaData().getType()) {
            res.isDateTimeOffset = (propertyMetadata as DateTimeMetaData).isDateTimeOffset;
        }

        return res;
    }

    // Ritorna le columns che mappano l'identity di una ExternalViewModel
    static getExternalCodesColumns(
        externalMetaData: ExternalMetaData,
        aggregateMetaData: AggregateMetaData,
        codePropertiesName: { propertyName: string, inputMode: ExternalFieldInputMode }[],
        pathName: string
    ): Array<ExtColumnInfo> {

        const codes = externalMetaData.dependentAggregateMetaData.rootMetaData.propertyNames.filter(
            item => codePropertiesName.map(code => code.propertyName).indexOf(MetaDataUtils.toCamelCase(item)) >= 0);

        const externalColumns = new Array<ExtColumnInfo>();

        const baseProperties = MetaDataUtils.getPropertiesForBaseTypes(externalMetaData.dependentAggregateMetaData.rootMetaData.name, externalMetaData.dependentAggregateMetaData);

        codes.forEach((value: string) => {

            const loweredPropertyName = MetaDataUtils.toCamelCase(value);
            const column = new ExtColumnInfo(loweredPropertyName, pathName);
            // TODO impostare la position quando sarà disponibile lato server
            // column.position = internalCollectionMetaData.position;
            const currentMetaData = externalMetaData.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(loweredPropertyName);
            column.header = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(currentMetaData.descriptions.displayNameKey) : currentMetaData.descriptions.displayName;
            column.headerTooltip = aggregateMetaData.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(currentMetaData.descriptions.descriptionKey) : currentMetaData.descriptions.description;
            column.inputMode = codePropertiesName.find((c) => c.propertyName === loweredPropertyName)?.inputMode ?? ExternalFieldInputMode.Simple;
            column.propertyTypeName = 'ExternalCode-' + baseProperties.get(loweredPropertyName).getType();
            column.isDecodeDescriptionProperty = false;
            externalColumns.push(column);

        });

        return externalColumns;
    }

    static getSortedColumns(columns: Array<ColumnInfo>): Array<ColumnInfo> {

        const nullPositionColumns = columns.filter(item => item.position === null || item.position === undefined);
        const sortedColumns = columns.filter(item => item.position !== null && item.position !== undefined).sort((a, b) => a.position - b.position);
        sortedColumns.push(...nullPositionColumns);

        return sortedColumns;
    }

    // Ritorna le columns che mappano le description di una ExternalViewModel
    static getExternalDecodesColumns(externalEntityTypeName: string, externalMetaData: ExternalMetaData,
        decodePropertiesName: string[], pathName: string, useMessageResourceKey: boolean): Array<ExtColumnInfo> {

        const decodes = MetaDataUtils.getPropertiesForBaseTypes(externalEntityTypeName, externalMetaData.dependentAggregateMetaData);

        const externalListColumns = new Array<ExtColumnInfo>();

        const baseProperties = MetaDataUtils.getPropertiesForBaseTypes(externalMetaData.dependentAggregateMetaData.rootMetaData.name, externalMetaData.dependentAggregateMetaData);

        decodes.forEach((value, key) => {
            const loweredPropertyName = MetaDataUtils.toCamelCase(value.name);
            if (decodePropertiesName.indexOf(loweredPropertyName) >= 0) {
                const column = new ExtColumnInfo(loweredPropertyName, pathName);
                const currentMetaData = externalMetaData.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(loweredPropertyName);

                column.position = undefined; // TODO: keys.Select(x => x.Position).Max(),
                column.header = useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(currentMetaData.descriptions.displayNameKey) : currentMetaData.descriptions.displayName;
                column.headerTooltip = useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(currentMetaData.descriptions.descriptionKey) : currentMetaData.descriptions.description;
                column.isEnabled = false;
                column.propertyTypeName = 'ExternalDecode-' + baseProperties.get(loweredPropertyName).getType();
                column.isDecodeDescriptionProperty = true;
                externalListColumns.push(column);
            }
        });

        return externalListColumns;
    }

    // Ritorna le columns che mappano gli ExternalViewModel
    // Rendering tramite la Decode, devono essere generate 2 colonne (Codice e Descizione(readnoly))
    // A livello di metadati sono ExternalRelation
    static getExternalColumns(
        aggregateMetaData: AggregateMetaData,
        domainModelTypeName: string,
        externalColumnsMapInfo: ExternalColumnMapInfo[]
    ): Array<ColumnInfo> {

        const externalColumns = new Array<ExtColumnInfo>();

        externalColumnsMapInfo.forEach(info => {

            const externalRelationMetaData = MetaDataUtils.getExternalRelationsProperties(domainModelTypeName, [info.externalPropertyName], aggregateMetaData)[0];

            // TODO: TEMP PER SUPPORTO GRID INTERNALS
            // if (externalRelationMetaData == null) {
            //     externalRelationMetaData = MetaDataUtils.getInternalRelationsProperties(domainModelTypeName, [info.externalPropertyName], aggregateMetaData)[0];
            // }

            const loweredRelationPropName = MetaDataUtils.toCamelCase(externalRelationMetaData.principalPropertyName);

            const codes = ColumnsUtils.getExternalCodesColumns(externalRelationMetaData, aggregateMetaData,
                info.externalCodesPropertyName, loweredRelationPropName);

            const positions = codes.map(c => c.position).filter(p => p != null);

            const maxPosition = positions.length === 0 ? null : Math.max(...positions);

            const decodes = ColumnsUtils.getExternalDecodesColumns(externalRelationMetaData.dependentAggregateMetaData.rootName,
                externalRelationMetaData, info.externalDecodesPropertyName, loweredRelationPropName, aggregateMetaData.useMessageResourceKey);

            decodes.map(c => {
                c.position = maxPosition;
            });

            externalColumns.push(...codes);
            externalColumns.push(...decodes);

        });

        return externalColumns;
    }

    // static sortColumnByArrayWithMetaDataPosition(array: ExtColumnInfo[] | ColumnInfo[], order: string[], orderFromMetaData: string[]) {

    //     // Sono tutte le colonne
    //     array

    //     // Sono le property di tutte le colonne
    //     order
    // }

    // TODO riverificare
    static sortColumnByArray(array: ExtColumnInfo[] | ColumnInfo[], order: string[]) {
        if (array.length != order.length) {
            throw new Error('La lunghezza delle colonne non corrisponde a quello dell\'ordinamento');
        }

        array.sort((a, b) => {
            const aFullPropertyName = (a as ExtColumnInfo).isRef ? a.propertyName : (a as ExtColumnInfo).path ? (a as ExtColumnInfo).path + '.' + a.propertyName : a.propertyName;
            const bFullPropertyName = (b as ExtColumnInfo).isRef ? b.propertyName : (b as ExtColumnInfo).path ? (b as ExtColumnInfo).path + '.' + b.propertyName : b.propertyName;
            if (order.indexOf(aFullPropertyName) == -1 || order.indexOf(bFullPropertyName) == -1) {
                throw new Error(`La colonna ${order.indexOf(aFullPropertyName) == -1 ? aFullPropertyName : bFullPropertyName} non è presente nell'elenco dell\'ordinamento`);
            }

            if (order.indexOf(aFullPropertyName) > order.indexOf(bFullPropertyName)) {
                return 1;
            } else {
                return -1;
            }
        });
        return array;
    }
    // static sortColumnByArray(array: ExtColumnInfo[] | ColumnInfo[], order: string[]) {
    //     // if (array.length != order.length) {
    //     //     throw new Error('La lunghezza delle colonne non corrisponde a quello dell\'ordinamento');
    //     // }

    //     // Gestisce l'ordinamento
    //     if (order?.length > 0) {
    //         let unfoundPosition = 0;
    //         for (const column of array) {
    //             if (!column.isVisible) {
    //                 if (invisiblePosition === 0) {
    //                     invisiblePosition = order?.length + 20;
    //                 }
    //                 column.position = invisiblePosition++;
    //             } else {
    //                 const index = order.indexOf(column.fieldName.replace('.value', ''));
    //                 column.position = index + 1;
    //                 if (index === -1) {

    //                     if (unfoundPosition === 0) {
    //                         unfoundPosition = order?.length + 1;
    //                     }

    //                     column.position = unfoundPosition++;
    //                 }
    //             }
    //         }
    //     } else {
    //         let position = 0
    //         for (const column of array) {
    //             column.position = ++position;
    //         }
    //     }

    //     // Riordina in base alla position impostata
    //     array.sort((a, b) => (a.position > b.position) ? 1 : -1);



    //     // array.sort((a, b) => {
    //     //     const aFullPropertyName = (a as ExtColumnInfo).path ? (a as ExtColumnInfo).path + '.' + a.propertyName : a.propertyName;
    //     //     const bFullPropertyName = (b as ExtColumnInfo).path ? (b as ExtColumnInfo).path + '.' + b.propertyName : b.propertyName;
    //     //     if (order.indexOf(aFullPropertyName) == -1 || order.indexOf(bFullPropertyName) == -1) {
    //     //         throw new Error(`La colonna ${order.indexOf(aFullPropertyName) == -1 ? aFullPropertyName : bFullPropertyName} non è presente nell'elenco dell\'ordinamento`);
    //     //     }

    //     //     if (order.indexOf(aFullPropertyName) > order.indexOf(bFullPropertyName)) {
    //     //         return 1;
    //     //     } else {
    //     //         return -1;
    //     //     }
    //     // });
    //     // return array;
    // }

}
