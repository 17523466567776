import { ModifiedSubscriberInterface } from './modified-subscriber.interface';
import { ViewModelEventDispatcher } from './view-model-event-dispatcher';
import { ViewModelInterface } from './view-model.interface';
import { DomainModelMetaData } from '../meta-data';
import { ModelInterface, PropertyMetaDataInterface } from '@nts/std/interfaces';

export class PropertyViewModelInitializationInfo {
    propertyName: string;
    model: ModelInterface;
    domainModelMetadata: DomainModelMetaData;
    propertyMetaData: PropertyMetaDataInterface;
    eventDispatcher: ViewModelEventDispatcher;
    modifiedSubscriber: ModifiedSubscriberInterface;
    isCustom: boolean;
    useMessageResourceKey: boolean;
    parent: ViewModelInterface;

    customGetter?: () => any;
    customSetter?: (x: any) => Promise<void>;

    // Viene utilizzata come property nei custom getter/custom setter di default
    customValue?: any;
    path: string;
}
