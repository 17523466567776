import { Observable } from 'rxjs';
import { OCCAuditDeactivableModel } from '../domain-models/occ-audit-deactivable-model';
import { SnapShotFrameIdentity } from '../domain-models/snap-shot-frame-identity';
import { SingleAggregateAuditDeactivableModel } from '../domain-models/single-aggregate-audit-deactivable-model';
import { SingleAggregateIdentity } from '../domain-models/single-aggregate-identity';
import { GetAllSnapShotsFrameRequest } from '../requests/get-all-snap-shots-frame-request';
import { GetModelBySnapShotIdentityRequest } from '../requests/get-model-by-snap-shot-identity-request';
import { GetModelBySnapShotIdentityResponse } from '../responses/get-model-by-snap-shot-identity-response';
import { GetSnapShotFrameListResponse } from '../responses/get-snap-shot-frame-list-response';
import { CrudApiClient } from './crud/crud-api-client';
import { CompanySingleAggregateAuditDeactivableModel } from '../domain-models/company-single-aggregate-audit-deactivable-model';
import { CompanySingleAggregateIdentity } from '../domain-models/company-single-aggregate-identity';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { from, switchMap } from 'rxjs';
import { GetByIdentityCachedResponse } from '../responses/get-by-identity-cached-response';
import { CacheOptionsInterface, WebApiServiceAgent } from './web-api-service-agent';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { EnterpriseDataDto } from '../auth/dto/enterprise-data.dto';
import { Injectable } from '@angular/core';
import { OnlineService } from '@nts/std/utility';
import { CurrentSessionService } from '../auth/current-session.service';
import { IdentityInterface } from '@nts/std/interfaces';

@Injectable()
export class SnapShotAPIClient<TModel extends OCCAuditDeactivableModel<TIdentity>, TIdentity extends IdentityInterface>
    extends CrudApiClient<TModel, TIdentity> {

    getSnapShotFrameList(request: GetAllSnapShotsFrameRequest<TIdentity>): Observable<GetSnapShotFrameListResponse> {

        const response: GetSnapShotFrameListResponse = new GetSnapShotFrameListResponse();
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance<GetAllSnapShotsFrameRequest<TIdentity>, GetSnapShotFrameListResponse>(
                'GetSnapShotFrameList', request, response);
    }

    getModelBySnapShotIdentity(
        request: GetModelBySnapShotIdentityRequest<SnapShotFrameIdentity>,
    ): Observable<GetModelBySnapShotIdentityResponse<TModel, TIdentity>> {
        const responseInstance = new GetModelBySnapShotIdentityResponse<TModel, TIdentity>(this.rootModelType);
        return this.getWebApiServiceAgent()
            .invokePostWithResponseWithInstance<
                GetModelBySnapShotIdentityRequest<SnapShotFrameIdentity>, GetModelBySnapShotIdentityResponse<TModel, TIdentity>>(
                    'GetModelBySnapShotIdentity', request, responseInstance);
    }
}

@Injectable()
export class SingleAggregateSnapShotApiClient<TModel extends SingleAggregateAuditDeactivableModel<TIdentity>,
    TIdentity extends SingleAggregateIdentity> extends SnapShotAPIClient<TModel, TIdentity> {

        getSingleAggregateCached(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityCachedResponse<TModel, TIdentity>> {

            // Default for getSingleAggregateCached
            cacheOptions = {
                bypass: false,                         
                expirationTime: undefined,                  
                force: false,
                enterpriseBarrier: true,
                tenantBarrier: true,
                userBarrier: true,
                ...cacheOptions
            };

            const identity = new SingleAggregateIdentity() as TIdentity;
            const request = new GetByIdentityRequest<TIdentity>(identity);
            return this.getByIdentityCached(
                request,
                cacheOptions
            );
        }

        getSingleAggregate(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
            const identity = new SingleAggregateIdentity() as TIdentity;
            const request = new GetByIdentityRequest<TIdentity>(identity);
            return this.getByIdentity(
                request,
                cacheOptions
            );
        }
}

@Injectable()
export class CompanySingleAggregateSnapShotApiClient<TModel extends CompanySingleAggregateAuditDeactivableModel<TIdentity>,
    TIdentity extends CompanySingleAggregateIdentity> extends SnapShotAPIClient<TModel, TIdentity> {

        constructor(
            agent: WebApiServiceAgent,
            onlineService: OnlineService,
            protected currentSession: CurrentSessionService
        ) {
            super(agent, onlineService)
        }

        getSingleAggregateCached(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityCachedResponse<TModel, TIdentity>> {

            // Default for getSingleAggregateCached
            cacheOptions = {
                bypass: false,                         
                expirationTime: undefined,                  
                force: false,
                enterpriseBarrier: true,
                tenantBarrier: true,
                userBarrier: true,
                ...cacheOptions
            };

            const identity = new CompanySingleAggregateIdentity() as TIdentity;
            return from(this.currentSession.getEnterpriseData()).pipe(
                switchMap((enterpriseData: EnterpriseDataDto) => {
                    identity.companyId = enterpriseData?.companyId;
                    const request = new GetByIdentityRequest<TIdentity>(identity);
                    return this.getByIdentityCached(
                        request,
                        cacheOptions
                    );
                })
            )
        }

        getSingleAggregate(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
            const identity = new CompanySingleAggregateIdentity() as TIdentity;
            return from(this.currentSession.getEnterpriseData()).pipe(
                switchMap((enterpriseData: EnterpriseDataDto) => {
                    identity.companyId = enterpriseData?.companyId;
                    const request = new GetByIdentityRequest<TIdentity>(identity);
                    return this.getByIdentity(
                        request,
                        cacheOptions
                    );
                })
            )
        }
}
