import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CoreOrchestratorViewModelInterface } from '../../../view-models/core-orchestrator-view-model.interface';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { RouterLink } from '@angular/router';
import { NTSTranslatePipe } from '../../pipe/nts-translation-pipe';
import { TextButtonComponent } from '../../shared/buttons/text-button/text-button.component';
import { MessageContainerInterface } from '@nts/std/interfaces';

@Component({
  selector: 'nts-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SvgIconComponent,
    RouterLink,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NTSTranslatePipe,
    NgFor,
    TextButtonComponent
  ]
})
export class NotificationComponent implements OnInit, OnDestroy {

  @Input() orchestratorViewModel: CoreOrchestratorViewModelInterface;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  defaultItemToShow = 1;
  itemToShow = this.defaultItemToShow - 1;

  constructor(private readonly cd: ChangeDetectorRef) {

  }

  ngOnInit() {

    merge(
      this.orchestratorViewModel.eventDispatcher.onRemovedMessageInViewModel,
      this.orchestratorViewModel.eventDispatcher.onRefreshMessageInViewModel,
      this.orchestratorViewModel.eventDispatcher.onAddMessageInViewModel,
      this.orchestratorViewModel.eventDispatcher.onClearMessagesInViewModel,
      this.orchestratorViewModel.eventDispatcher.onClearAllMessages
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  showMore(){
    this.itemToShow = this.orchestratorViewModel?.allViewModelInformationsBuildedList?.length 
  }

  showLess(){
    this.itemToShow = this.defaultItemToShow - 1
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  removeMessage(event, information: MessageContainerInterface) {
    event.preventDefault();
    this.orchestratorViewModel.removeMessageInViewModel(information);
  }
}
