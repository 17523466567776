import { Expose, Transform, TransformFnParams } from '@nts/std/serialization';
import { DateTimeOffset } from '@nts/std/types';

export class SnapShotFrameItem {

    @Expose()
    identity: number;

    @Expose()
    updateUser: string;

    @Expose()
    @Transform(DateTimeOffset.transform)
    updateDate: Date;

    @Expose()
    creationUser: string;

    @Expose()
    @Transform(DateTimeOffset.transform)
    creationDate: Date;

    @Expose()
    tenantId: number;
}
