import { Component, Input, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PropertyViewModelInterface } from '../../../../src/lib/view-models/property-view-model.interface';
import { ColumnCellRendererParams } from './column_cell_renderer_params.interface';

@Component({ template: '' })
export abstract class BaseCellRendererComponent implements AgRendererComponent, OnInit {

    @Input()
    params: ICellRendererParams;

    // Supporto dei componenti usati anche inclusi in altri componenti di template vedi dynamic
    ngOnInit(): void {
        if (this.params) {
            this.agInit(this.params);
        }
    }

    refresh(params: any): boolean {
        return true;
    }

    agInit(params: any): void {
        this.params = params;
    }

    private _propertyViewModel: PropertyViewModelInterface;

    get propertyViewModel(): PropertyViewModelInterface {
        if (!this._propertyViewModel) {
            this._propertyViewModel = (this.params as ColumnCellRendererParams).columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    if (o != null) {
                        return o[i];
                    }
                    return null;
                }, this.params.data) as PropertyViewModelInterface;
        }
        return this._propertyViewModel;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }
}
