import { Expose, Transform } from '@nts/std/serialization';
import { PropertyMetaData } from './property-meta-data';
import { TimeSpan } from '@nts/std/types';

/**
 * Metadati per property di tipo TimeSpan
 */
export class TimeSpanMetaData extends PropertyMetaData {

    /**
     * Eventuale valore minimo ammesso
     */
    @Expose()
    @Transform(TimeSpan.transform)
    minValue?: TimeSpan;

    /**
     * Eventuale valore massimo ammesso
     */
    @Expose()
    @Transform(TimeSpan.transform)
    maxValue?: TimeSpan;

    @Expose()
    isAutoComputed?: boolean;

    protected override _type = 'TimeSpan';

    override set(data: any) {
      super.set(data);
      if (data?.minValue != null) {
          this.minValue = data.minValue;
      }
      if (data?.maxValue != null) {
          this.maxValue = data.maxValue;
      }
      if (data?.isAutoComputed != null) {
          this.isAutoComputed = data.isAutoComputed;
      }
    }
}
