import { WingViewModelTypeInspector } from './decorators/wing-view-model-type.decorator';
import { WingViewModelInterface } from './wing-view-model.interface';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { BaseViewModel } from './base-view-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageContainerInterface } from '@nts/std/interfaces';

export class BaseWingViewModel<TOrchestratorViewModel extends CoreOrchestratorViewModelInterface, TWingInfo = any> extends BaseViewModel implements WingViewModelInterface<TWingInfo> {

    wingInfoChanged = new Subject<TWingInfo>();
    wingInfo: TWingInfo = null;
    onActionInProgress = new Subject<boolean>();

    get orchestratorViewModel(): TOrchestratorViewModel {
        return this._orchestratorViewModel;
    }

    get isCollapsed(): boolean {
        return this._isCollapsed;
    }
    set isCollapsed(newValue: boolean) {
        this.orchestratorViewModel.eventDispatcher.onWingCollapsed.next(newValue);
    }

    protected _wingViewModelType: any;

    private _isCollapsed: boolean;
    private _orchestratorViewModel: TOrchestratorViewModel;

    constructor(orchestratorViewModel: TOrchestratorViewModel) {
        super();
        this._orchestratorViewModel = orchestratorViewModel;
        this._wingViewModelType = WingViewModelTypeInspector.getValue(this);

        if (this._wingViewModelType === undefined) {
            throw new Error(
                `MetaData ${WingViewModelTypeInspector.META_DATA_KEY} not defined. You must use ${WingViewModelTypeInspector.DECORATOR_NAME}} in ${this.constructor.name}.`
            );
        }

        this.orchestratorViewModel.eventDispatcher.onWingCollapsed.subscribe((isCollapsed) => {
            this._isCollapsed = isCollapsed;
        });

        this.wingInfoChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe((res) => {
            this.wingInfo = res;
        })
    }

    async postInit(): Promise<void> {
        
    }

    override removeError(item: MessageContainerInterface) {
        throw new Error("Method not implemented.");
    }
}
