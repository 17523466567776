import intersection from 'lodash-es/intersection';
import { DomainModelCollectionInterface } from './domain-model-collection.interface';
import { TypedDomainModelCollectionInterface } from './typed-domain-model-collection.interface';
import { InternalInspector, ModelTypeInspector } from './decorators';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';
import { Expose, Type } from '@nts/std/serialization';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { classToPlain } from '@nts/std/serialization';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';
import { DomainModelState } from '@nts/std/types';

export class DomainModelCollection<TItem extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    implements DomainModelCollectionInterface, TypedDomainModelCollectionInterface<TItem, TIdentity> {

    protected _removedItems: Array<TItem>;

    protected _collectionItems: Array<TItem>;

    private _parentPropertyName: string;

    private _parent: ModelInterface;

    protected _domainModelType: any;

    constructor(
        parent?: ModelInterface,
        parentPropertyName?: string,
        collection: Array<TItem> = new Array<TItem>(),
        fromInherit = false, domainModelType = null
    ) {

        if (parent !== undefined) {
            this._parent = parent;
        }

        this._removedItems = new Array<TItem>();
        if (collection !== undefined) {
            this._collectionItems = collection;
        }

        this._domainModelType = domainModelType || ModelTypeInspector.getValue(this);

        if (this._domainModelType === undefined && fromInherit === false) {
            throw new Error(`MetaData ${ModelTypeInspector.META_DATA_KEY} not defined. You must use ${ModelTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        if (parentPropertyName !== undefined) {
            this._parentPropertyName = parentPropertyName;
        }
    }

    createTypedDomainModel(): TItem {
        return new this._domainModelType();
    }

    createDomainModel(): ModelInterface {
        return this.createTypedDomainModel();
    }

    @Expose()
    @Type((options) => {
        return (options?.newObject as DomainModelCollection<TItem, TIdentity>)?._domainModelType;
    })
    get removedItems(): Array<TItem> {
        if (this._removedItems === undefined) {
            this._removedItems = new Array<TItem>();
        }
        return this._removedItems;
    }

    set removedItems(value: Array<TItem>) {
        this._removedItems = value;
    }

    @Expose()
    @Type((options) => {
        return (options?.newObject as DomainModelCollection<TItem, TIdentity>)?._domainModelType;
    })
    get collectionItems(): Array<TItem> {
        if (this._collectionItems === undefined) {
            this._collectionItems = new Array<TItem>();
        }
        return this._collectionItems;
    }
    set collectionItems(value: Array<TItem>) {
        this._collectionItems = value;
    }

    get parentPropertyName(): string {
        return this._parentPropertyName;
    }

    get parent(): ModelInterface {
        return this._parent;
    }

    move(oldIndex: number, newIndex: number) {
        // TODO
    }

    removeAt(index: number) {
        if (this.collectionItems.length > index) {
            const item = this.collectionItems[index];
            // TODO Tommy: gestire la lista di quelli rimossi
            // TODO Tommy: il parent dell'elemento rimosso deve essere nullato
            // TODO Tommy: cambiare lo stato dell'elemento rimosso
            this.removeItemHandler(item);
            this.collectionItems.splice(index, 1);
        }
    }

    /**
     * Aggiunge un item alla fine della collection.
     * Oltre ad inserire l'item si preoccupa di fare la fixUpIdentityValues.
     *
     * @param item     item da inserire
     * @returns        indice dell'item inserito
     */
    add(item: TItem): number {
        const newLength = this.collectionItems.push(item);
        this.fixUpIdentityValues(item);
        return newLength - 1;
    }

    /**
     * Aggiunge un nuovo item vuoto alla collection
     * Oltre ad inserire l'item si preoccupa di fare la fixUpIdentityValues.
     *
     * @returns item vuoto aggiunto alla collection corrente
     */
    addNewItem(): TItem {
        const item: TItem = new this._domainModelType();
        this.add(item);
        return item;
    }

    /**
     * Inserisce un item alla collection corrente.
     * Oltre ad inserire l'item si preoccupa di fare la fixUpIdentityValues.
     *
     * @param index     indice dove inserire l'item
     * @param item      item da inserire
     */
    insert(index: number, item: TItem): void {
        if (index === this.length) {
            this.add(item);
        } else {
            this.collectionItems.splice(index, 0, item);
            this.fixUpIdentityValues(item);
        }
    }

    set(index: number, item: TItem) {
        // TODO Tommy verificare se necessario aggiornare lo stato
        this.collectionItems[index] = item;
        this.fixUpIdentityValues(item);
    }

    find(predicate: any) {
        return this.collectionItems.find(predicate);
    }

    get length(): number {
        return this.collectionItems.length;
    }

    private removeItemHandler(item: TItem) {
        if (item && item?.currentState !== DomainModelState.New) {
            if (item && this.removedItems.indexOf(item) === -1) {
                item.setState(DomainModelState.Removed);
                this.removedItems.push(item);
                const actualEntityCollection: DomainModelCollectionInterface = this as DomainModelCollectionInterface;
                if (actualEntityCollection.parent != null) {
                    if ((actualEntityCollection.parent as ModelInterface).currentState === DomainModelState.Unchanged) {
                        (actualEntityCollection.parent as ModelInterface).setState(DomainModelState.Modified);
                    }
                }
            }
        }
    }

    setParent(parent: ModelInterface, parentPropertyName: string) {
        this._parent = parent;
        this._parentPropertyName = parentPropertyName;
    }

    createItem(): TItem {
        const item: TItem = new this._domainModelType();
        this.fixUpIdentityValues(item, false);
        return item;
    }

    remove(item: TItem): boolean {
        const foundItemIndex = this.collectionItems.findIndex((element: TItem) => element.equals(item));
        if (foundItemIndex > -1) {
            this.removeAt(foundItemIndex);
        }
        return foundItemIndex > -1;
    }

    private associateInternalPrincipalAndDependant<TValue extends ModelInterface<TExternalIdentity>, TExternalIdentity extends IdentityInterface>(
        item: TItem, parent: TValue, principal: string, dependant: string
    ) {
        if (principal) {
            const value = parent.getPropertyValue(principal);
            item.setPropertyValue(dependant, value);
        }
    }

    private fixUpIdentityValues(item: TItem, setParent = true) {

        if (this.parent != null) {

            const internalMetaData: InternalRelationMetaData = InternalInspector.getValue(this.parent, (this.parentPropertyName));

            if (internalMetaData != null && internalMetaData.associationProperties.length > 0) {
                internalMetaData.associationProperties.forEach((ass) => {
                    this.associateInternalPrincipalAndDependant(
                        item,
                        this.parent as ModelInterface<IdentityInterface>,
                        MetaDataUtils.toCamelCase(ass.principalPropertyName),
                        MetaDataUtils.toCamelCase(ass.dependentPropertyName)
                    );
                });
            } else {
                const dependentIdentityProps = item.identityBase.getPropertyNames();

                // Recupero dalla classa solo le property che mi interessano, evito quelle senza expose, e le property con il modelTypeName
                const principalIdentityProps = Object.keys(
                    classToPlain(this.parent.identityBase, { strategy: 'excludeAll' })
                );

                const identityProps = intersection(dependentIdentityProps, principalIdentityProps);
                for (const prop of identityProps) {
                    const propValue = this.parent.getPropertyValue(prop);
                    item.setPropertyValue(prop, propValue);
                };
            }

        }

        if (setParent) {
            item.setParent(this.parent, this.parentPropertyName);
        }
    }
}
