import { Expose } from "@nts/std/serialization";
import { CoreMetaData } from './core-meta-data';
import { MetaDataDescriptionsInterface, PropertyMetaDataInterface, UserMetaDataInterface } from "@nts/std/interfaces";

export class PropertyMetaData extends CoreMetaData implements PropertyMetaDataInterface {

    /**
     * Indica l'obbligatorietà della property
     */
    @Expose()
    isRequired?: boolean;

    /**
     * Se la proprietà è nullable
     * ATTENZIONE: Non viene letta dal server ma viene utilizzata solo per gestirla manualmente tramite decoratore
     */
    isNullable: boolean;

    /**
     * Indica la position di default della property indipendentemente dalla UI
     */
    @Expose()
    position: number;

    protected _type: string;

    getType(): string {
        return this._type;
    }

    constructor(
        name?: string,
        descriptions?: MetaDataDescriptionsInterface,
        userMetaData?: UserMetaDataInterface,
        isRequired?: boolean,
        position?: number,
    ) {
        super(
            name,
            descriptions,
            userMetaData
        );
        this.isRequired = isRequired;
        this.position = position;
    }

    override set(data: any) {
        super.set(data);
        if (data?.isRequired != null) {
            this.isRequired = data.isRequired;
        }
        if (data?.position != null) {
            this.position = data.position;
        }
    }
}
