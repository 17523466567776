import { EventEmitter } from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { StringPropertyViewModel } from './base-type/string-property-view-model';
import { BaseViewModel } from './base-view-model';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { ExternalRetrieverInterface } from './external-retriever.interface';
import { ModifiedSubscriberInterface } from './modified-subscriber.interface';
import { PropertyViewModel } from './property-view-model';
import { PropertyViewModelInitializationInfo } from './property-view-model-initialization-info';
import { PropertyViewModelInterface } from './property-view-model.interface';
import { ViewModelStates } from './states/view-model-states';
import { ViewModelEventDispatcher } from './view-model-event-dispatcher';
import { GetGenericPropertyViewModelInterface, GetPropertyViewModelInterface, ViewModelInterface } from './view-model.interface';
import { AggregateMetaData, BoolMetaData, DateTimeMetaData, DomainModelMetaData, EnumMetaData, GuidMetaData, InternalCollectionMetaData, MetaDataUtils, ModelVersionMetaData, StringMetaData, TimeSpanMetaData } from '../meta-data';
import { BaseError } from '../messages/base-error';
import { MessageContainer } from './message-container';
import { NumericPropertyViewModel } from './base-type/numeric-property-view-model';
import { NNumericPropertyViewModel } from './base-type/nnumeric-property-view-model';
import { EnumPropertyViewModel, NEnumPropertyViewModel } from './base-type/enum-property-view-model';
import { BoolPropertyViewModel, NBoolPropertyViewModel } from './base-type/bool-property-view-model';
import { DateTimePropertyViewModel, NDateTimePropertyViewModel } from './base-type/date-time-property-view-model';
import { BaseViewModelInterface } from './base-view-model.interface';
import { DateTimeOffsetPropertyViewModel, NDateTimeOffsetPropertyViewModel } from './base-type/date-time-offset-property-view-model';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { NumericMetaData } from '../meta-data/numeric-meta-data';
import { InternalViewModelInterface } from './internal-view-model.interface';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { GenericsPropertiesTypeInspector, GenericsPropertiesTypeInterface } from '../decorators/generics-properties-type.decorator';
import { VersionPropertyViewModel } from './base-type/version-property-view-model';
import { PropertyViewModelFactory } from './property-view-model-factory';
import { GuidPropertyViewModel } from './base-type/guid-property-view-model';
import { LogService } from '@nts/std/utility';
import { NTimeSpanPropertyViewModel, TimeSpanPropertyViewModel } from './base-type/time-span-property-view-model';
import { CustomPropertyViewModelInspector } from './decorators/custom-property-view-model.decorator';
import { ClassConstructor } from '@nts/std/serialization';
import { PropertyViewModelPropertyChangedEventArgs } from './property-view-property-changed-event-args';
import { BigNumber, DomainModelState, GenericsPropertiesType, SourceMessage, TimeSpan } from '@nts/std/types';
import { DateTimeOffset } from '@nts/std/types';
import { PropertyMetaDataInterface } from '@nts/std/interfaces';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export abstract class ViewModel<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends BaseViewModel implements ViewModelInterface, GenericsPropertiesTypeInterface {

    domainModelName: string;
    domainModelType: ClassConstructor<TModel>;
    domainModelFullName: string;
    protected internalMetadataDescription: string = '';
    metadataDescription$: BehaviorSubject<string> = new BehaviorSubject<string>(this.internalMetadataDescription);
    protected internalMetadataShortDescription: string = '';
    metadataShortDescription$: BehaviorSubject<string> = new BehaviorSubject<string>(this.internalMetadataShortDescription);
    relationViewModels = new Map<string, BaseViewModelInterface>();
    externalRetriever: ExternalRetrieverInterface;
    domainModelMetaData: DomainModelMetaData;
    isMock = false;
    reservedPath = '';
    reservedName = '';
    propertyViewModels: Map<string, PropertyViewModelInterface> = new Map<string, PropertyViewModelInterface>();
    aggregateMetaData: AggregateMetaData;
    viewModelChanged = new EventEmitter<void>();

    /**
     * Passare la lista dei metadati custom csi vuole implementare delle collection view model custom
     * @returns lista meta dati custom della collection
     */
    getCustomCollectionViewModelsMetaData() {
        return []
    }

    // Da valutare, proprità che indica se un oggetto della collection e in fase di rimozione
    get isRemoving() {
        return this._isRemoving;
    }

    get metadataDescription(): string {
        return this.internalMetadataDescription;
    }

    set metadataDescription(value) {
        if (this.internalMetadataDescription !== value) {
            this.internalMetadataDescription = value;
            this.onPropertyChanged('metadataDescription');
            this.metadataDescription$.next(value);
        }
    }

    get metadataShortDescription(): string {
        return this.internalMetadataShortDescription;
    }

    set metadataShortDescription(value) {
        if (this.internalMetadataShortDescription !== value) {
            this.internalMetadataShortDescription = value;
            this.onPropertyChanged('metadataShortDescription');
            this.metadataShortDescription$.next(value);
        }
    }

    override set isEnabled(value) {
        if (this._isEnabled !== value) {
            this._isEnabled = value;
            this.switchIsEnabledForPropertyViewModels(value);
            this.switchIsEnabledForInternalExternalCollectionViewModels(value, true, true, true);
            this.onPropertyChanged('isEnabled');
            this.isEnabled$.next(value);
        }
    }

    override get isEnabled(): boolean {
        return this._isEnabled;
        // TODO verificare se si può ottimizzare così e magari eliminare i cicli sotto
        // return this.parent ? this.parent.isEnabled && this._isEnabled : this._isEnabled;
    }

    propertyChanged: EventEmitter<PropertyViewModelPropertyChangedEventArgs> = new EventEmitter();

    onPropertyChanged(propertyName: string = null) {
        const args = new PropertyViewModelPropertyChangedEventArgs();
        args.propertyName = propertyName;
        this.propertyChanged.emit(args);
    }

    set domainModel(value: TModel) {
        this._domainModel = value;
    }
    get domainModel(): TModel {
        return this._domainModel;
    }

    eventDispatcher: ViewModelEventDispatcher;
    protected _viewModelChangeDebouncer: Subject<void> = new Subject();

    // Variabile utilizzate per la cache in memoria dei pvm
    protected behindPropertyViewModels: Map<string, PropertyViewModelInterface> = new Map<string, PropertyViewModelInterface>();

    // Necessario per notificare che ci sono stati cambiamenti sul viewmodel
    protected _modifiedSubscriber: ModifiedSubscriberInterface;
    protected _layoutDefaultValueList: Map<string, string | number | BigNumber |boolean|Date|DateTimeOffset>;

    protected get modifiedSubscriber() {
        return this._modifiedSubscriber;
    }

    override updateCurrentErrors() {
        const errors = this.getErrors();
        this.errors$.next(errors);
    }


    override getErrors(): Array<string> {
        let ret = this.messageContainerCollection.filter(x => x.isErrorMessage).map(x => x.message);

        if (this.propertyViewModels != null) {
            this.propertyViewModels.forEach((item, key) => {
                if (item.isEnabled && item.isVisible) {
                    if (item.hasErrors) {
                        ret.push(...item.getErrors());
                    }
                }
            });
        }

        if (this.relationViewModels != null) {

            this.relationViewModels.forEach((item) => {
                // vengono controllati solo gli item abilitati e visibili
                if (item.isEnabled && item.isVisible) {
                    if (item.hasErrors) {
                        ret.push(...item.getErrors())
                    }
                }
            });
        }

        return ret;

        // if (propertyName && propertyName === this.bindedValuePropertyName && this.messageContainerCollection.length > 0) {
        //     return this.messageContainerCollection.filter(x => x.isErrorMessage).map(x => x.message);
        // } else {
        //     return null;
        // }
    }

    override get hasErrors(): boolean {
        let ret = this.messageContainerCollection.length > 0;

        if (this.propertyViewModels != null) {
            this.propertyViewModels.forEach((item, key) => {
                if (item.isEnabled && item.isVisible) {
                    if (item.hasErrors) {
                        ret = true;
                    }
                }
            });
        }

        if (ret === false && this.relationViewModels != null) {

            this.relationViewModels.forEach((item) => {
                // vengono controllati solo gli item abilitati e visibili
                if (item.isEnabled && item.isVisible) {
                    if (item.hasErrors) {
                        ret = true;
                    }
                }
            });

        }

        return ret;
    }

    get currentState(): ViewModelStates {
        // Necessario perchè in alcuni casi potremmo aver bisogno di accedere
        // al getter anche quando il domain model non è caricato (buildPropertyLists())
        if (this.domainModel == null) { return null; }

        switch (this.domainModel.currentState) {
            case DomainModelState.New:
                return ViewModelStates.New;

            case DomainModelState.Unchanged:
                return ViewModelStates.Unchanged;

            case DomainModelState.Modified:
                return ViewModelStates.Modified;

            case DomainModelState.Removed:
                return ViewModelStates.Removed;

            default:
                throw new Error('Unknown State "' + this.domainModel.currentState + '" on ViewModel');
        }
    }

    private readonly _genericsPropertiesType: GenericsPropertiesType<any>;
    private _domainModel: TModel;
    private _isRemoving = false;

    constructor() {
        super();

        this._viewModelChangeDebouncer
            .pipe(debounceTime(100))
            .subscribe((val) => this.viewModelChanged.emit());

        this._genericsPropertiesType = GenericsPropertiesTypeInspector.getValue(this);
    }

    override removeError(item: MessageContainer) {
    }

    // Lancia l'onDestroy prima sui figli fino arrivare al padre
    override onDestroy() {
        this.propertyViewModels.forEach(p => {
            p.onDestroy();
        });
        this.relationViewModels.forEach(p => {
            p.onDestroy();
        });
        super.onDestroy();
    }

    override clearErrors() {
        if (this.hasErrors) {

            this.removeAllMessages();

            // cancello gli errori dai PropertyViewModel
            this.propertyViewModels.forEach((pvm, key) => {
                if (pvm.hasErrors) {
                    pvm.clearErrors();
                }
            });

            if (this.relationViewModels != null) {
                // cancello gli errori dai viewModel child
                this.relationViewModels.forEach((vm) => {
                    if (vm.hasErrors) {
                        vm.clearErrors();
                    }
                });
            }
        }
    }

    validatePropertyImplementation(propertyName: string) {
        const domainModelValidateResults = new Array<BaseError>();
        this.domainModel.validateProperty(propertyName, domainModelValidateResults);

        for (const item of domainModelValidateResults) {

            let addToMessageContainerCollection = true;
            if (item.propertyName != null) {

                if (this.propertyViewModels.has(item.propertyName)) {
                    this.propertyViewModels.get(item.propertyName).addError(SourceMessage.Validation, item);
                    addToMessageContainerCollection = false;
                } else if (this.relationViewModels.has(item.propertyName)) {
                    this.relationViewModels.get(item.propertyName).addError(SourceMessage.Validation, item);

                    // Il metodo SetError è virtual e per il momento è implementato realmente solo sulle External e non sulle internal
                    // pertanto nel caso di internal non ha fatto nulla e quindi dovrò poi aggiungere a mano alla collection
                    // if ((<ExternalViewModelInterface>this.relationViewModels.get(item.propertyName)).findExternal != null) {
                    addToMessageContainerCollection = false;
                    // }
                } else {
                    // appendo l'errore a livello di domain model
                    // this.addError(SourceMessage.Validation, item);
                    addToMessageContainerCollection = false;
                }

            }

            if (addToMessageContainerCollection) {
                // appendo l'errore a livello di domain model
                this.addError(SourceMessage.Validation, item);
                if (this['eventDispatcher']) {
                    const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                    ed.onAddMessageInViewModel.next({ viewModel: this, messages: [item] });
                }
            }
        }
    }

    validateImplementation() {

            // ----------------------------------------------------------------------------------------------------
            // Property del ViewModel
            // ----------------------------------------------------------------------------------------------------
            // Questo torna già la lista completa degli errori di questo domain model (non va in ricorsione),
            // tranne quelli di eventuali di campi custom del viewModel
            const domainModelValidateResults = new Array<BaseError>();
            this.domainModel.validateAllProperties(domainModelValidateResults, false);

            for (const item of domainModelValidateResults) {

                let addToMessageContainerCollection = true;
                if (item.propertyName != null) {

                    if (this.propertyViewModels.has(item.propertyName)) {
                        this.propertyViewModels.get(item.propertyName).addError(SourceMessage.Validation, item);
                        addToMessageContainerCollection = false;
                    } else if (this.relationViewModels.has(item.propertyName)) {
                        this.relationViewModels.get(item.propertyName).addError(SourceMessage.Validation, item);

                        // Il metodo SetError è virtual e per il momento è implementato realmente solo sulle External e non sulle internal
                        // pertanto nel caso di internal non ha fatto nulla e quindi dovrò poi aggiungere a mano alla collection
                        // if ((<ExternalViewModelInterface>this.relationViewModels.get(item.propertyName)).findExternal != null) {
                        addToMessageContainerCollection = false;
                        // }
                    } else {
                        // appendo l'errore a livello di domain model
                        // this.addError(SourceMessage.Validation, item);
                        addToMessageContainerCollection = false;
                    }

                }

                if (addToMessageContainerCollection) {
                    // appendo l'errore a livello di domain model
                    this.addError(SourceMessage.Validation, item);
                    if (this['eventDispatcher']) {
                        const ed = (this as any).eventDispatcher as ViewModelEventDispatcher;
                        ed.onAddMessageInViewModel.next({ viewModel: this, messages: [item] });
                    }
                }
            }

            // prendo anche gli errori dei campi custom del ViewModel
            const customPVMList: Array<PropertyViewModelInterface> = new Array<PropertyViewModelInterface>();

            // lancia la validate su tutte le pvm
            this.propertyViewModels.forEach(p => {
                if (p.isCustom) {
                    customPVMList.push(p);
                } else {
                    p.validate();
                }
            });

            customPVMList.forEach(customPVM => {
                // TODO CustomPVM validation!
                customPVM.validate();
                // var res = customPVM.validate();
                // if (res.Count > 0)
                // {
                //     results.AddRange(res);
                //     customPVM.SetErrors(SourceMessage.Validation, res.Select(item => item.ErrorMessage).ToList());
                // }
            });
    }

    override validate() {
        if (this.skipValidation || this.isMock) {
            return;
        }

        // cancello gli errori su tutte le property
        this.clearErrors();

        // NOTA: al momento la nostra gestione non prevede che una eventuale internal "obbligatoria" sia presente senza
        // domainModel, ovvero: se è obbligatoria allora domainModel è stato sicuramente creato, se invece il domainModel
        // non c'è deve per forza essere non-obbligatorio e quindi non faccio nemmeno le validazioni
        if (this.domainModel != null) {

            // ----------------------------------------------------------------------------------------------------
            // ChildViewModel
            // ----------------------------------------------------------------------------------------------------
            // Parto con la validazione dei ChildViewModel (ricorsiva).
            // Questo approccio è più prudente, dato che i figli validano se stessi ma poi i padri potrebbero aggiungere sui figli
            // altri errori che dipendono da elementi più in alto nella gerarchia e che pertanto i figli non potrebbero conoscere;
            // i figli, come ogni domain model, iniziano la loro validazione resettando i propri errori, pertanto è giusto che gli errori
            // aggiunti dai padri vengano aggiunti "dopo"
            if (this.relationViewModels != null) {
                this.relationViewModels.forEach(child => {
                    child.validate();
                });
            }

            this.validateImplementation();
        }
    }

    override validateProperty(propertyName: string) {
        if (this.skipValidation || this.isMock) {
            return;
        }

        // cancello gli errori solo della property
        this.removePropertyErrors(propertyName);

        if (this.domainModel != null) {
            this.validatePropertyImplementation(propertyName);
        }
    }

    removePropertyErrors(propertyName: string){

        let property = this.getProperty(propertyName) as BaseViewModelInterface;
        if (!property) {
            property = this.relationViewModels.get(propertyName)
        }
        if (property?.messageContainerCollection) {
            for (const message of property?.messageContainerCollection) {
                if (message.propertyName === propertyName) {
                    property.removeError(message);
                }
            }
        }

    }

    signObjectToRemove() { this._isRemoving = true; }

    getDomainModel(): TModel {
        return this.domainModel;
    }

    getProperty(propertyName: string): PropertyViewModelInterface {
        if (this.propertyViewModels.has(propertyName)) {
            return this.propertyViewModels.get(propertyName);
        } else {
            return null;
        }
    }

    getProperties(): PropertyViewModelInterface[] {
        const arr = new Array<PropertyViewModelInterface>();
        this.propertyViewModels.forEach((v) => {
            arr.push(v);
        });
        return arr;
    }

    switchIsEnabledForPropertyViewModels(isEnabled: boolean) {
        this.propertyViewModels.forEach((value, key) => {
            value.isEnabled = isEnabled;
        });
    }

    switchIsEnabledForInternalExternalCollectionViewModels(isEnabled: boolean,
        includeInternals: boolean, includeExternals: boolean, includeCollections: boolean) {
        this.relationViewModels.forEach(vm => {

            if (includeInternals && (vm as InternalViewModelInterface).initInternalViewModel) {
                vm.isEnabled = isEnabled;
            }

            if (includeExternals && (vm as ExternalViewModelInterface).initExternalViewModel) {
                vm.isEnabled = isEnabled;
            }

            if (includeCollections && (vm as CollectionViewModelInterface<any>).init) {
                vm.isEnabled = isEnabled;
            }
        });
    }

    getGenericPropertyType(propertyName: string) {
        if (!this._genericsPropertiesType || this._genericsPropertiesType[propertyName] == null) {
            throw new Error(
                `MetaData ${GenericsPropertiesTypeInspector.META_DATA_KEY} not defined. You must use ${GenericsPropertiesTypeInspector.DECORATOR_NAME} in ${this.constructor.name} and define ${propertyName}.`
            );
        }
        return this._genericsPropertiesType[propertyName];
    }

    async preInit() { }

    async postInit() {
        this.disableIdentities();
    }

    static getGenericPropertyType(propertyName: string) {
        const decoratorData = GenericsPropertiesTypeInspector.getValue(this);
        if (!decoratorData || decoratorData[propertyName] == null) {
            throw new Error(
                `MetaData ${GenericsPropertiesTypeInspector.META_DATA_KEY} not defined. You must use ${GenericsPropertiesTypeInspector.DECORATOR_NAME} in ${this.constructor.name} and define ${propertyName}.`
            );
        } else if (decoratorData[propertyName] == null) {
            throw new Error(
                `Decorator ${GenericsPropertiesTypeInspector.DECORATOR_NAME} found in ${this.constructor.name} but yhou should and define ${propertyName}.`
            );
        }
        return decoratorData[propertyName];
    }

    /// <summary>
    /// Cancella tutti gli errori di tutti i propertyViewModel, senza andare in ricorsione
    /// sui ChildViewModel. Solleva poi l'evento di OnPropertyChanged("HasErrors")
    /// </summary>
    protected resetErrorsForFullValidateClient() {
        if (this.hasErrors) {
            // errori sulle property
            this.propertyViewModels.forEach(pvm => {
                if (pvm.hasErrors) {
                    pvm.clearErrors();
                }
            });

            // Cancello gli errori rimanenti, ovvero errori di VM che non sono di specifiche
            // property (vengono di solito da una validateDomainModel o da validazione di Api client)
            const list = this.messageContainerCollection;
            list.forEach(item => {
                // se questo errore è su una ChildViewModel potrebbe essere un classico errore di tipo ValidateDecode che
                // verrebbe resettato quando la validate va in ricorsione, ma potrebbe anche essere un errore in generale
                // di tipo Validate che non viene resettato dalla Validate della external, quindi devo resettarlo qui
                // NOTA: per adesso la SetError e la RemoveError sono metodi virtual realmente implementate solo sulle ExternalViewModel e non sulle internal;
                // sulle internal quindi non fanno nulla e quindi per essere sicuro che sia rimosso dalla MessageContainerCollection non posso
                // farla eseguire nei casi di internal, ma solo nei casi di external. Nei casi di internal rimuovo "a mano" dalla MessageContainerCollection
                // if (this.relationViewModels != null && item.propertyName && this.relationViewModels.has(item.propertyName)
                //     && item.sourceMessage !== SourceMessage.ValidationDecode && (<ExternalViewModelInterface>this.relationViewModels.get(item.propertyName)).findExternal != null) {
                //     this.relationViewModels.get(item.propertyName).removeError(item);
                // } else {
                this.messageContainerCollection.splice(this.messageContainerCollection.indexOf(item), 1);
                // }
            });
        }

    }

    protected disableIdentities() {
        // Disabilito le identity in base ai decoratori o allo stato del viewmodel
        this.domainModelMetaData?.identityNames.filter((identityPropertyName: string) =>
            !MetaDataUtils.checkIfPropertyIsACodeForExternal(this.domainModelMetaData, identityPropertyName)
        ).forEach((identityName: string) => {
            const identity = this.getProperty(MetaDataUtils.toCamelCase(identityName));

            // Può succedere che la identity non viene dichiarata nel viewmodel quindi identity sarà null.
            // In questo caso non devo fare niente
            if (identity && identity.propertyMetaData.getType() === 'Numeric') {
                const propertyMetaData: NumericMetaData = identity.propertyMetaData as NumericMetaData;
                identity.isEnabled = !propertyMetaData.isAutoComputed;
            }
            if (identity && (this.currentState === ViewModelStates.Modified || this.currentState === ViewModelStates.Unchanged)) {
                identity.isEnabled = false;
            }
        });
    }

    // Inizializzo il viewmodel dai metadati e i suoi property view model
    protected async initViewModel(
        domainModel: TModel,
        aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        domainModelName: string,
        domainModelType: ClassConstructor<TModel>
    ): Promise<void> {

        if (aggregateMetaData != null) {
            this.aggregateMetaData = aggregateMetaData;
            this._modifiedSubscriber = orchestratorViewModel;
            this.externalRetriever = orchestratorViewModel;
            if (orchestratorViewModel) {
                this.eventDispatcher = orchestratorViewModel.eventDispatcher;
                this._layoutDefaultValueList = orchestratorViewModel.layoutDefaultValueList;
            }
            this.domainModel = domainModel;

            if (domainModelName != null) {
                this.domainModelName = domainModelName;
            } else if (this.domainModel != null) {
                this.domainModelName = this.domainModel.modelTypeName;
            }

            if (domainModelType != null) {
                this.domainModelType = domainModelType;
            }

            const domainModelMetaData = aggregateMetaData.domainModels.find(
                (element: DomainModelMetaData) => MetaDataUtils.toCamelCase(element.name) === MetaDataUtils.toCamelCase(this.domainModelName)
            );
            if (!domainModelMetaData) {
                LogService.warn(
                    `DomainModelName '${MetaDataUtils.toCamelCase(this.domainModelName)}' non trovato. Verfica il nome del decoratore @ModelTypeNameDecorator`);

            }
            if (domainModelMetaData != null) {
                this.domainModelMetaData = domainModelMetaData;
                this.domainModelFullName = domainModelMetaData.fullName;
                await this.buildPropertyLists();
                this.metadataShortDescription = orchestratorViewModel?.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(this.domainModelMetaData.descriptions.displayNameKey) : this.domainModelMetaData.descriptions.displayName;
                this.metadataDescription = orchestratorViewModel?.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(this.domainModelMetaData.descriptions.descriptionKey) : this.domainModelMetaData.descriptions.description;
            }

        }
    }

    // Popolo la lista di tutti i property view models
    // Attenzione al momento i pvm custom non sono gestiti e popolati in propertyViewModels
    protected async buildPropertyLists(): Promise<void> {
        this.propertyViewModels = new Map<string, PropertyViewModelInterface>();
        const allProperties = this.domainModelMetaData.allPropertyNames
            .map((val) => MetaDataUtils.toCamelCase(val));
        for (const key of allProperties) {
            const propertyValue = this[key];
            if (propertyValue != null && propertyValue instanceof PropertyViewModel) {
                this.propertyViewModels.set(key, propertyValue);

                // Imposto il parent
                propertyValue.parent = this;

                // Imposto la security
                propertyValue.securityAccess = this.domainModelMetaData.getPropertyMetaData(key)?.userMetaData?.securityAccess;

                // #1625 aggiunto per gestire il fallback sui campi numerici required non nullable
                if (propertyValue.isRequired && propertyValue instanceof NumericPropertyViewModel && propertyValue.value == null) {
                    const oldValue = propertyValue.canNotifyModified;
                    propertyValue.canNotifyModified = false;
                    await propertyValue.resetValue(true);
                    propertyValue.canNotifyModified = oldValue;
                }

                // #3312 aggiunto per gestire il fallback sui campi enum non nullable, imposta il valore della prima opzione disponibile
                if (propertyValue instanceof EnumPropertyViewModel && propertyValue.value == null) {
                    const oldValue = propertyValue.canNotifyModified;
                    propertyValue.canNotifyModified = false;
                    await propertyValue.resetValue(true);
                    propertyValue.canNotifyModified = oldValue;
                }

                // aggiunto per gestire il fallback sui campi date non nullable, imposta la data di oggi
                if (propertyValue instanceof DateTimePropertyViewModel && propertyValue.value == null) {
                    const oldValue = propertyValue.canNotifyModified;
                    propertyValue.canNotifyModified = false;
                    await propertyValue.resetValue(true);
                    propertyValue.canNotifyModified = oldValue;
                }

                if (
                    (this.domainModel?.isMock === false) && this.currentState === ViewModelStates.New ||
                    (this.domainModel?.forceDefaultValue === true) && this.currentState === ViewModelStates.New
                ) {
                    await propertyValue.setCurrentValueWithDefaultValueFromLayoutMetaData();
                }

                merge(
                    propertyValue.propertyViewModelChanged,
                    propertyValue.propertyChanged.pipe(filter((args) => args.propertyName === propertyValue.bindedValuePropertyName))
                ).pipe(takeUntil(this.destroySubscribers$)).subscribe(e => {
                    this._viewModelChangeDebouncer.next();
                });
            }
            if (propertyValue?.formattedValue$) {
                propertyValue.formattedValue$.next(propertyValue.formattedValue);
            }
            if (propertyValue?.value$) {
                propertyValue.value$.next(propertyValue.value);
            }

        }
    }
    
    //#region getStringPropertyViewModel
    getStringPropertyViewModel<TStringPropertyViewModel extends StringPropertyViewModel = StringPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<string, TStringPropertyViewModel>
    ): TStringPropertyViewModel;
    /**
     * @deprecated utilizza getStringPropertyViewModel con in input l'object params e non l'action
     */
    getStringPropertyViewModel<TStringPropertyViewModel extends StringPropertyViewModel = StringPropertyViewModel>(
        action: (value: TStringPropertyViewModel) => void,
        value: TStringPropertyViewModel,
        propertyName: string,
        customGetter?: () => string,
        customSetter?: (value: string) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TStringPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TStringPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel String
     */
    getStringPropertyViewModel<TStringPropertyViewModel extends StringPropertyViewModel = StringPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<string, TStringPropertyViewModel> | ((value: TStringPropertyViewModel) => void),
        value: TStringPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => string) = undefined,
        customSetter: undefined | ((value: string) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TStringPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TStringPropertyViewModel {
        return this.getGenericPropertyViewModel<string, TStringPropertyViewModel, StringMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? StringPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.strings
        })
    }
    //#endregion getStringPropertyViewModel

    //#region getGuidPropertyViewModel
    getGuidPropertyViewModel<TGuidPropertyViewModel extends GuidPropertyViewModel = GuidPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<string, TGuidPropertyViewModel>
    ): TGuidPropertyViewModel;
    /**
     * @deprecated utilizza getGuidPropertyViewModel con in input l'object params e non l'action
     */
    getGuidPropertyViewModel<TGuidPropertyViewModel extends GuidPropertyViewModel = GuidPropertyViewModel>(
        action: (value: TGuidPropertyViewModel) => void,
        value: TGuidPropertyViewModel,
        propertyName: string,
        customGetter?: () => string,
        customSetter?: (value: string) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TGuidPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TGuidPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Guid
     */
    getGuidPropertyViewModel<TGuidPropertyViewModel extends GuidPropertyViewModel = GuidPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<string, TGuidPropertyViewModel> | ((value: TGuidPropertyViewModel) => void),
        value: TGuidPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => string) = undefined,
        customSetter: undefined | ((value: string) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TGuidPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TGuidPropertyViewModel {
        return this.getGenericPropertyViewModel<string, TGuidPropertyViewModel, GuidMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? GuidPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.guids
        })
    }
    //#endregion getGuidPropertyViewModel

    //#region getVersionPropertyViewModel
    getVersionPropertyViewModel<TVersionPropertyViewModel extends VersionPropertyViewModel = VersionPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<string, TVersionPropertyViewModel>
    ): TVersionPropertyViewModel;
    /**
     * @deprecated utilizza getVersionPropertyViewModel con in input l'object params e non l'action
     */
    getVersionPropertyViewModel<TVersionPropertyViewModel extends VersionPropertyViewModel = VersionPropertyViewModel>(
        action: (value: TVersionPropertyViewModel) => void,
        value: TVersionPropertyViewModel,
        propertyName: string,
        customGetter?: () => string,
        customSetter?: (value: string) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TVersionPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TVersionPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Version
     */
    getVersionPropertyViewModel<TVersionPropertyViewModel extends VersionPropertyViewModel = VersionPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<string, TVersionPropertyViewModel> | ((value: TVersionPropertyViewModel) => void),
        value: TVersionPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => string) = undefined,
        customSetter: undefined | ((value: string) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TVersionPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TVersionPropertyViewModel {
        return this.getGenericPropertyViewModel<string, TVersionPropertyViewModel, ModelVersionMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? VersionPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: [this.domainModelMetaData.version]
        })
    }
    //#endregion getVersionPropertyViewModel

    //#region getTimeSpanPropertyViewModel
    getTimeSpanPropertyViewModel<TTimeSpanPropertyViewModel extends TimeSpanPropertyViewModel = TimeSpanPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<TimeSpan, TTimeSpanPropertyViewModel>
    ): TTimeSpanPropertyViewModel;
    /**
     * @deprecated utilizza getTimeSpanPropertyViewModel con in input l'object params e non l'action
     */
    getTimeSpanPropertyViewModel<TTimeSpanPropertyViewModel extends TimeSpanPropertyViewModel = TimeSpanPropertyViewModel>(
        action: (value: TTimeSpanPropertyViewModel) => void,
        value: TTimeSpanPropertyViewModel,
        propertyName: string,
        customGetter?: () => TimeSpan,
        customSetter?: (value: TimeSpan) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TTimeSpanPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TTimeSpanPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel TimeSpan Non Nullable
     */
    getTimeSpanPropertyViewModel<TTimeSpanPropertyViewModel extends TimeSpanPropertyViewModel = TimeSpanPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<TimeSpan, TTimeSpanPropertyViewModel> | ((value: TTimeSpanPropertyViewModel) => void),
        value: TTimeSpanPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => TimeSpan) = undefined,
        customSetter: undefined | ((value: TimeSpan) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TTimeSpanPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TTimeSpanPropertyViewModel {
        return this.getGenericPropertyViewModel<TimeSpan, TTimeSpanPropertyViewModel, TimeSpanMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? TimeSpanPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.timeSpans
        })
    }
    //#endregion getTimeSpanPropertyViewModel

    //#region getNTimeSpanPropertyViewModel
    getNTimeSpanPropertyViewModel<TNTimeSpanPropertyViewModel extends NTimeSpanPropertyViewModel = NTimeSpanPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<TimeSpan, TNTimeSpanPropertyViewModel>
    ): TNTimeSpanPropertyViewModel;
    /**
     * @deprecated utilizza getNTimeSpanPropertyViewModel con in input l'object params e non l'action
     */
    getNTimeSpanPropertyViewModel<TNTimeSpanPropertyViewModel extends NTimeSpanPropertyViewModel = NTimeSpanPropertyViewModel>(
        action: (value: TNTimeSpanPropertyViewModel) => void,
        value: TNTimeSpanPropertyViewModel,
        propertyName: string,
        customGetter?: () => TimeSpan,
        customSetter?: (value: TimeSpan) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TNTimeSpanPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TNTimeSpanPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel TimeSpan Nullable
     */
    getNTimeSpanPropertyViewModel<TNTimeSpanPropertyViewModel extends NTimeSpanPropertyViewModel = NTimeSpanPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<TimeSpan, TNTimeSpanPropertyViewModel> | ((value: TNTimeSpanPropertyViewModel) => void),
        value: TNTimeSpanPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => TimeSpan) = undefined,
        customSetter: undefined | ((value: TimeSpan) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TNTimeSpanPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TNTimeSpanPropertyViewModel {
        return this.getGenericPropertyViewModel<TimeSpan, TNTimeSpanPropertyViewModel, TimeSpanMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? NTimeSpanPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.timeSpans
        })
    }
    //#endregion getNTimeSpanPropertyViewModel

    //#region getDateTimeOffsetPropertyViewModel
    getDateTimeOffsetPropertyViewModel<TDateTimeOffsetPropertyViewModel extends DateTimeOffsetPropertyViewModel = DateTimeOffsetPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<DateTimeOffset, TDateTimeOffsetPropertyViewModel>
    ): TDateTimeOffsetPropertyViewModel;
    /**
     * @deprecated utilizza getDateTimeOffsetPropertyViewModel con in input l'object params e non l'action
     */
    getDateTimeOffsetPropertyViewModel<TDateTimeOffsetPropertyViewModel extends DateTimeOffsetPropertyViewModel = DateTimeOffsetPropertyViewModel>(
        action: (value: TDateTimeOffsetPropertyViewModel) => void,
        value: TDateTimeOffsetPropertyViewModel,
        propertyName: string,
        customGetter?: () => DateTimeOffset,
        customSetter?: (value: DateTimeOffset) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TDateTimeOffsetPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TDateTimeOffsetPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel DateTimeOffset Non Nullable
     */
    getDateTimeOffsetPropertyViewModel<TDateTimeOffsetPropertyViewModel extends DateTimeOffsetPropertyViewModel = DateTimeOffsetPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<DateTimeOffset, TDateTimeOffsetPropertyViewModel> | ((value: TDateTimeOffsetPropertyViewModel) => void),
        value: TDateTimeOffsetPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => DateTimeOffset) = undefined,
        customSetter: undefined | ((value: DateTimeOffset) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TDateTimeOffsetPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TDateTimeOffsetPropertyViewModel {
        return this.getGenericPropertyViewModel<DateTimeOffset, TDateTimeOffsetPropertyViewModel, DateTimeMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? DateTimeOffsetPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.dateTimes
        })
    }
    //#endregion getDateTimeOffsetPropertyViewModel

    //#region getNDateTimeOffsetPropertyViewModel
    getNDateTimeOffsetPropertyViewModel<TNDateTimeOffsetPropertyViewModel extends NDateTimeOffsetPropertyViewModel = NDateTimeOffsetPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<DateTimeOffset, TNDateTimeOffsetPropertyViewModel>
    ): TNDateTimeOffsetPropertyViewModel;
    /**
     * @deprecated utilizza getNDateTimeOffsetPropertyViewModel con in input l'object params e non l'action
     */
    getNDateTimeOffsetPropertyViewModel<TNDateTimeOffsetPropertyViewModel extends NDateTimeOffsetPropertyViewModel = NDateTimeOffsetPropertyViewModel>(
        action: (value: TNDateTimeOffsetPropertyViewModel) => void,
        value: TNDateTimeOffsetPropertyViewModel,
        propertyName: string,
        customGetter?: () => DateTimeOffset,
        customSetter?: (value: DateTimeOffset) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TNDateTimeOffsetPropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TNDateTimeOffsetPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel DateTimeOffset Nullable
     */
    getNDateTimeOffsetPropertyViewModel<TNDateTimeOffsetPropertyViewModel extends NDateTimeOffsetPropertyViewModel = NDateTimeOffsetPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<DateTimeOffset, TNDateTimeOffsetPropertyViewModel> | ((value: TNDateTimeOffsetPropertyViewModel) => void),
        value: TNDateTimeOffsetPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => DateTimeOffset) = undefined,
        customSetter: undefined | ((value: DateTimeOffset) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TNDateTimeOffsetPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TNDateTimeOffsetPropertyViewModel {
        return this.getGenericPropertyViewModel<DateTimeOffset, TNDateTimeOffsetPropertyViewModel, DateTimeMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? NDateTimeOffsetPropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.dateTimes
        })
    }
    //#endregion getNDateTimeOffsetPropertyViewModel

    //#region getDateTimePropertyViewModel
    getDateTimePropertyViewModel<TDateTimePropertyViewModel extends DateTimePropertyViewModel = DateTimePropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<Date, TDateTimePropertyViewModel>
    ): TDateTimePropertyViewModel;
    /**
     * @deprecated utilizza getDateTimePropertyViewModel con in input l'object params e non l'action
     */
    getDateTimePropertyViewModel<TDateTimePropertyViewModel extends DateTimePropertyViewModel = DateTimePropertyViewModel>(
        action: (value: TDateTimePropertyViewModel) => void,
        value: TDateTimePropertyViewModel,
        propertyName: string,
        customGetter?: () => Date,
        customSetter?: (value: Date) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TDateTimePropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TDateTimePropertyViewModel;
    /**
     * Restituisce un PropertyViewModel DateTime Non Nullable
     */
    getDateTimePropertyViewModel<TDateTimePropertyViewModel extends DateTimePropertyViewModel = DateTimePropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<Date, TDateTimePropertyViewModel> | ((value: TDateTimePropertyViewModel) => void),
        value: TDateTimePropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => Date) = undefined,
        customSetter: undefined | ((value: Date) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TDateTimePropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TDateTimePropertyViewModel {
        return this.getGenericPropertyViewModel<Date, TDateTimePropertyViewModel, DateTimeMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? DateTimePropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.dateTimes
        })
    }
    //#endregion getDateTimePropertyViewModel

    //#region getNDateTimePropertyViewModel
    getNDateTimePropertyViewModel<TNDateTimePropertyViewModel extends NDateTimePropertyViewModel = NDateTimePropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<Date, TNDateTimePropertyViewModel>
    ): TNDateTimePropertyViewModel;
    /**
     * @deprecated utilizza getNDateTimePropertyViewModel con in input l'object params e non l'action
     */
    getNDateTimePropertyViewModel<TNDateTimePropertyViewModel extends NDateTimePropertyViewModel = NDateTimePropertyViewModel>(
        action: (value: TNDateTimePropertyViewModel) => void,
        value: TNDateTimePropertyViewModel,
        propertyName: string,
        customGetter?: () => Date,
        customSetter?: (value: Date) => Promise<void>,
        propertyViewModelType?: ClassConstructor<TNDateTimePropertyViewModel>,
        additionalCustomValidation?: () => BaseError[],
        additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TNDateTimePropertyViewModel;
    /**
     * Restituisce un PropertyViewModel DateTime Nullable
     */
    getNDateTimePropertyViewModel<TNDateTimePropertyViewModel extends NDateTimePropertyViewModel = NDateTimePropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<Date, TNDateTimePropertyViewModel> | ((value: TNDateTimePropertyViewModel) => void),
        value: TNDateTimePropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => Date) = undefined,
        customSetter: undefined | ((value: Date) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TNDateTimePropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TNDateTimePropertyViewModel {
        return this.getGenericPropertyViewModel<Date, TNDateTimePropertyViewModel, DateTimeMetaData>({
            action,
            value,
            propertyName,
            customGetter,
            customSetter,
            propertyViewModelType: propertyViewModelType ?? NDateTimePropertyViewModel as any,
            additionalCustomValidation,
            additionalObserverForValuePropertyChange,
            metaDataList: this.domainModelMetaData.dateTimes
        })
    }
    //#endregion getNDateTimePropertyViewModel

    //#region getEnumPropertyViewModel
    getEnumPropertyViewModel<TEnumPropertyViewModel extends EnumPropertyViewModel = EnumPropertyViewModel>(
      params: GetGenericPropertyViewModelInterface<number, TEnumPropertyViewModel>
    ): TEnumPropertyViewModel;
    /**
     * @deprecated utilizza getEnumPropertyViewModel con in input l'object params e non l'action
     */
    getEnumPropertyViewModel<TEnumPropertyViewModel extends EnumPropertyViewModel = EnumPropertyViewModel>(
      action: (value: EnumPropertyViewModel) => void,
      value: EnumPropertyViewModel,
      propertyName: string,
      customGetter?: () => number,
      customSetter?: (value: number) => Promise<void>,
      propertyViewModelType?: ClassConstructor<EnumPropertyViewModel>,
      additionalCustomValidation?: () => BaseError[],
      additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TEnumPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Enum Non Nullable
     */
    getEnumPropertyViewModel<TEnumPropertyViewModel extends EnumPropertyViewModel = EnumPropertyViewModel>(
      action: GetGenericPropertyViewModelInterface<number, TEnumPropertyViewModel> | ((value: TEnumPropertyViewModel) => void),
      value: TEnumPropertyViewModel | undefined = undefined,
      propertyName: string = '',
      customGetter: undefined | (() => number) = undefined,
      customSetter: undefined | ((value: number) => Promise<void>) = undefined,
      propertyViewModelType: undefined | ClassConstructor<TEnumPropertyViewModel> = undefined,
      additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
      additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TEnumPropertyViewModel {
      return this.getGenericPropertyViewModel<number, TEnumPropertyViewModel, EnumMetaData>({
        action,
        value,
        propertyName,
        customGetter,
        customSetter,
        propertyViewModelType: propertyViewModelType ?? EnumPropertyViewModel as any,
        additionalCustomValidation,
        additionalObserverForValuePropertyChange,
        metaDataList: this.domainModelMetaData.enums
      })
    }
    //#endregion getEnumPropertyViewModel

    //#region getNEnumPropertyViewModel
    getNEnumPropertyViewModel<TNEnumPropertyViewModel extends NEnumPropertyViewModel = NEnumPropertyViewModel>(
        params: GetGenericPropertyViewModelInterface<number, TNEnumPropertyViewModel>
      ): TNEnumPropertyViewModel;
    /**
     * @deprecated utilizza getNEnumPropertyViewModel con in input l'object params e non l'action
     */
    getNEnumPropertyViewModel<TNEnumPropertyViewModel extends NEnumPropertyViewModel = NEnumPropertyViewModel>(
      action: (value: NEnumPropertyViewModel) => void,
      value: NEnumPropertyViewModel,
      propertyName: string,
      customGetter?: () => number,
      customSetter?: (value: number) => Promise<void>,
      propertyViewModelType?: ClassConstructor<NEnumPropertyViewModel>,
      additionalCustomValidation?: () => BaseError[],
      additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TNEnumPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Enum Nullable
     */
    getNEnumPropertyViewModel<TNEnumPropertyViewModel extends NEnumPropertyViewModel = NEnumPropertyViewModel>(
      action: GetGenericPropertyViewModelInterface<number, TNEnumPropertyViewModel> | ((value: TNEnumPropertyViewModel) => void),
      value: TNEnumPropertyViewModel | undefined = undefined,
      propertyName: string = '',
      customGetter: undefined | (() => number) = undefined,
      customSetter: undefined | ((value: number) => Promise<void>) = undefined,
      propertyViewModelType: undefined | ClassConstructor<TNEnumPropertyViewModel> = undefined,
      additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
      additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TNEnumPropertyViewModel {
      return this.getGenericPropertyViewModel<number, TNEnumPropertyViewModel, EnumMetaData>({
        action,
        value,
        propertyName,
        customGetter,
        customSetter,
        propertyViewModelType: propertyViewModelType ?? NEnumPropertyViewModel as any,
        additionalCustomValidation,
        additionalObserverForValuePropertyChange,
        metaDataList: this.domainModelMetaData.enums
      })
    }
    //#endregion getNEnumPropertyViewModel

    //#region getBoolPropertyViewModel
    getBoolPropertyViewModel<TBoolPropertyViewModel extends BoolPropertyViewModel = BoolPropertyViewModel>(
      params: GetGenericPropertyViewModelInterface<boolean, TBoolPropertyViewModel>
    ): TBoolPropertyViewModel;
    /**
     * @deprecated utilizza getBoolPropertyViewModel con in input l'object params e non l'action
     */
    getBoolPropertyViewModel<TBoolPropertyViewModel extends BoolPropertyViewModel = BoolPropertyViewModel>(
      action: (value: TBoolPropertyViewModel) => void,
      value: TBoolPropertyViewModel,
      propertyName: string,
      customGetter?: () => boolean,
      customSetter?: (value: boolean) => Promise<void>,
      propertyViewModelType?: ClassConstructor<TBoolPropertyViewModel>,
      additionalCustomValidation?: () => BaseError[],
      additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TBoolPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Bool Non Nullable
     */
    getBoolPropertyViewModel<TBoolPropertyViewModel extends BoolPropertyViewModel = BoolPropertyViewModel>(
      action: GetGenericPropertyViewModelInterface<boolean, TBoolPropertyViewModel> | ((value: TBoolPropertyViewModel) => void),
      value: TBoolPropertyViewModel | undefined = undefined,
      propertyName: string = '',
      customGetter: undefined | (() => boolean) = undefined,
      customSetter: undefined | ((value: boolean) => Promise<void>) = undefined,
      propertyViewModelType: undefined | ClassConstructor<TBoolPropertyViewModel> = undefined,
      additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
      additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TBoolPropertyViewModel {
      return this.getGenericPropertyViewModel<boolean, TBoolPropertyViewModel, BoolMetaData>({
        action,
        value,
        propertyName,
        customGetter,
        customSetter,
        propertyViewModelType: propertyViewModelType ?? BoolPropertyViewModel as any,
        additionalCustomValidation,
        additionalObserverForValuePropertyChange,
        metaDataList: this.domainModelMetaData.bools
      })
    }
    //#endregion getBoolPropertyViewModel

    //#region getNBoolPropertyViewModel
    getNBoolPropertyViewModel<TNBoolPropertyViewModel extends NBoolPropertyViewModel = NBoolPropertyViewModel>(
      params: GetGenericPropertyViewModelInterface<boolean, TNBoolPropertyViewModel>
    ): TNBoolPropertyViewModel;
    /**
     * @deprecated utilizza getNBoolPropertyViewModel con in input l'object params e non l'action
     */
    getNBoolPropertyViewModel<TNBoolPropertyViewModel extends NBoolPropertyViewModel = NBoolPropertyViewModel>(
      action: (value: TNBoolPropertyViewModel) => void,
      value: TNBoolPropertyViewModel,
      propertyName: string,
      customGetter?: () => boolean,
      customSetter?: (value: boolean) => Promise<void>,
      propertyViewModelType?: ClassConstructor<TNBoolPropertyViewModel>,
      additionalCustomValidation?: () => BaseError[],
      additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): NBoolPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Bool Nullable
     */
    getNBoolPropertyViewModel<TNBoolPropertyViewModel extends NBoolPropertyViewModel = NBoolPropertyViewModel>(
        action: GetGenericPropertyViewModelInterface<boolean, TNBoolPropertyViewModel> | ((value: TNBoolPropertyViewModel) => void),
        value: TNBoolPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => boolean) = undefined,
        customSetter: undefined | ((value: boolean) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TNBoolPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined,
    ): TNBoolPropertyViewModel {
      return this.getGenericPropertyViewModel<boolean, TNBoolPropertyViewModel, BoolMetaData>({
        action,
        value,
        propertyName,
        customGetter,
        customSetter,
        propertyViewModelType: propertyViewModelType ?? NBoolPropertyViewModel as any,
        additionalCustomValidation,
        additionalObserverForValuePropertyChange,
        metaDataList: this.domainModelMetaData.bools
      })
    }
    //#endregion getNBoolPropertyViewModel

    //#region getNumericPropertyViewModel
    getNumericPropertyViewModel<TModelType = BigNumber|number,TNumericPropertyViewModel extends NumericPropertyViewModel<TModelType> = NumericPropertyViewModel<TModelType>>(
      params: GetGenericPropertyViewModelInterface<TModelType, TNumericPropertyViewModel>
    ): TNumericPropertyViewModel;
    /**
     * @deprecated utilizza getNumericPropertyViewModel con in input l'object params e non l'action
     */
    getNumericPropertyViewModel<TModelType = BigNumber|number, TNumericPropertyViewModel extends NumericPropertyViewModel<TModelType> = NumericPropertyViewModel<TModelType>>(
      action: (value: TNumericPropertyViewModel) => void,
      value: TNumericPropertyViewModel,
      propertyName: string,
      customGetter?: undefined | (() => TModelType),
      customSetter?: undefined | ((value: TModelType) => Promise<void>),
      propertyViewModelType?: undefined | ClassConstructor<TNumericPropertyViewModel>,
      additionalCustomValidation?: undefined | (() => BaseError[]),
      additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TNumericPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Numerico Non Nullable
     */
    getNumericPropertyViewModel<TModelType = BigNumber|number, TNumericPropertyViewModel extends NumericPropertyViewModel<TModelType> = NumericPropertyViewModel<TModelType>>(
        action: GetGenericPropertyViewModelInterface<TModelType, TNumericPropertyViewModel> | ((value: TNumericPropertyViewModel) => void),
        value: TNumericPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => TModelType) = undefined,
        customSetter: undefined | ((value: TModelType) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TNumericPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined
    ): TNumericPropertyViewModel {

      return this.getGenericPropertyViewModel<TModelType, TNumericPropertyViewModel, NumericMetaData>({
        action,
        value,
        propertyName,
        customGetter,
        customSetter,
        propertyViewModelType: propertyViewModelType ?? NumericPropertyViewModel as any,
        additionalCustomValidation,
        additionalObserverForValuePropertyChange,
        metaDataList: this.domainModelMetaData.numerics
      })
    }
    //#endregion getNumericPropertyViewModel

    //#region getNNumericPropertyViewModel
    getNNumericPropertyViewModel<TModelType = BigNumber|number, TNNumericPropertyViewModel extends NNumericPropertyViewModel<TModelType> = NNumericPropertyViewModel<TModelType>>(
      params: GetGenericPropertyViewModelInterface<TModelType, TNNumericPropertyViewModel>
    ): TNNumericPropertyViewModel;
    /**
     * @deprecated utilizza getNNumericPropertyViewModel con in input l'object params e non l'action
     */
    getNNumericPropertyViewModel<TModelType = BigNumber|number, TNNumericPropertyViewModel extends NNumericPropertyViewModel<TModelType> = NNumericPropertyViewModel<TModelType>>(
      action: ((value: TNNumericPropertyViewModel) => void),
      value: TNNumericPropertyViewModel | undefined,
      propertyName: string,
      customGetter?: undefined | (() => TModelType),
      customSetter?: undefined | ((value: TModelType) => Promise<void>),
      propertyViewModelType?: undefined | ClassConstructor<TNNumericPropertyViewModel>,
      additionalCustomValidation?: undefined | (() => BaseError[]),
      additionalObserverForValuePropertyChange?: undefined | Observable<any>
    ): TNNumericPropertyViewModel;
    /**
     * Restituisce un PropertyViewModel Numerico Nullable
     */
    getNNumericPropertyViewModel<TModelType = BigNumber|number, TNNumericPropertyViewModel extends NNumericPropertyViewModel<TModelType> = NNumericPropertyViewModel<TModelType>>(
        action: GetGenericPropertyViewModelInterface<TModelType, TNNumericPropertyViewModel> | ((value: TNNumericPropertyViewModel) => void),
        value: TNNumericPropertyViewModel | undefined = undefined,
        propertyName: string = '',
        customGetter: undefined | (() => TModelType) = undefined,
        customSetter: undefined | ((value: TModelType) => Promise<void>) = undefined,
        propertyViewModelType: undefined | ClassConstructor<TNNumericPropertyViewModel> = undefined,
        additionalCustomValidation: undefined | (() => BaseError[]) = undefined,
        additionalObserverForValuePropertyChange: undefined | Observable<any> = undefined
    ): TNNumericPropertyViewModel {

      return this.getGenericPropertyViewModel<TModelType, TNNumericPropertyViewModel, NumericMetaData>({
        action,
        value,
        propertyName,
        customGetter,
        customSetter,
        propertyViewModelType: propertyViewModelType ?? NNumericPropertyViewModel as any,
        additionalCustomValidation,
        additionalObserverForValuePropertyChange,
        metaDataList: this.domainModelMetaData.numerics
      })
    }
    //#endregion getNNumericPropertyViewModel

    //#region getPropertyViewModel
    getPropertyViewModel<T extends PropertyViewModelInterface>(
      params: GetPropertyViewModelInterface<T>
    ): T;
    /**
     * @deprecated utilizza getPropertyViewModel con in input l'object params e non l'action
     */
    getPropertyViewModel<T extends PropertyViewModelInterface>(
      action: (value: T) => void,
        value: T,
        propertyName: string,
        propertyViewModelType: ClassConstructor<PropertyViewModelInterface>,
        metaDataList: PropertyMetaDataInterface[]
    ): T;
    /**
     * Ritorna un PropertyViewModel
     */
    getPropertyViewModel<T extends PropertyViewModelInterface>(
        action: GetPropertyViewModelInterface<T> | ((value: T) => void),
        value: T|undefined = undefined,
        propertyName: string = '',
        propertyViewModelType: ClassConstructor<T>|undefined = undefined,
        metaDataList: PropertyMetaDataInterface[]|undefined = undefined
    ): T {
      if (typeof action != "function") {
        var {
          value = value,
          propertyName = propertyName,
          propertyViewModelType = propertyViewModelType,
          metaDataList = metaDataList,
        } = action;
        var action: GetPropertyViewModelInterface<T> | ((value: T) => void) = action.action;
      }
      if (value == null) {
          value = this.createPropertyViewModel<T>(
              propertyName,
              propertyViewModelType,
              metaDataList
          );
      }
      action(value);
      return value;
    }
    //#endregion getPropertyViewModel

    protected getGenericPropertyViewModel<TValue, TPropertyViewModel extends PropertyViewModelInterface<TValue>, TMetaDataList extends PropertyMetaDataInterface = PropertyMetaDataInterface>({
      action,
      value,
      propertyName,
      customGetter,
      customSetter,
      propertyViewModelType,
      additionalCustomValidation,
      additionalObserverForValuePropertyChange,
      metaDataList
    }: {
      action: GetGenericPropertyViewModelInterface<TValue, TPropertyViewModel> | ((value: TPropertyViewModel) => void),
      value: TPropertyViewModel | undefined,
      propertyName: string,
      customGetter: undefined | (() => TValue),
      customSetter: undefined | ((value: TValue) => Promise<void>),
      propertyViewModelType: undefined | ClassConstructor<TPropertyViewModel>,
      additionalCustomValidation: undefined | (() => BaseError[]),
      additionalObserverForValuePropertyChange: undefined | Observable<any>,
      metaDataList: TMetaDataList[]
    }) {
      if (typeof action != "function") {
        var {
          value = value,
          propertyName = propertyName,
          customGetter = customGetter,
          customSetter = customSetter,
          propertyViewModelType = propertyViewModelType,
          additionalCustomValidation = additionalCustomValidation,
          additionalObserverForValuePropertyChange = additionalObserverForValuePropertyChange
        } = action;
        var action: GetGenericPropertyViewModelInterface<TValue, TPropertyViewModel> | ((value: TPropertyViewModel) => void) = action.action;
      }

      const pvm = this.getPropertyViewModel<TPropertyViewModel>({
        action,
        value,
        propertyName,
        propertyViewModelType,
        metaDataList
      });
      pvm.customGetter = customGetter || pvm.customGetter;
      pvm.customSetter = customSetter || pvm.customSetter;

      if (additionalCustomValidation) {
        pvm.additionalCustomValidation = () => additionalCustomValidation();
      }

      if (additionalObserverForValuePropertyChange) {
        pvm.setAdditionalObserverForValuePropertyChange(additionalObserverForValuePropertyChange);
      }

      return pvm;
    }

    protected createPropertyViewModel<TPropertyViewModel extends PropertyViewModelInterface>(
        propertyName: string,
        propertyViewModelType: ClassConstructor<PropertyViewModelInterface>,
        metaDataList: PropertyMetaDataInterface[]
      ): TPropertyViewModel {
        if (!this.behindPropertyViewModels.has(propertyName)) {
            const metaData = metaDataList.find(item => MetaDataUtils.toCamelCase(item.name) === propertyName);
            const isCustom = CustomPropertyViewModelInspector.isApplied(this, propertyName);
            if (!metaData && !isCustom) {
                LogService.warn(
                    `MetaData non trovato per la property '${propertyName}' di tipo ${propertyViewModelType.prototype.constructor.name} nel view model ${this.constructor.name}`,
                    { propertyName, propertyViewModelType, metaDataList }
                )
            }
            const initializationInfo = this.createPVMInitializationInfo(propertyName, metaData, this);
            const pvm = new propertyViewModelType(initializationInfo) as PropertyViewModelInterface;

            this.setDefaultValueFromLayout(pvm);

            this.behindPropertyViewModels.set(propertyName, pvm);
        }
        return this.behindPropertyViewModels.get(propertyName) as TPropertyViewModel;
    }

    protected createPVMInitializationInfo<TPropertyMetaData extends PropertyMetaDataInterface>(
        propertyName: string,
        propertyMetaData: PropertyMetaDataInterface,
        parent: ViewModelInterface,
        setModel = true,
        setParent = true,
    ): PropertyViewModelInitializationInfo {

        return PropertyViewModelFactory.createPVMInitializationInfo(
            this,
            propertyName,
            propertyMetaData,
            parent,
            this.modifiedSubscriber,
            this.eventDispatcher,
            setModel ? this.domainModel : null,
            setModel,
            setParent,
            [this.reservedPath, this.reservedName].filter((w) => w?.length > 0).join('.')
        )
    }

    private setDefaultValueFromLayout(pvm: PropertyViewModelInterface): void {
        const path = pvm.propertyPath?.length > 0 ? (pvm.propertyPath + '.') : '';
        const fullPathName = (path + pvm.propertyName).split('.').filter((w) => w !== 'selectedItem').join('.');
        const defaultValue: string | number | BigNumber |boolean|Date|DateTimeOffset = this._layoutDefaultValueList?.get(fullPathName)
        if (defaultValue) {
            pvm.setDefaultValueFromLayoutMetaData(defaultValue);
        }
    }
}
