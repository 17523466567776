import { ItemViewModel } from './item-view-model';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';
import { AuditViewModelInterface } from './audit-view-model.interface';
import { StringPropertyViewModel, DateTimeOffsetPropertyViewModel } from './base-type';

export class AuditItemViewModel<
    TModel extends ModelInterface<TIdentity>,
    TIdentity extends IdentityInterface>
    extends ItemViewModel<TModel, TIdentity> implements AuditViewModelInterface {

    private _updateUser: StringPropertyViewModel;

    get updateUser(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._updateUser = value; }, this._updateUser, 'updateUser');
    }

    private _updateDate: DateTimeOffsetPropertyViewModel;

    get updateDate(): DateTimeOffsetPropertyViewModel {
        return this.getDateTimeOffsetPropertyViewModel((value) => { this._updateDate = value; }, this._updateDate, 'updateDate');
    }

    private _creationUser: StringPropertyViewModel;

    get creationUser(): StringPropertyViewModel {
        return this.getStringPropertyViewModel((value) => { this._creationUser = value; }, this._creationUser, 'creationUser');
    }

    private _creationDate: DateTimeOffsetPropertyViewModel;

    get creationDate(): DateTimeOffsetPropertyViewModel {
        return this.getDateTimeOffsetPropertyViewModel((value) => { this._creationDate = value; }, this._creationDate, 'creationDate');
    }
}
