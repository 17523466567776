import { ItemViewModel } from './item-view-model';
import { CollectionViewModel } from './collection-view-model';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class BaseSerialCollectionViewModel<
    TItemViewModel extends ItemViewModel<TItemModel, TItemIdentity>,
    TItemModel extends ModelInterface<TItemIdentity>,
    TItemIdentity extends IdentityInterface
    > extends CollectionViewModel<TItemViewModel, TItemModel, TItemIdentity> {
}

