import { InternalViewModelInterface } from './internal-view-model.interface';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { InternalRelationMetaData } from '../meta-data/internal-relation-meta-data';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { RootViewModel } from './root-view-model';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { ClassConstructor } from '@nts/std/serialization';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class InternalRootViewModel<TModel extends ModelInterface<TIdentity>, TIdentity extends IdentityInterface>
    extends RootViewModel<TModel, TIdentity> implements InternalViewModelInterface {

    relationMetaData: InternalRelationMetaData;

    async initInternalViewModel(
        domainModel: TModel,
        metaData: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        relationMetaData: InternalRelationMetaData,
        path: string,
        isMockedViewModel = false,
        parent = null,
        domainModelType: ClassConstructor<TModel> = null
    ) {
        
        this.reservedPath = path;
        this.reservedName = MetaDataUtils.toCamelCase(relationMetaData.principalPropertyName);
        this.relationMetaData = relationMetaData;
        this.isMock = isMockedViewModel;
        this.parent = parent;

        await this.initAggregateElementViewModel(
            domainModel, 
            metaData, 
            orchestratorViewModel,
            undefined, 
            this.isMock,
            domainModelType
        );

        this.metadataShortDescription = orchestratorViewModel.useMessageResourceKey ?
            MessageResourceManager.Current.getMessageIfExists(
                relationMetaData.principalMetaData.descriptions.displayNameKey) : relationMetaData.principalMetaData.descriptions.displayName;

        this.metadataDescription = orchestratorViewModel.useMessageResourceKey ?
            MessageResourceManager.Current.getMessageIfExists(
                relationMetaData.principalMetaData.descriptions.descriptionKey) : relationMetaData.principalMetaData.descriptions.description;
    }
}
