import { BaseNumericPropertyViewModel } from './base-numeric-property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { NumeralService } from '@nts/std/utility';
import { BigNumber } from '@nts/std/types';

export class NNumericPropertyViewModel<TModelType = BigNumber|number> extends BaseNumericPropertyViewModel<TModelType> {

    getFormattedValue(value: TModelType) {
        if (typeof value !== 'number' && !BigNumber.isBigNumber(value)) {
            // TODO: we should throw an exception
            return '';
        }
        if (value == null) {
            return '';
        }
        if (this.isBigNumber && BigNumber.isBigNumber(value)) {
            // TODO gestire formato in base al locale (this.format)
            return value.toString();
        }
        return NumeralService.Current(value).format(this.format);
    }

    constructor(
        initInfo: PropertyViewModelInitializationInfo,
    ) {
        super(initInfo, true);
        this.setFaultBackValue(null);
        this.isNullable = true;
    }
}
