import { registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { BaseValidator } from './commons/base-validator';
import { RangeValidation } from './validations/range-validation';
import { NumericMetaData } from '../../meta-data/numeric-meta-data';
import { BaseValidationDecoratorInterface } from './commons/base-validation-decorator.interface';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';
import { BigNumber } from '@nts/std/types';

export interface NumberDecoratorInterface<TValue = BigNumber|number> extends BaseValidationDecoratorInterface {
    /**
     * Valore minimo ammesso, proprietà esposta per inizializzare l'attributo
     */
    minValue?: TValue;

    /**
     * Valore massimo ammesso, proprietà esposta per inizializzare l'attributo
     */
    maxValue?: TValue;

    /**
     * Massimo numero di decimali ammessi, proprietà esposta per inizializzare l'attributo
     */
    maxDecimalPrecision?: number;

    /**
     * Massimo numero di cifre per la parte intera, proprietà interna per gestire i valori nullabili
     */
    maxIntegerPrecision?: number;

    /**
     * Il metadata rappresenta un numero decimale
     */
    isDecimal?: boolean;
}

@ValidatorConstraint({ name: 'numberValidator', async: false })
export class NumberValidator<TValue = BigNumber | number> extends BaseValidator<TValue> implements ValidatorConstraintInterface {

    override validate(value: TValue, args: ValidationArguments): boolean {
        const decorator = args.constraints[0] as NumberDecoratorInterface<TValue>;
        const result = super.validate(value, args) &&
            this.validateRangeValue(value, decorator.minValue, decorator.maxValue, args)

        return result;
    }

    static getDecoratorDataFromPropertyMetaData(
        propertyMetaData: NumericMetaData) {
        const decoratorData: NumberDecoratorInterface = {};
        decoratorData.isRequired = propertyMetaData.isRequired;
        decoratorData.isNullable = propertyMetaData.isNullable;
        decoratorData.descriptionKey = propertyMetaData.descriptions.descriptionKey;
        decoratorData.displayNameKey = propertyMetaData.descriptions.displayNameKey;
        decoratorData.maxValue = propertyMetaData.maxValue;
        decoratorData.minValue = propertyMetaData.minValue;
        decoratorData.maxDecimalPrecision = propertyMetaData.maxDecimalPrecision;
        decoratorData.maxIntegerPrecision = propertyMetaData.maxIntegerPrecision;
        decoratorData.context = propertyMetaData.context;
        return decoratorData;
    }

    protected static override getPropertyMetaDataFromDecoratorData<TDecoratorData extends BaseValidationDecoratorInterface>(
        decoratorData: TDecoratorData, propertyName: string) {
        const propertyMetaData = new NumericMetaData();
        propertyMetaData.context = decoratorData.context;
        propertyMetaData.isRequired = (decoratorData as NumberDecoratorInterface).isRequired;
        propertyMetaData.isNullable = (decoratorData as NumberDecoratorInterface).isNullable;
        propertyMetaData.minValue = (decoratorData as NumberDecoratorInterface).minValue;
        propertyMetaData.maxValue = (decoratorData as NumberDecoratorInterface).maxValue;
        propertyMetaData.maxDecimalPrecision = (decoratorData as NumberDecoratorInterface).maxDecimalPrecision;
        propertyMetaData.maxIntegerPrecision = (decoratorData as NumberDecoratorInterface).maxIntegerPrecision;
        propertyMetaData.descriptions.descriptionKey = (decoratorData as NumberDecoratorInterface).descriptionKey;
        propertyMetaData.descriptions.displayNameKey = (decoratorData as NumberDecoratorInterface).displayNameKey;
        propertyMetaData.name = MetaDataUtils.toPascalCase(propertyName);
        return propertyMetaData;
    }

    private validateRangeValue(value: TValue, minValue: TValue, maxValue: TValue, args: ValidationArguments): boolean {
        const rangeValidation = new RangeValidation<TValue>(minValue, maxValue);
        if (!rangeValidation.validate(value, args)) {
            this.errorMessage = rangeValidation.errorMessage;
            this.messageCode = rangeValidation.messageCode;
            return false;
        }
        return true;
    }
}

export function NumberDecorator(decoratorInterface: NumberDecoratorInterface) {
    return (object: object, propertyName: string) => {

        // Metodo di base per tutti i decoratori
        BaseValidator.initBaseValidator(decoratorInterface, object, propertyName);

        // Aggiunge informazioni alla property sulla validazione sul tipo della classe
        NumberValidator.buildPropertyMetaData<NumberDecoratorInterface>(
            object.constructor, propertyName, decoratorInterface);
        // Aggiunge informazioni alla property sulla validazione sull'instanza della classe
        NumberValidator.buildPropertyMetaData<NumberDecoratorInterface>(
            object, propertyName, decoratorInterface);

        registerDecorator({
            target: object.constructor,
            propertyName,
            options: {context: decoratorInterface.context},
            constraints: [decoratorInterface],
            validator: NumberValidator
        });
    };
}
