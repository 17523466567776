import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SimpleFieldMetaData } from "../../../layout-meta-data";
import { FieldTypes } from "../../../layout-meta-data/field-types.enum";
import { MetaDataUtils } from "../../../meta-data/meta-data-utils";
import { MessageResourceManager } from "../../../resources/message-resource-manager";
import { ExternalViewModelInterface } from "../../../view-models/external-view-model.interface";
import { PropertyViewModelInterface } from "../../../view-models/property-view-model.interface";
import { BaseExternalPropertyTextBox } from "../core/base-external-property-text-box";
import { LabelBoxComponent } from "../core/base/label-box/label-box.component";
import { ExtEnumTextBoxComponent } from "../core/ext-enum-text-box/ext-enum-text-box.component";
import { ExtNewAutocompleteTextBoxComponent } from "../core/ext-new-autocomplete-text-box/ext-new-autocomplete-text-box.component";
import { ExternalFieldComponent } from "../core/external-field/external-field.component";

@UntilDestroy()
@Component({
    selector: 'nts-external-list',
    templateUrl: './external-list.component.html',
    styleUrls: ['./external-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LabelBoxComponent,
        ExtEnumTextBoxComponent,
        ExtNewAutocompleteTextBoxComponent
    ]
})
export class ExternalListComponent extends ExternalFieldComponent implements OnInit, OnChanges {

    /**
     * Property valorizzata solo dal layout custom, vincerà sul externalPropertyViewModel.isVisible solo se sarà impostata a false
     */
    @Input() override isHidden = false;

    @HostBinding('class.additional-field')
    @Input() override additionalField = false;

    @HostBinding('class.full-column')
    @Input() fullColumn = false;

    @Input() externalPropertyViewModel: ExternalViewModelInterface;

    /**
     * @deprecated
     * Utilizza [decodeProperties] per elencare la lista delle decode properties
     */
    @Input() decodeDescription?: PropertyViewModelInterface;

    /**
     * @deprecated
     * Aggiungi in [decodeProperties] il codice se vuoi visualizzarlo
     */
    @Input() showCodeInDescription: boolean | null = null;

    /**
     * @deprecated
     * Il codice viene automaticamente recuperato dai metadati
     */
    @Input() code?: PropertyViewModelInterface | null;

    /**
     * Lista dei campi in Pascal Case, si possono anche passare i custom property view model (sempre in Pascal Case)
     */
    @Input() decodeProperties: string[] = null;

    /**
     * Lista dei campi dove effettuare la ricerca in Pascal Case
     */
    @Input() searchProperties: string[] = null;

    /**
     * Stringa da utilizzare come separatore
     */
    @Input() fieldSeparator: string = null;

    /**
     * Al momento supporta solo la stringa 'top', permette di impostare la label sopra il campo
     */
    @Input() labelPosition = '';

    @Input() isDisabled: boolean = false;
    @Input() tabIndex: number;
    @Input() isLabelZoomEnabled = true;
    @Input() primaryColor = null;
    @Input() scrollElementClass = 'layout-main scrollable-content'
    @Input() appendTo = 'app-root' //'.layout-main.scrollable-content';

    @Output() externalPropertyViewModelInitialized = new EventEmitter<void>();

    @ViewChild('textBox', { static: false }) set content(content: BaseExternalPropertyTextBox<any>) {
        this.textBox = content;
    }

    /**
     * Property calcolata in base alle due property: isHidden e externalPropertyViewModel.isVisible
     */
    @HostBinding('class.is-hidden')
    isHiddenCalculated = false;

    override type = FieldTypes.ExternalList;

    get isFullWidth() {
        return (this.labelPosition === 'top' ? true : false);
    }

    get name(): string {
        return MetaDataUtils.toCamelCase(this.externalPropertyViewModel.externalMetaData.principalPropertyName);
    }

    get path(): string {
        return this.externalPropertyViewModel.reservedPath;
    }

    get description(): string {
        return this.externalPropertyViewModel.externalMetaData.descriptions.description?.length > 0 ? this.externalPropertyViewModel.externalMetaData.descriptions.description : MessageResourceManager.Current.getMessage(this.externalPropertyViewModel.externalMetaData.descriptions.descriptionKey);
    }

    get displayName(): string {
        return this.externalPropertyViewModel.externalMetaData.descriptions.displayName?.length > 0 ? this.externalPropertyViewModel.externalMetaData.descriptions.displayName : MessageResourceManager.Current.getMessage(this.externalPropertyViewModel.externalMetaData.descriptions.displayNameKey);
    }

    get availableExternalFields(): SimpleFieldMetaData[] {
        return this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.propertyNames.map((p) => {
            const metaData = this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(p);
            if (metaData != null) {
                const field = new SimpleFieldMetaData();
                field.descriptions = metaData.descriptions;
                field.name = metaData.name;
                return field;
            } else {
                console.error(`metadato di ${p} non trobvato per generare gli ExternalFields di ${this.externalPropertyViewModel.externalMetaData.dependentAggregateMetaData.rootMetaData.fullName}`)
            }
            return null;

        }).filter((f) => f != null)
    }

    protected destroyOnChange$: Subject<void> = new Subject<void>();

    private textBox: BaseExternalPropertyTextBox<any>;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (!this.externalPropertyViewModel) { throw new Error('Missing viewModel for externalPropertyViewModel!'); }
        let decodeProperties = null;
        if (this.decodeDescription) {

            if (this.decodeDescription?.isCustom === false) {
                decodeProperties = [this.decodeDescription.propertyMetaData.name];
            } else {
                decodeProperties = [this.decodeDescription.propertyName];
            }

        }
        if (this.decodeProperties?.length > 0) {
            decodeProperties = this.decodeProperties;
        }
        if (this.showCodeInDescription == null) {
            this.showCodeInDescription = this.externalPropertyViewModel.showCode;
        }
        this.decodeProperties = decodeProperties;
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['isHidden']) {
            if (changes['isHidden']?.currentValue === true) {
                this.isHiddenCalculated = true;
            } else {
                this.isHiddenCalculated = !this.externalPropertyViewModel?.isVisible;
            }
        } else if (changes['externalPropertyViewModel']) {
            this.destroyOnChange$.next();

            this.isHiddenCalculated = this.isHidden === true ? true : !this.externalPropertyViewModel.isVisible;

            merge(
                this.externalPropertyViewModel.externalDomainModelChanged,
                this.externalPropertyViewModel.decodeCompleted,
                this.externalPropertyViewModel.propertyChanged
            ).pipe(
                untilDestroyed(this),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.cd.detectChanges();
            });

            this.externalPropertyViewModelInitialized.emit();
        }
    }

    getCodes(): string[] | null {
        return this.externalPropertyViewModel?.domainModelMetaData?.identityNames?.map((i) => MetaDataUtils.toCamelCase(i)) ?? null
    }

    getCodesPvm(): PropertyViewModelInterface[] | null {
        return this.getCodes()?.map((c) => this.externalPropertyViewModel.getProperty(c)) ?? null
    }

    openZoom() {
        if ((this.isDisabled || this.externalPropertyViewModel.isEnabled === false || !this.getCodesPvm()?.every((c) => c.isEnabled)) && this.externalPropertyViewModel?.securityAccess == null) {
            this.textBox.startPresentation(null, true, false);
            return;
        }
        if (this.externalPropertyViewModel?.securityAccess == null) {
            this.textBox.zoom();
        }
    }
}
