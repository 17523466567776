import { Observable } from 'rxjs';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { AutoCompleteRequest } from '../requests/auto-complete-request';
import { FindValuesResponse } from '../responses/find-values-response';
import { FindValuesOptions } from '../domain-models/find-options/find-values-options';
import { FindValuesRequest } from '../requests/find-values-request';
import { FindRequest } from '../requests/find-request';
import { FindValuesExternalRequest } from '../requests/find-values-external-request';
import { FindValuesExternalOptions } from '../domain-models/find-options/find-values-external-options';
import { ActionHttpCommunication } from './action-http-communication';
import { ModelInterface } from '@nts/std/interfaces';
import { IdentityInterface } from '@nts/std/interfaces';
export class ExternalReaderHttpCommunication extends ActionHttpCommunication {

    static readonly getMinimalRequestUri = 'GetMinimal';

    getExternal<TExternalModel extends ModelInterface<TExtenalIdentity>, TExtenalIdentity extends IdentityInterface>(
        request: GetByIdentityRequest<TExtenalIdentity>,
        externalEntityTypeName: string,
        externalEntityType: any
    ): Observable<GetByIdentityResponse<TExternalModel, TExtenalIdentity>> {
        const responseInstance = new GetByIdentityResponse<TExternalModel, TExtenalIdentity>(externalEntityType);
        return this.agent.invokePostWithResponseWithInstance<
            GetByIdentityRequest<TExtenalIdentity>, GetByIdentityResponse<TExternalModel, TExtenalIdentity>>(
                externalEntityTypeName + '/GetMinimal', request, responseInstance);
    }

    autoCompleteExternal(
        request: AutoCompleteRequest,
        externalDomainModelTypeName: string,
    ): Observable<FindValuesResponse> {
        const responseInstance = new FindValuesResponse(Object);
        return this.agent.invokePostWithResponseWithInstance<
            AutoCompleteRequest, FindValuesResponse>(
                externalDomainModelTypeName + '/AutoComplete', request, responseInstance);
    }

    getMinimal<TIdentity extends IdentityInterface, TModel extends ModelInterface<TIdentity>>(
        request: GetByIdentityRequest<TIdentity>, rootModelType: any
    ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
        const responseInstance = new GetByIdentityResponse<TModel, TIdentity>(rootModelType);
        return this.agent.invokePostWithResponseWithInstance<
            GetByIdentityRequest<TIdentity>, GetByIdentityResponse<TModel, TIdentity>>(
                ExternalReaderHttpCommunication.getMinimalRequestUri, request, responseInstance);
    }

    override findValues(
        entityToLookUp = null, 
        findOptions: FindValuesOptions, 
        entityToLookUpFullName: string, 
        rootModelName = null
    ): Observable<FindValuesResponse> {
        if (rootModelName === entityToLookUp) {
            const request = new FindValuesRequest();
            request.requestData = findOptions;
            return this.agent.invokePostWithResponse<
            FindRequest, FindValuesResponse>(
                ExternalReaderHttpCommunication.findValuesRequestUri, request, FindValuesResponse);
        } else {
            const request = new FindValuesExternalRequest();
            const requestData = new FindValuesExternalOptions(findOptions);
            requestData.fullRootModelName = entityToLookUpFullName;
            request.requestData = requestData;
            return this.agent.invokePostWithResponse<
            FindValuesExternalRequest, FindValuesResponse>(
                ExternalReaderHttpCommunication.findValuesRequestUri, request, FindValuesResponse, true);
        }
    }
}
