
<div class="input-container"
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    [style.borderColor]="showErrorBorder && errorList?.length > 0 ? null : (overrideBorderColor ? overrideBorderColor : null)"
    [class.is-disabled]="isDisabled"
    [class.is-readonly]="isReadonly">

    <input #numericBox
        type="text"
        [attr.inputmode]="computedInputMode$ | async"
        unmask="typed"
        [imask]="mask"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
        [ngModel]="value"
        #popperError="popper"
        popperAppendTo="body"
        [popper]="tooltipErrTemplate"
        [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="error"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        [attr.min]="minValue"
        [attr.max]="maxValue"
        [attr.tabindex]="tabIndex"
        [disabled]="isDisabled ? true: null"
        [readonly]="isReadonly ? true : null"
        [attr.placeholder]="placeholder"
        [ngClass]="customClasses"
        [class.is-disabled]="isDisabled"
        [class.is-readonly]="isReadonly"
        [class.has-commands]="customCommandList?.length > 0"
        (blur)="blur($event)"
        (keydown)="onKeyDown.emit($event)"
        (input)="onInputChange($event)"
        (ngModelChange)="onModelChange($event)"
        (focus)="focus($event)"
        (focusout)="onFocusOut.emit($event)"
        autocomplete="autocomplete-off"
    />

    <div class="command-list-container" *ngIf="customCommandList?.length > 0" [class.is-disabled]="isDisabled" >
        <ng-template ngFor let-command [ngForOf]="customCommandList">
            <nts-ribbon-button [title]="command.tooltip"
                [tabIndex]="0"
                [icon]="command.iconClass"
                [isDisabled]="!(command.canExecute$ | async)"
                (onClicked)="command.execute()">
            </nts-ribbon-button>
        </ng-template>
    </div>
</div>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>
