import { IdentityTypeDecorator } from '../../decorators/identity-type.decorator';
import { ModelTypeNameDecorator } from '../decorators/model-type-name.decorator';
import { NumberDecorator } from '../decorators/number.decorator';
import { StringDecorator } from '../decorators/string.decorator';
import { CoreModel } from '../core-model';
import { TenantProfileIdentity } from './tenant-profile.identity';
import { EnumDecorator } from '../decorators/enum.decorator';
import { Expose } from "@nts/std/serialization";
import { TenantTypes } from '@nts/std/types';

@IdentityTypeDecorator(TenantProfileIdentity)
@ModelTypeNameDecorator('TenantProfile')
export class TenantProfile extends CoreModel<TenantProfileIdentity> {

    private _id: number;

    /**
     * ID Tenant
     */
    @Expose()
    @NumberDecorator({ displayNameKey: 'id' })
    get id(): number {
        return this.getValueForIdentityValue<number>(() => this._id, 'id');
    }
    set id(value: number) {
        this.setValueForIdentityValue<number>(() => { this._id = value; }, value, 'id');
    }

    private _businessName: string;

    /**
     * Ragione sociale
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'businessName' })
    get businessName(): string {
        return this.getValue<string>(() => this._businessName, 'businessName');
    }
    set businessName(value: string) {
        this.setValue<string>(() => { this._businessName = value; }, value, 'businessName');
    }

    private _vatNumber: string;

    /**
     * Partita iva
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'vatNumber' })
    get vatNumber(): string {
        return this.getValue<string>(() => this._vatNumber, 'vatNumber');
    }
    set vatNumber(value: string) {
        this.setValue<string>(() => { this._vatNumber = value; }, value, 'vatNumber');
    }

    private _email: string;

    /**
     * Indirizzo email
     */
    @Expose()
    @StringDecorator({ displayNameKey: 'email' })
    get email(): string {
        return this.getValue<string>(() => this._email, 'email');
    }
    set email(value: string) {
        this.setValue<string>(() => { this._email = value; }, value, 'email');
    }

    private _tenantType: TenantTypes;

    /**
     * Tipo tenant
     */
    @Expose()
    @EnumDecorator({ displayNameKey: 'tenantType' })
    get tenantType(): TenantTypes {
        return this.getValue<TenantTypes>(() => this._tenantType, 'tenantType');
    }
    set tenantType(value: TenantTypes) {
        this.setValue<TenantTypes>(() => { this._tenantType = value; }, value, 'tenantType');
    }

    private _partnerId: number;

    /**
     * ID del partner
     */
    @Expose()
    @NumberDecorator({ displayNameKey: 'partnerId' })
    get partnerId(): number {
        return this.getValue<number>(() => this._partnerId, 'partnerId');
    }
    set partnerId(value: number) {
        this.setValue<number>(() => { this._partnerId = value; }, value, 'partnerId');
    }

    private _base64Image: string;

    /**
     * Immagine in Base64
     */
    @Expose()
    @NumberDecorator({ displayNameKey: 'base64Image' })
    get base64Image(): string {
        return this.getValue<string>(() => this._base64Image, 'base64Image');
    }
    set base64Image(value: string) {
        this.setValue<string>(() => { this._base64Image = value; }, value, 'base64Image');
    }
}

