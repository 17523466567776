import { ZoomFilterViewModel } from './zoom-filter-view-model';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ZoomMetaData } from '../../../meta-data/zoom-meta-data';
import { ZoomOperatorViewModelProperty } from '../zoom-operator-view-model-property';
import { NumericZoomPropertyViewModel } from '../property-view-model/numeric-zoom-property-view-model';
import { ZoomPropertyViewModelInterface } from '../property-view-model/zoom-property-view-model.interface';
import { FilterOperators } from '../../../domain-models/find-options/filter';
import { BigNumber } from '@nts/std/types';

export class NumericZoomFilterViewModel extends ZoomFilterViewModel {

    constructor(metadata: ZoomMetaData, private modifiedSubscriber: ModifiedSubscriberInterface) {
        super(metadata);
        this.operator = new ZoomOperatorViewModelProperty(
            metadata.propertyMetadata.isRequired ? ZoomOperatorViewModelProperty.operatorsForNumeric : ZoomOperatorViewModelProperty.operatorsForNNumeric,
            modifiedSubscriber);
        this.filterValue = new NumericZoomPropertyViewModel(metadata.numericMetaData, modifiedSubscriber);
        this.filterValue2 = new NumericZoomPropertyViewModel(metadata.numericMetaData, modifiedSubscriber);
        this.init();
    }

    protected override async onOperatorChanged(value: FilterOperators): Promise<void> {
        await super.onOperatorChanged(value);
        if (this.OperatorDoesNotRequireValue) {
            for (const filter of this.filterValues) {
                filter.setEnabled(false);
                await filter.resetValue();
            }
        } else {
            for (const filter of this.filterValues) {
                filter.setEnabled(true);
            }
        }
    }

    getEmptyFilter(): NumericZoomPropertyViewModel {
        return new NumericZoomPropertyViewModel(this.metaData.numericMetaData, this.modifiedSubscriber);
    }

    setZoomPropertyViewModelValuesFromEntities(entities: Array<number|BigNumber>) {
        this.setZoomPropertyViewModelValues(entities.map((e:number|BigNumber) => {
            const vm: ZoomPropertyViewModelInterface<number|BigNumber> = this.getEmptyFilter();
            vm.setValue(e);
            return vm;
        }))
    }

    get filterValues(): Array<ZoomPropertyViewModelInterface<number|BigNumber>> {
        return this.internalZoomPropertyViewModelValues;
    }

    private internalZoomPropertyViewModelValues: Array<ZoomPropertyViewModelInterface<number|BigNumber>> = [];

    setZoomPropertyViewModelValues(pvmToSet: Array<ZoomPropertyViewModelInterface<number|BigNumber>>) {
        if (this.internalZoomPropertyViewModelValues != pvmToSet) {
            this.internalZoomPropertyViewModelValues = pvmToSet;
            this.modifiedSubscriber.notifyModified();
        }   
    }
}
