import { AsyncPipe, NgFor, NgForOf, NgIf } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { BehaviorSubject, Observable, Subject, Subscription, merge } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { MessageCodes } from '../../../resources/message-codes';
import { MessageResourceManager } from '../../../resources/message-resource-manager';
import { CommandFactory, PanelViewModelInterface, PanelViewModelUtility, UICommandInterface } from '../../../view-models';
import { PropertyViewModelInterface } from '../../../view-models/property-view-model.interface';
import { BaseFieldComponent } from '../../controls/core/base-field/base-field.component';
import { TextButtonComponent } from '../buttons/text-button/text-button.component';

@UntilDestroy()
@Component({
  selector: 'nts-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
  standalone: true,
  imports: [
    TextButtonComponent,
    NgxPopperjsModule,
    NgIf,
    NgFor,
    NgForOf,
    AsyncPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableComponent implements OnChanges, OnDestroy, OnInit, AfterContentInit  {

  private _isCollapsed: boolean;
  private toggleAdditionalFieldsCommand: UICommandInterface
  private toggleIsCollapsedCommand: UICommandInterface
  private onChanges$ = new Subject<SimpleChanges>();
  private _showAdditionalFields = false;
  private externalEntityChangedSubscriptionDestroy$: Subject<boolean> = new Subject<boolean>();
  private propertyViewModelChangedSubscriptions: Subscription[] = [];
  private noAdditionalFieldsFound$ = new BehaviorSubject<boolean>(false);
  private isCollapsed$ = new BehaviorSubject<boolean>(false);
  private isCollapsedFromPanelVm$ = new BehaviorSubject<boolean>(false);
  private isVisible$ = new BehaviorSubject<boolean>(true);
  private isVisibleFromPanelVm$ = new BehaviorSubject<boolean>(true);
  private hideAdditionalFieldsToggle$ = new BehaviorSubject<boolean>(false);
  private hideAdditionalFieldsToggleFromPanelVm$ = new BehaviorSubject<boolean>(false);

  computedIsCollapsed$ = new BehaviorSubject<boolean>(false);
  computedIsVisible$ = new BehaviorSubject<boolean>(false);
  computedHideAdditionalFieldsToggle$ = new BehaviorSubject<boolean>(false);

  @Input() title = MessageResourceManager.Current.getMessage('std_Expandable_Main_DisplayName');
  @Input() panels?: Map<string, PanelViewModelInterface>;
  @Input() isVisible: boolean = true;

  /**
   * Nasconde il toggle dei campi addizionali.
   * NB: se non sono presenti campi addizionali viene forzato a true.
   */
  @Input() hideAdditionalFieldsToggle: boolean = false;

  @HostBinding('class.isCollapsed')
  @Input()
  set isCollapsed(value: boolean) {
      this.setCollapsed(value);
  }
  get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  @Input() panelId?: string = null;

  @HostBinding('class.full-column')
  @Input() fullColumn = true;

  @Input() disableToggle = false;
  @Input() showBorder = true;
  @Input() showHeader = true;
  @Input() promotedFields: PropertyViewModelInterface[] = [];
  @Input() commands: UICommandInterface[] = [];
  @Input() defaultCommands: UICommandInterface[] = [];

  @Output()
  isCollapsedChange = new EventEmitter<boolean>();

  @Output()
  onExpanded: EventEmitter<any> = new EventEmitter();

  @Output()
  onCollapsed: EventEmitter<any> = new EventEmitter();

  @Output()
  onCollapsedAdditionalFields: EventEmitter<void> = new EventEmitter();

  @Output()
  onExpandedAdditionalFields: EventEmitter<void> = new EventEmitter();

  @ContentChildren('expandableChild') expandableChildren: QueryList<BaseFieldComponent>;

  promotedFieldsFiltered: PropertyViewModelInterface[] = [];
  ngxPopperjsTriggers = NgxPopperjsTriggers;
  ngxPopperjsPlacements = NgxPopperjsPlacements;

  @Input()
  set showAdditionalFields(value: boolean) {
    this.setAdditionalFieldVisibility(value);
  }
  get showAdditionalFields(): boolean {
    return this._showAdditionalFields;
  }

  // Mostra dati aggiuntivi
  showMoreDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableShowMoreDescription);

  // Nascondi dati aggiuntivi
  showLessDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableShowLessDescription);

  // Apri pannello
  openPanelDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableOpenPanelDescription);

  // Chiudi pannello
  closePanelDescription = MessageResourceManager.Current.getMessage(MessageCodes.ExpandableClosePanelDescription);

  constructor(public readonly el: ElementRef, private readonly cd: ChangeDetectorRef) {
    this.toggleAdditionalFieldsCommand = CommandFactory.createUICommand(
      // Execute
      () => this.toggleAdditionalFields(),

      // Can
      () => this.canExecuteToggleAdditionalFields(),
      null,

      // Visibility
      () => this.computedHideAdditionalFieldsToggle$.pipe(map((isHidden) => !isHidden))
    )

    this.toggleIsCollapsedCommand = CommandFactory.createUICommand(
      // Execute
      () => this.toggleIsCollapsedAsync(),

      // Can
      null,

      null,

      // Visibility
      () => this.onChanges$.pipe(
        filter((onChanges: SimpleChanges) => onChanges['disableToggle'] != null),
        startWith(!this.disableToggle),
        map(() => !this.disableToggle)
      )
    )

    this.defaultCommands = [
      this.toggleAdditionalFieldsCommand,
      this.toggleIsCollapsedCommand
    ]
   }

  ngOnInit(): void {
    this.updateToggleAdditionalFieldDescriptions();
    this.updateToggleAdditionalFieldStyles();

    this.onChangePanels()

    this.onChangeIsCollapsed();
    this.onChangeIsVisible();
    this.onChangeDisableAdditionalFields();

    this.computeCollapsability();
    this.computeVisibility();
    this.computeDisableAdditionalFields();

    this.cd.detectChanges();
  }

  ngAfterContentInit(): void {
    if (this.expandableChildren?.length > 0) {
      const foundAdditionalField = this.expandableChildren.find((e) => e.additionalField === true);
      if (!foundAdditionalField) {
        this.noAdditionalFieldsFound$.next(true);
      }
    } else {
      this.noAdditionalFieldsFound$.next(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges$.next(changes);

    if (changes['showAdditionalFields']) {
      this.updateToggleAdditionalFieldDescriptions();
      this.updateToggleAdditionalFieldStyles();
    }

    if (changes['isCollpased']) {
      this.onChangeIsCollapsed();
    }

    if (changes['panels']) {
      this.onChangePanels();
    }

    if (changes['isVisible']) {
      this.onChangeIsVisible();
    }

    if (changes['disableAdditionalFields']) {
      this.onChangeDisableAdditionalFields();
    }

    this.externalEntityChangedSubscriptionDestroy$.next(true);

    this.promotedFields.forEach(pvm => {
      this.propertyViewModelChangedSubscriptions.push(
        merge(
          pvm.propertyViewModelChanged,
          pvm.propertyChanged,
          pvm.onFocusRequested,
        )
        .pipe(
          untilDestroyed(this),
          takeUntil(this.externalEntityChangedSubscriptionDestroy$), startWith(null as any))
        .subscribe(() => {
          this.promotedFieldsFiltered = this.promotedFields.filter((field) => field.formattedValue.length > 0);
          this.cd.detectChanges();
        })
      );
    });
  }

  ngOnDestroy() {
    // Gestione specifica degli externalEntityChanged observables
    this.externalEntityChangedSubscriptionDestroy$.next(true);
    this.externalEntityChangedSubscriptionDestroy$.unsubscribe();
  }

  computeVisibility() {
    merge(
      this.isVisibleFromPanelVm$,
      this.isVisible$
    ).pipe(untilDestroyed(this))
    .subscribe(() => {
      if (this.isVisible$.value === false) {
        this.computedIsVisible$.next(false);
      } else if (this.isVisibleFromPanelVm$.value === false) {
        this.computedIsVisible$.next(false);
      } else {
        this.computedIsVisible$.next(true);
      }
    })
  }

  computeDisableAdditionalFields() {
    merge(
      this.hideAdditionalFieldsToggleFromPanelVm$,
      this.hideAdditionalFieldsToggle$,
      this.noAdditionalFieldsFound$
    ).pipe(untilDestroyed(this))
    .subscribe(() => {
      if (this.noAdditionalFieldsFound$.value === true) {
        this.computedHideAdditionalFieldsToggle$.next(true);
      } else if (this.hideAdditionalFieldsToggle$.value === true) {
        this.computedHideAdditionalFieldsToggle$.next(true);
      } else if (this.hideAdditionalFieldsToggleFromPanelVm$.value === true) {
        this.computedHideAdditionalFieldsToggle$.next(true);
      } else {
        this.computedHideAdditionalFieldsToggle$.next(false);
      }
    })
  }

  computeCollapsability() {
    merge(
      this.isCollapsedFromPanelVm$,
      this.isCollapsed$
    ).pipe(untilDestroyed(this))
    .subscribe((isCollapsed) => {
        this.computedIsCollapsed$.next(isCollapsed);

        this.updateToggleIsCollapsedDescriptions();
        this.updateToggleIsCollapsedStyles();
    })
  }

  onChangePanels() {
    if (this.panelId?.length > 0  && this.panels != null) {
      const panelVm = PanelViewModelUtility.getPanelViewModel(this.panels, this.panelId);
      panelVm.isVisible$.pipe(untilDestroyed(this)).subscribe(this.isVisibleFromPanelVm$);
      panelVm.isCollapsed$.pipe(untilDestroyed(this)).subscribe(this.isCollapsedFromPanelVm$);
      panelVm.disableAdditionalFields$.pipe(untilDestroyed(this)).subscribe(this.hideAdditionalFieldsToggleFromPanelVm$);
    } else {
      this.isVisibleFromPanelVm$.next(true);
    }
  }

  onChangeIsCollapsed(): void {
    this.isCollapsed$.next(this.isCollapsed);
  }

  onChangeIsVisible(): void {
    this.isVisible$.next(this.isVisible);
  }

  onChangeDisableAdditionalFields(): void {
    this.hideAdditionalFieldsToggle$.next(this.hideAdditionalFieldsToggle);
  }

  canExecuteToggleAdditionalFields(): Observable<boolean> {
    return this.isCollapsedChange.pipe(startWith(this.computedIsCollapsed$.value), map((isCollapsed) => !isCollapsed));
  }

  updateToggleAdditionalFieldDescriptions() {
    this.toggleAdditionalFieldsCommand.tooltip$.next(this.showAdditionalFields ? this.showLessDescription : this.showMoreDescription)
  }

  updateToggleIsCollapsedDescriptions() {
    this.toggleIsCollapsedCommand.tooltip$.next(this.computedIsCollapsed$.value ? this.openPanelDescription : this.closePanelDescription)
  }

  updateToggleAdditionalFieldStyles() {
    this.toggleAdditionalFieldsCommand.iconClass$.next(this.showAdditionalFields ? 'eye-close' : 'eye-alt')
  }

  updateToggleIsCollapsedStyles() {
    this.toggleIsCollapsedCommand.iconClass$.next(this.computedIsCollapsed$.value ? 'de-compress' : 'compress')
  }

  setAdditionalFieldVisibility(show: boolean) {
    this._showAdditionalFields = show;

    if (this.showAdditionalFields) {
      this.onExpandedAdditionalFields.emit();
    } else {
      this.onCollapsedAdditionalFields.emit();
    }

    this.cd.detectChanges();
  }

  setCollapsed(isCollapsed: boolean) {
    if (this.disableToggle) {
      return;
    }

    this._isCollapsed = isCollapsed;
    this.onChangeIsCollapsed();

    if (isCollapsed) {
      this.onCollapsed.emit();
    } else {
      this.onExpanded.emit();
    }

    this.isCollapsedChange.emit(isCollapsed);

    this.cd.detectChanges();
  }

  async toggleAdditionalFields() {
    if (!this.computedIsCollapsed$.value) {
      this.showAdditionalFields = !this.showAdditionalFields;
    }
    this.updateToggleAdditionalFieldDescriptions();
    this.updateToggleAdditionalFieldStyles();
  }

  async toggleIsCollapsedAsync() {
    this.setCollapsed(!this.computedIsCollapsed$.value);
  }
}
