import { DecodedTokenInterface } from "@nts/std/interfaces";
import { decodeJwt, decodeProtectedHeader } from "jose";

export class JwtHelper {

    static verifyHeader(token: string): boolean {
        let result = true;
        try {
            decodeProtectedHeader(token)
        } catch (err) {
            result = false;
        }
        return result;
    }

    static decodeToken(token: string = ''): DecodedTokenInterface {
        try {
            return decodeJwt(token);
        }
        catch (e) {
            throw new Error('Failed to parse the decoded payload as JSON');
        }
        
    }
}
