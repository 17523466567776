import { ClassConstructor } from '@nts/std/serialization';
import { OCCAuditDeactivableModel } from '../occ-audit-deactivable-model';
import { BaseAssociationModelInterface, IdentityInterface, ModelInterface } from '@nts/std/interfaces';

/**
 * Base class per i model che rappresentano una associazione per realizzare una relazione N:M
 */
export abstract class BaseAssociationModel<
  /**
   * Identity del model (composto dalle identity delle due estremità (N ed M)
   */
  TIdentity extends IdentityInterface,

  /**
   * Model della prima estremità della relazione (N)
   */
  TLeftExternal extends ModelInterface<TLeftExternalIdentity>,

  /**
   * Identity della prima estremità della relazione (N)
   */
  TLeftExternalIdentity extends IdentityInterface,

  /**
   * Model della seconda estremità della relazione (M)
   */
  TRightExternal extends ModelInterface<TRightExternalIdentity>,

  /**
   * Identity della seconda estremità della relazione (M)
   */
  TRightExternalIdentity extends IdentityInterface,
> extends OCCAuditDeactivableModel<TIdentity> implements BaseAssociationModelInterface {

  private internalLeftExternalRef: TLeftExternal;

  /**
   * Metodo da usare nel getter della property che rappresenta il model dell' estremità N
   *
   * @param callerName Nome della property
   * @param classType Tipo della classe della property
   * @returns Model che rappresenta l'esremità N
   */
  protected getLeftExternalRef(callerName: string, classType: ClassConstructor<TLeftExternal>): TLeftExternal {
      return this.getValueForExternal<TLeftExternal>(() => this.internalLeftExternalRef, callerName, classType);
  }

  /**
   * Metodo da utilizzare nel setter della property che rappresenta il model dell' esremità N
   *
   * @param value valore per il model dell' esremità N
   * @param callerName Nome della property
   */
  protected setLeftExternalRef(value: TLeftExternal, callerName: string): void {
      this.setValueForExternal<TLeftExternal, TLeftExternalIdentity>(() => { this.internalLeftExternalRef = value; }, value, callerName);
  }

  private internalRightExternalRef: TRightExternal;

  /**
   * Metodo da usare nel getter della property che rappresenta il model del' esremità M
   *
   * @param callerName Nome della property
   * @param classType Tipo della classe della property
   * @returns Model che rappresenta l'esremità M
   */
  protected getRightExternalRef(callerName: string, classType: ClassConstructor<TRightExternal>): TRightExternal {
    return this.getValueForExternal<TRightExternal>(() => this.internalRightExternalRef, callerName, classType);
  }

  /**
   *  Metodo da utilizzare nel setter della property che rappresenta il model dell' esremità M
   *
   * @param value valore per il model dell' esremità M
   * @param callerName Nome della property
   */
  protected setRightExternalRef(value: TRightExternal, callerName: string): void {
    this.setValueForExternal<TRightExternal, TRightExternalIdentity>(() => { this.internalRightExternalRef = value; }, value, callerName);
  }

}
