import { ClassInformationInterface } from "@nts/std/interfaces";
import { ClassAdditionalInfo, ClassInformationType } from "@nts/std/types";

export class ClassInformationUtility {

    static checkClassType(instance: ClassInformationInterface, classInformationType: ClassInformationType): boolean {
        return instance.classType === classInformationType;
    }

    static checkClassAdditionalInfo(instance: ClassInformationInterface, classAdditionalInfo: ClassAdditionalInfo): boolean {
        return instance.classAdditionalInfo === classAdditionalInfo;
    }
}
