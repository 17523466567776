import moment from 'moment';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import _Inputmask from 'inputmask';
import { MaskedPattern } from 'imask';
import { IMaskDirective, IMaskModule } from "angular-imask";
import { FormsModule } from '@angular/forms';

export interface DateMaskSettings {
    format?: string;
    showTime?: boolean
    min?: Date;
    max?: Date;
    nullable?: boolean;
}
@Component({
    selector: 'nts-base-date-mask-text-box',
    templateUrl: './base-date-mask-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./base-date-mask-text-box.component.scss'],
    standalone: true,
    imports: [
        IMaskModule,
        FormsModule
    ]
})
export class BaseDateMaskTextBoxComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() dateTabIndex?: number;
    @Input() valueDate = null;
    @Input() isDisabled = false;
    @Input() maskSettings: DateMaskSettings;
    @Input() errorList: string[] = [];
    @Input() initialChar: string | null = null;
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;
    
    @Output() onDateBlur = new EventEmitter();
    @Output() valueDateChange = new EventEmitter();
    
    @ViewChild('dateBox', { static: true }) dateBox: ElementRef;
    @ViewChild(IMaskDirective, { static: true }) iMask: IMaskDirective<any>;
    
    mask = null;
    formattedValueDate: string;
    showTime = false;
    minDate = null;
    maxDate = null;
    nullable = false;
    dateFormat: string; // DD/MM/YYYY HH:mm
    datePlaceholderChar: string = '_';
    dateSeparator: string ; // /
    keysSeparator: string[] = ['/', ':', '\\', '.', '-', ','];
    overrideBorderColor = null;
    isActive = false;
    isHover = false;

    constructor(
        private el: ElementRef,
        private zone: NgZone,
        private renderer: Renderer2,
        private cd: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        this.dateSeparator = this.getDateSeparator();
        this.initMaskSettings()
        this.handleOverridesColors();
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }
    
    onModelChange(value: string): void {
        if (value === `${this.getDateSeparator()}${this.getDateSeparator()}`) {
            this.valueDate = null;
            this.formattedValueDate = '';
            this.valueDateChange.emit(this.valueDate);
        } else {
            this.valueDate =  moment(value, this.dateFormat, true).toDate();
            if (this.isValidDate(this.valueDate)) {
                //this.valueDate = null;
                this.cd.detectChanges();
                this.valueDateChange.emit(this.valueDate);
            }
            // else if(value.match(/^[^0-9]*$/g)){
            //     this.blurLogic();
            // }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        //const previousValue = changes.valueDate?.previousValue;
        //const currentDate = changes.valueDate?.currentValue;

        if (changes['maskSettings']?.currentValue){
            this.initMaskSettings(changes['maskSettings'].currentValue);
        }
        
        if (changes['valueDate']){
            if(this.isValidDate(this.valueDate)){
                const newFormattedDate = this.getFormattedValueDate(this.valueDate);
                if(this.formattedValueDate != newFormattedDate){
                    this.formattedValueDate = newFormattedDate;
                    this.cd.detectChanges();
                }
            } else if(!this.valueDate){
                //if(this.valueDate == null && !this.nullable && this.isValidDate(this.formattedValueDate)){
                //}
                this.formattedValueDate = '';
            } else{
                //in questo caso mi sta arrivando direttamente la stringa "Invalid Date"
            }
        }

        

        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }
    }

    ngAfterViewInit(): void {        
       
        this.input.tabIndex = this.dateTabIndex ?? 0;
        
        if (this.valueDate){
            this.formattedValueDate = this.getFormattedValueDate(this.valueDate);
        }

        if (this.initialChar && this.initialChar.length > 0) {
            this.valueDate = null;
            
            setTimeout(() => {
                this.input.focus();

                if(this.isNumber(this.initialChar)){
                    let newValue = this.replaceAll(this.getMaskDatePattern(this.dateFormat), '`', ''); //'`00/`00/`0000 00:00'
                    newValue = this.replaceAll(newValue, '0', this.datePlaceholderChar)
                    this.input.value = this.initialChar + newValue.substring(1);
                    this.iMask.maskRef.updateValue()
                }

                this.input.setSelectionRange(1, 2);
                //this.input.dispatchEvent(new KeyboardEvent('keydown', {'key': this.initialChar}));
            }, 10);   
        }
    }
    
    getFormattedValueDate(date: Date): string{
        const newDate = moment(date).format(this.dateFormat);
        return this.isValidDate(newDate) ? newDate : '';
    }

    // Date
    get input(): HTMLInputElement {
        return this.dateBox.nativeElement;
    }
    
    protected initMaskSettings(maskSettings?: any): any {
        
        this.showTime = maskSettings?.showTime?? this.showTime;
        
        this.dateFormat = this.getDateFormat();
        
        this.nullable = maskSettings?.nullable ?? this.nullable;
        
        const mask = new MaskedPattern({
            mask: this.getMaskDatePattern(this.dateFormat), //'`00/`00/`0000',
            overwrite: true,
            placeholderChar: this.datePlaceholderChar           
        });        
        this.mask = { mask };
    }
   
    isValidDate(value: string|Date): boolean{
        if(value){
            const date = typeof value === 'string'? moment(value, this.dateFormat, true): moment(value);
            if(date.isValid()){
                // check min/max
                //if(date.toDate().getTime() >= this.minDate?.getTime() && date.toDate().getTime() <= this.maxDate?.getTime()) {
                    return true;
                //}
            }
        }
        return false;
    }

    getDateSeparator(): string{
        const localDate = moment().format('l');
        return (localDate.indexOf('/') > -1 ? "/" : (localDate.indexOf('-') > -1 ? '-' : '.'));
    }

    getDateFormat(){
        let  dateFormat = moment().localeData().longDateFormat('L');
        if(this.maskSettings?.format){
            dateFormat = this.maskSettings?.format
        } else {
            if (this.showTime) {
                dateFormat += ' HH:mm';
            }
        }
        return dateFormat;
    }

    getMaskDatePattern(format:string){
        const localSeparator = this.getDateSeparator();

        let pattern = format.split(localSeparator).map(x=> x = '`' + x ).join(`{${localSeparator}}`);
        
        if(this.showTime) {
            const date = pattern.split(' ')[0];
            const time = pattern.split(' ')[1];
            pattern = date + '{ }'  + time.split(":").map(x=> x = '`' + x ).join(`{:}`);
        }
        //return pattern; '`DD/`MM/`YYYY HH:mm',
        pattern = this.replaceAll(pattern, 'D', '0');
        pattern = this.replaceAll(pattern, 'M', '0');
        pattern = this.replaceAll(pattern, 'Y', '0');
        pattern = this.replaceAll(pattern, 'H', '0');
        pattern = this.replaceAll(pattern, 'm', '0');
        return pattern; //'`00/`00/`0000 00:00'
    }

    onFocus(event) {
        
        /*setTimeout(() => {
            if(!this.formattedValueDate || this.formattedValueDate == '' ){
                let newValue = this.replaceAll(this.getMaskDatePattern(this.dateFormat), '`', ''); //'`00/`00/`0000 00:00'
                this.input.value = this.replaceAll(newValue, '0', this.datePlaceholderChar)
                this.iMask.maskRef.updateValue();
            }*/
        this.mask.mask.updateOptions({lazy: false})
        //},5);

        this.isActive = true;
        this.handleOverridesColors();
    }
    
    onBlur() {
        this.blurLogic();
        this.isActive = false;
        this.handleOverridesColors();
        // this.onDateBlur.emit(event);
        this.onDateBlur.emit(this.valueDate)
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    blurLogic() {
        if(!this.nullable && !this.isValidDate(this.formattedValueDate)){
            this.mask.mask.updateOptions({lazy: true})
        }        
        if(!this.isValidDate(this.valueDate)){
            this.valueDate = this.getDateFromString(this.input.value);
            if(this.valueDate){
                this.formattedValueDate = this.getFormattedValueDate(this.valueDate);
            } else { 
                //data non valida
                this.formattedValueDate = '';
            }
        }
        
        this.cd.detectChanges();
    }

    getDateFromString(value: string): Date{
        //prova a correggere la data
        const newDateValue = this.tryFixPaddingDate(value);
        if(this.isValidDate(newDateValue)){
            return newDateValue;
        }
        return null;
    }


    onKeyDown(event) {
        switch (event?.key?.toLowerCase()) {
            case 'i':
                this.valueDate = this.getPreviousDate(this.valueDate);
                this.formattedValueDate = this.getFormattedValueDate(this.valueDate);
                this.blurLogic()
                break;
            case 'd':
                this.valueDate = this.getNextDate(this.valueDate);
                this.formattedValueDate = this.getFormattedValueDate(this.valueDate);
                this.blurLogic()
                break;
            case 'o':
                this.valueDate = this.getToday();
                this.formattedValueDate = this.getFormattedValueDate(this.valueDate);
                this.blurLogic()
                break;
            case 'enter':
            case 'tab':
                this.onBlur()
                break;
            default:
                if(this.keysSeparator.indexOf(event.key) >= 0){
                    this.paddingMaskValue(event);
                }
                break;
        }
    }

    isNumber(value){
        return  /^[0-9]$/i.test(value);
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    tryFixPaddingDate(currentValue: string): Date{
        const today = new Date();
        const posDay = this.dateFormat.toLowerCase().indexOf('dd');
        const posMonth = this.dateFormat.indexOf('MM');
        const posYear = this.dateFormat.toLowerCase().indexOf('yyyy');
        const posHour = this.dateFormat.toLowerCase().indexOf('hh');
        const posMinute = this.dateFormat.indexOf('mm');
        let newDate = currentValue;
        
        //GIORNI
        //1) __ non faccio niente
        //2) NN non faccio niente
        //3) N_ diventa 0N
        //4) _N diventa 0N
        let newDay = newDate.substring(posDay, posDay + 2); 
        if(/^([0-9]_)$/.test(newDay)){
            //N_ -> 0N
            newDay = "0" + newDay[0]; 
        }else if(/^(_[0-9])$/.test(newDay)){
            //_N -> N0
            newDay = "0" + newDay[1];
        }

        //MESE
        //1) __ non faccio niente
        //2) NN non faccio niente
        //3) N_ diventa 0N
        //4) _N diventa 0N
        let newMonth = newDate.substring(posMonth, posMonth + 2); 
        if(/^([0-9]_)$/.test(newMonth)){
            //N_ -> 0N
            newMonth = "0" + newMonth[0]; 
        }else if(/^(_[0-9])$/.test(newMonth)){
            //_N -> N0
            newMonth = "0" + newMonth[1];
        }

        /*ANNO
        1) ____ diventa anno attuale
        2) NNNN non faccio niente
        3) __NN diventa iniziali anno attuale XXNN
        4) NN__ diventa iniziali anno attuale XXNN
        5) NNN_ non gestiti
           _NNN
           N___ 
           ___N   
           N__N
           N_N_ 
           _NN_ 
           _N__ 
           _NN_
           __N_
           _N_N
        */
        //DD/MM/YYYY: cerco di correggere gli ultimi due numeri dell'anno se non compilati
        //YYYY/MM/DD: l'anno viene corretto nella keydown
        let newYear = newDate.substring(posYear, posYear + 4);
        const currentYear = today.getFullYear().toString();
        if(/^(____)$/.test(newYear)){
            newYear = currentYear;
        }else if(/^([0-9][0-9]__)$/.test(newYear)){
            newYear = currentYear.charAt(0) + currentYear.charAt(1) + newYear.substring(0, 2);
        }else if(/^(__[0-9][0-9])$/.test(newYear)){
            newYear = currentYear.charAt(0) + currentYear.charAt(1) + newYear.substring(2, 4);
        }

        //fix day
        newDate = newDate.substring(0, posDay) +  newDay + newDate.substring(posDay + 2);
        //fix month
        newDate = newDate.substring(0, posMonth) +  newMonth + newDate.substring(posMonth + 2);
        //fix year
        newDate = newDate.substring(0, posYear) +  newYear + newDate.substring(posYear + 4);

        //fix time HH:mm, se non compilato propongo ora attuale
        if(this.showTime){
            //ORE
            //1) __ diventa 00
            //2) NN non faccio niente
            //3) N_ diventa 0N
            //4) _N diventa 0N
            let newHour = newDate.substring(posHour, posHour + 2); 
            if(/^(__)$/.test(newHour)){
                newHour = "00"; 
            }else if(/^([0-9]_)$/.test(newHour)){
                //N_ -> 0N
                newHour = "0" + newHour[0]; 
            }else if(/^(_[0-9])$/.test(newHour)){
                //_N -> N0
                newHour = "0" + newHour[1];
            }

            //MINUTI
            //1) __ diventa 00
            //2) NN non faccio niente
            //3) N_ diventa 0N
            //4) _N diventa 0N
            let newMinute = newDate.substring(posMinute, posMinute + 2);
            if(/^(__)$/.test(newMinute)){
                newMinute = "00"; 
            }if(/^([0-9]_)$/.test(newMinute)){
                //N_ -> 0N
                newMinute = "0" + newMinute[0]; 
            }else if(/^(_[0-9])$/.test(newMinute)){
                //_N -> N0
                newMinute = "0" + newMinute[1];
            }
            
            //fix ore
            newDate = newDate.substring(0, posHour) +  newHour + newDate.substring(posHour + 2);
            //fix minuti
            newDate = newDate.substring(0, posMinute) +  newMinute + newDate.substring(posMinute + 2);
        }
        
        return moment(newDate, this.dateFormat, true).toDate();
    }

    paddingMaskValue(event: any){
        const cursorPos = this.input.selectionStart;//this.iMask.maskRef.selectionStart;
        const posDay = this.dateFormat.toLowerCase().indexOf('dd');
        const posMonth = this.dateFormat.toLowerCase().indexOf('mm');
        const posYear = this.dateFormat.toLowerCase().indexOf('yyyy');
        const currentValue = this.input.value;
        let newValue = null;
        let newCursorPosition = null;
        if(cursorPos == posDay + 1){
            //DD/MM/YYYY 
            //YYYY/MM/DD il giorno viene corretto nell'onBlur
            if(posDay < posYear) {
                //prima D del giorno DD
                if(currentValue[posDay + 1] == this.datePlaceholderChar) {
                    newValue = "0" + currentValue.substring(posDay, 1) + currentValue.substring(posDay + 2)
                    newCursorPosition = posDay + 2
                }
            }
        }else if(cursorPos == posMonth + 1){
            //prima M del mese
            if(currentValue[posMonth + 1] == this.datePlaceholderChar) {
                newValue = currentValue.substring(0, posMonth) + "0" + currentValue.substring(posMonth, posMonth + 1) + currentValue.substring(posMonth + 2)
                newCursorPosition = posMonth + 2
            }
        }else if(cursorPos == posYear + 1){
            //prima Y dell'anno
            
            //TODO automatismo anno inglese
            //YYYY/MM/DD: cerco di correggere gli ultimi due numeri dell'anno se non compilati
            //DD/MM/YYYY: l'anno viene corretto nella onblur            

            /*if(posDay > posYear) {
                // se mi trovo tra le 2 yyyy (quindi keypress sul secondo numero)
                if (cursorPos ==  posYear + 1) {  
                    const year = new Date().getFullYear().toString();
                    // se i primi due numeri dell'anno sono uguali all'anno attuale
                    if(currentValue[posYear] == year.charAt(0) && keyPressed.toString() == year.charAt(1) ){
                        // se gli ultimi due caratteri dell'anno non solo compilati (this.mask.mask.placeholderChar) li propongo
                        if(currentValue[posYear + 2] == this.mask.mask.placeholderChar  && currentValue[posYear + 3] == this.mask.mask.placeholderChar ) {
                            newValue = currentValue.substring(0, posYear + 1 ) + keyPressed + year.charAt(2) + year.charAt(3) + currentValue.substring(posYear + 4 )
                            newCursorPosition = posYear + 2;
                        }
                    }
                }
            }*/
        }

        if(newValue != null){
            //event.preventDefault();
            
            //setTimeout(() => {
                this.input.value = newValue;
                this.iMask.maskRef.updateValue()
                this.input.setSelectionRange(newCursorPosition, newCursorPosition + 1);
            //}, 10); 
            
        }
    }

    getPreviousDate(date: Date) {
        let previusDate = null;
        if (!date) {
            // ieri
            previusDate = moment(new Date(), this.dateFormat).startOf('day').add(-1, 'days').toDate();
        } else {
            // precedente
            previusDate = moment(date).add(-1, 'days').toDate();
            
            if (this.minDate && previusDate < this.minDate) {
                // minimo
                previusDate = this.minDate;
            }
        }
        return previusDate;
    }

    getToday(): Date {
        if(this.showTime){
            return moment(new Date(), this.dateFormat).set({hour:0,minute:0,second:0,millisecond:0}).toDate();
        } else{
            return moment(new Date(), this.dateFormat).startOf('day').toDate();
        }
    }

    getNextDate(date: Date) {
        let nextDate = null;
        if (!date) {
            // domani
            nextDate = moment(new Date(), this.dateFormat).startOf('day').add(1, 'days').toDate();
        } else {
            // successivo
            nextDate = moment(date).add(1, 'days').toDate();
            
            if (this.maxDate && nextDate > this.maxDate) {
                // massimo
                nextDate = this.maxDate;
            }
        }
        return nextDate;
    }
}
