<div class="main-container" #labelledContainer
    [class.full-width]="isFullWidth">
    <div class="label-container" [class.full-width]="isFullWidth">
        <nts-label-box
            (click)="isLabelZoomEnabled ? openZoom() : null"
            [class.zoom-label]="isLabelZoomEnabled"  
            [title]="externalPropertyViewModel.metadataDescription"
            [label]="externalPropertyViewModel.metadataShortDescription"></nts-label-box>
    </div>
    
    <div class="edit-container">
        <!-- <nts-ext-autocomplete-text-box 
            #textBox
            (keyDown)="onKeyDown($event)"
            [decodeDescription]="decodeDescription"
            [decodeProperties]="decodeProperties"
            [showCodeInDescription]="showCodeInDescription" 
            [isDisabled]="isDisabled" 
            [appendTo]="appendTo" 
            [code]="code"
            [externalPropertyViewModel]="externalPropertyViewModel" 
            [propertyViewModel]="code">
        </nts-ext-autocomplete-text-box> -->
        <nts-ext-new-autocomplete-text-box
            #textBox
            [isDisabled]="isDisabled" 
            [appendTo]="appendTo"
            [decodeProperties]="decodeProperties"
            [searchProperties]="searchProperties"
            [fieldSeparator]="fieldSeparator"
            (keyDown)="onKeyDown($event)"
            [externalPropertyViewModel]="externalPropertyViewModel"
            [tabIndex]="tabIndex"
            [scrollElementClass]="scrollElementClass"
            [updateOnBlur]="true"
            [enableOutsideClickListener]="true"    
            [showCodeInDescription]="showCodeInDescription"
            >
        </nts-ext-new-autocomplete-text-box>
    </div>
</div>