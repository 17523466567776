<div class="main-container" #labelledContainer [class.full-width]="isFullWidth">
    <div class="label-container" [class.full-width]="isFullWidth">
        <nts-label-box (click)="isLabelZoomEnabled ? openZoom() : null" [class.zoom-label]="isLabelZoomEnabled"
            [title]="description" [label]="displayName"></nts-label-box>
    </div>

    <div class="edit-container">
        <nts-ext-new-autocomplete-text-box #textBox [isDisabled]="isDisabled" [appendTo]="appendTo"
            [decodeProperties]="decodeProperties" [searchProperties]="searchProperties"
            [fieldSeparator]="fieldSeparator" [externalPropertyViewModel]="externalPropertyViewModel"
            [tabIndex]="tabIndex" [scrollElementClass]="scrollElementClass" [updateOnBlur]="true" [isSearchable]="false"
            [showCodeInDescription]="showCodeInDescription">
        </nts-ext-new-autocomplete-text-box>
    </div>
</div>