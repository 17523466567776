import { ZoomPropertyViewModel } from './zoom-property-view-model';
import { MessageContainer } from '../../message-container';
import { ModifiedSubscriberInterface } from '../../modified-subscriber.interface';
import { ExternalMetaData } from '../../../meta-data/domain-model-meta-data';
import { BigNumber } from '@nts/std/types';

export class ExternalZoomPropertyViewModel extends ZoomPropertyViewModel<{ identity: {[key:string]: string | number | BigNumber}; description?: string; isValid?: boolean; }> {

  constructor(propertyMetaData: ExternalMetaData, modifiedSubscriber: ModifiedSubscriberInterface) {
    super(propertyMetaData, modifiedSubscriber);
  }

  override removeError(item: MessageContainer) {
    throw new Error("Method not implemented.");
  }

  setValue(propertyValue: { identity: {[key:string]: string | number | BigNumber}; description?: string; isValid?: boolean; }) {
    if (propertyValue == null) {
      this.typedValue = null;
    } else {
      this.typedValue = propertyValue;
    }
  }
}
