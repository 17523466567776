import { EventEmitter } from '@angular/core';
import { LogService, UUIDHelper } from '@nts/std/utility';
import { IDatasource, IGetRowsParams, PaginationChangedEvent } from 'ag-grid-community';
import { firstValueFrom, from, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { CollectionViewModel } from './collection-view-model';
import { DatasourceCollectionViewModelInterface } from './datasource-collection-view-model.interface';
import { ItemViewModel } from './item-view-model';
import { ViewModelStates } from './states';
import { NewViewModelState } from './states/new-view-model-state';
import { UnchangedViewModelState } from './states/unchanged-view-model-state';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class DatasourceCollectionViewModel<
    TItemViewModel extends ItemViewModel<TItemModel, TItemIdentity>,
    TItemModel extends ModelInterface<TItemIdentity>,
    TItemIdentity extends IdentityInterface>
    extends CollectionViewModel<TItemViewModel, TItemModel, TItemIdentity>
    implements DatasourceCollectionViewModelInterface<TItemViewModel> {

    override onFocusRequested: Subject<void> = new Subject();
    paginationChanged = new Subject<PaginationChangedEvent>();

    currentDatasourceId: string | null = null;

    private destroyDatasourceSubscribers = new Subject<void>();

    datasourceUpdate() {
        this.currentDatasourceId = UUIDHelper.generateUUID();
        this.destroyDatasourceSubscribers.next();
        this.datasourceUpdated.next(this.currentDatasourceId);
    }

    override get pagination(): boolean {
        return true
    }

    /**
     * Viene utilizzato il replay subject per consentire anche a chi arriva tardi (massimo 1 secondo di ritardo) a sottoscriversi
     */
    datasourceUpdated: ReplaySubject<string> = new ReplaySubject(1, 1000);

    /**
     * Passa true se è il primo caricamento
     */
    paginatedCollectionItemsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Passa true se è il primo caricamento
     */
    paginatedCollectionItemsError: EventEmitter<boolean> = new EventEmitter<boolean>();
    skipItemPostInit = false;
    datasource: IDatasource = {
        rowCount: null,
        getRows: async (params: IGetRowsParams): Promise<void> => {

            const currentTake = this.getTakeParameter(params);
            this._currentTake = currentTake;
            let currentSkip = this.getSkipParameter(params);
            this._currentSkip = currentSkip;

            LogService.debug(
                'asking for ' + params.startRow + ' to ' + params.endRow
            );

            const collectionsItems: TItemModel[] = await firstValueFrom(
                from(this.getPaginatedCollectionItems(currentTake, currentSkip))
                    .pipe(
                        takeUntil(this.destroyDatasourceSubscribers)
                    ),
                { defaultValue: [] }
            );
            // collectionsItems = await this.getPaginatedCollectionItems(currentTake, currentSkip);

            // Errore durante il recupero
            if (!collectionsItems) {

                LogService.warn(
                    'Pagination API failed!'
                );
                this.paginatedCollectionItemsError.emit(params.startRow === 0);
                this.clear(false, false);
                return params.successCallback(this, 0);
            }

            // Recupero lo stato attuale
            const oldState = this.orchestratorViewModel?.currentState?.value;

            for (let i = 0; i < collectionsItems.length; i++) {
                const item = await this.createByDomainModel(collectionsItems[i], true, false, this.isMock, this.skipItemPostInit);

                if (this.length === params.startRow + i) {
                    this.addCollectionItem(item, false, true, false);
                } else {
                    this.setCollectionItem(params.startRow + i, item, false);
                }

            }

            // Ripristino lo stato precedente
            if (oldState === ViewModelStates.Unchanged) {
                this.orchestratorViewModel.currentState = new UnchangedViewModelState(this.orchestratorViewModel)
            }
            if (oldState === ViewModelStates.New) {
                this.orchestratorViewModel.currentState = new NewViewModelState(this.orchestratorViewModel)
            }

            let lastRow = -1;
            if (this.length <= (params.startRow + currentTake - 1)) {
                lastRow = this.length;
            }

            params.successCallback(this.slice(params.startRow, params.startRow + currentTake), lastRow);

            this.paginatedCollectionItemsLoaded.emit(params.startRow === 0);

            if (collectionsItems?.length >= currentTake) {
                while ((((currentSkip - params.startRow) + currentTake) < (params.endRow - params.startRow))) {
                    currentSkip = currentSkip + currentTake;

                    const result = await firstValueFrom(from(this.getPaginatedCollectionItems(currentTake, currentSkip)).pipe(takeUntil(this.destroyDatasourceSubscribers)), { defaultValue: [] })
                    if (result?.length === currentTake) {

                        for (let i = 0; i < result.length; i++) {
                            const item = await this.createByDomainModel(result[i], true, false, this.isMock, this.skipItemPostInit);

                            if (this.length === currentSkip + i) {
                                this.addCollectionItem(item, false, true, false);
                            } else {
                                this.setCollectionItem(currentSkip + i, item, false);
                            }

                        }

                        let lastRow = -1;
                        if (this.length <= (currentSkip + currentTake - 1)) {
                            lastRow = this.length;
                        }

                        params.successCallback(this.slice(params.startRow, currentSkip + currentTake), lastRow);

                        this.paginatedCollectionItemsLoaded.emit(currentSkip === 0);

                        // collectionsItems = [...collectionsItems, ...result];
                    } else {
                        if (result?.length > 0) {

                            for (let i = 0; i < result.length; i++) {
                                const item = await this.createByDomainModel(result[i], true, false, this.isMock, this.skipItemPostInit);

                                if (this.length === currentSkip + i) {
                                    this.addCollectionItem(item, false, true, false);
                                } else {
                                    this.setCollectionItem(currentSkip + i, item, false);
                                }

                            }

                            let lastRow = -1;
                            if (this.length <= (currentSkip + currentTake - 1)) {
                                lastRow = this.length;
                            }

                            params.successCallback(this.slice(params.startRow, currentSkip + currentTake), lastRow);

                            this.paginatedCollectionItemsLoaded.emit(currentSkip === 0);
                        }
                        break;
                    }
                }
            }
        }
    };

    private _currentTake: number;
    private _currentSkip: number;

    override async postInit(): Promise<void> {
        await super.postInit();
        this.datasourceUpdated.subscribe(() => {
            this.clear(false, false);
        });
    }

    private internalMaxPagination = 0;

    maxRowsForPage = null;

    get maxPagination(): number {
        return this.internalMaxPagination;
    }

    setMaxPagination(value: number) {
        this.internalMaxPagination = value;
    }

    getTakeParameter(params: IGetRowsParams): number {
        const take = params.endRow - params.startRow + 1;
        if (this.maxPagination > 0) {
            return take > this.maxPagination ? this.maxPagination : take;
        }
        return take;
    }

    getSkipParameter(params: IGetRowsParams): number {
        return params.startRow;
    }

    async getPaginatedCollectionItems(take: number, skip: number): Promise<TItemModel[] | null> {
        return [];
    }
}
