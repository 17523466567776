import moment from 'moment';
import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { BigNumber } from '@nts/std/types';
import { BehaviorSubject, takeUntil } from 'rxjs';

export abstract class BaseDateTimePropertyViewModel extends PropertyViewModel<Date>  {

    private internalShowTime = false;
    showTime$ = new BehaviorSubject<boolean>(this.internalShowTime);
    advancingCaret = true;
    
    get showTime(): boolean {
        return this.internalShowTime;
    }
    set showTime(value: boolean) {
        if (this.internalShowTime !== value) {
            this.internalShowTime = value;
            this.onPropertyChanged('showTime');
            this.showTime$.next(value);
        }
    }


    private readonly MASKDATEANDTIME: string = 'g'; // General date/time pattern(short time)
    private readonly MASKDATE_DEFAULT: string = 'd'; // d	Short date pattern
    private _editMask: string;


    get editMask(): string {
        if (this._editMask) {
            return this._editMask;
        }

        if (this.showTime) {
            return this.MASKDATEANDTIME;
        } else {
            return this.MASKDATE_DEFAULT;
        }
    }
    set editMask(value: string) {
        this._editMask = value;
    }

    setDefaultValueFromLayoutMetaData(value: string | number | BigNumber |boolean) {
        // TODO
    }
}

export class DateTimePropertyViewModel extends BaseDateTimePropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, false);
        this.setFaultBackValueFunc(() => new Date());
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        if (useDefaultValue) {
            await this.setValueAsync(new Date());
        } else {
            await this.setValueAsync(this.faultBackValue);
        }
    }

    protected getFormattedValue(value: Date) {
        if (!value) {
            return '';
        }

        if (this.value == null) { return ''; }
        let formattedValue = moment(value).format('L');
        if (this.showTime) {
            formattedValue += ' ' + moment(value).format('LT');
        }
        return formattedValue;
    }
}

export class NDateTimePropertyViewModel extends BaseDateTimePropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
        this.isNullable = true;
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        if (useDefaultValue) {
            await this.setValueAsync(new Date());
        } else {
            await this.setValueAsync(this.faultBackValue);
        }
    }

    protected getFormattedValue(value: Date) {
        if (!value) {
            return '';
        }

        if (this.value == null) { return ''; }
        let formattedValue = moment(this.value).format('L');
        if (this.showTime) {
            formattedValue += ' ' + moment(this.value).format('LT');
        }
        return formattedValue;
    }
}
