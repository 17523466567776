import { DomainModelCollection } from './domain-model-collection';
import { BaseMultiLanguageModel } from './base-multi-language-model';
import { MultiLanguageIdentityInterface } from './multi-language-identity.interface';
import { IdentityInterface } from '@nts/std/interfaces';

export abstract class MultiLanguageCollection<
    TMlModel extends BaseMultiLanguageModel<TMlIdentity>,
    TMlIdentity extends IdentityInterface & MultiLanguageIdentityInterface> extends DomainModelCollection<TMlModel, TMlIdentity> {

}
