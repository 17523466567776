import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseCellEditorComponent } from '../base_cell_editor.component';
import { ColumnCellEditorParams } from '../column_cell_editor_params.interface';
import { DateTimeOffsetPropertyViewModel } from '../../../../../src/lib/view-models/base-type/date-time-offset-property-view-model';
import { DateOffsetTextBoxComponent } from '../../../../../src/lib/components/controls/core/date-offset-text-box/date-offset-text-box.component';
import { DateTimeOffset } from '@nts/std/types';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 9;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_LEFT = 37;
const KEY_RIGHT = 39;

// create your cellEditor as a Angular component
@Component({
    selector: 'nts-date-offset-text-box-editor-cell',
    styleUrls: ['./date-offset-cell-editor.component.scss'],
    standalone: true,
    imports: [
        DateOffsetTextBoxComponent
    ],
    template: `
        <nts-date-offset-text-box 
            #textBox
            (keyDown)="onKeyDown($event)"
            (onFinishEditing)="onFinishEditing()"
            (valueChange)="valueChange($event)"
            [style.width.px]="cellWidth"
            [style.height.px]="cellHeight"
            [propertyViewModel]="datePropertyViewModel"
            [initialChar]="char"
            [initialValue]="value"
            [listenClickOutside]="true"
            [modelUpdate]="false"
            [showErrorBorder]="false">
        </nts-date-offset-text-box>
    `
})
export class DateOffsetCellEditorComponent extends BaseCellEditorComponent<DateTimeOffset> implements AfterViewInit {
    
    override textBox: DateOffsetTextBoxComponent;
    char: string;
    highlightAllOnFocus = true;    

    @ViewChild('textBox', { static: true })
    set content(content: DateOffsetTextBoxComponent) {
        this.textBox = content;
    }

    get datePropertyViewModel() {
        return this.propertyViewModel as DateTimeOffsetPropertyViewModel
    }

    get cellWidth() {
        return this.params.eGridCell.clientWidth;
    }

    get cellHeight() {
        return this.params.eGridCell.clientHeight;
    }

    private cancelBeforeStart = false;

    override ngAfterViewInit() {
        setTimeout(() => {
            if (this.textBox) {
                this.textBox.focus();
                this.textBox.input.select();
            }
        });
    }

    override agInit(params: ColumnCellEditorParams): void {
        this.params = params;
        this.setInitialState(this.params);

        // only start edit if key pressed is a number, not a letter
        this.cancelBeforeStart =
            params.eventKey && '1234567890'.indexOf(params.eventKey) < 0;
    }

    override isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    override onKeyDown(event: any): void {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (
            !this.finishedEditingPressed(event) &&
            !this.isKeyPressedNumeric(event)
        ) {
            if (event.preventDefault) { event.preventDefault(); }
        }

        if (this.isUpOrDown(event)) {
            this.navigateToNextCell(event);
        }
    }

    isPopup(): boolean {
        return false;
    }

    onFinishEditing() {
        this.params.stopEditing();
    }

    setInitialState(params: any) {
        let startValue = null;
        let charValue = null;
        let highlightAllOnFocus = true;

        if (params.keyPress === KEY_BACKSPACE || params.keyPress === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (params.eventKey) {
            // if a letter was pressed, we start with the letter
            charValue = params.eventKey;
            highlightAllOnFocus = true;
            startValue = charValue;
        } else {
            // otherwise we start with the current value
            startValue = params.columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o[i];
                    }
                    return o[i];
                }, this.params.data
            )

            if (params.keyPress === KEY_F2) {
                highlightAllOnFocus = true;
            }
        }

        this.char = charValue;
        this.value = startValue;
        this.highlightAllOnFocus = highlightAllOnFocus;
    }

    private getCharCodeFromEvent(event: any): any {
        event = event || window.event;
        return typeof event.which == 'undefined' ? event.keyCode : event.which;
    }

    private deleteOrBackspace(event: any) {
        return (
            [KEY_DELETE, KEY_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) > -1
        );
    }

    private isLeftOrRight(event: any) {
        return [KEY_LEFT, KEY_RIGHT].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private isUpOrDown(event: any) {
        return [KEY_UP, KEY_DOWN].indexOf(this.getCharCodeFromEvent(event)) > -1;
    }

    private finishedEditingPressed(event: any) {
        const charCode = this.getCharCodeFromEvent(event);
        return charCode === KEY_ENTER || charCode === KEY_TAB;
    }

    private isKeyPressedNumeric(event: any): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return this.isCharNumeric(charStr) || charStr === '/';
    }

    private isCharNumeric(charStr: string): boolean {
        return !!/\d/.test(charStr);
    }
}
