import { Expose } from "@nts/std/serialization";
import { IdentityInterface } from '@nts/std/interfaces';

export class PinIdentityToDashboardDto<TIDentity extends IdentityInterface> {

    @Expose()
    identity: TIDentity;

    @Expose()
    title: string;
}
