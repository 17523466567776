import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { ElementRef, ChangeDetectionStrategy, Component, Input, ViewChild, OnInit, OnChanges, OnDestroy, ChangeDetectorRef, SimpleChanges, Output, EventEmitter, NgZone, Renderer2, AfterViewInit } from '@angular/core';
import { BaseNumericPropertyTextBox } from '../base-numeric-property-text-box';
import { BaseNumericBoxComponent, NtsMaskedNumber, NumericMaskSettings } from '../base/base-numeric-box/base-numeric-box.component';
import { LogService, MobileHelper, PopperHelper } from '@nts/std/utility';
import { Meta } from '@angular/platform-browser';
import { UICommandInterface } from '../../../../view-models/commands/ui-command.interface';
import { AsyncPipe, NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, takeUntil } from 'rxjs';
import { PropertyViewModelPropertyChangedEventArgs } from '../../../../view-models/property-view-property-changed-event-args';
import { BigNumber } from '@nts/std/types';

@UntilDestroy()
@Component({
    selector: 'nts-numeric-text-box',
    templateUrl: './numeric-text-box.component.html',
    styleUrls: ['./numeric-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BaseNumericBoxComponent,
        NgIf,
        BaseSecurityTextBoxComponent,
        AsyncPipe
    ]
})
export class NumericTextBoxComponent extends BaseNumericPropertyTextBox<BigNumber | number> implements OnInit, OnChanges, OnChanges, AfterViewInit, OnDestroy {
    
    /**
     * Update model in pvm?
     */
    @Input() override modelUpdate = true;

    /**
     * Valore iniziale:
     * - se si passa un numero il valore iniziale sarà quel numero
     * - se si passa una stringa vuota, il campo verrà pulito
     * - se si passa null verrà utilizzato il valore del pvm
     */
    @Input() override initialValue: BigNumber | number | null | '';

    /**
     * Update model at blur
     */
    @Input() override updateOnBlur = true;

    /**
     * Update model at focus out
     */
    @Input() override updateOnFocusOut = true;

    /**
     * Is disabled?
     */
    @Input() softDisable = false;
    @Input() selectAllOnFocus = true;
    @Input() customCommandsName: string[] = null;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() listenClickOutside = false

    @Output() onFinishEditing = new EventEmitter();
    
    @ViewChild('baseNumericBox', { static: false }) baseNumericBox: BaseNumericBoxComponent<BigNumber | number>;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;    
        
    documentClickListener: any;
    maskSettings: NumericMaskSettings;
    customCommandList: UICommandInterface[] = [];

    get input(): any {
        return (this.propertyViewModel?.securityAccess == null ? this.baseNumericBox?.numericBox?.nativeElement : this.baseSecurityTextBox?.securityTextBox?.nativeElement);
    }

    get inputValue(): BigNumber | number {
        if (this.input) {

            if (this.propertyViewModel.isBigNumber) {
                if (typeof this.input.value == 'string' && this.input.value?.length > 0) {
                    return (BigNumber.parseFromLocaleString(BaseNumericBoxComponent.thousandSeparator, BaseNumericBoxComponent.decimalSeparator, this.input.value))
                } else {
                    return (this.propertyViewModel.isNullable ? null : this.propertyViewModel.faultBackValue);
                }
            }

            if (this.baseNumericBox?.mask != null) {
                const mask = new NtsMaskedNumber({
                    ...BaseNumericBoxComponent.getMaskedNumberOptions(this.baseNumericBox.maskSettings, this.baseNumericBox.numericBox) as any,
                    mapToRadix: []
                });
                mask.resolve(this.input.value);
                return mask.typedNullableValue;
            } else {
                return this.input.value;
            }      
        } else {
            return null;
        }
    }

    constructor(
        cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2,
        private meta: Meta
    ) {
        super(cd);
    }
    ngOnDestroy(): void {
        if (this.input) {
            MobileHelper.clearFixZoomIssue(this.input, this.meta);
        }        
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    override ngOnChanges(changes: SimpleChanges) {

        super.ngOnChanges(changes);

        if (changes && changes['propertyViewModel']) {

            //Non aggiungere questa riga, emit al destroyOnChange 
            //viene fatto nella classe base nel super.ngOnChanges(changes);
            //this.destroyOnChange$.next(true);
            // this.input.value = this.initialValue != null ? this.initialValue : this.propertyViewModel.value

            const customCommandNameToUse = this.customCommandsName?.length >= 0 ? this.customCommandsName : this.propertyViewModel?.customCommandsName
            this.customCommandList = customCommandNameToUse.map(
                (commandName: string)=> {
                    const uiCommand = this.propertyViewModel.customCommandList.get(commandName)
                    if (!uiCommand) {
                        LogService.warn(
                            `Comando custom pvm "${commandName}" non trovato per la property "${this.propertyViewModel?.propertyPath?.length > 0 ? (this.propertyViewModel?.propertyPath + '.') : ''}${this.propertyViewModel?.propertyName}"`);
                    }
                    return uiCommand;
                }
            ).filter((mustExists: UICommandInterface) => mustExists) || [];

            // Rimane in ascolto quando cambiano gli attributi del pvm
            this.propertyViewModel.propertyChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe((arg: PropertyViewModelPropertyChangedEventArgs) => {
                if (arg.propertyName === 'minValue') {
                    this.maskSettings = this.getMaskSettings();
                }
                if (arg.propertyName === 'maxValue') {
                    this.maskSettings = this.getMaskSettings();
                }
                this.cd.detectChanges();
            });

            this.checkValueWithMaskedLogic(this.initialValue != null ? this.initialValue : this.propertyViewModel.value);

            this.cd.detectChanges();
        }

            

        // this.input.onkeyup = (ev) => {
        //     if (ev.key === '0' && typeof this.input.selectionStart === 'number'
        //         && this.input.selectionStart === 0 && this.input.selectionEnd === this.input.value.length) {
        //         this.propertyViewModel.value = 0;
        //         setTimeout(() => {
        //             if (this.input === document.activeElement) {
        //                 this.input.setSelectionRange(
        //                     this.input.value.indexOf(this.decimalSeparator),
        //                     this.input.value.indexOf(this.decimalSeparator));
        //             }
        //         }, 10);
        //     }
        // };
    }

    // Verifica il valore esterno con quello presente nel mask
    // Se sono differenti il pvm viene aggiornato con quello del mask
    checkValueWithMaskedLogic(valueTocheck: any) {
        const maskedValue = BaseNumericBoxComponent.getMaskedValue(this.getMaskSettings(), valueTocheck);
        if (maskedValue?.toString() !== valueTocheck?.toString()) {
            this.propertyViewModel.value = maskedValue;
        }
    }

    ngOnInit() {

        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }

        this.maskSettings = this.getMaskSettings();
    }

    ngAfterViewInit(): void {
        super.init();

        if (this.propertyViewModel.minValue != null) {
            this.input.min = this.propertyViewModel.minValue.toString();
        }

        if (this.propertyViewModel.maxValue != null) {
            this.input.max = this.propertyViewModel.maxValue.toString();
        }

        MobileHelper.fixZoomIssue(this.input, this.meta);
    }

    selectAllContent($event: FocusEvent) {
        if (this.selectAllOnFocus) {
            setTimeout(() => {
                const tgt = ($event.target as HTMLInputElement);
                if (tgt === document.activeElement) {
                    ($event.target as HTMLInputElement).select();
                }
            }, 50);
        }
    }

    showErroTooltip(): void {
        if (this.baseNumericBox?.popperError) {
            PopperHelper.show(this.baseNumericBox?.popperError)
        }        
    }
}
