import { Expose } from "@nts/std/serialization";
import { AccessMode } from './access-mode.enum';
import { UserMetaDataInterface } from "@nts/std/interfaces";

export class UserMetaData implements UserMetaDataInterface {

    @Expose()
    securityAccess?: AccessMode;

}
