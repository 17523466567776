import { Observable } from 'rxjs';
import { SingleAggregateIdentity } from '../domain-models';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { GetByIdentityCachedResponse } from '../responses/get-by-identity-cached-response';
import { GetByIdentityResponse } from '../responses/get-by-identity-response';
import { CrudApiClient } from './crud/crud-api-client';
import { SingleAggregateApiClientInterface } from './single-aggregate-api-client.interface';
import { CacheOptionsInterface } from './web-api-service-agent';
import { ModelInterface } from '@nts/std/interfaces';

export class SingleAggregateApiClient<TModel extends ModelInterface<TIdentity>, TIdentity extends SingleAggregateIdentity>
    extends CrudApiClient<TModel, TIdentity> implements SingleAggregateApiClientInterface {

        getSingleAggregateCached(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityCachedResponse<TModel, TIdentity>> {

            // Default for getSingleAggregateCached
            cacheOptions = {
                bypass: false,                         
                expirationTime: undefined,                  
                force: false,
                enterpriseBarrier: true,
                tenantBarrier: true,
                userBarrier: true,
                ...cacheOptions
            };

            const identity = new SingleAggregateIdentity() as TIdentity;
            const request = new GetByIdentityRequest<TIdentity>(identity);
            return this.getByIdentityCached(
                request,
                cacheOptions
            );
        }

        getSingleAggregate(
            cacheOptions: CacheOptionsInterface = {
                bypass: false,
                expirationTime: undefined,
                force: false,
            },
        ): Observable<GetByIdentityResponse<TModel, TIdentity>> {
            const identity = new SingleAggregateIdentity() as TIdentity;
            const request = new GetByIdentityRequest<TIdentity>(identity);
            return this.getByIdentity(
                request,
                cacheOptions
            );
        }
}
