import { RootViewModel } from '../root-view-model';
import { ExternalViewModelTypeDecorator } from '../decorators/external-view-model-type.decorator';
import { CollectionViewModelTypeDecorator } from '../decorators/collection-view-model-type.decorator';
import { ClassConstructor } from '@nts/std/serialization';
import { NmRootViewModelTypeInspector } from '../decorators/nm/nm-root-view-model-type.decorator';
import { NmItemsViewModelTypeInspector } from '../decorators/nm/nm-items-view-model-type.decorator';
import { ItemViewModel } from '../item-view-model';
import { ExternalViewModel } from '../external-view-model';
import { CollectionViewModel } from '../collection-view-model';
import { takeUntil } from 'rxjs';
import { DomainModelCollection, NmOCCModel } from '../../domain-models';
import { MetaDataUtils } from '../../meta-data/meta-data-utils';
import { ViewModelStates } from '../states/view-model-states';
import { IdentityInterface, ModelInterface } from '@nts/std/interfaces';

export class NmRootViewModel<
    TModel extends NmOCCModel<TIdentity, TRoot, TItems, TAssociation, TAssociationIdentity>, 
    TIdentity extends IdentityInterface, 
    TRoot extends ModelInterface<TIdentity>,
    TAssociationIdentity extends IdentityInterface,
    TAssociation extends ModelInterface<TAssociationIdentity>,
    TItems extends DomainModelCollection<TAssociation, TAssociationIdentity>,
    TRootViewModel extends ExternalViewModel<TRoot, TIdentity>, 
    TItemViewModel extends ItemViewModel<TAssociation, TAssociationIdentity>,
    TItemsViewModel extends CollectionViewModel<TItemViewModel, TAssociation, TAssociationIdentity>> 
extends RootViewModel<TModel, TIdentity> {

    private _rootViewModelType: ClassConstructor<TRootViewModel>;

    private _itemsViewModelType: ClassConstructor<TItemsViewModel>;

    @ExternalViewModelTypeDecorator((target: any, propertyKey: string) => {
        return target.rootType;
    })
    root: TRootViewModel;

    @CollectionViewModelTypeDecorator((target: any, propertyKey: string) => {
        return target.itemsType;
    })
    items: TItemsViewModel;

    get rootType(): ClassConstructor<TRootViewModel> {
        return this._rootViewModelType;
    }

    get itemsType(): ClassConstructor<TItemsViewModel> {
        return this._itemsViewModelType;
    }    

    constructor() {

        super();

        this._rootViewModelType = NmRootViewModelTypeInspector.getValue(this);
        if (this._rootViewModelType === undefined) {
            throw new Error(
                `MetaData ${NmRootViewModelTypeInspector.META_DATA_KEY} not defined. You must use ${NmRootViewModelTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._itemsViewModelType = NmItemsViewModelTypeInspector.getValue(this);
        if (this._itemsViewModelType === undefined) {
            throw new Error(
                `MetaData ${NmItemsViewModelTypeInspector.META_DATA_KEY} not defined. You must use ${NmItemsViewModelTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

    }

    override async postInit(): Promise<void> {
        
        this.root.isEnabled = this.currentState == ViewModelStates.New;
        
        this.root.externalDomainModelChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe(() => {
            const discriminatorPropertiesName = this.getDomainModel().discriminatorPropertiesName[0];
            const discriminatorPvm = this.getProperty(MetaDataUtils.toCamelCase(discriminatorPropertiesName));
            
            let collectionRefPropertyName;
            const internalCollectionMetaData = this.aggregateMetaData.rootMetaData.internalCollections.find(x=>x.principalPropertyName == 'Items');
            for (let externalMetaData of internalCollectionMetaData.dependentMetaData.externals) {
                const association = externalMetaData.associationProperties.find(x => x.principalPropertyName == discriminatorPropertiesName);
                if (association) {
                    collectionRefPropertyName = MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName);
                    break;
                }
            }
            this.items.forEach((i) => {
                const extVm = i[collectionRefPropertyName] as TRootViewModel;
                extVm.setCodeValue(discriminatorPvm.value);
            })
        })
    }

}
